<template>
    <svg xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 800 528"
        role="presentation"
    >
        <g id="departement_bzh" class=default-features>
            <path d="M 279.4 63.22 281.33 77.84 294.05 88.77 300.14 98.45 290.25 111.68 292.94 131.73 289.55 143.74 300 146.91 299.56 159.43 307.12 175.2 299.37 191.1 297.98 203.75 272.17 213.65 265.02 226.38 272.33 230.98 283.13 262.87 299.86 260.56 313.74 268.27 326.53 267.41 331.9 271.93 329.77 296.71 320.76 299.13 313.04 310.34 306.22 302.87 302.56 310.61 304.39 326.59 275.13 324.09 264.94 316.43 251.54 320.91 231.47 306.74 229.69 296.66 221.67 289.41 216.77 301.5 204.07 303.79 196.95 297.77 180.04 302.86 174.38 318.06 159.23 319.93 140.87 318.54 143.36 308.26 140.84 291.09 134.42 277.82 122.92 263.89 106.51 254.85 101.02 258.57 85 251.66 69.84 248.63 80.64 237.85 87.58 238.6 105.89 232.64 116.02 233.05 138.51 226.06 151.79 232.25 158.74 219.31 152.95 202.06 123.95 189 115.93 188.83 105.86 205.24 101.16 190.01 90.18 180.63 100.65 174 113.26 175.62 128.79 172.42 136.53 177.31 151.59 170.79 154.95 157.53 140.07 156.68 130.26 162.75 125.25 154.07 128.56 141.03 92.36 158.73 78.21 153.48 72.88 160.4 60.14 161.31 62.38 148.4 55.99 135.5 62.74 119.42 59.51 113.29 68.35 93.86 88.05 87.5 101.66 73.77 121.11 72.79 128.13 65.29 143.04 58.17 158.53 66.09 166.29 65.34 175.44 55.09 187.24 52.56 200.92 54.25 205.29 46.89 217.04 51.34 222.21 65.11 232.41 67.05 245.28 47.48 264.8 48.83 279.05 52.82 279.4 63.22 Z" id="29"/>
            <path d="M 279.4 63.22 294.74 59.99 294.04 45.93 301.02 37.41 294 28.5 303.85 20.36 308.15 10.7 324.39 22 330.93 20.95 346.32 9.73 351.74 11.62 363.59 1 370.2 13.29 376.16 5.27 386.79 1.2 398.92 16.57 403.45 28.37 420.34 33.63 419.5 42.92 443.33 65.79 444.77 82.79 471.96 101.36 481.87 99.28 494.2 87.25 512.96 74.15 544.59 56.74 555.18 67.47 562.1 82.24 573.86 87.15 581.84 79.55 591.25 90.49 604.85 90.95 616.45 99.08 623.45 105.02 615.11 164.89 603.44 176.01 595.72 170.45 584.52 183.89 569.38 185.82 563.42 195.65 561.94 208.22 549.63 219.1 533.06 222.54 525.67 208.81 508.74 211.99 492.99 237.79 481.88 248.08 474.23 244.42 477.73 223.36 467.58 225.26 452.19 222.99 446.58 215.32 438.63 216.91 418.36 208.15 409.24 210.85 402.63 199.72 386.31 197.66 378.66 211.8 371.31 210.05 355.61 217.25 342.9 208.07 327.1 215.52 326.21 207.84 314.78 203.66 297.98 203.75 299.37 191.1 307.12 175.2 299.56 159.43 300 146.91 289.55 143.74 292.94 131.73 290.25 111.68 300.14 98.45 294.05 88.77 281.33 77.84 279.4 63.22 Z" id="22"/>
            <path d="M 385.35 456.41 396.76 458.84 392.19 468.12 374.52 463.1 366.45 465.1 357.32 448.02 360.23 440.08 378.54 445.46 385.35 456.41 Z M 306.22 302.87 313.04 310.34 320.76 299.13 329.77 296.71 331.9 271.93 326.53 267.41 313.74 268.27 299.86 260.56 283.13 262.87 272.33 230.98 265.02 226.38 272.17 213.65 297.98 203.75 314.78 203.66 326.21 207.84 327.1 215.52 342.9 208.07 355.61 217.25 371.31 210.05 378.66 211.8 386.31 197.66 402.63 199.72 409.24 210.85 418.36 208.15 438.63 216.91 446.58 215.32 452.19 222.99 467.58 225.26 477.73 223.36 474.23 244.42 481.88 248.08 492.99 237.79 508.74 211.99 525.67 208.81 533.06 222.54 549.63 219.1 560.27 236.25 551.14 250.76 559.02 258.68 579.25 264.67 590.63 282.2 583.3 293.53 596.46 312.32 588.54 335.29 580.32 340.07 583.69 361.36 587.1 366.66 589.09 375.01 585.07 395.95 558.09 406.87 547.29 404.87 544.59 415.36 527.43 417.83 519.3 406.1 500.41 397.52 496.66 401.26 475.35 399.57 448.59 408.72 438.89 405.38 434.54 395.62 445.95 389.55 462.31 392.61 467.94 378.77 452.85 370.23 438.56 370.27 426.04 378.43 416.98 371.55 414.13 382.81 384.96 384.73 367.92 363.93 368.24 357.28 355.88 352.77 352.53 346.3 341.43 344.14 320.05 347.95 310.71 337.37 304.85 322.43 306.22 302.87 Z" id="56"/>
            <path d="M 604.85 90.95 591.25 90.49 581.84 79.55 579.92 69.81 596.62 69.84 607.26 87.29 604.85 90.95 Z M 587.1 366.66 583.69 361.36 580.32 340.07 588.54 335.29 596.46 312.32 583.3 293.53 590.63 282.2 579.25 264.67 559.02 258.68 551.14 250.76 560.27 236.25 549.63 219.1 561.94 208.22 563.42 195.65 569.38 185.82 584.52 183.89 595.72 170.45 603.44 176.01 615.11 164.89 623.45 105.02 616.45 99.08 604.05 79.45 600.51 66.88 612.15 55.13 634.55 49.6 638.29 57.27 631.49 66.74 636.73 75.99 652.67 79.8 672.97 77.66 690.7 72.98 700.97 90.81 698.14 96.34 706.68 113.78 718.66 121.75 728.88 123.01 749.56 100.53 762.5 98.07 774.22 105.42 789.4 108.16 790.3 124.97 786.88 130.5 792.56 145.07 794.32 161.74 787.02 170.43 783.48 182.01 791.31 214.27 793.42 232.07 798.6 238.23 799 260.02 782.96 261.74 772.86 268.59 765.92 297.34 757.35 311.35 754.75 324.11 733.68 317.96 726.28 308.99 699.17 321.44 686.3 324.31 674.2 342.9 651.04 347.04 633 344.54 612.31 349.99 591.49 360.72 587.1 366.66 Z" id="35"/>
        </g>
    </svg>
</template>

<script>
    export default {
        props: {
            departementsId: {
                default: null
            },
            width: {
                type: [Number, String],
                default: 20
            },
            height: {
                type: [Number, String],
                default: 20
            },
        },
        methods: {
            // Style features
            styleDepartement (departementsId) {
                // Clean previous selected features
                const featuresSelected = this.$el.getElementsByClassName("selected-features");
                if (featuresSelected.length > 0) {
                    featuresSelected.forEach(feature => {
                        feature.classList.remove("selected-features")
                    });
                };
                // Style selected features
                if(departementsId) {
                    this.$el.getElementById(departementsId.toString())
                            .classList.add("selected-features");
                }
            }
        },
        mounted () {
            this.styleDepartement(this.departementsId);
        },
        watch: {
            departementsId () {
                this.styleDepartement(this.departementsId);
            }
        }   
    }
</script>

<style scoped>
    .default-features {
        fill: #F7CF0F;
        stroke: #F7CF0F;
        stroke-width: 1px;
    }
    .selected-features {
        fill: #FF380D;
        stroke: #FF380D;
    }
</style>