<template>
    <svg xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 800 528"
        role="presentation"
    >
    
        <g id="commubes_bzh" class=default-features>
            <path d="M 165.63 101.45 168.64 105.2 169.72 108.65 168.46 108.51 163.43 110.13 163.67 106.98 164.83 101.91 165.63 101.45 Z" id="29288"/>
            <path d="M 160.13 278.08 164.54 277.83 165.14 275.75 168.57 275.28 172.14 276.04 173.53 279.91 172.72 281.1 170.91 281.28 169.53 283.92 166.01 285.47 161.82 286.55 161.57 287.94 159.78 286.7 156.03 287.46 155.89 283.87 157.73 282.45 158.16 279.9 160.13 278.08 Z" id="29159"/>
            <path d="M 466.19 176.45 466.85 177.29 467.22 182.16 464.74 182.46 465.15 184.35 460.88 189.25 459.32 189.27 457.33 188.72 456.26 186.97 459.34 183.33 455.23 179.25 458.03 178.42 457.96 175.36 459.38 173.82 461.51 174.63 464.16 174.35 466.19 176.45 Z" id="22060"/>
            <path d="M 460.88 189.25 465.15 184.35 464.74 182.46 467.22 182.16 466.85 177.29 466.19 176.45 468.44 174.44 469.7 175.63 472.7 175.91 473.3 177.44 476.72 176.11 478.88 174.51 481.62 176.01 483.45 175.89 486.2 177.04 486.85 182.94 488.32 185.24 485.37 185.51 483.71 187.57 484.34 194.24 481.05 195.23 475.38 193.48 473.25 194.95 471.06 193.87 469.53 194.93 467.61 193.48 467.84 191.92 462.83 192.07 460.88 189.25 Z" id="22219"/>
            <path d="M 749.76 215.04 751.23 216.91 748.61 222.43 742.59 222.52 742.42 221.67 746.05 217.11 748.65 216.52 749.76 215.04 Z" id="35141"/>
            <path d="M 425.78 252.01 427.34 246.25 430.89 243.7 425.83 240.29 427.47 239.98 427.12 236.88 430.03 235.36 431.05 237.82 432.87 237.53 433.12 240.19 434.97 241.11 439.84 239.8 440.51 238.06 442.63 238.22 443.86 236.7 444.46 243.47 446.25 243.69 450.37 240.69 451.5 244.05 451.29 246.53 448.59 245.98 447.35 248.38 445.26 249.59 443.84 248.93 440.55 251.28 440.03 253.37 441.43 257.58 439.13 257.67 438.07 259.47 435.95 259.06 435.87 263.36 434.7 265.29 432.61 265.19 431.83 263.93 433.08 260.22 430.61 258.26 429.63 255.14 425.78 252.01 Z" id="56151"/>
            <path d="M 599.55 253 601.34 252.76 605.84 252.12 608.31 250.44 609.41 251.25 609.27 253.84 611.54 254.35 615.59 252.77 616.03 256.13 613.95 256.76 612.54 258.63 612.19 260.82 609.31 261.48 606.06 263.36 602.24 262.84 601.4 256.59 599.38 255.39 599.55 253 Z" id="35340"/>
            <path d="M 324.38 282.41 326.52 285.27 326.43 288.16 327.93 289.48 325.99 290.28 326.58 293.02 325.84 294.16 322.27 293.75 319.92 294.38 316.68 290.67 315.32 288.34 315.17 285.42 316.9 281.97 323.47 283.03 324.38 282.41 Z" id="29136"/>
            <path d="M 131.64 250.8 134.52 248.34 134.03 246.83 135.19 244.02 136.75 243.01 139.93 242.32 140.76 250.14 144.08 251.3 144.16 253.34 144.13 255.26 141.93 255.4 138.64 254.09 136.23 253.96 131.08 255 131.64 250.8 Z" id="29145"/>
            <path d="M 752.25 277.92 752.52 276.19 758.19 272.25 758.12 269.77 761.24 269.16 763.32 265.73 765.44 265.76 767.14 267.92 767.63 270.43 770.69 270.08 772.61 271.53 771.01 273.8 768.52 274.99 766.6 273.21 764.28 276.89 759.34 278.75 758.86 280.33 756.94 280.11 756.79 277.84 754.67 277.36 752.25 277.92 Z" id="35200"/>
            <path d="M 317.32 141.71 319.25 143.62 324.92 142.61 325.94 143.58 327.56 148.23 330.8 149.86 331.16 151.81 329.16 153.1 327.99 155.21 325.23 155.54 323.19 157.23 322.79 160.05 320.55 158.57 319.27 156.08 317.06 155.28 314.18 152.43 313.65 151.75 314.1 147.23 315.92 143.37 317.32 141.71 Z" id="22243"/>
            <path d="M 626.66 163.96 628.07 160.4 629.17 164.67 631.26 167.48 630.04 169.82 627.69 170.01 626.86 167.48 626.66 163.96 Z" id="35346"/>
            <path d="M 656.07 134.65 661.4 134.21 662.3 135.99 664.87 135.93 664.83 139.74 663.5 140 660.84 142.65 659.64 139.38 657.82 140.14 655.12 136.81 656.07 134.65 Z" id="35159"/>
            <path d="M 240.94 237.69 242.3 236.71 241.54 231.76 246.55 229.1 248.6 229.19 250.24 230.77 252.27 229.95 253.58 236.27 250.79 237.22 249.05 238.85 248.71 240.56 246.84 242.66 245.32 241.08 241.32 242.19 240.94 237.69 Z" id="29291"/>
            <path d="M 239.35 229.51 242.32 228.51 243.03 226.85 242.65 224.06 244.66 220.18 247.5 217.87 252.37 219.91 256.11 220.16 255.51 222.2 256.99 224.42 260.22 223.69 261.13 226.69 265.63 226.51 267.53 229.97 265.61 230.75 262.58 230.63 258.96 233.67 256.64 234.15 253.61 236.27 253.58 236.27 252.27 229.95 250.24 230.77 248.6 229.19 246.55 229.1 241.54 231.76 239.99 231.45 239.35 229.51 Z" id="29122"/>
            <path d="M 561.84 131.34 562.57 127.81 566.77 128.18 570.84 130.83 571.32 131.85 568.96 133.7 562.2 136.37 561.14 136.15 562.64 133.82 561.84 131.34 Z" id="22318"/>
            <path d="M 366.3 209.57 367.87 210.18 370.02 205.9 373.06 206.23 375.78 202.8 378.87 200.52 381.07 200.97 383.84 202.29 384.81 204.53 387.59 203.89 387.96 205.11 385.91 207.33 383.07 208.83 381.8 210.89 379.15 211.42 375.16 214.13 370.79 215.04 370.7 216.54 365.96 218.02 365.75 215.21 366.83 213.09 366.3 209.57 Z" id="22181"/>
            <path d="M 627.23 303.62 627.79 301.68 629.58 300.31 629.57 298.37 631.28 299.02 633.75 298.48 632.78 300.55 632.49 304.32 629.61 307.62 627.29 305.13 627.23 303.62 Z" id="35155"/>
            <path d="M 342.78 133.54 346.32 132.11 348.83 131.93 352.08 130.5 352.63 132.8 354.43 132.83 356.32 134.82 359.22 134.54 359.95 139.79 358.27 142.67 357.21 142.61 355.28 145.62 354.05 146.03 351.44 150.72 349.71 150.94 347.97 147.25 345.35 145.72 343.55 143.64 341.29 144.3 343.69 140.7 344.5 136.79 345.49 135.97 342.78 133.54 Z" id="22023"/>
            <path d="M 417.31 343.31 422.24 341.4 423.97 338.46 426.23 339.28 428.18 337.68 430.35 338.88 432.8 338.01 432.31 334 435.98 335.49 438.3 335.48 440.02 337.2 438.57 341.95 439.84 345.5 436.75 347.3 436.9 348.79 439.04 350.09 437.87 356.07 434.98 353.49 429.47 351.72 425.15 351.03 422.96 349.04 420.54 348.99 417.44 349.65 416.8 347.67 417.31 343.31 Z" id="56175"/>
            <path d="M 548.03 288.49 549.49 286.7 549.98 284.36 556.3 283.16 561.81 282.68 565.05 285.07 567.55 282.89 568.54 286.78 566.14 288.55 565.31 294.09 563.33 295.71 562.26 299.91 560.77 300.76 556.55 300.21 552.84 299.39 553.87 297.62 549.06 297.79 548.57 296.06 544.71 296.05 544.84 294.26 547.48 292.39 548.03 288.49 Z" id="56006"/>
            <path d="M 533.34 306.8 535.24 307.45 539.12 304.57 540.41 304.77 542.5 300.56 543.85 299.09 544.71 296.05 548.57 296.06 549.06 297.79 553.87 297.62 552.84 299.39 556.55 300.21 556.6 303.29 553.88 306.15 554.29 310.31 546.29 312.69 539.99 312.11 535.86 313.84 532.71 314.48 531.49 313.36 533.45 309.89 533.34 306.8 Z" id="56035"/>
            <path d="M 635.55 140.76 636.95 137.07 639.23 137.1 640.68 139.26 642.84 138.83 643.66 137.14 645 131.14 646.54 129.19 649.43 130.63 647.74 136.01 647.99 137.87 646.28 141.89 647.47 143.65 647.06 145.84 650.56 147.07 649.81 150.3 650.96 153.39 649.52 153.95 648.06 153.19 646.14 154.13 642.67 152.01 641.04 153.2 638.45 153.6 635.88 150.89 638.42 148.15 638.18 145.65 634.65 145.86 633.58 141.94 635.55 140.76 Z" id="35172"/>
            <path d="M 623.36 154.74 625.5 151.58 625.01 149.29 625.35 146.22 624.78 143.74 625.16 140.09 627.85 140.75 635.55 140.76 633.58 141.94 634.65 145.86 638.18 145.65 638.42 148.15 635.88 150.89 638.45 153.6 637.88 154.14 636.45 156.64 634.36 155.13 631.38 155.34 625.11 155.88 623.36 154.74 Z" id="35226"/>
            <path d="M 589.84 150.07 590.99 148.3 593.32 146.84 594.22 148 593.42 150.21 596.31 151.69 595.36 153.18 592.96 152.7 591.85 153.69 590.02 151.73 589.84 150.07 Z" id="22082"/>
            <path d="M 527.96 150.28 529.09 147.32 533.05 146.02 537.24 146.59 537.96 150.11 539.49 152.54 532.2 153.92 529.43 152.71 527.96 150.28 Z" id="22341"/>
            <path d="M 574.25 202.99 581.72 202.07 584.31 203.12 583.23 204.78 582.26 208.85 577.68 210.21 577.62 208.82 579.27 206.24 577.1 205.27 574.83 205.57 574.25 202.99 Z" id="35091"/>
            <path d="M 265.38 190.5 267.45 188.09 269.72 184.37 275.59 184.48 278.26 183.68 280 184.16 281.27 186.15 280.61 189.29 280.21 190.69 278.03 192.05 277.93 193.89 279.97 194.67 279.3 197.72 277.33 196.6 275.82 198.19 276.26 201.54 279.14 202.7 277.3 203.71 275.2 202.27 272.24 203.48 270.18 203.08 269.57 199.45 268.52 198.87 266.6 194.9 265.38 190.5 Z" id="29102"/>
            <path d="M 262.2 173.03 265.67 174.87 269.58 178.33 272.74 177.35 274.9 177.79 275.33 179.96 277.53 181.07 278.26 183.68 275.59 184.48 269.72 184.37 267.45 188.09 265.38 190.5 260.86 190.28 261.2 186.06 257.89 181.71 256.63 175.72 262.2 173.03 Z" id="29036"/>
            <path d="M 328.41 181.25 329.41 179.09 329.77 174.99 328.82 172.65 330.07 172.38 337.65 175.2 339.92 174.53 342.63 175.62 344.81 177.81 343.98 179.25 344.6 185.67 344.06 189.43 340.41 189.33 339.34 191.44 336.3 189.75 334.48 190.9 330.28 190.4 329.04 187.48 326.2 187.81 324.46 184.93 328.32 184.02 328.41 181.25 Z" id="22137"/>
            <path d="M 620.38 289.73 622.09 292.35 622.57 294.76 625.1 296.31 626.51 294.86 628.8 297.75 625.94 299.13 622.72 298.77 621.96 299.58 616.89 299.17 610.79 297.1 608.32 293.25 611.97 293.21 614.3 289.95 618.09 290.62 620.38 289.73 Z" id="35175"/>
            <path d="M 562.26 299.91 563.33 295.71 565.31 294.09 566.51 295.21 574.37 293.48 575.45 297.52 579.24 299.28 580.87 300.97 580.96 303.45 578.38 308.14 573.94 307.55 569.86 307.56 568.16 308.37 566.76 306.32 564.13 304.52 562.46 301.98 562.26 299.91 Z" id="56136"/>
            <path d="M 619 286.35 618.4 283 620.32 280.78 621.88 276.66 623.9 274.46 625.26 273.5 628.08 274.84 626.69 276.21 628.41 283.63 626.63 285.35 627.05 288.5 625.21 289.65 625.4 292.44 626.51 294.86 625.1 296.31 622.57 294.76 622.09 292.35 620.38 289.73 619 286.35 Z" id="35057"/>
            <path d="M 764.06 119.26 768.87 120.11 768.78 122.2 770.15 123.81 771.71 125.52 768.63 130.75 767.31 131.78 763.45 131.25 760.34 127.48 758.66 125.88 759.37 122.96 764.06 119.26 Z" id="35174"/>
            <path d="M 604.12 141.66 605.91 141.41 605.72 143.14 607.22 147.33 606.79 150.44 605.76 152.26 604.04 152.41 603.55 154.2 601.7 155 600.53 153.07 600.41 148.58 601.7 145.25 603.34 144.3 604.12 141.66 Z" id="22026"/>
            <path d="M 333.92 115.86 337.59 115.21 338.5 118.15 337.28 121.09 333.91 120.38 333.19 118.6 333.92 115.86 Z" id="22129"/>
            <path d="M 339.69 99.56 341.4 95.42 341.29 91.34 342.51 87.96 349.44 86.8 351.62 89.63 351.58 90.81 351.5 92.33 353.64 94.34 352.36 95.86 355.47 97.97 355.76 101.91 357.81 103.3 359.49 107.25 360.72 112.31 357.79 114.6 353.93 115.27 351.59 117.99 350.2 116.89 346.96 117.39 345.76 113.15 342.26 109.15 342.63 107.48 339.69 99.56 Z" id="22135"/>
            <path d="M 352.67 62.78 352.97 60.84 355.32 59.75 358.01 60.82 358.38 64.76 356.22 66 353.78 64.71 352.67 62.78 Z" id="22006"/>
            <path d="M 331.69 96.44 331.78 94.62 335.18 90.92 336.08 85.41 337.56 83.53 341.69 85.98 342.51 87.96 341.29 91.34 341.4 95.42 339.69 99.56 336.75 101.31 333.44 99.65 331.69 96.44 Z" id="22359"/>
            <path d="M 322.47 127.26 323.24 123.91 327.9 124.62 331.29 126.16 335.02 127.08 338.02 128.52 338.08 132.76 341.68 133.31 340.34 135.39 337.36 137.55 332.21 135.92 329.93 134.3 325.99 133.62 324.08 134.25 319.03 133.31 320.11 130.07 322.47 128.93 322.47 127.26 Z" id="22037"/>
            <path d="M 366.96 63.76 369.13 64.82 372.4 62.79 373.65 62.36 375.87 64.18 373.37 65 374.31 67.83 371.36 70.75 369.92 69.47 367.84 66.73 366.96 63.76 Z" id="22269"/>
            <path d="M 311.77 108.11 315.45 105.55 316.21 106.54 319.46 106.54 324.83 109.87 325.12 112.17 326.66 113.95 330.4 115.3 333.92 115.86 333.19 118.6 333.91 120.38 332.52 120.26 327.9 124.62 323.24 123.91 322.47 127.26 320.03 126.08 315.2 127.31 312.41 129.74 310.01 127.86 311.71 124.29 310.9 122.25 312.76 118.81 310.89 115.6 313.47 113.55 311.51 109.57 311.77 108.11 Z" id="22131"/>
            <path d="M 341.97 44.99 344 43.65 346.83 44.8 348.74 46.07 348.91 47.63 343.85 48.84 341.97 44.99 Z" id="22381"/>
            <path d="M 355.32 59.75 357.39 57.31 363.03 58 362.66 59.36 358.01 60.82 355.32 59.75 Z" id="22141"/>
            <path d="M 336.75 101.31 339.69 99.56 342.63 107.48 342.26 109.15 345.76 113.15 346.96 117.39 345.79 119.42 343.14 118.59 340.74 119.19 338.5 118.15 337.59 115.21 339.33 110.13 338.05 107.5 338.54 102.97 336.75 101.31 Z" id="22005"/>
            <path d="M 348.33 40.21 349.64 40.84 352.77 39.33 354.9 40.33 356.6 42.83 358.8 43.33 358.21 44.65 357.05 46.08 351.15 47.55 348.91 47.63 348.74 46.07 346.83 44.8 346.55 42.74 348.33 40.21 Z" id="22042"/>
            <path d="M 342.17 35.35 345.31 37.46 347.68 37.17 348.33 40.21 346.55 42.74 346.83 44.8 344 43.65 341.97 44.99 339.32 44.01 337.18 44.28 339.35 39.13 342.02 37 342.17 35.35 Z" id="22090"/>
            <path d="M 337.38 45.92 337.18 44.28 339.32 44.01 341.97 44.99 343.85 48.84 342.98 49.36 342.81 53.29 345.72 53.88 345.88 56.82 345.28 57.86 340.27 58.14 338.42 56.1 335.31 54.45 335.45 51.49 337.83 49.37 337.38 45.92 Z" id="22265"/>
            <path d="M 343.85 48.84 348.91 47.63 351.15 47.55 349.83 49.74 347.51 51.3 345.72 53.88 342.81 53.29 342.98 49.36 343.85 48.84 Z" id="22110"/>
            <path d="M 327.9 124.62 332.52 120.26 333.91 120.38 337.28 121.09 338.5 118.15 340.74 119.19 343.14 118.59 345.79 119.42 346.96 117.39 350.2 116.89 351.59 117.99 351.89 120.19 350.87 123.41 351.95 123.95 353.28 129.37 352.08 130.5 348.83 131.93 346.32 132.11 342.78 133.54 341.68 133.31 338.08 132.76 338.02 128.52 335.02 127.08 331.29 126.16 327.9 124.62 Z" id="22216"/>
            <path d="M 317.35 98.76 319.94 97.96 322.53 99.07 325.42 96.97 330.06 97.34 331.69 96.44 333.44 99.65 336.75 101.31 338.54 102.97 338.05 107.5 339.33 110.13 337.59 115.21 333.92 115.86 330.4 115.3 326.66 113.95 325.12 112.17 324.83 109.87 319.46 106.54 318.53 104.9 317.14 99.99 317.35 98.76 Z" id="22228"/>
            <path d="M 347.94 34.95 350.72 32.8 353.79 35.09 356.34 33.96 359.54 34.36 360.65 40.25 358.8 43.33 356.6 42.83 354.9 40.33 352.77 39.33 349.64 40.84 348.33 40.21 347.68 37.17 347.94 34.95 Z" id="22028"/>
            <path d="M 325.17 36.84 327.33 35.83 327.95 33.86 329.93 33.86 331.58 35.94 329.48 38.85 330.29 42.01 327.73 40.95 325.91 38.94 325.17 36.84 Z" id="22324"/>
            <path d="M 345.72 53.88 347.51 51.3 349.83 49.74 352.44 52.17 354.52 52.43 354.64 56.07 352.87 57.91 349.13 59.17 348.5 57.83 345.88 56.82 345.72 53.88 Z" id="22257"/>
            <path d="M 428.22 87.67 430.23 87.67 429.74 84.66 430.73 82.28 433.15 81.58 435.26 84.4 434.32 88.76 435.81 90.37 438.84 89.06 441.6 89.45 442.82 91.39 441.64 94.57 439.7 95.65 435.23 96.79 432.44 93.67 429.58 95.49 429.28 93.89 430.7 91.2 428.22 87.67 Z" id="22117"/>
            <path d="M 378.01 77.62 379.91 75.84 383.9 76.15 384.99 74.74 385.65 77.1 387.34 79.02 386.77 81.6 388.13 83.68 384.88 85.86 381.9 84.48 379.74 85 378.88 83.26 378.9 79.8 378.01 77.62 Z" id="22338"/>
            <path d="M 391.98 76.7 395.82 70.92 399.84 72.5 401.45 70 404.75 70.95 404.27 73.36 401.1 74.45 400.4 76.71 399.28 78.29 398.31 77.8 395.53 80.23 392.36 79.12 391.98 76.7 Z" id="22293"/>
            <path d="M 399.28 78.29 400.4 76.71 403.19 77.5 407.83 75.1 407.95 75.82 412.18 78.49 412.47 80.8 409.51 81.72 410.72 83.42 406.69 86.61 404.94 86.31 402.71 84.61 402.2 82.47 399.28 78.29 Z" id="22063"/>
            <path d="M 417.95 77.29 418.12 76.24 418.91 74.37 422.17 77.8 424.64 75.42 428.57 76.46 430.57 77.62 430.73 82.28 429.74 84.66 430.23 87.67 428.22 87.67 425 88.22 424.07 87.35 420.77 83.4 419.59 80.18 420.02 78.67 417.95 77.29 Z" id="22177"/>
            <path d="M 411.09 68.66 416.37 68.71 417.56 67.12 420.11 67.92 419.59 70.48 421.22 72.58 423.55 73.34 424.64 75.42 422.17 77.8 418.91 74.37 418.12 76.24 416.78 74.75 412.18 72.11 411.09 68.66 Z" id="22236"/>
            <path d="M 349.69 75.88 350.46 70.51 351.56 67.69 349.89 65.94 349.69 63.63 352.67 62.78 353.78 64.71 356.22 66 358.38 64.76 358.01 60.82 362.66 59.36 362.98 60.53 366.96 63.76 367.84 66.73 359.88 70.51 358.63 70.88 355.84 74.93 351.68 75.77 349.69 75.88 Z" id="22254"/>
            <path d="M 314.64 80.73 316.55 78.16 319.91 78.45 321.89 75.2 323.33 74.64 324.72 76.35 328.92 76.86 327.63 79.47 328.14 85.51 325.66 88.42 325.25 92.65 324.16 94.67 325.42 96.97 322.53 99.07 319.94 97.96 319.47 95.64 317.46 93.06 315.55 86.92 315.91 82.25 314.64 80.73 Z" id="22207"/>
            <path d="M 324.54 59.34 326.09 58.29 325.75 56.05 327.24 54.7 331.5 57.29 333.33 57.83 334.56 60.97 332.81 62.94 335.42 68.39 335.28 69.6 332.73 73.54 333.19 76.83 333.16 76.82 328.92 76.86 324.72 76.35 323.33 74.64 321.89 75.2 322.25 74.06 325.97 71.12 326.24 69.26 323.16 68.55 321.8 67.45 320.71 64.47 321.02 62.86 324.54 59.34 Z" id="22211"/>
            <path d="M 338.98 59.33 340.27 58.14 345.28 57.86 345.88 56.82 348.5 57.83 349.13 59.17 349.49 60.96 348.11 62.46 344.32 63.89 339.99 63.52 338.98 59.33 Z" id="22030"/>
            <path d="M 419.51 94.65 421.69 92.98 423.98 92.6 427.93 96.06 429.58 95.49 432.44 93.67 435.23 96.79 432.89 103.35 433.52 104.75 436.69 104.86 436.85 107.39 438.97 107.55 442.69 110.64 440.49 111.93 440.6 114.24 434.43 114.62 432.84 116.09 430.3 113.69 425.12 114.84 424.41 113.04 422.07 110.87 419.85 107.23 420.75 104.5 423.35 103.33 423.24 101.42 420.87 99.14 419.57 97.05 419.51 94.65 Z" id="22182"/>
            <path d="M 371.36 70.75 374.31 67.83 373.37 65 375.87 64.18 380.73 64.9 382.55 63.62 382.57 63.89 385.18 68.48 386.11 71 384.15 73.46 384.99 74.74 383.9 76.15 379.91 75.84 378.01 77.62 377.64 75.96 373.86 74.89 373.48 72.45 371.36 70.75 Z" id="22212"/>
            <path d="M 407.95 75.82 411.67 73.9 412.18 72.11 416.78 74.75 418.12 76.24 417.95 77.29 416.99 79.27 412.99 81.81 412.47 80.8 412.18 78.49 407.95 75.82 Z" id="22112"/>
            <path d="M 395.74 68.94 396.72 66.98 401.48 65.72 404.06 64.1 403.66 65.48 406.51 66.57 405.91 69.4 404.75 70.95 401.45 70 399.84 72.5 395.82 70.92 395.74 68.94 Z" id="22057"/>
            <path d="M 334.56 60.97 336.13 59.64 338.98 59.33 339.99 63.52 340.03 66.01 341.55 66.51 342.72 71.22 344.61 73.62 342.2 74.37 339.95 76.5 337.22 77.46 333.19 76.83 332.73 73.54 335.28 69.6 335.42 68.39 332.81 62.94 334.56 60.97 Z" id="22340"/>
            <path d="M 367.79 79.27 368.45 75.81 369.97 73.42 369.92 69.47 371.36 70.75 373.48 72.45 373.86 74.89 374.6 78.62 373.22 79.81 371.62 84.16 368.72 81.76 367.79 79.27 Z" id="22018"/>
            <path d="M 435.23 96.79 439.7 95.65 441.64 94.57 441.88 97.9 440.93 101.76 440.93 104.84 438.97 107.55 436.85 107.39 436.69 104.86 433.52 104.75 432.89 103.35 435.23 96.79 Z" id="22356"/>
            <path d="M 430.57 77.62 432.95 78.85 435.71 76.37 437.52 78.45 439.97 78.5 441.95 77.12 442.43 75 444.91 78.24 445.28 79.58 442.84 82.35 442.91 84.52 441.88 86.61 441.6 89.45 438.84 89.06 435.81 90.37 434.32 88.76 435.26 84.4 433.15 81.58 430.73 82.28 430.57 77.62 Z" id="22232"/>
            <path d="M 351.68 75.77 355.84 74.93 358.63 70.88 359.88 70.51 361.32 73.13 363.08 72.83 365.89 75.3 368.45 75.81 367.79 79.27 366.87 81.08 367.51 82.77 367.28 86.13 365.71 87.38 363.58 86.3 362.15 89.31 360.88 91.11 357.44 87.43 355.82 87.83 354.28 91.44 351.58 90.81 351.62 89.63 349.44 86.8 350.76 84.44 350.65 78.4 351.68 75.77 Z" id="22004"/>
            <path d="M 382.57 63.89 385.93 63.7 388.15 64.12 390.06 67.12 389.61 69.48 393.02 69.57 395.74 68.94 395.82 70.92 391.98 76.7 389.99 75.97 388.9 78.68 387.34 79.02 385.65 77.1 384.99 74.74 384.15 73.46 386.11 71 385.18 68.48 382.57 63.89 Z" id="22283"/>
            <path d="M 387.34 79.02 388.9 78.68 389.99 75.97 391.98 76.7 392.36 79.12 395.53 80.23 398.31 77.8 399.28 78.29 402.2 82.47 402.71 84.61 404.94 86.31 405.09 88.33 406.7 90.3 404.59 91.67 401.95 91.73 400.38 90.54 398.46 91.12 396.28 94.24 394.16 96.07 391.22 96.62 390.33 94.19 387.39 88.68 388.61 84.97 388.13 83.68 386.77 81.6 387.34 79.02 Z" id="22248"/>
            <path d="M 424.07 87.35 425 88.22 428.22 87.67 430.7 91.2 429.28 93.89 429.58 95.49 427.93 96.06 423.98 92.6 421.69 92.98 422.45 89.72 424.07 87.35 Z" id="22361"/>
            <path d="M 404.06 64.1 406 62.47 409.02 62.44 407.76 64.53 407.82 66.46 406.51 66.57 403.66 65.48 404.06 64.1 Z" id="22108"/>
            <path d="M 359.88 70.51 367.84 66.73 369.92 69.47 369.97 73.42 368.45 75.81 365.89 75.3 363.08 72.83 361.32 73.13 359.88 70.51 Z" id="22041"/>
            <path d="M 328.92 76.86 333.16 76.82 333.46 80.05 335.31 81.25 336.08 85.41 335.18 90.92 331.78 94.62 331.69 96.44 330.06 97.34 325.42 96.97 324.16 94.67 325.25 92.65 325.66 88.42 328.14 85.51 327.63 79.47 328.92 76.86 Z" id="22387"/>
            <path d="M 367.79 79.27 368.72 81.76 371.62 84.16 372.95 85.31 372.24 86.67 372.63 90.88 370.57 91.83 369.85 91.01 365.22 91.39 363.68 89.4 362.15 89.31 363.58 86.3 365.71 87.38 367.28 86.13 367.51 82.77 366.87 81.08 367.79 79.27 Z" id="22310"/>
            <path d="M 371.62 84.16 373.22 79.81 374.6 78.62 373.86 74.89 377.64 75.96 378.01 77.62 378.9 79.8 378.88 83.26 375.6 83.82 372.95 85.31 371.62 84.16 Z" id="22095"/>
            <path d="M 404.75 70.95 405.91 69.4 406.9 69.96 407.83 75.1 403.19 77.5 400.4 76.71 401.1 74.45 404.27 73.36 404.75 70.95 Z" id="22378"/>
            <path d="M 406.51 66.57 407.82 66.46 411.09 68.66 412.18 72.11 411.67 73.9 407.95 75.82 407.83 75.1 406.9 69.96 405.91 69.4 406.51 66.57 Z" id="22370"/>
            <path d="M 409.02 62.44 411.26 60.17 412.67 57.51 413.03 58.47 418.09 59.59 418.69 61.65 420.19 62.54 419.02 65.61 417.56 67.12 416.37 68.71 411.09 68.66 407.82 66.46 407.76 64.53 409.02 62.44 Z" id="22178"/>
            <path d="M 339.99 63.52 344.32 63.89 348.11 62.46 349.49 60.96 349.13 59.17 352.87 57.91 352.97 60.84 352.67 62.78 349.69 63.63 349.89 65.94 351.56 67.69 350.46 70.51 349.69 75.88 348.3 76.47 345.66 75.83 344.61 73.62 342.72 71.22 341.55 66.51 340.03 66.01 339.99 63.52 Z" id="22034"/>
            <path d="M 333.16 76.82 333.19 76.83 337.22 77.46 339.95 76.5 342.2 74.37 344.61 73.62 345.66 75.83 348.3 76.47 349.69 75.88 351.68 75.77 350.65 78.4 350.76 84.44 349.44 86.8 342.51 87.96 341.69 85.98 337.56 83.53 336.08 85.41 335.31 81.25 333.46 80.05 333.16 76.82 Z" id="22245"/>
            <path d="M 134.97 100.29 137.97 98.17 140.25 95.7 140.89 93.87 144.8 95.49 149.24 98 149.23 98.1 147.89 100.55 145.58 101.24 142.97 103.37 141.31 102.98 134.97 100.29 Z" id="29093"/>
            <path d="M 119.26 105.04 119.49 104.87 121.95 106.19 122.09 106.63 122.76 106.36 123.66 107.41 124.28 107.47 123.64 106.4 123.6 105.43 124.2 105.6 124.51 105.39 128.65 106.07 129.92 103.81 134.09 100.24 134.97 100.29 141.31 102.98 140.77 105.25 142.98 107.04 139.86 109.39 139.67 111.37 135.57 114.25 133.88 116.55 133.43 121.81 131.1 122.06 131.71 119.29 129.89 118.13 128.85 115.39 126.86 114.71 125.13 112.86 121.25 111.2 119.91 109.07 119.26 105.04 Z" id="29209"/>
            <path d="M 112.15 118.87 114.89 118.11 114.01 115.47 117.1 112.57 119.91 109.07 121.25 111.2 120.5 113.44 118.14 114.01 117.2 115.64 119.74 119.37 119.43 124.29 120.54 125.33 119.66 127.35 116.81 126.87 115.11 124.13 114.31 120.32 112.15 118.87 Z" id="29035"/>
            <path d="M 76.7 115.99 77.67 115.48 82.61 117.84 83.38 113.67 86.32 109.33 89.31 115 92.48 116.14 93.07 118.38 95.04 119.57 99.02 119.5 99.69 118.35 101.25 119.6 99.87 122.34 96.12 125.53 95.94 128.17 92.11 128.95 92.41 127.35 90.67 123.74 88.61 122.27 86.78 124.74 85.78 123.23 82 122.51 80.72 123.51 80.12 121.39 80.67 119.13 76.7 115.99 Z" id="29208"/>
            <path d="M 154.53 120.55 156.31 119.48 158.37 122.35 158.39 124.08 160.74 126.2 165.15 128.48 165.58 132.3 164.74 134.8 163.41 135.68 162.89 132.99 160.69 131.2 155.98 129.85 154.98 125.75 153.41 123.91 154.53 120.55 Z" id="29268"/>
            <path d="M 245.91 178.84 248.93 177.69 251 175.45 252.32 175.57 252.92 171.5 254.96 171.69 257.95 170.68 261.32 171.2 262.2 173.03 256.63 175.72 257.89 181.71 261.2 186.06 260.86 190.28 265.38 190.5 266.6 194.9 268.52 198.87 269.57 199.45 270.18 203.08 272.24 203.48 273.32 206.19 269.43 205.99 264.67 204.19 262.65 204.59 260.25 203.79 256.94 205.1 252.43 204.36 247.05 207.8 244.51 207.59 244.34 206.08 246.31 199.02 245.9 197.39 248.56 193.4 246.76 187.05 247.11 184.45 245.69 183.36 244.27 179.18 245.91 178.84 Z" id="29175"/>
            <path d="M 234.35 221.98 236.77 219.83 236.93 216.37 240.24 213.61 241.57 214.21 247.5 217.87 244.66 220.18 242.65 224.06 243.03 226.85 242.32 228.51 239.35 229.51 235.07 227.63 235.5 225.15 234.35 221.98 Z" id="29267"/>
            <path d="M 236.27 183.73 236.79 187.08 239.54 187.45 241.57 188.65 243.49 185.36 245.69 183.36 247.11 184.45 246.76 187.05 248.56 193.4 245.9 197.39 246.31 199.02 244.1 198.5 240.96 196.15 238.6 196.2 236.05 195.43 235.37 188.96 233.87 185.65 236.27 183.73 Z" id="29033"/>
            <path d="M 212.96 213.55 214.93 211.29 214.42 205.15 215.65 203.57 220.11 205.6 218.94 207.74 220.02 208.88 219.87 211.02 221.72 212.45 221.77 214.08 218.9 217.54 218.42 219.98 214.17 219.17 211.34 219.24 211.25 219.08 213.55 214.73 212.96 213.55 Z" id="29142"/>
            <path d="M 235.08 180.04 240.47 177.42 242.58 176.33 245.44 176.75 245.91 178.84 244.27 179.18 245.69 183.36 243.49 185.36 241.57 188.65 239.54 187.45 236.79 187.08 236.27 183.73 235.08 180.04 Z" id="29115"/>
            <path d="M 241.74 162.96 248.69 164.21 249.6 165.96 256.86 167.64 258.6 165.92 261.43 166.05 263.85 168.81 261.32 171.2 257.95 170.68 254.96 171.69 252.92 171.5 252.32 175.57 251 175.45 248.93 177.69 245.91 178.84 245.44 176.75 242.58 176.33 240.47 177.42 239.15 174.18 240.78 173.07 241.78 170.9 241.74 162.96 Z" id="29141"/>
            <path d="M 184.69 228.3 188.47 228.9 194.07 225.97 195.59 224.43 197.61 225.54 198.71 228.47 205.13 228.96 206.52 230.88 205.89 232.87 202.08 235.43 196.45 238.32 193.79 236.13 188.16 236.72 186.99 237.42 185.8 235.27 182.71 233.77 181.46 231.7 184.69 228.3 Z" id="29229"/>
            <path d="M 182.73 223.22 186.19 224.54 186.34 222.84 188.81 222.82 189.05 218.58 192.68 219.26 195.97 217.7 196.84 215.79 198.49 215.32 201.39 217.34 203.16 215.29 204.52 214.46 207.24 215.07 206.35 217.78 207.37 219.52 210.17 218.33 211.25 219.08 211.34 219.24 210.28 224.49 210.63 226.11 208.92 227.21 207.71 229.48 206.52 230.88 205.13 228.96 198.71 228.47 197.61 225.54 195.59 224.43 194.07 225.97 188.47 228.9 184.69 228.3 182.67 225.96 182.73 223.22 Z" id="29025"/>
            <path d="M 142.97 103.37 145.58 101.24 147.89 100.55 149.23 98.1 150.71 99.86 150.44 105.51 146.91 107.61 145.56 104.68 142.97 103.37 Z" id="29100"/>
            <path d="M 149.24 98 150.44 94.96 153.88 97.48 157.32 98.38 156.46 99.07 158 102.58 158.36 104.55 154.51 106.42 151.3 107.05 150.44 105.51 150.71 99.86 149.23 98.1 149.24 98 Z" id="29055"/>
            <path d="M 144.07 106.94 145.56 104.68 146.91 107.61 150.44 105.51 151.3 107.05 149.97 109.4 151.93 112.11 152.72 116.25 151.14 116.72 147.91 116.93 148.14 115.14 144.5 112.9 143.06 107.46 144.07 106.94 Z" id="29047"/>
            <path d="M 207.71 229.48 208.92 227.21 210.63 226.11 210.28 224.49 211.34 219.24 214.17 219.17 218.42 219.98 219.74 224.4 220.83 226.89 217.91 230.69 217.73 234.25 219.66 235.43 221.05 233.99 224.08 234.71 224.19 237.62 223.2 239.44 223.16 243.35 224.05 244.86 227.27 241.37 226.68 239.2 227.35 236.68 230.62 233.83 234.07 237.79 234.85 240.86 234.71 244.45 232.35 246.58 230.43 243.98 228.56 244.46 226.13 243.03 222.77 247.68 220.12 247.2 219.28 249.54 217.63 250.47 220.74 252.65 220.94 255.2 217.07 257.7 215.41 256.08 211.32 256.66 210.51 256.05 208.82 251.67 209.01 248.1 213.11 240.5 211.45 238.31 212.78 237.15 212.45 235.2 209.43 233.05 209.32 230.82 207.71 229.48 Z" id="29020"/>
            <path d="M 176.27 238.48 176.57 234.55 182.42 235.18 182.71 233.77 185.8 235.27 186.99 237.42 180.66 240.15 178.97 239.91 178.5 242.71 177.13 243.15 175.5 240.97 176.27 238.48 Z" id="29134"/>
            <path d="M 219.74 224.4 220.65 225.11 230.42 222.58 234.35 221.98 235.5 225.15 235.07 227.63 239.35 229.51 239.99 231.45 241.54 231.76 242.3 236.71 240.94 237.69 236.81 238.16 234.07 237.79 230.62 233.83 227.35 236.68 226.68 239.2 227.27 241.37 224.05 244.86 223.16 243.35 223.2 239.44 224.19 237.62 224.08 234.71 221.05 233.99 219.66 235.43 217.73 234.25 217.91 230.69 220.83 226.89 219.74 224.4 Z" id="29048"/>
            <path d="M 201.16 247.35 202.42 245.1 203.3 239.46 203.05 236.48 202.08 235.43 205.89 232.87 206.52 230.88 207.71 229.48 209.32 230.82 209.43 233.05 212.45 235.2 212.78 237.15 211.45 238.31 213.11 240.5 209.01 248.1 208.82 251.67 206.9 247.5 203.16 246.98 201.16 247.35 Z" id="29106"/>
            <path d="M 144.8 95.49 146.85 91.73 146.79 89.11 150.53 86.72 154.26 86.85 155.13 87.14 152.64 92.02 150.44 94.96 149.24 98 144.8 95.49 Z" id="29248"/>
            <path d="M 101.25 119.6 105.48 120.12 108.77 118.55 108.86 124.13 106.57 126.22 103.25 125.61 101.9 121.88 99.87 122.34 101.25 119.6 Z" id="29299"/>
            <path d="M 212.77 192.25 216.74 190.16 222.13 184.09 223.45 185.95 226.23 185.4 229.13 182.56 235.08 180.04 236.27 183.73 233.87 185.65 235.37 188.96 236.05 195.43 238.6 196.2 239.22 199.2 237.6 201.69 236.76 205.76 234.64 208.27 234.56 211.08 232.88 210.72 229.61 211.71 229.45 208.95 227.79 207.71 224.82 210.74 221.3 208.38 220.02 208.88 218.94 207.74 220.11 205.6 215.65 203.57 213.28 202.24 214.58 200.63 213.17 197.54 215.02 198.21 216.18 196.07 213.53 194.48 212.77 192.25 Z" id="29162"/>
            <path d="M 93.45 134.53 94.21 131.32 95.94 128.17 96.12 125.53 99.87 122.34 101.9 121.88 103.25 125.61 103.8 127.89 103.07 134.34 101.28 135.71 100.5 138.19 96.97 138.03 96.22 137.14 93.58 135.79 93.45 134.53 Z" id="29119"/>
            <path d="M 218.42 219.98 218.9 217.54 221.77 214.08 221.72 212.45 219.87 211.02 220.02 208.88 221.3 208.38 224.82 210.74 227.79 207.71 229.45 208.95 229.61 211.71 232.88 210.72 234.56 211.08 235.82 215.94 236.93 216.37 236.77 219.83 234.35 221.98 230.42 222.58 220.65 225.11 219.74 224.4 218.42 219.98 Z" id="29062"/>
            <path d="M 241.57 214.21 242.98 212.62 245.85 213.45 246.44 210.58 244.51 207.59 247.05 207.8 252.43 204.36 256.94 205.1 260.25 203.79 262.65 204.59 264.67 204.19 269.43 205.99 267.71 210.07 269.99 212.09 269.74 213.3 266.14 215.55 264.46 216.58 263.86 213.43 262.1 214.04 261.93 217.38 259.77 217.92 259.07 213.28 257.05 213.25 256.93 219.48 256.11 220.16 252.37 219.91 247.5 217.87 241.57 214.21 Z" id="29027"/>
            <path d="M 234.56 211.08 234.64 208.27 236.76 205.76 237.6 201.69 239.22 199.2 238.6 196.2 240.96 196.15 244.1 198.5 246.31 199.02 244.34 206.08 244.51 207.59 246.44 210.58 245.85 213.45 242.98 212.62 241.57 214.21 240.24 213.61 236.93 216.37 235.82 215.94 234.56 211.08 Z" id="29123"/>
            <path d="M 219.71 172.06 221.14 170.05 225.68 168.7 229.13 168.58 231.95 167.79 233.22 164.6 231.47 162.09 235.57 160.96 239.94 161.39 241.12 162.55 241.74 162.96 241.78 170.9 240.78 173.07 239.15 174.18 240.47 177.42 235.08 180.04 229.13 182.56 226.23 185.4 223.45 185.95 222.13 184.09 221.24 178.23 221.92 175.51 219.71 172.06 Z" id="29016"/>
            <path d="M 502.66 244.04 504.54 243.87 506.78 241.85 508.53 242.75 512.79 236.07 515.15 237.79 520.97 240.84 521.78 244.39 519.52 244.58 517.97 245.81 518.56 249.65 523.34 254.4 519.74 255.87 516.4 255.34 513.71 258.31 510.53 258.89 507.08 252.89 504.25 249.84 502.66 244.04 Z" id="56134"/>
            <path d="M 594.39 137.1 596.02 133.52 597.63 132.34 601.96 130.29 602.52 132.1 601.02 136.87 602.07 141.25 600.66 142.46 598.44 141.87 596.09 139.77 594.39 137.1 Z" id="22050"/>
            <path d="M 583.51 138.28 589.15 136.5 591.82 137.82 594.39 137.1 596.09 139.77 593.88 140.56 593.55 142.23 592.73 143.79 590.39 142.42 588.35 142.53 586.09 144.69 586.36 145.8 582.76 147.74 582.17 145.27 581.92 143.04 584.07 141.88 583.51 138.28 Z" id="22364"/>
            <path d="M 225.98 121.11 227.29 119.07 227.31 116.73 230.02 113.18 231.06 108.58 232.45 107.43 234.91 102.52 234.63 100.17 236.18 97.04 239.03 103.03 238.87 105.06 240.31 108.71 238.61 111.75 237.71 115.16 238.13 119.06 241.5 122.39 241.97 124.94 240.15 126.68 237.8 126.23 237.22 129.91 236.08 130.24 235.16 131.55 235.6 133.97 233.15 137.34 231.59 137.92 229.01 136.95 227.68 135.39 226.02 132.95 227.12 130.85 227.09 126.99 225.68 125.8 225.98 121.11 Z" id="29266"/>
            <path d="M 326.72 313.23 328.33 310.52 328.19 306.06 331.8 304.31 335.39 304.09 335.83 305.53 338.24 305.57 337.69 308.63 339.77 310.42 339.02 313.44 340.32 314.57 339.7 317.3 340.54 319.11 338.92 321.48 336.43 321.42 334.5 320.13 332.09 318.47 330.11 316.09 328.6 315.86 326.72 313.23 Z" id="56179"/>
            <path d="M 297.08 311.75 301.31 307.96 300.65 305.14 304.62 301.7 304.56 300.53 309.38 297.83 310.26 299.76 313.22 300.55 314.78 300.94 316.41 302.95 316.66 305.51 315.12 306.35 316.67 309.43 314.82 309.69 313.1 310.55 310.76 313.56 310.79 317.13 308.31 317.31 304.78 314.41 303.31 314.14 302.07 316.83 301.5 311.97 299.39 312.68 297.08 311.75 Z" id="29233"/>
            <path d="M 319.92 294.38 322.27 293.75 325.84 294.16 326.58 293.02 325.99 290.28 327.93 289.48 329.48 287.08 332.16 287.25 335.15 288.3 336.65 289.94 336.07 292.12 337.06 295.11 337.41 299.18 337.16 304.71 338.24 305.57 335.83 305.53 335.39 304.09 331.8 304.31 328.19 306.06 327.4 303.27 323.22 302.66 318.69 298.34 319.92 294.38 Z" id="29002"/>
            <path d="M 280.93 239.28 284.34 239.54 288.01 239.45 290.09 238.21 292.27 238.55 292.32 240.58 294.34 243.25 298.22 243.68 299.41 242.68 300.53 245.19 303.21 246.24 305.29 248.31 307.68 252.19 308.95 252.56 312.51 256.02 308.99 258 305.47 258.13 305.6 261.2 302.83 262.14 304.52 266.91 301.03 269.08 297.66 269.19 296.7 270.4 295.54 271.39 291.21 270.26 291.27 268.67 289.88 264.21 287.14 260.65 287.21 257.36 284.53 252.33 284.81 248.93 282.86 248.74 279.31 247.09 279.9 244.35 278.98 240.99 280.93 239.28 Z" id="56081"/>
            <path d="M 337.41 299.18 340.89 299.98 341.84 297.85 344.08 296 347.91 294.28 350.98 296.16 350.23 298.49 350.54 301.96 352.42 307.07 353.8 307.3 354.07 309.14 350.9 309.53 349.06 308.74 348.1 310.74 342.98 313.91 343.66 316.03 339.7 317.3 340.32 314.57 339.02 313.44 339.77 310.42 337.69 308.63 338.24 305.57 337.16 304.71 337.41 299.18 Z" id="56040"/>
            <path d="M 313.22 300.55 314.36 299.22 317.96 299.63 318.69 298.34 323.22 302.66 327.4 303.27 328.19 306.06 328.33 310.52 326.72 313.23 323.39 313.54 321.57 316.06 316.22 314.55 314.35 312.25 314.82 309.69 316.67 309.43 315.12 306.35 316.66 305.51 316.41 302.95 314.78 300.94 313.22 300.55 Z" id="29234"/>
            <path d="M 336.65 289.94 339.1 289.91 340.48 287.39 338.28 284.99 337.56 282.44 339.23 280.25 342.75 280.1 345.26 282.57 346.94 281.48 347.64 276.94 350.05 272.72 352.14 273 353.96 275.52 356.6 274.04 357.6 276.4 359.71 277.44 360.38 281.44 362.3 281.91 361.58 283.97 362.2 288.35 361.45 290.62 362.7 292.52 361.26 297.47 357.78 295.56 354.22 295.52 350.98 296.16 347.91 294.28 344.08 296 341.84 297.85 340.89 299.98 337.41 299.18 337.06 295.11 336.07 292.12 336.65 289.94 Z" id="56166"/>
            <path d="M 323.11 265.67 322.38 264.22 324.93 261.39 329.34 259.63 331.04 261.23 332.5 259.43 335.84 258.44 336.5 259.45 336.8 261.66 335.47 263.33 335.06 266.87 337.89 270.58 338.15 273.13 337.26 275.62 333.76 275.46 328.88 278.19 326.11 278.75 324.74 281.74 322.24 280.08 321.48 275.68 319.05 272.75 318.65 271.36 320.88 270.25 323.11 265.67 Z" id="56131"/>
            <path d="M 301.03 269.08 304.52 266.91 302.83 262.14 305.6 261.2 305.47 258.13 308.99 258 312.51 256.02 313.29 257.24 315.8 257.77 316.95 260.55 318.6 260.83 319.02 265.84 321.49 264.14 323.11 265.67 320.88 270.25 318.65 271.36 319.05 272.75 315.61 273.32 313.15 271.1 312.61 269.38 309.65 269.94 308.53 268.33 304.81 268.48 302.3 270.56 301.03 269.08 Z" id="56105"/>
            <path d="M 324.74 281.74 326.11 278.75 328.88 278.19 333.76 275.46 337.26 275.62 337.34 277.45 339.44 278.5 339.23 280.25 337.56 282.44 338.28 284.99 340.48 287.39 339.1 289.91 336.65 289.94 335.15 288.3 332.16 287.25 329.48 287.08 327.93 289.48 326.43 288.16 326.52 285.27 324.38 282.41 324.74 281.74 Z" id="29071"/>
            <path d="M 231.59 137.92 233.15 137.34 235.6 133.97 235.16 131.55 236.08 130.24 236.5 132 241.12 129.19 243.13 127.08 249.33 125.65 252.14 127.09 253.73 129.05 255.56 128.8 256.72 131.48 255.28 135.1 253.97 135.93 255.94 141.12 253.62 141.69 251.58 143.64 242.45 146.72 237.79 149.14 236.86 144.46 234.89 140.55 231.59 137.92 Z" id="29202"/>
            <path d="M 262.33 88.17 264.22 87.61 266.55 83.8 269.37 83.48 270.04 82.41 271.32 84.73 270.83 88.38 270.77 91.7 271.77 93.9 269.52 95.19 264.12 97.11 262.26 97.04 261.53 95.24 263.59 93.03 262.33 88.17 Z" id="29059"/>
            <path d="M 239.03 103.03 241.15 99.18 243.48 98.76 243.5 97.12 246.52 96.71 245.79 98.92 248.02 103.96 246.35 106.48 244.06 107.14 242.94 108.94 240.31 108.71 238.87 105.06 239.03 103.03 Z" id="29265"/>
            <path d="M 296.7 270.4 297.66 269.19 301.03 269.08 302.3 270.56 304.81 268.48 308.53 268.33 309.65 269.94 312.61 269.38 313.15 271.1 315.61 273.32 319.05 272.75 321.48 275.68 322.24 280.08 324.74 281.74 324.38 282.41 323.47 283.03 316.9 281.97 315.17 285.42 315.32 288.34 310.85 287.94 309.55 290.79 307.49 290.92 302.61 287.58 299.84 287.07 299.91 278.89 301.95 277.44 302.21 275.81 296.7 270.4 Z" id="29230"/>
            <path d="M 290.45 271.44 291.21 270.26 295.54 271.39 296.7 270.4 302.21 275.81 301.95 277.44 299.91 278.89 299.84 287.07 296.96 285.72 295.32 287.17 293.04 284.62 289.33 282.87 287.91 279.79 292.13 276.31 291.63 273.47 290.45 271.44 Z" id="29269"/>
            <path d="M 251.58 143.64 253.62 141.69 255.94 141.12 259.9 142.31 259.97 139.73 265.69 138.42 266.69 140.74 268.67 140.99 269.07 142.59 271.7 144.04 275.12 142.22 276.5 145.06 282.64 148.62 282.96 152.23 282.36 154 277.13 153.15 276.55 157.55 273.23 159.28 271.91 156.5 268.1 156.58 266.59 157.46 265.91 160.02 260.96 157.9 260.1 153.28 257.29 152.14 255.02 149.34 254.66 147.15 252.01 146.31 251.58 143.64 Z" id="29007"/>
            <path d="M 236.08 130.24 237.22 129.91 237.8 126.23 240.15 126.68 241.97 124.94 241.5 122.39 238.13 119.06 237.71 115.16 238.61 111.75 240.31 108.71 242.94 108.94 244.06 107.14 246.35 106.48 247.49 107.75 250.53 106.65 251.54 108.23 253.45 104.96 255.49 107.94 254.86 110.83 252.88 114.62 253.06 116.92 255.44 124.83 254.82 127.13 255.56 128.8 253.73 129.05 252.14 127.09 249.33 125.65 243.13 127.08 241.12 129.19 236.5 132 236.08 130.24 Z" id="29163"/>
            <path d="M 260.29 101.03 264.69 101.33 266.53 102.61 271.02 101.99 275.11 103.28 278.56 109.16 279.84 109.61 281.44 112.89 281.21 115.49 282.85 117.15 282.37 119.55 284.45 122.62 284.43 124.64 285.56 128.15 285.09 130.78 281.8 134.81 279.69 134.2 278.28 135.89 274.27 135.66 269.51 136.2 269.79 127.84 268.34 124.62 268.35 120.8 268.96 118.17 266.16 113.01 267.09 111.41 266.78 109.19 264.24 106.16 261.93 104.43 260.6 101.75 260.29 101.03 Z" id="29191"/>
            <path d="M 194.22 157.31 198.75 159.37 200.93 159.13 203.14 155.99 204.43 155.74 206.58 160.7 210.19 162.35 210.42 164.02 208.38 163.31 205.83 163.59 200.75 163 199.43 162.24 194.55 162.32 191.86 163.5 189.87 162.88 189.19 160.56 190.97 158.32 194.22 157.31 Z" id="29246"/>
            <path d="M 255.56 128.8 254.82 127.13 255.44 124.83 259.39 125.59 260.08 123.65 262.93 125.7 265.93 126.29 266.53 124.47 268.34 124.62 269.79 127.84 269.51 136.2 265.69 138.42 259.97 139.73 259.9 142.31 255.94 141.12 253.97 135.93 255.28 135.1 256.72 131.48 255.56 128.8 Z" id="29034"/>
            <path d="M 253.45 104.96 253.41 100.97 255.74 101.45 256.52 99.3 258.59 102.09 260.6 101.75 261.93 104.43 264.24 106.16 266.78 109.19 267.09 111.41 266.16 113.01 268.96 118.17 268.35 120.8 268.34 124.62 266.53 124.47 265.93 126.29 262.93 125.7 260.08 123.65 259.39 125.59 255.44 124.83 253.06 116.92 252.88 114.62 254.86 110.83 255.49 107.94 253.45 104.96 Z" id="29207"/>
            <path d="M 182.37 144.86 184.72 141.22 186.69 140.36 189.57 140.55 191.97 144.87 191.29 146.63 192.2 148.55 190.63 150.09 187.16 151.13 186.23 149.1 182.37 144.86 Z" id="29286"/>
            <path d="M 178.26 130.87 180.11 129.81 180.32 128.19 178.78 124.63 179.83 123.06 182.3 123.47 182.82 125.15 186.32 126.91 187.88 125.04 190.26 124.31 192.83 125.13 193.2 125.13 190.68 127.14 188.37 128.01 188.85 131.83 187.18 131.66 184.51 133.13 181.92 133.68 178.26 130.87 Z" id="29237"/>
            <path d="M 179.91 122.33 181.69 121.38 182.79 118.88 185.66 122.49 182.82 125.15 182.3 123.47 179.83 123.06 179.91 122.33 Z" id="29116"/>
            <path d="M 153.09 84.9 154.28 83.65 156.59 84.08 159.21 81.28 161.19 81.04 160.01 85.92 162.64 87.94 165.32 88.38 168.99 84.97 168.78 88.63 170.68 91.33 173.44 91.3 174.35 93.91 174.82 98.34 170.25 99.3 166.71 99.2 165.49 95.49 161.39 93.21 159.11 90.57 157.68 90.29 155.13 87.14 154.26 86.85 153.09 84.9 Z" id="29198"/>
            <path d="M 151.3 107.05 154.51 106.42 158.36 104.55 158 102.58 160.17 102.82 162.04 100.4 165.21 100.31 165.63 101.45 164.83 101.91 163.67 106.98 163.43 110.13 168.46 108.51 169.72 108.65 172.45 111.35 173.96 111.82 174.29 113.11 170.01 116.01 168.47 115.08 167.31 118.79 165.1 118.76 164.76 120.44 166.63 123.11 166.08 126.85 166.88 129.28 165.15 128.48 160.74 126.2 158.39 124.08 158.37 122.35 156.31 119.48 154.53 120.55 152.72 116.25 151.93 112.11 149.97 109.4 151.3 107.05 Z" id="29179"/>
            <path d="M 201.47 145.68 201.33 140.77 203.5 137.89 204.66 139.38 208.21 139.77 211.25 141.23 212.48 140.33 214.94 140.64 216.65 138.22 219.41 142.4 220.05 144.8 217.38 145.19 216.85 147.92 219.41 149.55 220.47 154.27 222.57 154.64 226.48 157.4 230.69 154.61 232.74 154.79 230.21 159.55 228.45 161.83 222.13 159.85 212.41 162.25 212.11 162.4 210.42 164.02 210.19 162.35 206.58 160.7 204.43 155.74 202.94 153.67 202.13 150.69 202.38 148.42 201.47 145.68 Z" id="29277"/>
            <path d="M 167.31 118.79 171.14 122.77 172.88 121.45 175.66 121.47 177.6 120.43 179.91 122.33 179.83 123.06 178.78 124.63 180.32 128.19 180.11 129.81 178.26 130.87 176.55 132.43 173.67 132.26 171.98 129.88 168.61 131.1 166.88 129.28 166.08 126.85 166.63 123.11 164.76 120.44 165.1 118.76 167.31 118.79 Z" id="29181"/>
            <path d="M 173.12 159.79 177.03 157.74 180.01 154.32 181.61 151.59 187.14 151.14 186.94 153.42 189.25 153.64 192.12 155.05 194.22 157.31 190.97 158.32 189.19 160.56 189.87 162.88 191.86 163.5 190.16 164.76 186.04 165.31 183.69 163.7 179.57 166.56 176.92 167.58 173.51 167.42 174.03 165.46 172.92 163.89 174.3 162.5 173.12 159.79 Z" id="29086"/>
            <path d="M 187.14 151.14 187.16 151.13 190.63 150.09 192.2 148.55 193.03 146.91 195.08 146.27 195.52 143.29 197.07 142.52 198.55 143.01 200.38 145.61 201.47 145.68 202.38 148.42 202.13 150.69 202.94 153.67 204.43 155.74 203.14 155.99 200.93 159.13 198.75 159.37 194.22 157.31 192.12 155.05 189.25 153.64 186.94 153.42 187.14 151.14 Z" id="29294"/>
            <path d="M 187.18 131.66 188.85 131.83 188.37 128.01 190.68 127.14 193.2 125.13 198.71 123.28 201.52 127.21 199.58 130.39 202.74 131.78 202.01 134.81 205.35 135.02 203.5 137.89 201.33 140.77 201.47 145.68 200.38 145.61 198.55 143.01 197.07 142.52 196.66 140.38 191.88 134.87 187.18 131.66 Z" id="29180"/>
            <path d="M 174.82 98.34 174.35 93.91 177.75 95.43 180.22 94.58 183.2 95.37 185.82 94.98 188.88 93.36 189.86 95.79 188.89 97.28 189.1 101.12 187.76 100.71 186.68 104.73 180.44 102.98 174.27 101.09 174.82 98.34 Z" id="29111"/>
            <path d="M 180.64 143.15 180.75 143.11 182.37 144.86 186.23 149.1 187.16 151.13 187.14 151.14 181.61 151.59 180.01 154.32 177.03 157.74 173.12 159.79 171.91 157.65 172.35 154.72 175.71 153.31 176.66 149.9 175.86 145.64 180.64 143.15 Z" id="29270"/>
            <path d="M 181.92 133.68 184.51 133.13 187.18 131.66 191.88 134.87 196.66 140.38 197.07 142.52 195.52 143.29 195.08 146.27 193.03 146.91 192.2 148.55 191.29 146.63 191.97 144.87 189.57 140.55 186.69 140.36 184.72 141.22 182.37 144.86 180.75 143.11 183.7 138.17 182.51 136.46 181.92 133.68 Z" id="29144"/>
            <path d="M 219.41 142.4 222.33 141.56 225.91 139.44 225.3 138 227.68 135.39 229.01 136.95 231.59 137.92 234.89 140.55 236.86 144.46 237.79 149.14 237.38 149.45 232.74 154.79 230.69 154.61 226.48 157.4 222.57 154.64 220.47 154.27 219.41 149.55 216.85 147.92 217.38 145.19 220.05 144.8 219.41 142.4 Z" id="29038"/>
            <path d="M 165.21 100.31 166.71 99.2 170.25 99.3 174.82 98.34 174.27 101.09 172.93 102.77 174.85 104.66 176.31 107.77 176.75 110.5 173.96 111.82 172.45 111.35 169.72 108.65 168.64 105.2 165.63 101.45 165.21 100.31 Z" id="29255"/>
            <path d="M 176.55 132.43 178.26 130.87 181.92 133.68 182.51 136.46 183.7 138.17 180.75 143.11 180.64 143.15 177.49 140.61 175.79 142.36 174.65 138.04 177.12 137.17 176.55 132.43 Z" id="29156"/>
            <path d="M 174.29 113.11 174.88 116.47 176.76 117.37 177.6 120.43 175.66 121.47 172.88 121.45 171.14 122.77 167.31 118.79 168.47 115.08 170.01 116.01 174.29 113.11 Z" id="29295"/>
            <path d="M 253.61 236.27 256.64 234.15 258.96 233.67 262.58 230.63 265.61 230.75 267.53 229.97 270.77 228.4 272.07 229.34 275.32 229.91 276.58 230.93 273.12 233.49 274.13 236.13 273.41 238.15 269.9 240.41 266.24 241.11 262.28 244.68 261.21 249.13 261.15 246.98 258.53 246.8 258.58 243.96 256.87 243.57 255.97 239.53 253.61 236.27 Z" id="29125"/>
            <path d="M 195.18 110.74 195.73 109.44 199.76 110.16 202.22 109.38 205.17 108.99 205.65 112.11 203.69 113.7 202.39 118.48 202.56 121.2 198.71 123.28 193.2 125.13 192.83 125.13 193.54 121.64 193.12 118.17 193.47 114.69 195.18 110.74 Z" id="29010"/>
            <path d="M 206.39 122.34 210.13 121.36 213.34 119.5 216.18 118.93 218.6 120.28 218.76 122.09 220.57 123.93 217.51 128.11 218.51 131.43 218.33 134.16 216.35 133.94 214.27 131.34 211.85 130.04 210.94 128.35 207.65 127.59 206.39 122.34 Z" id="29097"/>
            <path d="M 203.26 101.28 208.63 98.56 212.58 98.01 210.96 107.58 210.01 108.71 212.62 110.32 211.15 111.43 208.52 109.74 206.86 113.41 205.65 112.11 205.17 108.99 202.22 109.38 201.63 106.58 203.92 104.77 203.26 101.28 Z" id="29193"/>
            <path d="M 198.6 89.88 197.57 85.17 199.05 84.5 201.78 85.77 201.51 87.96 202.63 89.77 208.23 91.65 210.02 90.43 211.04 91.37 209.67 95.91 207.77 96.31 208.63 98.56 203.26 101.28 198.77 97.7 196.59 95.86 198.6 89.88 Z" id="29213"/>
            <path d="M 336.5 259.45 338.19 258.46 340.87 261.89 341.59 261.56 345.64 261.73 346.91 265.91 350.52 266.56 352.59 269.42 350.05 272.72 347.64 276.94 346.94 281.48 345.26 282.57 342.75 280.1 339.23 280.25 339.44 278.5 337.34 277.45 337.26 275.62 338.15 273.13 337.89 270.58 335.06 266.87 335.47 263.33 336.8 261.66 336.5 259.45 Z" id="56014"/>
            <path d="M 275.32 229.91 275.85 228.23 277.7 227.28 277.07 224.61 280.29 223.92 280.58 221.98 285.25 222.15 285.16 224.59 287.11 227.87 286.44 230.58 287.58 232.07 285.57 235.66 284.34 239.54 280.93 239.28 279.5 237.85 277.06 237.46 273.41 238.15 274.13 236.13 273.12 233.49 276.58 230.93 275.32 229.91 Z" id="56199"/>
            <path d="M 266.14 215.55 269.74 213.3 269.99 212.09 267.71 210.07 269.43 205.99 273.32 206.19 272.24 203.48 275.2 202.27 277.3 203.71 279.14 202.7 280.8 200.93 282.51 202.93 285.94 201.83 287.3 202.95 288.84 205.9 292.85 206.58 294.22 208.55 294.45 215.59 295.18 216.48 293.24 217.4 288.92 220.79 285.25 222.15 280.58 221.98 275.68 221.14 275.55 219.77 271.93 218.76 268.2 216 266.14 215.55 Z" id="29278"/>
            <path d="M 174.27 101.09 180.44 102.98 179.76 108.8 180.56 110.74 186.57 111.76 189.21 112.88 187.54 114.96 186.9 117.87 187.66 120.07 190.5 121.76 190.26 124.31 187.88 125.04 186.32 126.91 182.82 125.15 185.66 122.49 182.79 118.88 181.69 121.38 179.91 122.33 177.6 120.43 176.76 117.37 174.88 116.47 174.29 113.11 173.96 111.82 176.75 110.5 176.31 107.77 174.85 104.66 172.93 102.77 174.27 101.09 Z" id="29204"/>
            <path d="M 198.71 123.28 202.56 121.2 206.39 122.34 207.65 127.59 206.75 133.95 205.35 135.02 202.01 134.81 202.74 131.78 199.58 130.39 201.52 127.21 198.71 123.28 Z" id="29128"/>
            <path d="M 256.11 220.16 256.93 219.48 257.05 213.25 259.07 213.28 259.77 217.92 261.93 217.38 262.1 214.04 263.86 213.43 264.46 216.58 266.14 215.55 268.2 216 271.93 218.76 275.55 219.77 275.68 221.14 280.58 221.98 280.29 223.92 277.07 224.61 277.7 227.28 275.85 228.23 275.32 229.91 272.07 229.34 270.77 228.4 267.53 229.97 265.63 226.51 261.13 226.69 260.22 223.69 256.99 224.42 255.51 222.2 256.11 220.16 Z" id="29249"/>
            <path d="M 189.21 112.88 192.31 107.36 193.92 108.18 195.18 110.74 193.47 114.69 193.12 118.17 193.54 121.64 192.83 125.13 190.26 124.31 190.5 121.76 187.66 120.07 186.9 117.87 187.54 114.96 189.21 112.88 Z" id="29264"/>
            <path d="M 216.35 133.94 218.33 134.16 218.51 131.43 221.09 130.71 222 131.81 224.79 129.17 227.12 130.85 226.02 132.95 227.68 135.39 225.3 138 225.91 139.44 222.33 141.56 219.41 142.4 216.65 138.22 216.35 133.94 Z" id="29262"/>
            <path d="M 216.18 118.93 216.96 117.52 217.25 110.79 216.31 110.39 216.47 107.21 218.97 103.5 221.95 102.46 224.16 100.61 226.51 101.22 226.92 98.58 230.12 96.66 232.64 94.73 234.28 92.09 236.76 95.19 236.18 97.04 234.63 100.17 234.91 102.52 232.45 107.43 231.06 108.58 230.02 113.18 227.31 116.73 227.29 119.07 225.98 121.11 221.54 120.1 218.6 120.28 216.18 118.93 Z" id="29068"/>
            <path d="M 188.88 93.36 192.21 90.28 193.35 85.27 194.61 86.16 196.3 89.08 198.6 89.88 196.59 95.86 198.77 97.7 197.03 97.8 194.74 99.31 193.28 102.52 189.58 102.33 189.1 101.12 188.89 97.28 189.86 95.79 188.88 93.36 Z" id="29271"/>
            <path d="M 180.44 102.98 186.68 104.73 187.76 100.71 189.1 101.12 189.58 102.33 187.73 105.45 192.31 107.36 189.21 112.88 186.57 111.76 180.56 110.74 179.76 108.8 180.44 102.98 Z" id="29244"/>
            <path d="M 207.69 176.06 209.6 175.06 211.58 172.5 212.9 165.39 212.11 162.4 212.41 162.25 217.26 164 219.71 172.06 221.92 175.51 221.24 178.23 222.13 184.09 216.74 190.16 212.77 192.25 209.31 189.78 208.62 184.35 209.56 178.32 207.69 176.06 Z" id="29139"/>
            <path d="M 261.21 249.13 262.28 244.68 266.24 241.11 269.9 240.41 273.41 238.15 277.06 237.46 279.5 237.85 280.93 239.28 278.98 240.99 279.9 244.35 279.31 247.09 282.86 248.74 284.81 248.93 284.53 252.33 287.21 257.36 287.14 260.65 289.88 264.21 291.27 268.67 291.21 270.26 290.45 271.44 286.02 269.89 281.44 269.25 279.26 272.49 281.01 276.14 282.88 276.76 282.33 278.82 278.85 278.64 275.87 277.61 273.77 274.48 269.91 274.39 270.21 271.57 268.32 266.83 262.96 266.85 261.15 266.03 258.03 267.12 258.24 261.26 259.47 258.76 259.25 255.1 260.69 252.54 261.21 249.13 Z" id="29274"/>
            <path d="M 281.44 112.89 284.36 112.46 285.86 113.7 287.97 112.96 287.64 115.15 288.26 118.08 289.38 118.47 290.83 121.9 292.04 123.09 289.93 125.99 291.3 128.11 288.57 130.79 285.09 130.78 285.56 128.15 284.43 124.64 284.45 122.62 282.37 119.55 282.85 117.15 281.21 115.49 281.44 112.89 Z" id="29114"/>
            <path d="M 205.65 112.11 206.86 113.41 208.52 109.74 211.15 111.43 212.62 110.32 216.31 110.39 217.25 110.79 216.96 117.52 216.18 118.93 213.34 119.5 210.13 121.36 206.39 122.34 202.56 121.2 202.39 118.48 203.69 113.7 205.65 112.11 Z" id="29105"/>
            <path d="M 237.38 149.45 237.79 149.14 242.45 146.72 251.58 143.64 252.01 146.31 254.66 147.15 255.02 149.34 257.29 152.14 260.1 153.28 260.96 157.9 258.69 158.58 260.03 160.73 255.88 161.51 253.25 160.96 250.57 157.79 247.12 158.33 247.26 155.9 242.6 153.54 241.9 153.86 238.68 150.19 237.38 149.45 Z" id="29054"/>
            <path d="M 189.58 102.33 193.28 102.52 194.74 99.31 197.03 97.8 198.77 97.7 203.26 101.28 203.92 104.77 201.63 106.58 202.22 109.38 199.76 110.16 195.73 109.44 195.18 110.74 193.92 108.18 192.31 107.36 187.73 105.45 189.58 102.33 Z" id="29187"/>
            <path d="M 203.5 137.89 205.35 135.02 206.75 133.95 207.65 127.59 210.94 128.35 211.85 130.04 214.27 131.34 216.35 133.94 216.65 138.22 214.94 140.64 212.48 140.33 211.25 141.23 208.21 139.77 204.66 139.38 203.5 137.89 Z" id="29131"/>
            <path d="M 218.6 120.28 221.54 120.1 225.98 121.11 225.68 125.8 227.09 126.99 227.12 130.85 224.79 129.17 222 131.81 221.09 130.71 218.51 131.43 217.51 128.11 220.57 123.93 218.76 122.09 218.6 120.28 Z" id="29074"/>
            <path d="M 230.21 159.55 232.74 154.79 237.38 149.45 238.68 150.19 241.9 153.86 241.66 157.48 242.65 158.91 241.12 162.55 239.94 161.39 235.57 160.96 231.47 162.09 230.21 159.55 Z" id="29013"/>
            <path d="M 211.04 91.37 213.4 87 215.37 86.7 217.7 89.86 217.32 91.77 221.78 91.14 222.42 91.89 225.6 93.5 227.95 95.79 230.12 96.66 226.92 98.58 226.51 101.22 224.16 100.61 221.95 102.46 218.97 103.5 216.47 107.21 216.31 110.39 212.62 110.32 210.01 108.71 210.96 107.58 212.58 98.01 208.63 98.56 207.77 96.31 209.67 95.91 211.04 91.37 Z" id="29210"/>
            <path d="M 212.41 162.25 222.13 159.85 228.45 161.83 230.21 159.55 231.47 162.09 233.22 164.6 231.95 167.79 229.13 168.58 225.68 168.7 221.14 170.05 219.71 172.06 217.26 164 212.41 162.25 Z" id="29261"/>
            <path d="M 614.15 262.08 617.36 262.31 620.34 260.98 622.24 261.73 622.74 259.45 627.05 259.06 627.04 259.15 626.31 260.61 626.46 265.39 628.95 266.82 631.58 271.5 629.72 272.55 628.37 274.88 628.08 274.84 625.26 273.5 623.9 274.46 622.48 273.24 619.64 272.85 615.93 273.57 614.15 262.08 Z" id="35016"/>
            <path d="M 661.35 249.11 664.91 246.82 666.93 247.85 665.66 250.85 669.9 252.1 671.15 253.29 668.54 257.24 665.19 259.19 663.4 254.55 661.16 254.17 661.35 249.11 Z" id="35066"/>
            <path d="M 666.93 247.85 667.38 245.56 666.91 242.83 668.55 242.51 670.93 243.73 676.53 242.95 676.93 245.55 678.45 246.89 676.49 248.08 676.21 250.14 680.46 248.54 681.06 250.86 682.98 253.91 679.99 258.46 677.9 257.97 672.61 259.42 670.56 258.63 668.54 257.24 671.15 253.29 669.9 252.1 665.66 250.85 666.93 247.85 Z" id="35206"/>
            <path d="M 630.04 169.82 631.26 167.48 632.08 168.21 638.04 167.27 638.3 166.37 640.92 168.96 645.47 167.93 647.39 169.46 648.96 168.05 652.4 168.88 653.04 172.15 647.61 174.62 645.99 180.04 642.4 181.39 641.45 178.65 643.11 174.18 638.81 172.54 636.72 175.39 635.15 175.45 633.86 175.1 630.04 169.82 Z" id="35337"/>
            <path d="M 559.38 341.59 563.09 340.65 564.77 341.89 567.75 345.42 572.74 345.87 575.58 344.77 576.49 345.63 575.15 348.98 575.66 350.93 573.92 354.53 569.34 353.73 566.95 354.27 566.33 351.9 561.78 353.2 561 351.6 558.5 349.92 558.15 346.92 557.01 345.24 559.38 341.59 Z" id="56154"/>
            <path d="M 676.53 242.95 680.64 240.53 681.39 237.15 683.46 237.36 685 240.62 686.77 241.01 689.52 239.38 692.23 239.26 693.22 242.88 690.86 246.82 685.88 243.87 682.58 243.04 680.46 243.78 680.23 246.32 678.45 246.89 676.93 245.55 676.53 242.95 Z" id="35055"/>
            <path d="M 648.62 242.67 649.94 242.54 652.76 245.47 655.32 245.35 654.99 247.05 652.96 249.85 653.77 253.07 652.02 254.01 652.02 256.98 648.32 255.8 647.78 253.39 644.57 252.6 646.55 247.01 648.55 245.83 648.62 242.67 Z" id="35076"/>
            <path d="M 535.69 359.3 539.05 355.55 541.08 354.87 543.45 351.92 544.22 348.77 545.36 348.05 547.17 350.88 549.1 350.04 552.83 350.52 553.61 351.34 557.57 351.21 558.5 349.92 561 351.6 561.78 353.2 562.23 354.18 561.28 359.63 558.73 363.7 554.69 362.81 542.63 360.82 536.43 359.49 535.69 359.3 Z" id="56123"/>
            <path d="M 649.43 130.63 651.72 130.92 652.83 132.32 655.41 132.64 656.07 134.65 655.12 136.81 657.82 140.14 659.64 139.38 660.84 142.65 663.5 140 664.83 139.74 664.72 143.26 667.39 143.97 667.52 141.85 670.79 141.04 670.97 142.53 669.8 145.97 673.56 146.57 674.48 148.81 671.99 153.46 668.72 149.61 665.84 149.37 664.21 150.47 664.93 152.42 668.74 154.79 666.87 155.62 666.27 158.25 663.07 157.83 661.44 159.51 659.02 160.19 655.84 160.1 652.82 162.28 652.34 159.71 649.99 157.75 649.52 153.95 650.96 153.39 649.81 150.3 650.56 147.07 647.06 145.84 647.47 143.65 646.28 141.89 647.99 137.87 647.74 136.01 649.43 130.63 Z" id="35085"/>
            <path d="M 695.04 289.21 695.47 288.21 699.04 283.92 700.23 279.97 700.21 276.83 698.05 273.77 699.25 270.31 702.22 273.8 706.78 272.25 709.27 273.48 713.39 273.39 713 270.81 713.98 269.93 716.78 273.7 712.32 277.46 710.93 280.13 709.44 280.58 710.48 285.77 711.1 288.29 712.78 290.12 712.82 292.69 710.21 292.35 709.38 295.26 708.04 292.58 704.17 291.54 701.92 293.61 698.57 292.06 697.42 292.62 695.04 289.21 Z" id="35136"/>
            <path d="M 678.86 175.82 681.81 178.07 681.92 180.28 680.4 183.29 679.34 181.91 678.86 175.82 Z" id="35007"/>
            <path d="M 690.86 246.82 693.22 242.88 692.23 239.26 694.58 237.54 697.95 237.45 699.24 239.68 700.19 244.79 702.43 248.1 703.23 250.78 701.64 254.68 700.64 255.52 696.11 251.75 692.68 249.79 690.86 246.82 Z" id="35099"/>
            <path d="M 695.52 231.2 697.88 228.5 700.64 227.91 702.72 231.4 701.99 234.15 706.44 236.06 709.13 239.3 711.82 237.35 714.22 238.51 715.1 240.38 714.27 243.05 715.56 244.77 715.07 245.88 710.58 245.67 709.07 248.1 706.66 248.75 705.49 247.91 702.43 248.1 700.19 244.79 699.24 239.68 697.95 237.45 694.58 237.54 695.52 231.2 Z" id="35207"/>
            <path d="M 693.82 194.35 696.86 194.89 697.1 197.04 697.49 199.34 694.91 201.16 690.7 199.01 689.59 203.21 687.9 204 687.08 201.29 687.79 197.29 686.53 196.9 688.02 193.97 692.4 194.98 693.82 194.35 Z" id="35067"/>
            <path d="M 702.72 231.4 706.07 231.22 708.34 229.95 711.48 232.83 711.82 237.35 709.13 239.3 706.44 236.06 701.99 234.15 702.72 231.4 Z" id="35039"/>
            <path d="M 700.37 264.13 699.96 259.43 702.64 258.58 704.15 259.67 707.23 258.65 708.28 256.37 710.22 257.32 710.51 259.88 712.35 265.14 712.61 268.62 713.98 269.93 713 270.81 713.39 273.39 709.27 273.48 706.78 272.25 702.22 273.8 699.25 270.31 701.12 266.87 700.37 264.13 Z" id="35002"/>
            <path d="M 671.29 279.02 672.21 274.99 675.1 274.45 676.64 276.24 678.01 279.73 681.63 278.12 679.86 275.64 682.41 273.12 685.62 274.35 685.02 279.11 689.26 279.27 692.23 278.24 689.9 282.51 688.65 284.57 686.75 283.64 682.53 284.2 680.17 282.98 678.44 283.61 675.88 281.93 673.12 281.05 671.29 279.02 Z" id="35054"/>
            <path d="M 652.02 256.98 652.02 254.01 653.77 253.07 652.96 249.85 654.99 247.05 657.16 246.34 657.85 248.58 661.35 249.11 661.16 254.17 663.4 254.55 665.19 259.19 664.04 259.96 663.1 263.96 662.01 262.65 659.55 264.4 660.08 266.28 658.85 271.55 656.52 271.98 655.51 269.71 656.79 267.64 654.62 265.5 650.63 265.15 650.94 261.35 652.59 258.85 652.02 256.98 Z" id="35047"/>
            <path d="M 655.71 288.46 654.99 285.86 657.19 283 657.16 280.68 659.5 282.74 664.41 284.51 669.84 284.35 669.92 285.78 673.29 287.98 671.75 289.34 670.45 289.92 669.44 293.47 666.31 293.18 664.3 294.26 660.58 294.2 657.89 292.99 655.73 295.16 653.2 289.47 655.71 288.46 Z" id="35033"/>
            <path d="M 630.62 230.51 631.66 233.6 636.33 232.69 637.4 234.72 636.09 236.73 631.81 237.67 631.32 238.55 628.04 237 626.26 237.61 624.77 233.24 627.75 231.05 630.62 230.51 Z" id="35080"/>
            <path d="M 625.13 172.6 627.69 170.01 630.04 169.82 633.86 175.1 633.58 177.99 632.18 180.11 627.16 179.53 623.56 177.3 625.13 172.6 Z" id="35017"/>
            <path d="M 601.75 314.07 604.18 312.04 606.73 311.65 609.03 314.02 609.99 316.31 613.55 319.5 614.07 322.4 615.25 324.22 612.72 327.29 610.45 327.59 607.35 325.86 604.89 324.61 602.9 321.74 602.18 319.24 599.69 318.34 601.75 314.07 Z" id="35045"/>
            <path d="M 669.84 284.35 672.03 284.68 673.12 281.05 675.88 281.93 678.44 283.61 679.11 289.36 678.49 290.94 675.46 290.61 671.75 289.34 673.29 287.98 669.92 285.78 669.84 284.35 Z" id="35090"/>
            <path d="M 559.28 238.08 559.72 240.12 556 241.35 555.45 239.96 557.03 238.37 559.28 238.08 Z" id="56225"/>
            <path d="M 574.37 293.48 575.84 292.33 578.11 292.62 575.64 286.12 575.68 283.49 578.57 282.04 581.04 281.58 581.95 279.01 585.57 277.36 586.8 277.49 588.53 279.92 589.42 281.39 590.04 285.05 593.56 289.34 592.96 292.37 590.7 294.24 588.31 295.36 586.37 297.81 586.27 300.69 590.24 306 588.32 306.93 587.54 309.7 580.31 308.08 578.38 308.14 580.96 303.45 580.87 300.97 579.24 299.28 575.45 297.52 574.37 293.48 Z" id="56075"/>
            <path d="M 583.39 362.39 587.5 364.81 587.12 368.1 590.44 368.32 590.47 370.03 588.81 371.26 586.81 371.26 582.39 374.25 583 368.95 580.8 366.5 583.39 362.39 Z" id="56223"/>
            <path d="M 654.94 212.03 657.73 209.69 661.34 210.96 662.18 213.33 662.79 216.01 664.56 219.45 660.92 217.77 659.98 218.2 658.05 219.41 654.85 217.87 655.31 215.39 654.94 212.03 Z" id="35059"/>
            <path d="M 633.59 86.51 633.02 88.43 635.34 90.98 634.27 95.5 631.15 95.59 630.64 93.26 628.47 93.01 627.25 94.39 626.39 93.47 626.74 89.39 624.78 86.86 627.19 87.43 630.91 86.27 633.59 86.51 Z" id="35122"/>
            <path d="M 547.48 267.34 547.67 266 553.06 260.25 554.39 259.93 555.44 263.48 553.19 265.37 553.17 268.52 550.01 268.95 547.48 267.34 Z" id="56256"/>
            <path d="M 583.95 334.92 592.18 334.48 592.55 338.18 596.8 337.81 597.48 341.69 594.32 341.32 588.7 341.69 587.6 342.26 585.99 338.26 582.06 338.96 581.55 338.07 583.95 334.92 Z" id="56044"/>
            <path d="M 681.09 206.41 681.89 198.13 684.49 198.57 686.53 196.9 687.79 197.29 687.08 201.29 687.9 204 686.47 208.49 681.09 206.41 Z" id="35197"/>
            <path d="M 637.88 154.14 638.45 153.6 641.04 153.2 642.67 152.01 646.14 154.13 648.06 153.19 649.52 153.95 649.99 157.75 646.22 158.2 644.17 156 642.05 155.02 641.75 157.44 639.9 158.48 637.88 154.14 Z" id="35056"/>
            <path d="M 669.77 112.5 672.23 112.58 673.15 111.54 673.82 108.14 675.76 107.73 679.3 108.47 681.92 108.38 682.86 110.48 681.76 117.34 680.62 120.29 679.9 123.35 676.1 123.83 674.76 124.75 671.42 123.18 671.97 118.25 671.24 113.63 669.77 112.5 Z" id="35034"/>
            <path d="M 638.1 278.03 639.76 276.74 640.73 270.53 643.15 269.22 646.22 268.5 650.23 266.35 650.63 265.15 654.62 265.5 656.79 267.64 655.51 269.71 656.52 271.98 658.21 276.36 657.16 280.68 657.19 283 654.99 285.86 655.71 288.46 652.93 285.82 649.76 284.95 648.15 282.92 645.43 283.5 641.59 282.99 640.42 280.8 638.9 280.69 638.1 278.03 Z" id="35126"/>
            <path d="M 628.07 160.4 629.97 158.33 631.38 155.34 634.36 155.13 636.45 156.64 637.88 154.14 639.9 158.48 637.92 161.68 638.3 166.37 638.04 167.27 632.08 168.21 631.26 167.48 629.17 164.67 628.07 160.4 Z" id="35265"/>
            <path d="M 541.03 277.58 542.36 273.19 548.28 271.68 550.01 268.95 553.17 268.52 554.01 268.71 559.59 266.73 563.85 266.45 567.2 268.17 567.41 270.34 566.6 273.96 568.61 279.56 567.55 282.88 567.55 282.89 565.05 285.07 561.81 282.68 556.3 283.16 549.98 284.36 549.49 286.7 548.03 288.49 546.93 287.25 543.15 286.92 543.03 284.07 541.74 281.83 540.83 280.02 541.03 277.58 Z" id="56032"/>
            <path d="M 615.13 236.32 619.83 231.29 622.98 231 623.02 232.97 624.77 233.24 626.26 237.61 627.5 239.81 630.15 241.79 630.93 244.21 632.8 245.44 629.64 246.18 627.78 248.3 624.12 248.95 624.4 242.85 621.12 242.82 619.3 241.83 616.88 241.86 614.76 238.84 615.13 236.32 Z" id="35331"/>
            <path d="M 595.29 238.36 596.46 237.1 600.69 234.65 596.4 230.31 593.83 230.16 594.78 226.27 591.1 223.72 592.07 221.87 595.73 223.78 600.02 222.17 605.31 221.23 605.26 213.94 608.97 214.97 610.81 217.7 613.11 220.25 614.14 222.31 613.29 225.46 610.62 229.35 610.29 231.64 611.61 234.54 615.13 236.32 614.76 238.84 616.88 241.86 616.16 244.01 608.75 246.97 606.72 247.14 601.15 246.08 600.19 248.1 598.27 245.53 595.6 246.55 594.48 244.9 591.03 244.65 590.73 243.93 595.29 238.36 Z M 584.55 220.73 586.61 219.86 586.88 222.59 589.12 221.53 591 223.77 586.69 222.64 583.44 223.38 584.55 220.73 Z" id="35133"/>
            <path d="M 677.89 266.69 682.75 262.48 686.08 261.75 687.2 265.94 688.05 266.2 685.12 271.86 682.41 273.12 679.86 275.64 681.63 278.12 678.01 279.73 676.64 276.24 678.43 275.86 679.66 272.33 678.07 269.36 675.55 268.76 675.22 266.92 677.89 266.69 Z" id="35032"/>
            <path d="M 533.59 257.48 535.2 257.23 539.26 252.66 540.83 254.2 542.67 253.37 549.56 253.78 552.86 253.62 555.92 257.07 554.39 259.93 553.06 260.25 547.67 266 547.48 267.34 540.54 267.25 537.58 264.2 536.62 262.42 534.6 261.54 533.59 257.48 Z" id="56145"/>
            <path d="M 671.99 153.46 671.61 155.65 668.74 154.79 664.93 152.42 664.21 150.47 665.84 149.37 668.72 149.61 671.99 153.46 Z" id="35148"/>
            <path d="M 682.41 273.12 685.12 271.86 688.05 266.2 690.8 265.97 694.22 263.74 695.39 263.92 699.17 265.45 700.37 264.13 701.12 266.87 699.25 270.31 698.05 273.77 693.89 273.3 692.23 278.24 689.26 279.27 685.02 279.11 685.62 274.35 682.41 273.12 Z" id="35088"/>
            <path d="M 686.23 208.98 686.47 208.49 687.9 204 689.59 203.21 690.7 199.01 694.91 201.16 694.76 204.07 693.03 205.43 690.21 209.4 686.23 208.98 Z" id="35315"/>
            <path d="M 673.2 191.64 676.11 191.37 677.73 190.29 679.5 192.45 678.02 194.16 677.03 197.25 673.07 194.78 673.2 191.64 Z" id="35274"/>
            <path d="M 680.4 183.29 681.92 180.28 681.81 178.07 686.62 176.57 689.11 173.41 691.21 174.05 693.22 176.44 690.22 179.47 690.58 180.71 693.8 180.1 693.01 181.78 690.59 183.49 688.34 182.93 685.65 184.52 681.83 185.14 680.4 183.29 Z" id="35003"/>
            <path d="M 659.53 189.39 665.73 189.12 668 187.34 669.13 184.09 671.51 182.66 672.59 180.05 675.91 180.82 678.03 182.78 679.34 181.91 680.4 183.29 681.83 185.14 678.55 186.96 677.73 190.29 676.11 191.37 673.2 191.64 669.12 191.66 667.04 191.83 662.66 190.94 660.07 191.67 659.53 189.39 Z" id="35296"/>
            <path d="M 547.34 343.23 552.55 342.44 554.35 340.01 558.03 339.52 559.38 341.59 557.01 345.24 558.15 346.92 558.5 349.92 557.57 351.21 553.61 351.34 552.83 350.52 549.1 350.04 547.17 350.88 545.36 348.05 547.88 345.68 547.34 343.23 Z" id="56218"/>
            <path d="M 656.52 271.98 658.85 271.55 660.08 266.28 659.55 264.4 662.01 262.65 663.1 263.96 664.21 266.19 665.7 265.66 666.56 268.17 668.6 268.26 668.71 277.92 671.29 279.02 673.12 281.05 672.03 284.68 669.84 284.35 664.41 284.51 659.5 282.74 657.16 280.68 658.21 276.36 656.52 271.98 Z" id="35139"/>
            <path d="M 639.88 127.83 643.16 126.17 643.53 124.11 646.19 122.31 648.14 126.81 650.63 127.52 652.93 125.9 656.72 124.98 659.99 122.96 661.41 124.19 663.26 128.75 664.78 129.27 664.55 132.48 666.07 132.86 664.87 135.93 662.3 135.99 661.4 134.21 656.07 134.65 655.41 132.64 652.83 132.32 651.72 130.92 649.43 130.63 646.54 129.19 645 131.14 643.03 131.55 642.36 128.96 639.88 127.83 Z" id="35029"/>
            <path d="M 671.42 123.18 674.76 124.75 676.1 123.83 679.9 123.35 680.98 125.39 680.24 127.32 680.64 129.63 676.48 131.25 675.24 129.9 670.45 134.14 668.1 131.51 668.54 127.25 671.42 123.18 Z" id="35044"/>
            <path d="M 627.04 259.15 632.27 259.6 635.09 261.44 638.34 261.42 641.67 259.76 642.4 255.13 643.43 252.45 644.57 252.6 647.78 253.39 648.32 255.8 652.02 256.98 652.59 258.85 650.94 261.35 650.63 265.15 650.23 266.35 646.22 268.5 643.15 269.22 640.73 270.53 636.25 270.7 631.58 271.5 628.95 266.82 626.46 265.39 626.31 260.61 627.04 259.15 Z" id="35123"/>
            <path d="M 687.2 257.42 688.54 253.26 689.71 251.72 692.04 251.35 692.68 249.79 696.11 251.75 700.64 255.52 703.44 256.47 704.15 259.67 702.64 258.58 699.96 259.43 700.37 264.13 699.17 265.45 695.39 263.92 695.02 261.24 692.17 260.07 689.98 260.15 688.61 257.79 687.2 257.42 Z" id="35204"/>
            <path d="M 626.26 237.61 628.04 237 631.32 238.55 631.81 237.67 636.09 236.73 637.4 234.72 636.33 232.69 640.16 231.65 640.33 230.05 641.97 231.81 642.03 235.19 641.19 236.76 648.19 240.03 648.62 242.67 648.55 245.83 646.55 247.01 644.57 252.6 643.43 252.45 643.96 248.88 642.4 246.42 640.02 247.58 638.26 247.09 636.88 245.24 633.1 245.36 632.8 245.44 630.93 244.21 630.15 241.79 627.5 239.81 626.26 237.61 Z" id="35196"/>
            <path d="M 639.9 158.48 641.75 157.44 642.05 155.02 644.17 156 646.22 158.2 649.99 157.75 652.34 159.71 652.82 162.28 648.96 168.05 647.39 169.46 645.47 167.93 640.92 168.96 638.3 166.37 637.92 161.68 639.9 158.48 Z" id="35233"/>
            <path d="M 677.73 190.29 678.55 186.96 681.83 185.14 685.65 184.52 688.34 182.93 690.59 183.49 693.01 181.78 691.12 185.98 693.47 190.59 694.08 193.11 693.82 194.35 692.4 194.98 688.02 193.97 686.53 196.9 684.49 198.57 681.89 198.13 682.28 195.92 679.99 193.93 679.5 192.45 677.73 190.29 Z" id="35251"/>
            <path d="M 692.23 278.24 693.89 273.3 698.05 273.77 700.21 276.83 700.23 279.97 699.04 283.92 695.47 288.21 694.46 286.17 689.9 282.51 692.23 278.24 Z" id="35041"/>
            <path d="M 675.11 205.22 677.54 198.18 677.03 197.25 678.02 194.16 679.5 192.45 679.99 193.93 682.28 195.92 681.89 198.13 681.09 206.41 681.3 209.12 679.13 208.86 677.19 206.41 675.11 205.22 Z" id="35079"/>
            <path d="M 661.06 201.11 663.22 201.14 663.08 198.6 665.98 198.44 665.96 194.46 669.12 191.66 673.2 191.64 673.07 194.78 677.03 197.25 677.54 198.18 675.11 205.22 673.92 208.08 671.72 207.78 667.39 213.64 664.37 212.9 662.18 213.33 661.34 210.96 662.94 210 661.46 208.17 662.19 205.94 660.53 203.9 661.06 201.11 Z" id="35173"/>
            <path d="M 554.39 259.93 555.92 257.07 558.17 254.05 560.48 252.24 562.11 250.75 566.79 251.37 568.54 250.76 571.29 251.22 575.86 247.67 576.15 243.93 578.56 241.43 580.23 241.26 584.1 241.78 585.32 243.3 590.19 244.5 590.73 243.93 591.03 244.65 592.71 247.46 591.62 250.14 594.13 251.4 595.25 254.77 593.35 257.88 589.86 258.09 586.14 266.71 581.93 267.63 581.49 269.7 582.25 272.1 580.84 270.68 577.15 271.05 575.26 272.01 572.63 270.8 572.49 269.39 567.9 267.57 567.2 268.17 563.85 266.45 559.59 266.73 554.01 268.71 553.17 268.52 553.19 265.37 555.44 263.48 554.39 259.93 Z" id="35211"/>
            <path d="M 600.19 248.1 601.15 246.08 606.72 247.14 608.75 246.97 616.16 244.01 616.88 241.86 619.3 241.83 621.12 242.82 616.19 247.44 617.82 250.92 615.59 252.77 611.54 254.35 609.27 253.84 609.41 251.25 608.31 250.44 605.84 252.12 601.34 252.76 600.19 248.1 Z" id="35187"/>
            <path d="M 121.25 111.2 125.13 112.86 126.86 114.71 128.85 115.39 129.89 118.13 131.71 119.29 131.1 122.06 133.43 121.81 134.17 122.96 129.9 128.05 128.97 130.06 126.52 134.23 124.67 133.92 124.06 129.82 121.95 129.82 119.66 127.35 120.54 125.33 119.43 124.29 119.74 119.37 117.2 115.64 118.14 114.01 120.5 113.44 121.25 111.2 Z" id="29015"/>
            <path d="M 582.41 233.8 585.19 233.72 586.89 235.49 588.3 235.08 592.86 234.94 595.29 238.36 590.73 243.93 590.19 244.5 585.32 243.3 584.1 241.78 580.23 241.26 580.02 236.11 582.41 233.8 Z" id="35290"/>
            <path d="M 612.19 260.82 612.54 258.63 613.95 256.76 616.03 256.13 615.59 252.77 617.82 250.92 622.61 250.34 624.41 251.83 627.73 252.97 627.05 259.06 622.74 259.45 622.24 261.73 620.34 260.98 617.36 262.31 614.15 262.08 612.19 260.82 Z" id="35319"/>
            <path d="M 622.61 250.34 624.32 249.13 627.91 248.31 629.64 246.24 632.57 245.97 633.1 245.36 636.88 245.24 638.26 247.09 640.02 247.58 642.4 246.42 643.96 248.88 643.43 252.45 642.4 255.13 641.67 259.76 638.34 261.42 635.09 261.44 632.27 259.6 627.04 259.15 627.05 259.06 627.73 252.97 624.41 251.83 622.61 250.34 Z" id="35037"/>
            <path d="M 595.25 254.77 599.55 253 599.38 255.39 601.4 256.59 602.24 262.84 606.06 263.36 606.49 264.85 601.84 268.76 597.53 270.64 597.95 272.76 596.4 277.31 592.63 276.78 590.43 279.7 588.53 279.92 586.8 277.49 585.57 277.36 585 276.61 582.25 272.1 581.49 269.7 581.93 267.63 586.14 266.71 589.86 258.09 593.35 257.88 595.25 254.77 Z" id="35223"/>
            <path d="M 570.25 221.79 572.93 220.95 575.24 223.63 577.1 223 578.74 224.49 580.67 223.71 583.59 223.77 583.32 225.53 585.52 227.54 586.28 229.52 582.41 233.8 580.02 236.11 580.23 241.26 578.56 241.43 576.15 243.93 573.6 242.12 572.31 233.07 572.73 231.46 571.35 228.29 570.25 221.79 Z" id="35201"/>
            <path d="M 621.34 179.42 618.84 181.61 618.79 179.75 621.34 179.42 Z" id="35022"/>
            <path d="M 151.71 133.34 155.85 130.94 155.98 129.85 160.69 131.2 162.89 132.99 163.41 135.68 163.5 136.5 161.31 136.21 157.59 138.14 155.58 139.79 155.79 137.91 153.89 135.14 151.71 133.34 Z" id="29245"/>
            <path d="M 96.22 137.14 96.97 138.03 100.5 138.19 101.28 135.71 103.17 134.94 103.38 140.34 105.86 141.71 107.41 146.9 104.1 148.2 103.78 146.71 99.11 146.21 97.05 146.97 96.11 143.94 95.74 138.26 96.22 137.14 Z" id="29260"/>
            <path d="M 146.83 132.31 148.25 131.98 147.69 126.63 149.56 126.05 149.32 121.98 150.82 121.66 152.41 119.58 151.14 116.72 152.72 116.25 154.53 120.55 153.41 123.91 154.98 125.75 155.98 129.85 155.85 130.94 151.71 133.34 149.32 134.46 146.83 132.31 Z" id="29095"/>
            <path d="M 559.22 247.82 563.4 247.76 563.77 244.2 566.3 242.65 572.37 242.65 573.6 242.12 576.15 243.93 575.86 247.67 571.29 251.22 568.54 250.76 566.79 251.37 562.11 250.75 560.48 252.24 558.87 249.16 559.22 247.82 Z" id="56043"/>
            <path d="M 585 276.61 585.57 277.36 581.95 279.01 581.04 281.58 578.57 282.04 577.78 277.69 578.9 276.49 583.96 277.08 585 276.61 Z" id="56226"/>
            <path d="M 580.2 358.47 583.95 356.98 585.1 357.57 588.64 359.32 588.84 362.24 587.5 364.81 583.39 362.39 580.46 359.14 580.2 358.47 Z" id="56232"/>
            <path d="M 627.25 94.39 628.47 93.01 630.64 93.26 631.15 95.59 634.27 95.5 634.15 97.31 633.93 100.69 632.43 100.43 629.3 102.25 628.77 101.77 626.06 96.31 627.25 94.39 Z" id="35279"/>
            <path d="M 634.65 118.25 637.07 120.51 640.08 120.23 640.7 118.33 643.03 118.36 643.31 120.74 646.24 121.07 646.19 122.31 643.53 124.11 643.16 126.17 639.88 127.83 637.78 127.85 634.07 123.35 633.07 119.62 634.65 118.25 Z" id="35362"/>
            <path d="M 634.27 95.5 635.34 90.98 635.82 90.38 637.98 89.23 641.02 90.08 642.51 89.16 644.08 90.67 645.91 97.4 645.88 102.08 646 103.07 639.32 98.82 637.89 97.76 634.15 97.31 634.27 95.5 Z" id="35116"/>
            <path d="M 646.24 121.07 646.62 114.62 651.16 112.44 653.59 111.77 658.11 112.72 659.28 112.41 658.78 117.64 659.94 117.68 659.99 122.96 656.72 124.98 652.93 125.9 650.63 127.52 648.14 126.81 646.19 122.31 646.24 121.07 Z" id="35009"/>
            <path d="M 587.6 342.26 588.7 341.69 594.32 341.32 597.48 341.69 596.8 337.81 600.21 336.57 601.35 337.17 602.73 340.58 601.66 345.31 605.54 346.25 604.08 348.2 601.68 353.39 598.6 355.93 596.71 358.96 589.31 358.8 588.64 359.32 585.1 357.57 584.6 355.33 585.99 349.9 583.77 347.47 583.31 346.21 587.1 344.3 587.6 342.26 Z" id="35013"/>
            <path d="M 590.7 294.24 593.74 297.91 594.81 298.24 596.35 303.05 598.14 303.94 598.15 303.98 596.29 306.37 595.33 308.86 591.04 311.1 589 311.04 587.54 309.7 588.32 306.93 590.24 306 586.27 300.69 586.37 297.81 588.31 295.36 590.7 294.24 Z" id="35084"/>
            <path d="M 633.93 100.69 634.15 97.31 637.89 97.76 639.32 98.82 640.14 102.04 636.11 103.21 633.93 100.69 Z" id="35153"/>
            <path d="M 659.94 117.68 663.3 115.56 663.5 113.31 665.35 110.18 668.27 111.15 669.77 112.5 671.24 113.63 671.97 118.25 671.42 123.18 668.54 127.25 668.1 131.51 667.02 132.77 666.07 132.86 664.55 132.48 664.78 129.27 663.26 128.75 661.41 124.19 659.99 122.96 659.94 117.68 Z" id="35104"/>
            <path d="M 662.04 103.7 665.35 100.27 667.91 98.58 669.42 102.78 672.47 101.48 674.32 103.9 674.42 106.52 675.76 107.73 673.82 108.14 673.15 111.54 672.23 112.58 669.77 112.5 668.27 111.15 665.35 110.18 663.69 105.89 662.04 103.7 Z" id="35010"/>
            <path d="M 627.16 179.53 632.18 180.11 632.8 185.77 631.45 187.45 627.78 188.49 626.78 185 625.23 183.51 627.16 179.53 Z" id="35050"/>
            <path d="M 632.18 180.11 633.58 177.99 633.86 175.1 635.15 175.45 634.59 176.67 636.69 178.51 635.69 180.14 637.23 181.24 637.44 183.33 633.88 184.47 632.8 185.77 632.18 180.11 Z" id="35134"/>
            <path d="M 591.03 244.65 594.48 244.9 595.6 246.55 598.27 245.53 600.19 248.1 601.34 252.76 599.55 253 595.25 254.77 594.13 251.4 591.62 250.14 592.71 247.46 591.03 244.65 Z" id="35305"/>
            <path d="M 598.93 307.36 600.65 307.63 602.27 305.68 607.93 304.54 607.7 307.1 609.47 308.21 606.73 311.65 604.18 312.04 601.75 314.07 599.5 313.31 598.27 308.21 598.93 307.36 Z" id="35311"/>
            <path d="M 105.86 141.71 109.81 139.42 111.65 137.71 114.26 137.02 117.25 139.77 117.52 141.32 120.26 147.27 118.35 149.4 112.91 150.47 111.74 152.06 110.25 151.54 109.85 148.94 107.41 146.9 105.86 141.71 Z" id="29069"/>
            <path d="M 588.53 279.92 590.43 279.7 594.44 282.18 595.93 281.16 598.22 286.32 593.92 288.1 593.56 289.34 590.04 285.05 589.42 281.39 588.53 279.92 Z" id="35160"/>
            <path d="M 121.27 135.64 123.41 136.5 124.97 139.62 120.26 147.27 117.52 141.32 117.25 139.77 117.18 138.29 120.13 137.67 121.27 135.64 Z" id="29011"/>
            <path d="M 590.7 294.24 592.96 292.37 595.29 293.23 598.82 293.34 600.99 295.28 604.26 296.77 604.3 298.98 598.14 303.94 596.35 303.05 594.81 298.24 593.74 297.91 590.7 294.24 Z" id="35046"/>
            <path d="M 126.52 134.23 128.97 130.06 130.64 129.53 131.82 131.33 131.75 133.29 135.88 133.86 138.42 132.7 138.32 136.48 136.96 136.04 136.32 140.43 132.98 142.7 130.32 141.11 126.52 134.23 Z" id="29061"/>
            <path d="M 133.43 121.81 133.88 116.55 135.57 114.25 139.67 111.37 139.86 109.39 142.98 107.04 144.07 106.94 143.06 107.46 144.5 112.9 148.14 115.14 147.91 116.93 151.14 116.72 152.41 119.58 150.82 121.66 149.32 121.98 149.56 126.05 147.69 126.63 148.25 131.98 146.83 132.31 143.75 132.14 138.42 132.7 135.88 133.86 131.75 133.29 131.82 131.33 130.64 129.53 128.97 130.06 129.9 128.05 134.17 122.96 133.43 121.81 Z" id="29160"/>
            <path d="M 567.2 268.17 567.9 267.57 572.49 269.39 572.63 270.8 575.26 272.01 577.15 271.05 580.84 270.68 582.25 272.1 585 276.61 583.96 277.08 578.9 276.49 577.78 277.69 578.57 282.04 575.68 283.49 573.04 283.12 569.33 283.64 567.55 282.88 568.61 279.56 566.6 273.96 567.41 270.34 567.2 268.17 Z" id="56012"/>
            <path d="M 573.92 354.53 575.66 350.93 575.15 348.98 576.49 345.63 579.84 345.48 580.66 346.6 583.77 347.47 585.99 349.9 584.6 355.33 585.1 357.57 583.95 356.98 580.2 358.47 573.92 354.53 Z" id="56239"/>
            <path d="M 657.61 196.33 660.07 191.67 662.66 190.94 667.04 191.83 669.12 191.66 665.96 194.46 665.98 198.44 663.08 198.6 663.22 201.14 661.06 201.11 659.02 197.2 657.61 196.33 Z" id="35193"/>
            <path d="M 668.6 268.26 671.53 264.88 672.16 261.64 674.14 261.11 676.87 263.93 677.89 266.69 675.22 266.92 675.55 268.76 678.07 269.36 679.66 272.33 678.43 275.86 676.64 276.24 675.1 274.45 672.21 274.99 671.29 279.02 668.71 277.92 668.6 268.26 Z" id="35208"/>
            <path d="M 621.12 242.82 624.4 242.85 624.12 248.95 627.78 248.3 629.64 246.18 632.8 245.44 633.1 245.36 632.57 245.97 629.64 246.24 627.91 248.31 624.32 249.13 622.61 250.34 617.82 250.92 616.19 247.44 621.12 242.82 Z" id="35351"/>
            <path d="M 594.79 324.12 595.15 320.38 599.69 318.34 602.18 319.24 602.9 321.74 604.89 324.61 607.35 325.86 607.26 328.41 604.72 333.78 606.6 334.85 607.45 339.16 608.97 338.74 611.96 340.56 611.26 343.27 608.51 343.06 602.73 340.58 601.35 337.17 600.21 336.57 596.8 337.81 592.55 338.18 592.18 334.48 583.95 334.92 584.84 331.98 586.98 332.24 588.39 329.59 590.2 330.02 594.41 326.98 594.79 324.12 Z" id="35328"/>
            <path d="M 664.83 139.74 664.87 135.93 666.07 132.86 667.02 132.77 667.61 134.93 670.21 135.41 669.8 136.91 670.79 141.04 667.52 141.85 667.39 143.97 664.72 143.26 664.83 139.74 Z" id="35342"/>
            <path d="M 566.51 366.76 568.13 363.15 569.99 362.67 570.4 360.16 575.19 360.87 577.63 359.22 580.46 359.14 583.39 362.39 580.8 366.5 583 368.95 582.39 374.25 576.01 377.45 575.32 379.43 575.69 384.39 573.47 385.96 571.05 384 572.45 379.64 571.38 375.87 567.12 375.36 567.63 373.76 565.84 372.58 563.96 372.76 564.71 369.39 566.61 368.71 566.51 366.76 Z" id="56001"/>
            <path d="M 528.07 351.5 530.86 349.33 529.65 347.78 529.9 345.2 532.07 339.58 534.94 340.77 537.82 340.83 541.16 339.61 547.34 343.23 547.88 345.68 545.36 348.05 544.22 348.77 543.45 351.92 540.61 351.64 541.08 354.87 539.05 355.55 535.69 359.3 532.45 357.74 530.19 355.75 528.07 351.5 Z" id="56171"/>
            <path d="M 103.25 125.61 106.57 126.22 108.86 124.13 108.77 118.55 109.73 117.06 112.15 118.87 114.31 120.32 115.11 124.13 116.81 126.87 119.66 127.35 121.95 129.82 124.06 129.82 124.67 133.92 123.11 133.41 121.27 135.64 120.13 137.67 117.18 138.29 117.25 139.77 114.26 137.02 111.65 137.71 109.81 139.42 105.86 141.71 103.38 140.34 103.17 134.94 101.28 135.71 103.07 134.34 103.8 127.89 103.25 125.61 Z" id="29076"/>
            <path d="M 561.78 353.2 566.33 351.9 566.95 354.27 569.34 353.73 573.92 354.53 580.2 358.47 580.46 359.14 577.63 359.22 575.19 360.87 570.4 360.16 569.99 362.67 568.13 363.15 566.51 366.76 561.46 364.55 558.73 363.7 561.28 359.63 562.23 354.18 561.78 353.2 Z" id="56221"/>
            <path d="M 514.94 343.76 516.95 337.29 515.64 334.48 518.83 334.5 521.57 332.98 523.74 336.72 531.1 337.07 532.07 339.58 529.9 345.2 529.65 347.78 530.86 349.33 528.07 351.5 525.63 350.91 522.26 349.1 520.45 345.09 518.35 346.33 514.94 343.76 Z" id="56135"/>
            <path d="M 678.45 246.89 680.23 246.32 680.46 243.78 682.58 243.04 685.88 243.87 690.86 246.82 692.68 249.79 692.04 251.35 689.71 251.72 688.54 253.26 687.2 257.42 685.85 259.16 686.08 261.75 682.75 262.48 683.14 259.1 682.98 253.91 681.06 250.86 680.46 248.54 676.21 250.14 676.49 248.08 678.45 246.89 Z" id="35352"/>
            <path d="M 593.56 289.34 593.92 288.1 598.22 286.32 595.93 281.16 596.94 280.46 600.84 281.05 610.99 277.2 610.94 283.1 613.64 283.86 616.37 283.75 619 286.35 620.38 289.73 618.09 290.62 614.3 289.95 611.97 293.21 608.32 293.25 610.79 297.1 616.89 299.17 621.96 299.58 622.72 298.77 625.94 299.13 628.8 297.75 629.57 298.37 629.58 300.31 627.79 301.68 627.23 303.62 623.69 303.16 622.12 305.85 618.15 306.79 615.88 305.87 612.44 306.02 611.84 307.23 614.28 308.56 614.84 309.99 617.34 311.93 614.4 312.47 612.14 309.96 611.35 307.58 609.47 308.21 607.7 307.1 607.93 304.54 602.27 305.68 600.65 307.63 598.93 307.36 598.15 303.98 598.14 303.94 604.3 298.98 604.26 296.77 600.99 295.28 598.82 293.34 595.29 293.23 592.96 292.37 593.56 289.34 Z" id="35168"/>
            <path d="M 569.69 329.65 571.94 327.69 574.61 327.21 580.84 327.54 582.37 329.23 584.24 328.17 584.57 325.64 587.17 325.43 587.2 322.4 588.47 322.33 592.01 324.26 594.79 324.12 594.41 326.98 590.2 330.02 588.39 329.59 586.98 332.24 584.84 331.98 583.95 334.92 581.55 338.07 582.06 338.96 585.99 338.26 587.6 342.26 587.1 344.3 583.31 346.21 583.77 347.47 580.66 346.6 579.84 345.48 576.49 345.63 575.58 344.77 574.82 344.63 575.84 339.23 577.15 337.38 576.95 335.68 571.86 334.31 571.46 331.71 569.69 329.65 Z" id="56061"/>
            <path d="M 568.16 308.37 569.86 307.56 573.94 307.55 578.38 308.14 580.31 308.08 587.54 309.7 589 311.04 591.04 311.1 595.33 308.86 596.29 306.37 598.15 303.98 598.93 307.36 598.27 308.21 599.5 313.31 601.75 314.07 599.69 318.34 595.15 320.38 594.79 324.12 592.01 324.26 588.47 322.33 587.2 322.4 587.17 325.43 584.57 325.64 584.24 328.17 582.37 329.23 580.84 327.54 574.61 327.21 571.94 327.69 569.69 329.65 568.31 327.73 568.93 325.76 571.46 325.07 572.45 320.28 571.21 319.75 570.33 318.25 571.91 316.41 570.96 313.91 568.4 312.34 568.87 309.82 568.16 308.37 Z" id="56033"/>
            <path d="M 700.64 255.52 701.64 254.68 703.23 250.78 702.43 248.1 705.49 247.91 706.66 248.75 709.07 248.1 710.58 245.67 715.07 245.88 715.56 244.77 717.43 245.58 719.07 253.59 716.98 255.12 713.56 255.68 710.22 257.32 708.28 256.37 707.23 258.65 704.15 259.67 703.44 256.47 700.64 255.52 Z" id="35069"/>
            <path d="M 636.11 103.21 640.14 102.04 639.32 98.82 646 103.07 649.21 105.16 650.72 109.1 651.16 112.44 646.62 114.62 643.84 114.32 642.04 110.64 639.55 109.43 638.98 107.05 635.91 104.06 636.11 103.21 Z" id="35246"/>
            <path d="M 629.3 102.25 632.43 100.43 633.93 100.69 636.11 103.21 635.91 104.06 638.98 107.05 639.55 109.43 642.04 110.64 643.84 114.32 646.62 114.62 646.24 121.07 643.31 120.74 643.03 118.36 640.7 118.33 640.08 120.23 637.07 120.51 634.65 118.25 634.89 115.44 633.87 111.35 634.8 108.17 634.45 105.86 632.81 104.27 630.17 103.52 629.3 102.25 Z" id="35224"/>
            <path d="M 635.15 175.45 636.72 175.39 638.81 172.54 643.11 174.18 641.45 178.65 642.4 181.39 641.44 182.42 637.44 183.33 637.23 181.24 635.69 180.14 636.69 178.51 634.59 176.67 635.15 175.45 Z" id="35258"/>
            <path d="M 280.61 189.29 284.3 190.56 285.6 190.13 288.83 191.35 289.73 189.38 292.22 188.62 294.08 189.2 296.1 188.2 297.8 189.71 297.79 193.63 296.71 194.44 295.45 195.12 295.06 197.58 292.71 198.63 291.98 200.61 288.59 200.79 287.3 202.95 285.94 201.83 282.51 202.93 280.8 200.93 279.14 202.7 276.26 201.54 275.82 198.19 277.33 196.6 279.3 197.72 279.97 194.67 277.93 193.89 278.03 192.05 280.21 190.69 280.61 189.29 Z" id="29029"/>
            <path d="M 504.28 335.06 505.75 332.77 509.28 334.11 511.69 332.94 515.64 334.48 516.95 337.29 514.94 343.76 507.71 343.09 505.37 341.85 504.36 340.05 505.49 336.15 504.28 335.06 Z" id="56045"/>
            <path d="M 654.99 247.05 655.32 245.35 656.82 242.38 658.76 242.94 661.36 241.5 664.76 238.63 668.87 238.15 666.91 242.83 667.38 245.56 666.93 247.85 664.91 246.82 661.35 249.11 657.85 248.58 657.16 246.34 654.99 247.05 Z" id="35281"/>
            <path d="M 345.55 194.23 347.94 192.52 351.12 192.72 352.83 191.93 352.88 190 357.39 191.68 357.39 196.92 358.37 198.62 361.7 202.13 362.15 203.76 360.8 206.23 361.36 207.45 364.7 209.97 363.05 210.15 359.22 213.26 354.92 212.9 352.1 212.01 354.09 210.33 352.56 208.57 351.52 204.86 348.42 201.35 347 201.6 346.78 198.73 348.05 196.57 345.55 194.23 Z" id="22266"/>
            <path d="M 314.82 309.69 314.35 312.25 316.22 314.55 321.57 316.06 323.39 313.54 326.72 313.23 328.6 315.86 330.11 316.09 329.06 317.49 328.18 321.25 326.99 323.73 327.96 325.28 326.53 324.6 325.18 327.07 326.62 328.38 325.43 331.44 327.59 334.67 323.89 338.55 321.33 340.55 318.11 341.6 316.04 337.23 312.4 333.29 312.34 329.25 313.45 324.65 314.79 324.05 314.26 321.28 311.29 319.11 310.79 317.13 310.76 313.56 313.1 310.55 314.82 309.69 Z" id="56078"/>
            <path d="M 347.96 340.32 346.39 343.19 344.1 344.92 342.41 343.57 340.61 343.62 338.94 343.56 338.81 340.23 337.67 335.42 335.96 333.55 337.06 331.21 338.69 331.14 341.3 332.64 343.98 332.16 344.5 333.16 345.05 335.06 346.68 335.49 347.96 340.32 Z" id="56121"/>
            <path d="M 338.94 343.56 340.61 343.62 342.44 344.89 343.17 347.62 342.5 349.95 339.51 352.29 335.6 351.91 334.85 349.05 336.15 346.91 338.21 345.57 338.94 343.56 Z" id="56107"/>
            <path d="M 312.4 333.29 310.74 334.41 304.47 332.48 302.98 333.31 297.53 331.88 296.12 332.49 298.58 322.52 300.78 321.37 302.46 318.05 302.07 316.83 303.31 314.14 304.78 314.41 308.31 317.31 310.79 317.13 311.29 319.11 314.26 321.28 314.79 324.05 313.45 324.65 312.34 329.25 312.4 333.29 Z" id="29031"/>
            <path d="M 758.86 280.33 759.34 278.75 764.28 276.89 766.6 273.21 768.52 274.99 771.01 273.8 773.51 275.28 771.96 276.42 770.98 279.72 766.69 282.58 764.06 282.28 761.21 282.3 758.86 280.33 Z" id="35008"/>
            <path d="M 776.74 256.99 778.72 256.49 781.95 258.08 785.21 260.71 789.16 262.25 789.34 264.22 786.8 266.69 787 270.59 784.26 269.66 784.48 267.22 780.38 262.97 777.98 261.7 776.73 259.81 776.74 256.99 Z" id="35042"/>
            <path d="M 359.5 329.39 357.33 331 356.65 333.76 354.08 336.18 350.82 340.15 347.96 340.32 346.68 335.49 345.05 335.06 344.5 333.16 345.4 330.78 349.81 328.32 351.63 330.47 354.38 328.7 357.22 324.97 358.87 326.19 358.25 327.99 359.5 329.39 Z" id="56098"/>
            <path d="M 761.12 307.29 759.04 308.72 760.85 311.07 760.43 313.37 758.06 313.06 755.44 311.14 749.98 310.43 751.16 309.01 748.97 306.48 748.6 304.73 751.45 304.43 753.95 302.9 755.64 303.25 758.33 305.88 761.12 307.29 Z" id="35077"/>
            <path d="M 774.01 224.64 776.45 224.17 776.78 227.39 779.58 228.23 784.64 227.49 786.89 227.99 787.56 230.06 790.75 229.67 792.05 235.31 787.67 235.53 785.34 237.45 783.91 240.13 782.15 240.4 780.47 242.95 777.66 243.36 775.39 241.91 773.01 243.38 770.73 242.18 772.13 234.71 770.79 232.81 772.46 230.84 774.25 227.13 774.01 224.64 Z" id="35105"/>
            <path d="M 285.25 222.15 288.92 220.79 293.24 217.4 295.18 216.48 301.44 214.57 302.8 213.32 306 212.24 308.32 214.04 310.92 216.41 313.07 220.92 313.04 224.85 314.28 228.18 313.82 229.43 309.66 227.87 303.32 230.54 300.31 233.2 299.23 235.04 299.68 236.84 297.76 240.31 299.41 242.68 298.22 243.68 294.34 243.25 292.32 240.58 292.27 238.55 290.09 238.21 288.01 239.45 284.34 239.54 285.57 235.66 287.58 232.07 286.44 230.58 287.11 227.87 285.16 224.59 285.25 222.15 Z" id="56066"/>
            <g/>
            <g/>
            <path d="M 360.54 329.51 365 326.24 366.66 324.29 369.28 322.63 373.96 323.14 376.34 326.78 376.18 329.71 375.97 334.35 376.41 336.74 373.09 338.02 371.33 340.05 368.96 339.91 366.85 340.95 361.85 340.29 357.16 340.23 354.33 339.64 355.8 338.97 357.69 332.81 359.84 332.96 360.54 329.51 Z" id="56094"/>
            <g/>
            <path d="M 384.15 379.45 377.01 374.28 376.91 372.97 374.28 369.06 375.04 367.22 377.83 367.1 379.58 364.72 382.99 365.09 386.28 364.38 387.45 366.54 389.35 366.08 389.01 363.75 391.66 362.54 394.16 363.64 394.35 364.77 391.68 367.04 394.82 370.27 394.67 373.94 392.23 373.92 388.87 375.24 388.18 377.19 384.15 379.45 Z" id="56054"/>
            <path d="M 335.6 351.91 332.64 351.22 330.6 352.88 327.85 353.85 326.03 351.33 323.17 349.58 323.31 348.38 318.45 343.58 318.11 341.6 321.33 340.55 323.89 338.55 327.59 334.67 332.05 334.53 332.61 333.49 335.96 333.55 337.67 335.42 338.81 340.23 338.94 343.56 338.21 345.57 336.15 346.91 334.85 349.05 335.6 351.91 Z" id="56162"/>
            <path d="M 356.64 355.49 356.98 355.23 360.71 356.66 361.2 355.78 359.55 352.27 363.2 352.28 364.09 351.08 367.97 347.1 369.93 344.49 371.87 344.89 374.84 342.56 376.21 342.54 374.55 347.21 377.8 351.89 381.53 353.89 376.78 357.71 375.99 360.56 374.57 362.56 375.04 367.22 374.28 369.06 368.03 363.21 362.73 358.69 356.64 355.49 Z" id="56169"/>
            <path d="M 412.94 221.16 415.31 219.52 419.2 219.38 420.32 223.54 423.03 225.69 423.75 229.78 427.36 231.08 430.22 230.32 431.98 231.51 433.24 229.63 432.17 232.45 430.03 235.36 427.12 236.88 427.47 239.98 425.83 240.29 421.8 238.07 420.39 239.28 417.16 239.48 415.99 236.99 414.54 233.77 416.6 232.28 416.18 230.53 412.98 230.92 412.52 228.9 414.46 228.04 413.12 225.94 414.75 224.11 412.94 221.16 Z" id="56146"/>
            <path d="M 444.4 225.15 445.68 225.37 450.43 223.43 452 223.58 453.09 225.67 456 228.06 457.07 230.68 457.21 233.82 450.29 232.39 449.26 234.31 444.14 236.47 445.34 234.7 443.6 232.65 442.65 229.77 445.84 227.35 444.4 225.15 Z" id="56215"/>
            <path d="M 458.82 240.85 461.43 237.97 461.88 234.99 463.44 232.35 465.02 233.18 472.62 233.52 472.2 237.01 473.47 239.34 468.95 240.79 469.64 244.41 468.63 246.22 465.97 248.05 461.68 245.82 459.66 246.27 456.66 245.14 454.59 243.87 458.82 240.85 Z" id="56198"/>
            <path d="M 140 150.89 141.96 146.97 143.78 145.56 146.77 145.49 148.94 149.88 147.91 151.02 145.65 150.98 142.97 152.93 140 150.89 Z" id="29235"/>
            <path d="M 536.21 228.13 536.12 229.51 538.69 233.16 535.58 235.31 533.79 237.88 533.86 239.75 529.86 238.43 527.64 239.26 526.7 234.38 530.1 230.75 531.22 228.17 535.14 226.95 536.21 228.13 Z" id="56025"/>
            <path d="M 502.98 236.76 504.36 236.21 507.97 230.65 511.72 232.41 512.79 236.07 508.53 242.75 506.78 241.85 504.54 243.87 502.66 244.04 502.7 243.97 502.6 240.91 501.57 237.73 502.98 236.76 Z" id="56257"/>
            <path d="M 785.34 237.45 787.67 235.53 792.05 235.31 792.61 240.01 794.35 243.42 797.32 245.85 794.93 246.11 793.52 242.17 788.8 241.16 787.68 242.2 787.55 239.04 785.34 237.45 Z" id="35038"/>
            <path d="M 308.32 214.04 314.12 214.31 319.17 213.08 322.46 212.17 323.6 213.83 327.7 214.36 329.15 211.9 334.01 215.67 330.46 219.05 330.83 221.06 334.45 223.82 335.62 227.22 333.06 230.26 333.43 231.87 331.67 235.69 330.47 235.99 326.14 240.97 325.75 243.82 322.81 245.46 318.82 244.83 315.46 243.44 314.66 241.54 310.23 241.45 309.57 240.54 312.94 234.83 313.82 229.43 314.28 228.18 313.04 224.85 313.07 220.92 310.92 216.41 308.32 214.04 Z" id="56100"/>
            <path d="M 550.27 394.86 551.38 393.3 554.57 392.37 553.97 388.48 558.13 387.3 562.36 387.96 563.93 385.78 568.46 385.07 569.2 389.82 567.5 394.29 567.07 398.67 569.73 400.95 570.88 403.33 570.49 406.72 568.62 407.04 568.5 407.04 564.38 410.26 561.16 411.56 560.23 411.04 558.11 406.08 554.44 407.26 551.26 405.4 549.52 403.19 550.51 400.36 548.92 397.48 550.27 394.86 Z" id="56147"/>
            <path d="M 590.07 372.27 591.96 374.26 592.51 376.27 592.13 380.31 588.84 382.45 589.52 386.73 588.32 387.67 584.85 387.45 581.7 384.71 577.92 385.52 575.69 384.39 575.32 379.43 576.01 377.45 582.39 374.25 586.81 371.26 588.81 371.26 590.07 372.27 Z" id="56194"/>
            <path d="M 526.9 416.47 525.63 416.9 524.09 419.74 521.08 420.71 520.31 424.81 520.25 424.84 518.07 424.42 515.19 426.03 513.41 424.88 511.8 421.82 513.19 420.85 514.34 418.23 511.63 413.23 512.3 411.34 515.41 411.06 517.68 413.25 521.32 412.68 522.27 411.5 527.18 412.05 526.9 416.47 Z" id="56155"/>
            <path d="M 587.41 186.3 591.78 186.29 594.28 183.41 593.27 181.05 595.83 180.83 598.46 179.57 598.92 181.84 606.02 184.98 605.63 188.7 603.95 190.99 602.85 194.72 601.61 196.39 601.65 200.53 598.64 200.19 596.92 197.13 594.38 199.91 592.55 199.72 590.67 197.78 590.17 195.46 588.15 193.08 589.87 189.08 586.97 187.95 587.41 186.3 Z" id="35171"/>
            <path d="M 417.44 349.65 420.54 348.99 421.3 350.14 421.75 354.7 423.28 355.43 427.96 354.22 429.47 351.72 434.98 353.49 437.87 356.07 437.88 356.08 436.12 356.4 434.27 358.29 431.29 358.31 429.81 359.9 429.98 363.28 428.56 366.14 424.73 368.03 423.84 370.05 420.47 365.55 421.72 364.71 421.91 362.25 419.2 358.79 418.63 356.36 416.95 354.72 418.55 351.71 417.44 349.65 Z" id="56176"/>
            <path d="M 349.26 350 348.93 348 349.5 348.06 349.26 350 Z" id="56181"/>
            <path d="M 187.71 292.88 188.64 296.4 190.71 296.93 194.51 296.1 198.36 300.09 198.04 301.13 199.84 303.94 192.51 308.03 192.16 306.09 189.6 307.46 188.57 302.23 186.04 300.49 181.59 296.88 182.63 293.69 182.95 289.5 185.29 286.42 185.72 286.81 188.47 290.54 187.71 292.88 Z" id="29037"/>
            <path d="M 159.79 161.57 154.99 166.68 150.9 166.08 149.92 171.14 147.39 171.97 141.07 172 140.47 169.56 135.09 171.74 134.62 168.41 136.73 167.1 135.77 164.7 137.47 162.79 138.46 160.19 140.8 159.12 142.92 155.65 145.2 153.87 150.71 151.33 151.68 149.75 154.38 149.33 156.37 154.34 159.07 156.19 157.61 158.65 159.74 159.96 159.79 161.57 Z" id="29189"/>
            <path d="M 606.1 95.24 609.31 96.1 607.56 100.91 605.05 103.58 603.35 97.15 606.1 95.24 Z" id="35181"/>
            <path d="M 443.86 236.7 444.14 236.47 449.26 234.31 450.29 232.39 457.21 233.82 458.82 240.85 454.59 243.87 456.66 245.14 454.33 248.94 451.29 246.53 451.5 244.05 450.37 240.69 446.25 243.69 444.46 243.47 443.86 236.7 Z" id="56072"/>
            <path d="M 620.84 74.38 623.74 73.26 631.05 73.2 635 74.82 633.51 78 636.55 84.23 633.59 86.51 630.91 86.27 627.19 87.43 624.78 86.86 623.3 87.18 620.4 85.18 619.91 82.53 618.15 80.85 617.95 77.51 620.84 74.38 Z" id="35299"/>
            <path d="M 529.14 394.92 532.07 394.99 534.8 398.52 535.2 402.63 539.56 407.66 535.28 410.82 534.75 410.03 533.37 408.32 531.29 408.83 529.15 410.95 525.65 409.93 523.24 410.17 523.26 406.88 522.42 404.6 523.13 403.29 526.93 401.9 526.67 399.13 529.14 394.92 Z" id="56004"/>
            <path d="M 423.84 370.05 424.73 368.03 428.56 366.14 429.67 368.22 429.33 370.99 431.89 372.72 429.07 374.63 427.84 374.02 426.72 373 423.76 373.78 423.84 370.05 Z" id="56262"/>
            <path d="M 620.35 153.96 622.23 151.6 623.36 154.74 625.11 155.88 631.38 155.34 629.97 158.33 628.07 160.4 626.66 163.96 624.25 164.76 621.91 163.18 618.95 159.57 619.19 155.68 620.35 153.96 Z" id="35345"/>
            <path d="M 505.41 220.64 509.73 219.81 514.98 220.86 514.13 218.32 515.95 217.46 517.98 219.02 521.07 218.73 522.21 216.11 527.74 216.01 530.23 217.53 530.61 219.8 533.87 223.7 535.9 225.2 536.21 228.13 535.14 226.95 531.22 228.17 530.1 230.75 526.7 234.38 527.64 239.26 528.02 242.8 523.95 243.45 521.78 244.39 520.97 240.84 515.15 237.79 512.79 236.07 511.72 232.41 507.97 230.65 509.33 227.4 508.67 224.96 506.71 223.52 505.41 220.64 Z" id="56129"/>
            <path d="M 706.47 313.95 708.73 314.75 710.32 316.53 711.71 320.69 711.11 322.93 705.53 324.29 704.89 323.38 701.58 325.66 699.85 328.22 695.75 328.69 690.87 330.04 693.78 324.68 691.21 321.97 693.38 318.29 696.06 318.39 702.6 316.54 706.47 313.95 Z" id="35332"/>
            <g/>
            <path d="M 453.01 365.73 454.39 366.67 455.59 369.56 455.48 371.39 457.93 373.68 457.6 374.85 453.74 376.1 451.6 374.82 446.52 376.48 443.13 374.89 440.52 375.31 441.76 371.99 443.8 371.25 444.94 367.06 450.97 365.07 453.01 365.73 Z" id="56003"/>
            <path d="M 495.93 388.41 495.51 389.64 496.07 390.05 496.06 391.37 494.7 392.01 496.32 396.24 494.4 399.87 491.84 401.05 490.49 399.12 489.96 396.44 488.16 396.7 489.43 399.79 486.42 400.3 484.21 399.62 481.34 396.35 479.25 397.3 475.52 394.55 475.19 392.95 475.02 391.13 475.72 388.69 479.58 387.38 476.24 384.07 478.35 383.36 480.35 381.1 481.93 381.61 484.79 379.36 487.58 378.61 491.34 378.93 492.51 381.58 496.15 381.44 500.84 381.98 499.3 383.13 498.75 385.67 495.93 388.41 Z" id="56248"/>
            <path d="M 315.71 367.5 316.03 366.45 319.3 365.91 324.08 367.6 325.25 367.17 328.69 368.97 330.96 368.5 334.68 371.45 333.51 375.52 331.01 373.22 325.81 375.46 323.66 373.37 321.27 372.97 317.51 369.9 315.71 367.5 Z" id="56069"/>
            <path d="M 584.85 387.45 588.32 387.67 590.09 389.6 588.52 396.55 589.92 398.08 589.65 400.28 586.61 401.8 580.91 402.74 583.06 397.64 582.3 395.75 583.79 388.43 584.85 387.45 Z" id="56250"/>
            <path d="M 760.43 313.37 759.11 318.22 757.51 317.93 757.39 317.91 755.83 322.93 755.81 323.03 755.78 323.12 753.1 324.86 750.67 324.82 750.45 321.46 748.66 320.99 749.15 316.46 748.09 313.38 748.86 311.53 749.98 310.43 755.44 311.14 758.06 313.06 760.43 313.37 Z" id="35103"/>
            <path d="M 334.45 223.82 336.26 223.02 337.09 220.86 339.81 221.91 342.29 219.82 345.7 217.96 347.38 218.41 350.06 216.31 350.53 217.94 352.61 219.53 352.29 222.6 353.81 223.19 351.67 228.88 349.71 230.8 347.95 230.78 347.19 232.64 340.52 233.86 340.24 235.51 337.99 236.48 337.21 238.68 335.45 238.16 331.67 235.69 333.43 231.87 333.06 230.26 335.62 227.22 334.45 223.82 Z" id="56170"/>
            <path d="M 289.28 317.54 285.14 316.22 282.52 317.9 279.97 317.99 276.98 321 273.23 322.7 271.3 318.78 271.41 315.99 270.62 314.38 270.96 313.68 270.59 311.55 270.11 310.35 270.58 309.91 273.79 309.24 274.82 303.04 273.54 299.72 275.87 299.14 276.05 297.72 280.39 295.18 280.46 296.91 284.47 305.35 288.64 302.99 290.14 301.47 294.15 300.89 297.09 302.3 296.58 303.6 293.3 304.88 293.77 308.11 292.86 312.06 292.41 314.66 289.28 317.54 Z" id="29236"/>
            <path d="M 580.91 402.74 578.58 403.65 578.59 403.87 579.19 406.95 578.97 410.19 578.92 410.87 576.69 412.78 573.57 412.67 571.52 409.93 573.25 409.43 573.06 406.46 570.49 406.72 570.88 403.33 569.73 400.95 567.07 398.67 567.5 394.29 569.2 389.82 568.46 385.07 571.05 384 573.47 385.96 575.69 384.39 577.92 385.52 581.7 384.71 584.85 387.45 583.79 388.43 582.3 395.75 583.06 397.64 580.91 402.74 Z" id="56212"/>
            <path d="M 503.5 403.38 501.61 406.67 498.86 405.38 495.21 404.87 491.72 405.42 490.96 402.09 496.63 403.58 495.02 401.39 496.49 400.15 498.35 400.41 501.77 399.53 502.92 400.06 503.5 403.38 Z" id="56052"/>
            <path d="M 206.38 284.77 208.43 285.45 210.18 286.09 212.62 284.2 213.64 286.13 217.86 288.35 217.2 290.64 218.22 291.86 217.25 293.94 216.73 297.75 215.75 298.37 212.94 297.81 212.44 293.86 209.97 294.25 209.34 290.9 207.17 289.37 206.38 284.77 Z" id="29161"/>
            <path d="M 772.37 206.48 776.52 206.31 779.83 204.42 783.12 202.65 785.35 205.2 786.39 206.66 782.62 206.83 781.31 208.49 778.76 208.22 775.33 210.06 772.27 210.02 772.37 206.48 Z" id="35185"/>
            <path d="M 385.98 408.79 392.13 409.09 392.55 410.94 396.31 414.71 398.09 418.09 394.83 417.58 392.52 416.07 390.28 417.08 387.12 414.67 387.27 412.9 385.98 408.79 Z" id="56186"/>
            <path d="M 464.62 372.05 459.73 372.6 456.44 371.69 455.09 367.53 454.51 366.65 453.01 365.73 450.97 365.07 451.99 363.9 455.01 363.91 454.38 359.95 454.15 357.73 456.76 356.33 458.44 354.46 460.49 355.46 461.24 358.68 463.71 357.92 464.24 360.43 467.34 361.61 470.35 359.68 471.89 361.67 474.11 360.38 476.87 361 475.35 364.18 471.45 367.11 468.15 365.21 466.23 366.27 466 368.61 464.11 370.54 464.62 372.05 Z" id="56260"/>
            <path d="M 269.91 310.8 268.95 313.72 271.1 316.88 270.41 319.37 271.84 321.11 272.5 323.71 267.92 326.54 262.89 326.16 260.49 327.31 258.46 325.58 258.83 321.76 260.72 315.62 260.35 314.1 262.49 311.38 266.16 309.95 267.97 311.1 269.91 310.8 Z" id="29153"/>
            <path d="M 683 307.21 688.1 308.31 688.1 310.18 689.58 311.77 691.64 312.03 693.53 310.89 696.33 311.68 698.6 314.66 701.28 314.15 703.04 315.05 704.14 312.19 706.47 313.95 702.6 316.54 696.06 318.39 693.38 318.29 691.21 321.97 693.78 324.68 690.87 330.04 688.78 329.63 687.29 330.81 683.92 329.85 683.81 326.53 680.28 324.73 681.33 320.2 683.32 318.92 683.72 314.42 682.49 313.18 683.13 310.26 683 307.21 Z" id="35106"/>
            <path d="M 208.71 303.44 214.54 300.55 215.75 298.37 216.73 297.75 217.25 293.94 218.22 291.86 217.2 290.64 217.86 288.35 219.61 287.12 221.16 288.51 222.53 287.33 221.7 290.86 223.7 295.8 225.46 298.51 223.84 300.03 224.8 301.94 224.73 308.23 221.33 309.15 216.69 309.51 213.97 311.04 211.93 308.48 209.48 306.85 207.87 304.52 208.71 303.44 Z" id="29058"/>
            <path d="M 356.64 355.49 351.92 354.16 352.77 352.73 356.98 355.23 356.64 355.49 Z" id="56062"/>
            <path d="M 198.73 276.11 200.12 277.41 199.81 281.16 200.2 284.58 198.98 285.85 196.85 285.65 196.14 286.2 196.3 287.23 194.47 287.56 194.54 288.74 193.18 291.31 193.63 295.75 189.41 295.97 187.71 292.88 188.47 290.54 185.72 286.81 185.96 284.49 187.47 280.86 189.94 278.17 193.24 276.07 197.11 276.28 198.73 276.11 Z" id="29170"/>
            <path d="M 672.62 88.4 673.47 88.65 678.9 87.26 679.66 90.42 676.42 92 676.58 94.44 679.31 98.99 680.02 101.2 680.46 104.47 679.3 108.47 675.76 107.73 674.42 106.52 674.32 103.9 672.47 101.48 669.42 102.78 667.91 98.58 668.48 96.9 667.43 94.03 670.15 93.17 669.07 89.94 672.62 88.4 Z" id="35259"/>
            <g/>
            <path d="M 74.49 156.4 72.48 154.04 71.72 148.07 73.33 145.42 78.16 143.59 86.88 145.01 87.45 143.88 90.81 143.06 96.11 143.94 97.05 146.97 97.81 148.44 94.58 149.42 92.79 152.53 90.92 151.17 89.25 152.13 89.14 155.35 84.82 158.94 82.42 159.65 82.72 156.35 81.61 154.62 79.35 156.5 77.36 159.52 74.49 156.4 Z" id="29201"/>
            <path d="M 617.38 173.61 620.27 172.23 623.8 173.41 625.13 172.6 623.56 177.3 621.34 179.42 618.79 179.75 618.84 181.61 617.23 182 616.84 181.12 617.01 179.93 614.86 178.15 617.14 177.04 617.38 173.61 Z" id="35156"/>
            <path d="M 704.14 312.19 706.01 306.35 708.33 302.77 710.15 301.56 713.81 305.24 716.6 305.55 716.86 307.36 720.76 308.35 722.9 308.23 723.84 309.97 723.08 315.9 720.32 314.38 717.17 315.02 713.43 314.65 709.39 313.63 708.73 314.75 706.47 313.95 704.14 312.19 Z" id="35335"/>
            <path d="M 465.97 248.05 468.63 246.22 469.64 244.41 468.95 240.79 473.47 239.34 472.2 237.01 472.62 233.52 472.87 231.73 477.96 227.99 479.55 227.58 482.61 231.62 482.71 234.12 481.67 235.21 483.02 237.55 482.26 240.63 480.72 242.26 480.49 244.35 478.83 248.55 479.03 251.36 481.15 253.08 480.77 254.93 481.9 256.7 482.69 259.23 482.62 263.22 481.38 263.76 479.05 263.39 477.17 260.31 473.92 260.36 472.02 258.56 471.67 256.35 466.63 251.74 466.86 249.26 465.97 248.05 Z" id="56024"/>
            <path d="M 533.86 239.75 533.79 237.88 535.58 235.31 538.69 233.16 539.54 233.9 542.3 235.64 543.24 238.9 546.75 242.79 544.15 244.14 543.2 242.52 539.37 243.36 537.99 246.88 536.62 245.28 533.92 243.98 532.96 241.21 533.86 239.75 Z" id="56208"/>
            <path d="M 436.25 453.94 437.94 454.95 440.01 454.71 441.39 456.52 438.39 457.81 436.84 456.48 436.25 453.94 Z" id="56085"/>
            <path d="M 436.8 380.35 432.91 379.93 431.83 382.44 432.59 383.53 429.67 385.31 429.44 380.95 426.48 381.74 425.07 375.16 427.84 374.02 429.07 374.63 431.89 372.72 436.54 370.45 437.8 368.95 441.76 371.99 440.52 375.31 443.1 377.1 440.74 379.26 442.58 380.64 441.61 382.23 436.8 380.35 Z" id="56008"/>
            <path d="M 772.2 260.62 772.23 258.64 775.67 256.1 776.74 256.99 776.73 259.81 777.98 261.7 780.38 262.97 784.48 267.22 784.26 269.66 780.26 269.28 779.05 270.19 776.61 275.12 773.51 275.28 771.01 273.8 772.61 271.53 770.69 270.08 771.16 268.31 773.45 267.48 773.57 264.94 774.62 262.57 772.2 260.62 Z" id="35119"/>
            <path d="M 450.73 385.51 455.16 380.85 456.52 381.49 452.54 386.68 450.73 385.51 Z" id="56088"/>
            <path d="M 391.71 460.2 396.79 462.54 400.92 461.99 404.27 464.1 402.59 465.61 402.17 468.04 400.73 469.03 400.16 471.48 396.92 472.33 392.14 469.96 387.62 470.7 386.63 469.1 384.42 469.06 386.1 467.78 386.97 465.52 389.1 462.7 391.71 460.2 Z" id="56114"/>
            <path d="M 513.12 402.49 512.16 403.98 508.15 402.19 503.5 403.38 502.92 400.06 501.77 399.53 498.35 400.41 496.23 399.44 497.44 396.92 501.6 395.32 497.92 394.01 497.13 395.52 496.37 394.5 497.5 393.26 497.59 392.05 496.66 391.82 496.23 390.76 496.87 390.18 495.76 389.69 495.93 388.41 498.75 385.67 499.3 383.13 500.84 381.98 504.57 380.65 506.89 381.17 507.17 382.95 505.82 384.11 505.25 388.83 508.1 389 507.46 392.96 513.19 393.55 512.96 395.56 513.76 399.64 512.33 400.82 513.12 402.49 Z" id="56002"/>
            <path d="M 549.07 404.66 549.52 403.19 551.26 405.4 551 405.38 549.07 404.66 Z" id="56195"/>
            <path d="M 296.12 332.49 291.61 331.36 289.7 329.64 285.96 329.16 283.33 330.32 280.68 327.79 276.15 324.47 275.52 322.86 280.48 318.31 282.7 318.38 285.3 316.76 289.28 317.54 292.41 314.66 292.86 312.06 295.34 310.62 297.08 311.75 299.39 312.68 301.5 311.97 302.07 316.83 302.46 318.05 300.78 321.37 298.58 322.52 296.12 332.49 Z" id="29150"/>
            <path d="M 206.38 284.77 207.17 289.37 203.27 289.85 203.84 293.08 203.01 295.33 199.25 296.07 197.18 297.04 194.44 294.82 193.88 292.15 194.81 287.59 196.39 287.7 196.51 286.41 198.97 286.26 202.35 282.53 203.34 283.86 206.38 284.77 Z" id="29060"/>
            <path d="M 680.62 120.29 681.76 117.34 682.86 110.48 681.92 108.38 685.49 107.75 690.04 106.29 691.03 103.56 694.18 104.25 696.21 104.18 700.2 102.72 699.02 105.24 699.12 107.31 702.26 109.78 702.03 112.09 700.77 112.09 698.57 114.97 696.59 114.55 694.9 117.41 691.53 116.94 690.47 120.21 687.12 119.5 686.36 118.33 680.62 120.29 Z" id="35222"/>
            <path d="M 498.78 99.48 500.21 99.14 502.46 101.96 507.74 101.52 511.41 98.76 512.24 95.9 514.14 95.17 518.61 96.32 517.85 100.99 518.76 101.28 516.86 105.41 518.11 107.79 517.39 110.35 512.83 111.05 511.62 112.48 510.85 113.74 504.91 111.89 502.37 108.66 501.03 103.46 499.55 102.87 498.78 99.48 Z" id="22273"/>
            <path d="M 678.9 87.26 691.78 83.88 692.8 87.37 694.89 88.88 693.2 90.91 692.41 94.48 694.31 97.57 691.19 99.8 691.05 101.49 687.61 101.11 684.25 103.61 682.25 100.51 684.51 98.49 684.91 94.85 683.06 93.08 681.17 93.13 679.66 90.42 678.9 87.26 Z" id="35247"/>
            <path d="M 223.7 295.8 221.7 290.86 222.53 287.33 225.02 282.66 226.78 281.34 229.83 282.37 229.84 284.08 233.65 283.64 235.13 286.63 231.51 288.84 232.26 293.48 231.06 296.86 229.58 295.84 227.17 296.37 223.7 295.8 Z" id="29057"/>
            <path d="M 644 334.67 648.58 333.27 651.62 331.72 651.65 331.91 651 336.59 652.89 337.58 651.63 339.45 651.91 345.63 651.42 350.69 650.21 352.64 644.22 350.87 641.94 349.55 640.12 350.65 640.78 346.74 639.24 343.07 639.19 340.99 642.05 338.55 644 334.67 Z" id="35249"/>
            <path d="M 71.64 137.19 73.51 139.18 76.27 138.57 77.69 136.32 75.05 135.79 74.77 132.51 76.25 129.49 80.38 128.37 82.66 128.28 83.14 129.91 85.59 133.69 89.26 133.16 91.83 135.12 93.45 134.53 93.58 135.79 96.22 137.14 95.74 138.26 96.11 143.94 90.81 143.06 87.45 143.88 86.88 145.01 78.16 143.59 73.33 145.42 71.72 148.07 67.92 145.63 69.52 142.7 70.09 139.02 71.64 137.19 Z" id="29177"/>
            <path d="M 151.54 298.19 155.8 295.13 158.49 294.08 161.64 296.34 157.45 301 158.48 303.47 155.19 304.79 153.65 305.63 151.54 298.19 Z" id="29292"/>
            <path d="M 691.05 101.49 691.19 99.8 694.31 97.57 692.41 94.48 693.2 90.91 694.89 88.88 696.97 89.78 698.29 91.91 696.93 95.34 697.16 97.43 699.36 97.14 701.82 101.3 700.2 102.72 696.21 104.18 694.18 104.25 693.93 101.93 691.05 101.49 Z" id="35270"/>
            <path d="M 772.22 224.56 772.53 221.54 773.82 220.5 773.79 217.59 774.64 211.4 775.33 210.06 778.76 208.22 781.31 208.49 782.62 206.83 786.39 206.66 787.41 209.06 787.06 213.98 788.12 217.82 787.85 219.93 788.01 221.09 783.53 222.98 783 224.4 778.62 222.92 776.45 224.17 774.01 224.64 772.22 224.56 Z" id="35300"/>
            <path d="M 201.37 300.44 199.2 299.49 197.55 297.46 199.25 296.07 203.01 295.33 203.84 293.08 203.27 289.85 207.17 289.37 209.34 290.9 209.97 294.25 212.44 293.86 212.94 297.81 209.9 298.62 207.34 297.61 203.84 300.2 201.37 300.44 Z" id="29032"/>
            <path d="M 512.06 89.23 515.95 85.85 518.27 81.91 515.76 80.71 516.59 78.32 518.31 76.94 521.04 77.4 523.31 76.29 527.07 77.58 527.9 82.11 523.85 89.01 521.43 93.42 519.52 94.21 518.61 96.32 514.14 95.17 512.24 95.9 513.16 92.93 512.06 89.23 Z" id="22054"/>
            <path d="M 687.29 330.81 687.36 332.97 685.08 334.62 683.26 334.32 683.17 334.45 682.07 334.09 682.04 334.09 680.87 335.39 676.82 335.47 676.77 333.33 678 331.02 676.55 325.52 680.28 324.73 683.81 326.53 683.92 329.85 687.29 330.81 Z" id="35316"/>
            <g/>
            <path d="M 292.08 93.51 293.47 95.11 295.74 94.44 299.16 96.36 300.37 95.84 302.3 99.08 300.96 102.29 299.36 103.69 298.07 104.41 297.81 107.34 296.13 107.16 294.04 108.58 292.9 105 291.74 100.05 290.44 97.37 290.57 95.56 292.08 93.51 Z" id="29183"/>
            <path d="M 264.43 61.75 267.11 59.48 270.65 59.97 270.58 62.44 271.75 64.09 272.68 68.94 270.43 70.38 269.12 78.77 270.04 82.41 269.37 83.48 266.55 83.8 267.05 81.29 263.54 80.24 263.47 76.65 264.27 73.54 265.48 71.73 264.18 67.01 265.48 65.86 264.43 61.75 Z" id="29251"/>
            <path d="M 183.91 181.49 184.44 179.8 186.69 177.55 188.26 178.79 190.42 178.33 192.65 176.43 193.65 173.55 197.12 172 200.95 173.29 204.62 176.12 202.2 177.27 200.28 176.56 198.57 178.35 192.29 180.17 190.79 179.44 189.01 180.38 189.73 184.11 186.93 183.68 186.53 182.3 183.91 181.49 Z" id="29053"/>
            <path d="M 159.66 206.99 157.56 206.29 159.84 203.74 160.34 200.69 159.54 197.39 157.62 195.73 157.05 193.54 158.15 190.21 158.31 188.34 163.42 186.91 165.9 186.83 167.29 186.25 171.32 188.63 173.02 191.91 174.77 193.92 173.67 196.87 170.24 199.55 166.02 199.32 162.97 201.17 162.37 203.85 159.66 206.99 Z M 150.76 185.52 153.45 183.17 156.42 183.21 155.71 185.68 152.86 188.35 151.1 188.85 150.76 185.52 Z" id="29001"/>
            <path d="M 246.52 96.71 246.03 95.9 246.36 90.7 249.43 89.03 252.33 92.07 253.15 94.98 253.57 96.27 251.97 98.92 253.41 100.97 253.45 104.96 251.54 108.23 250.53 106.65 247.49 107.75 246.35 106.48 248.02 103.96 245.79 98.92 246.52 96.71 Z" id="29254"/>
            <path d="M 459.35 100.24 461.83 101.8 469.54 104.58 468.52 107.17 469.83 109.02 468.5 111.9 465.95 112.85 463.1 114.04 457.97 113.15 456.23 110.85 452.7 111.15 452.17 112.46 449.81 112.84 449.46 112.46 448.66 111.36 444.08 110.46 445.35 110.56 448.15 108.79 451.83 107.84 453.58 106.57 454.99 102.93 459.35 100.24 Z" id="22187"/>
            <path d="M 169.17 223.44 169.04 221.95 171.93 220.71 174.29 218.36 179.52 216.15 181.44 217.41 187.05 217.83 189.05 218.58 188.81 222.82 186.34 222.84 186.19 224.54 182.73 223.22 179.79 221.39 177.7 221.05 174.74 221.71 172.89 223.13 169.17 223.44 Z" id="29166"/>
            <path d="M 493.95 119.79 499.06 118.18 500.92 118.58 502.26 120.3 506.21 120.06 507.87 118.77 508.81 123.25 507.18 126.92 502.61 127.47 501.44 125.45 499.14 125.67 497.3 124.71 497.5 122.58 495.54 121.79 493.95 119.79 Z" id="22002"/>
            <path d="M 497.44 222.21 499.54 222.15 501.06 220.66 505.35 220.6 505.41 220.64 506.71 223.52 508.67 224.96 509.33 227.4 507.97 230.65 504.36 236.21 502.98 236.76 501.24 234.6 501.28 230.15 500.56 227.51 498.59 227.99 499.03 224.78 497.44 222.21 Z" id="22043"/>
            <path d="M 541.15 195.11 543.53 193.43 545.89 198.25 545.01 200.34 545.55 202.15 543.63 204.41 540.95 202.78 539 200.33 535.43 201.84 533.75 199.8 531.25 199.06 534.25 198.61 534.49 195.72 538.37 196.21 541.15 195.11 Z" id="22309"/>
            <path d="M 542.79 159.37 546.21 162.03 549.2 157.21 550.68 156.23 552.81 157.32 554.19 160.4 553.01 164 549.73 166 551.15 169.51 553.77 168.58 557.02 169.82 554.62 172.77 553.6 175.07 555.63 178.72 554.2 180.54 553.36 183.45 553.3 183.58 550.05 180.97 545.94 180.09 544.81 177.83 542.36 176.03 539 171.93 535.28 170.36 536.45 167.73 536.83 164.87 541.5 161.3 542.79 159.37 Z" id="22337"/>
            <path d="M 531.88 119.04 534.8 119.27 537.66 117.75 541.21 117.98 544.18 119.1 543.24 123.19 541.54 124.4 541.34 126.38 543.6 128.75 548.74 127.41 548.07 128.84 548.53 132.18 547.1 132.39 545.73 136.19 544.7 136.9 541.69 138.97 538.98 141.93 537.46 144.56 533.53 143.31 533.03 141.03 531.02 139.86 530.9 136.61 527.23 134.37 529.64 131.68 527.33 127.29 526.42 123.14 527.99 120.69 531.88 119.04 Z" id="22175"/>
            <path d="M 543.35 112.36 547.59 112.39 547.01 109.54 548.35 108.84 553.53 110.95 555.71 106.62 558.79 103.48 561.78 103.6 561.9 106.41 560.38 108.44 561.29 110.1 560.45 112.13 562 113.8 561.47 116.77 558.05 119.27 557.53 122.4 555.94 122.19 553.75 124.21 552.81 122.69 553.04 121.83 549.91 119.38 546.52 117.94 545.73 115.18 543.71 114.58 543.35 112.36 Z" id="22237"/>
            <path d="M 552.44 138.96 552.62 135.92 553.56 134.59 552.47 131.55 555.47 129.28 555.84 127.53 553.75 124.21 555.94 122.19 557.53 122.4 558.05 119.27 561.47 116.77 561.89 118.49 563.96 119.76 564.35 122 562.64 124.12 560.09 130.86 556.9 131.57 556.16 133.76 559.08 135.4 558.91 140.09 557.48 143.68 555.83 144.11 553.69 144.63 552.73 143.1 552.44 138.96 Z" id="22014"/>
            <path d="M 475.12 384.19 475.22 380.28 476.02 377.66 475.06 373.58 472.91 372.34 473.43 370.71 471.11 367.78 471.45 367.11 475.35 364.18 476.87 361 477.06 360.88 477.97 362.34 481.23 364.59 483.42 362.46 485.42 362.68 486.45 360.99 490.74 361.97 490.49 365.97 492.83 367.51 492.08 370.63 494.6 371.73 496.17 376.67 495.76 378.26 494.39 377.88 491.34 378.93 487.58 378.61 484.79 379.36 481.93 381.61 480.35 381.1 478.35 383.36 476.24 384.07 475.12 384.19 Z" id="56251"/>
            <path d="M 560.87 160.32 562.38 157.01 564.5 156.41 564.6 153.63 566.3 153.39 569.59 156.47 570.29 162.3 569.07 163.54 564.19 165.85 560.36 163.93 562.08 161.41 560.87 160.32 Z" id="22348"/>
            <path d="M 600.26 168.72 600.66 171.22 603.74 171.86 608.11 169.62 614.24 166.28 615.12 166.52 615.16 166.56 617.38 173.61 617.14 177.04 614.86 178.15 617.01 179.93 616.84 181.12 615.19 181.68 613.48 179.25 609.11 179.92 607.81 181.48 608.38 183.49 606.02 184.98 598.92 181.84 598.46 179.57 597.61 176.28 597.05 172.57 595.94 171.53 597.27 169.82 600.26 168.72 Z" id="22208"/>
            <path d="M 609.69 105.74 610.92 107.41 613.85 107.8 613.76 111.37 611.74 113.11 611.38 115.9 612.2 120.93 610.71 121.03 609.01 120.4 605.75 121.02 603.77 120.1 602 117.63 603.54 115.4 601.14 113.33 601.23 108.51 601.28 108.51 604.93 108.73 609.69 105.74 Z" id="22213"/>
            <path d="M 242.55 308.78 243.92 306.77 246.89 306.38 248.94 303.42 251.06 303.6 251.09 300.9 255.66 299.47 258.35 305.2 261.19 302.96 261.04 308.58 262.49 311.38 260.35 314.1 260.72 315.62 258.83 321.76 258.46 325.58 253.25 324.64 251.34 326.04 249.74 322.49 247.84 321.18 242.39 314.15 240.69 313.48 241.43 310.32 242.55 308.78 Z" id="29293"/>
            <path d="M 416.3 440.24 416.98 438.44 421.33 440.59 425.23 441.5 423.7 443.54 420.73 442.83 418.5 440.37 416.3 440.24 Z" id="56086"/>
            <path d="M 605.91 141.41 608.74 136.75 611.63 137.13 615.75 135.78 618.7 136.41 617.96 141.11 621.07 144.22 620.18 146.87 618.35 147.93 616.1 146.48 611.53 148.28 610.57 147.21 607.22 147.33 605.72 143.14 605.91 141.41 Z" id="22035"/>
            <path d="M 464.11 230.16 463.44 232.35 461.88 234.99 461.43 237.97 458.82 240.85 457.21 233.82 457.07 230.68 457.94 229.16 460.02 230.64 461.73 229.1 464.11 230.16 Z" id="22314"/>
            <path d="M 352.1 212.01 354.92 212.9 359.22 213.26 363.05 210.15 364.7 209.97 366.3 209.57 366.83 213.09 365.75 215.21 365.96 218.02 365.44 220.82 366.34 222.84 365.21 224.45 362.94 225.37 360.58 225.14 359.32 222.97 355.78 220.88 354.22 221.05 353.81 223.19 352.29 222.6 352.61 219.53 350.53 217.94 350.06 216.31 352.1 212.01 Z" id="22146"/>
            <path d="M 154.19 315.04 154.81 312.76 154.51 309.63 158.42 308.17 162.02 307.53 164.16 308.57 167.08 308.43 168.54 304.94 170.37 304.16 171.84 303.61 172.91 305.29 174.18 310.74 172.15 313.34 172.91 316.07 171.54 317.39 168.65 317.26 166.7 318.95 168.56 321.62 164.37 322.12 163 321.25 162.7 318.13 158.6 315.93 156.76 316.33 154.19 315.04 Z" id="29171"/>
            <path d="M 388.45 392.87 390.74 392.38 389.34 397.68 389.55 400.89 390.5 402.58 390.96 406.54 392.13 409.09 385.98 408.79 386.49 407.44 385.35 403.36 388.17 399.38 389.11 396.01 388.45 392.87 Z" id="56234"/>
            <path d="M 377.76 448.33 381.73 449.54 385.14 449.2 385.81 453.89 387.22 454.93 386.95 457.22 388.51 458.73 391.21 458.77 391.71 460.2 389.1 462.7 387.9 460.67 385.59 459.46 381.43 460.4 377.25 456.99 376.2 456.05 375.94 452.14 374.46 451.1 375.37 449.39 377.76 448.33 Z" id="56152"/>
            <path d="M 178.04 325.89 176.68 325.69 170.01 326.64 169.08 323.58 168.56 321.62 166.7 318.95 168.65 317.26 171.54 317.39 171.45 318.57 173.53 320.8 174.08 322.76 176.16 323.04 178.04 325.89 Z" id="29284"/>
            <path d="M 189.6 307.46 192.16 306.09 192.51 308.03 190.45 309.56 189.6 307.46 Z" id="29085"/>
            <path d="M 308.44 179.19 310.58 179.4 312.81 174.48 315.01 173.67 315.89 173.61 316.07 181.61 317.25 183.77 315.06 184.23 313.4 183 311.19 182.98 308.44 179.19 Z" id="22351"/>
            <path d="M 395.4 42.93 395.62 39.76 396.95 38.46 397 34.41 398.76 29.98 401.42 32.81 401.99 35.93 404.68 37.11 406.45 36.46 407.34 38 409.71 37.67 410.22 40.33 412.21 42.85 417.14 43.88 417.36 46.08 415.21 46.76 412.77 45.53 411.21 47.03 408.18 47.82 406.69 46.7 404.01 43.85 399.73 44.31 395.4 42.93 Z" id="22162"/>
            <path d="M 381.7 20.56 382.29 18.35 387.04 17.03 389.64 15.58 390.15 14.03 398.53 13.35 397.11 15.57 393.71 17.74 393.46 21.02 395.33 23.9 390.19 26.01 382.63 28.4 383.17 25.84 382.3 24.64 381.7 20.56 Z" id="22195"/>
            <path d="M 349.83 49.74 351.15 47.55 357.05 46.08 358.21 44.65 363.22 47.92 364.15 49.05 362.03 53.19 364.22 55.87 363.03 58 357.39 57.31 355.32 59.75 352.97 60.84 352.87 57.91 354.64 56.07 354.52 52.43 352.44 52.17 349.83 49.74 Z" id="22101"/>
            <path d="M 330.09 33.47 338.08 32.79 339.98 31.96 340.68 34.16 342.17 35.35 342.02 37 339.35 39.13 337.18 44.28 337.38 45.92 332.74 42.09 330.29 42.01 329.48 38.85 331.58 35.94 329.93 33.86 330.09 33.47 Z" id="22134"/>
            <path d="M 350.46 24.66 352.94 21.83 355.3 22 357.93 23.27 361.44 20.76 363.04 22.84 362.1 26.46 362.55 29.17 364.05 30.25 359.54 34.36 356.34 33.96 353.79 35.09 350.72 32.8 350.08 30.67 352.57 30.55 350.46 24.66 Z" id="22166"/>
            <path d="M 311.58 54.08 314.03 55.13 315.91 58.29 315.36 61.22 317.42 62.21 324.54 59.34 321.02 62.86 320.71 64.47 321.8 67.45 323.16 68.55 326.24 69.26 325.97 71.12 322.25 74.06 321.89 75.2 319.91 78.45 316.55 78.16 313.91 76.07 313.6 74.34 310.79 72.74 309.29 67.42 307.22 67.34 306.97 65.24 310.79 63.02 309.89 56.95 307.81 54.01 311.58 54.08 Z" id="22226"/>
            <path d="M 142.22 281.08 140.83 278.04 134.65 271.64 132.05 270.53 133.09 268.99 138.43 265.66 138.4 263.43 139.4 263.17 141.56 265.62 145.76 263.97 146.59 263.05 150.16 264.62 150.05 268.77 148.6 271.8 148.5 273.79 147.11 275.5 147.01 278.28 144.48 280.53 142.22 281.08 Z" id="29215"/>
            <path d="M 99.25 257.57 95.97 259.86 90.53 258.22 86.89 258.59 84.3 256.45 81.2 256.5 79.04 254.45 83.78 254.07 85.22 252.62 89.97 253.05 99.02 254.97 99.25 257.57 Z" id="29168"/>
            <g/>
            <path d="M 518.61 96.32 519.52 94.21 521.43 93.42 523.85 89.01 525.75 90.05 525.33 93.67 529.43 96.05 528.31 98.01 525.39 100.08 518.76 101.28 517.85 100.99 518.61 96.32 Z" id="22012"/>
            <path d="M 178.04 325.89 176.16 323.04 174.08 322.76 173.53 320.8 171.45 318.57 171.54 317.39 172.91 316.07 172.15 313.34 174.18 310.74 176.47 312.28 179.15 310.8 181.61 311.49 181 312.5 182.55 317.23 183.88 319.24 183.32 321.61 181.51 323.38 178.04 325.89 Z" id="29165"/>
            <path d="M 294.04 108.58 296.13 107.16 297.81 107.34 298.07 104.41 299.36 103.69 300.49 104.87 304.96 104.86 307.17 106.66 308.27 108.88 310.87 109.59 303.97 116.73 302.25 119.5 299.49 121.24 296.93 116.48 295.79 115.9 295.92 110.91 294.04 108.58 Z" id="29067"/>
            <path d="M 258.33 83.04 252.24 83.29 251.01 80.65 249.26 74.74 249.58 73.68 247.94 70.7 249.81 69.92 250.26 73.48 251.53 73.85 253.42 76.11 255.68 77.62 257.72 77.68 259.4 79.4 261.83 80.47 263.54 80.24 267.05 81.29 266.55 83.8 264.22 87.61 262.33 88.17 260.74 84.67 258.33 83.04 Z" id="29186"/>
            <path d="M 186.61 259.92 188.88 259.71 189.59 261.02 192.56 261.13 195.05 259.56 193.19 256.83 198.37 253.55 201.56 250.83 201.16 247.35 203.16 246.98 206.9 247.5 208.82 251.67 210.51 256.05 211.22 258.26 209.74 259.45 209.25 262.85 209.65 264.84 208.9 267.53 214.28 268.56 216.46 268.25 218.78 269.42 219.43 271.43 217.48 274.3 214.83 276.84 214.7 279.82 212.62 284.2 210.18 286.09 208.43 285.45 206.54 284.14 203.75 283.53 200.73 280.68 202.94 279.44 203.26 274.8 200.61 276.55 198.73 276.11 197.11 276.28 195.16 271.39 192.33 269.79 191.01 264.05 188.58 262.86 186.61 259.92 Z" id="29232"/>
            <path d="M 251.53 73.85 250.85 68.81 249.9 66.54 252.4 60.67 254.53 58.72 256.17 61.76 257.86 58.26 259.68 58.95 262.28 61.43 264.43 61.75 265.48 65.86 264.18 67.01 265.48 71.73 264.27 73.54 263.47 76.65 263.54 80.24 261.83 80.47 259.4 79.4 257.72 77.68 255.68 77.62 253.42 76.11 251.53 73.85 Z" id="29188"/>
            <path d="M 400.36 205.16 402.45 201.5 404.25 201.31 404.99 199.49 404.33 195.96 405.21 195.43 407.64 198.46 407.49 200.61 413.34 199.18 414.83 198.14 415.69 200.39 415.05 202.17 411.56 200.7 410.39 203.19 412.8 206.43 412.21 208.65 409.87 209.38 408.27 207.03 405.85 207.31 404.38 206.1 401.32 206.53 400.36 205.16 Z" id="22033"/>
            <path d="M 360.54 329.51 359.5 329.39 358.25 327.99 358.87 326.19 357.22 324.97 355.15 322.54 357.33 319.3 357.47 317.39 360.88 316.15 362.1 317.42 366.11 317.96 366.49 316.87 370 314.87 370.34 320.56 369.28 322.63 366.66 324.29 365 326.24 360.54 329.51 Z" id="56083"/>
            <path d="M 249.22 83.09 249.27 86.17 246.72 85.49 246.74 83.82 249.22 83.09 Z" id="29132"/>
            <path d="M 219.53 57.79 219.66 57.38 220.53 54.62 220.29 56.02 221.66 58.87 225.7 55.61 228.61 55.69 226.86 59.25 226.91 61.11 224.15 61.31 222.44 63.47 220.5 63.26 220.34 58.09 219.53 57.79 Z" id="29239"/>
            <path d="M 174.42 77.02 176.73 77.25 184.21 76.33 188.78 77.65 191.24 80.86 190.79 82.6 193.35 85.27 192.21 90.28 188.88 93.36 185.82 94.98 183.2 95.37 180.22 94.58 177.75 95.43 174.35 93.91 173.44 91.3 175.79 90.97 175.79 88.99 177.41 88.04 177.89 85.23 175.99 83.89 174.42 77.02 Z" id="29206"/>
            <path d="M 143.49 201.53 141.93 201.57 135.13 197.88 132.37 196.89 129.61 197.77 127.02 197.63 125.41 198.5 124.29 200.74 126.15 202.14 123.63 204.1 122.17 206.56 121.53 210.51 117.33 213.71 116.71 217.39 114.62 217.49 113.88 214.56 115.18 213.55 114.72 204.81 113.38 202.39 112.61 198.96 116.07 195.67 115.6 193.07 113.73 191.48 115.81 188.09 112.86 185.96 112.02 183.31 111.91 181.41 114.71 180.49 118.13 183.71 120.81 181.85 119.91 180.4 121.63 179.13 123.47 176.02 124.8 177.36 122.28 180.51 124.47 184.53 127.19 186.96 130.03 187.04 129.96 189.74 136.69 191.17 144.81 188.57 147.27 188.46 147.41 186.25 150.76 185.52 151.1 188.85 149.99 191.95 147.08 192.54 147.19 194.93 144.91 195.57 145.2 199.29 143.49 201.53 Z" id="29042"/>
            <path d="M 477.04 123.64 475.61 125.49 471.96 125.21 468.89 122.84 466.15 121.54 467.53 119.42 468.41 116.13 471.25 114.71 473.83 119.53 477.04 123.64 Z" id="22106"/>
            <path d="M 344.48 27.48 350.46 24.66 352.57 30.55 350.08 30.67 350.72 32.8 347.94 34.95 345.89 31.34 344.48 27.48 Z" id="22379"/>
            <path d="M 200.46 196.48 201.34 197.44 205.45 196.93 205.45 195.06 207.55 191.3 209.31 189.78 212.77 192.25 213.53 194.48 216.18 196.07 215.02 198.21 213.17 197.54 209.01 199.4 208.03 201.45 208.55 203.17 207.09 201.06 204.3 201.55 202.97 204.81 201.8 205.62 200.37 206 199.12 203.92 200.46 196.48 Z" id="29263"/>
            <path d="M 349.5 348.06 351.29 345.83 356.97 340.57 359.91 341.57 359.74 343.69 361.53 344.08 360.9 348.08 364.09 351.08 363.2 352.28 359.55 352.27 356.25 351.89 355.1 350.37 352.99 351.2 349.26 350 349.5 348.06 Z" id="56193"/>
            <path d="M 170.24 199.55 173.67 196.87 174.77 193.92 177.66 193.16 179.66 191.15 180.31 194.15 181.94 196.99 182.62 199.44 174.42 201.59 170.24 199.55 Z" id="29289"/>
            <path d="M 444.21 71.91 445.74 72.85 446.92 75.23 448.81 75.25 449.61 79.14 445.88 79.02 444.91 78.24 442.43 75 444.21 71.91 Z" id="22325"/>
            <path d="M 447.25 212.11 449.7 211.27 453.99 207.52 459.55 206.28 460.87 203.07 462.5 200.88 462.78 203.98 464.69 203.3 467.68 204.54 469.4 206.1 473.18 205.29 476.42 207.12 479.69 206.41 481.08 207.2 481.33 208.7 478.36 212.26 477.9 218.33 475.53 219.06 473.51 221.12 469.66 220.87 467.73 223.28 465.35 228.51 464.11 230.16 461.73 229.1 460.02 230.64 457.94 229.16 457.07 230.68 456 228.06 453.09 225.67 452 223.58 450.82 221.01 450.32 217.83 448.95 217.38 446.71 213.43 447.25 212.11 Z" id="22136"/>
            <path d="M 504.43 215.27 505.41 213.5 512.55 208.89 514.64 205.28 515.97 204.64 519.77 205.29 518.86 210.69 521.24 211.89 520.86 213.3 522.21 216.11 521.07 218.73 517.98 219.02 515.95 217.46 514.13 218.32 514.98 220.86 509.73 219.81 505.41 220.64 505.35 220.6 504.12 217.51 504.43 215.27 Z" id="22062"/>
            <path d="M 543.53 193.43 547.31 190.49 547.57 187.23 548.9 185.73 548.78 182.98 550.05 180.97 553.3 183.58 554.03 187.98 556.03 188.5 556.64 194.56 555.62 196.02 558.14 198.4 559.48 203.05 559.33 205.18 554.71 204.42 547.86 201.32 545.55 202.15 545.01 200.34 545.89 198.25 543.53 193.43 Z" id="22114"/>
            <path d="M 525.39 100.08 528.31 98.01 529.43 96.05 532.86 94.71 533.75 95.35 539.45 91.96 538.15 97.06 539.82 97.6 540.79 100.21 539.57 101.66 539.47 103.99 542.86 112.18 542.69 112.62 540.99 115.37 535.57 113.82 534.75 111.26 531.33 110.61 528.69 106.92 529.76 105.29 525.53 101.33 525.39 100.08 Z" id="22076"/>
            <path d="M 553.3 183.58 553.36 183.45 557 184.93 559.84 185.14 563 186.67 566.09 184.8 568.86 185.88 568.37 188.32 573.33 192.02 574.36 193.31 572.57 194.31 572.76 197.83 571.83 200.75 573.17 203.7 573.18 205.08 569.46 205.68 567.59 204.01 564.7 204.9 563.72 203.97 560.7 206.58 559.33 205.18 559.48 203.05 558.14 198.4 555.62 196.02 556.64 194.56 556.03 188.5 554.03 187.98 553.3 183.58 Z" id="22240"/>
            <path d="M 165.9 186.83 163.42 186.91 158.31 188.34 158.15 190.21 153.24 189.92 152.86 188.35 155.71 185.68 156.42 183.21 162.16 179.87 169.31 180.25 166.79 182.46 166.82 185.89 165.9 186.83 Z" id="29104"/>
            <path d="M 258.33 83.04 260.74 84.67 262.33 88.17 263.59 93.03 261.53 95.24 262.26 97.04 258.61 98.03 260.29 101.03 260.6 101.75 258.59 102.09 256.52 99.3 255.74 101.45 253.41 100.97 251.97 98.92 253.57 96.27 253.15 94.98 252.4 91.82 249.38 88.53 249.01 87.22 251.71 83.56 258.33 83.04 Z" id="29151"/>
            <path d="M 471.25 114.71 468.41 116.13 467.53 119.42 466.15 121.54 463.54 124.82 461.54 122.16 457.99 119.4 456.54 119.65 453.3 115.57 452.35 115.79 449.81 112.84 452.17 112.46 452.7 111.15 456.23 110.85 457.97 113.15 463.1 114.04 465.95 112.85 470.07 112.57 471.25 114.71 Z" id="22278"/>
            <path d="M 408.36 370.43 408.39 372.79 410.13 378.85 406.35 379.24 406.97 381.45 405.18 382.27 404.63 384.21 405.15 387.85 404.37 389.83 401.54 389.69 398.02 391.41 394.72 388.78 395.29 385.96 397.22 384.47 396.95 382.56 398.17 379.86 394.67 373.94 394.82 370.27 397.17 368.87 401.2 369.55 402.16 370.59 407.21 369.09 408.23 370.16 408.36 370.43 Z" id="56034"/>
            <path d="M 169.04 221.95 168.57 220.88 164.7 220.2 164.24 218.28 165.38 216.66 164.68 212.65 166.34 210.86 170.67 208 175.17 205.64 184.88 207.53 187.09 210.15 191.23 212.31 197.35 212.83 197.45 211.45 200.28 210.37 199.52 214.53 198.49 215.32 196.84 215.79 195.97 217.7 192.68 219.26 189.05 218.58 187.05 217.83 181.44 217.41 179.52 216.15 174.29 218.36 171.93 220.71 169.04 221.95 Z" id="29172"/>
            <path d="M 797.32 245.85 796.79 248.93 795.69 250.84 797.07 252.89 795.88 257.07 798.11 261 799 263.67 797.78 267.73 795.39 268.38 791.61 270.7 787 270.59 786.8 266.69 789.34 264.22 789.16 262.25 785.21 260.71 784.43 258.03 787.18 254.9 785.18 252.19 786.12 249.89 783.85 248.8 783.57 245.01 786.98 244.73 787.68 242.2 788.8 241.16 793.52 242.17 794.93 246.11 797.32 245.85 Z" id="35217"/>
            <path d="M 467.67 128.6 473.07 128.2 475.61 125.49 477.04 123.64 478.1 123.42 478.76 125.1 483.76 125.28 484.02 128.17 483.22 133.05 480.35 136.09 476.39 135.23 478.42 131.55 474.7 132.52 474.04 134.3 470.56 134.8 468.56 132.05 467.67 128.6 Z" id="22389"/>
            <path d="M 161.19 81.04 161.44 80.75 163.6 81.73 165.01 80.94 166.59 82.13 169.58 82.79 168.99 84.97 165.32 88.38 162.64 87.94 160.01 85.92 161.19 81.04 Z" id="29064"/>
            <path d="M 109.29 104.43 110.45 103.14 113.71 103.47 118.45 105.3 119.26 105.04 119.91 109.07 117.1 112.57 114.88 109.76 114.37 106.96 112.98 105.31 109.29 104.43 Z M 119.49 104.87 122.35 106.32 123.42 106.39 123.55 105.74 123.49 105.38 123.73 105.33 124.26 105.51 124.51 105.39 124.2 105.6 123.6 105.43 123.64 106.4 124.28 107.47 123.66 107.41 122.76 106.36 122.09 106.63 121.95 106.19 119.49 104.87 Z" id="29290"/>
            <path d="M 97.26 109.52 100.62 110.72 100.06 107.29 102.48 106.96 106.78 105.19 109.29 104.43 112.98 105.31 114.37 106.96 114.88 109.76 117.1 112.57 114.01 115.47 114.89 118.11 112.15 118.87 109.73 117.06 108.77 118.55 105.48 120.12 101.25 119.6 99.69 118.35 99.7 115.14 97.56 111.81 97.26 109.52 Z" id="29196"/>
            <path d="M 85.5 106.07 85.2 104.03 86.14 101.66 87.69 100.41 90.21 101.22 93.39 99.86 93.28 104.45 94.62 107.63 97.26 109.52 97.56 111.81 99.7 115.14 99.69 118.35 99.02 119.5 95.04 119.57 93.07 118.38 92.48 116.14 89.31 115 86.32 109.33 85.5 106.07 Z" id="29178"/>
            <path d="M 540.39 216.13 541.75 215.28 542.98 212.03 543.63 204.41 545.55 202.15 547.86 201.32 554.71 204.42 559.33 205.18 560.7 206.58 559.19 210.33 557.73 212.43 555.59 213.84 553.36 219.84 551.94 221.21 549.07 220.15 546.77 217.16 545.58 216.63 542.21 217.3 540.39 216.13 Z" id="22371"/>
            <path d="M 557.02 169.82 559.04 169.59 560.57 167.73 564.44 167.18 564.19 165.85 569.07 163.54 569.13 166.13 570.56 166.95 569.36 172.09 568.84 176.88 565.89 182.99 566.09 184.8 563 186.67 559.84 185.14 557 184.93 553.36 183.45 554.2 180.54 555.63 178.72 553.6 175.07 554.62 172.77 557.02 169.82 Z" id="22020"/>
            <path d="M 555.83 144.11 557.48 143.68 558.91 140.09 559.08 135.4 556.16 133.76 556.9 131.57 560.09 130.86 561.84 131.34 562.64 133.82 561.14 136.15 560 141.73 558.68 142.9 558.8 146.51 555.63 146.43 555.83 144.11 Z" id="22317"/>
            <path d="M 90.34 162.83 88.11 163.18 86.18 164.65 86.21 167.42 84.43 170.12 80.78 170.3 79.32 169.38 77.63 170.45 72.88 170.78 72.57 168.8 77.72 166.26 77.62 162.71 78.9 161.3 80.99 161.06 82.42 159.65 84.82 158.94 89.14 155.35 91.24 156.43 90.04 160.15 90.34 162.83 Z" id="29190"/>
            <path d="M 620.35 153.96 619.19 155.68 618.95 159.57 621.91 163.18 622.49 165.38 619.15 166.72 615.16 166.56 615.12 166.52 613.91 154.85 620.35 153.96 Z" id="22306"/>
            <path d="M 436.27 218.36 436.98 217.14 441.04 216.66 443.04 215.71 446.18 217.64 448.95 217.38 450.32 217.83 450.82 221.01 452 223.58 450.43 223.43 445.68 225.37 444.4 225.15 438.91 222.33 436.27 218.36 Z" id="22075"/>
            <path d="M 615.96 104.42 616.5 102.63 618.98 102.04 620.01 105.05 621.95 105.94 619.17 109.04 614.67 110.16 616.41 106.35 615.96 104.42 Z" id="35358"/>
            <path d="M 366.34 222.84 368.67 222.2 371.85 222.99 374.35 224.81 375.61 226.08 375.8 228.67 373.69 231.08 373.83 234.11 374.91 235.95 376.5 241.09 375.19 241.91 368.08 236.12 364.71 234.64 363.85 232.13 364.99 229.89 367.21 229.5 362.94 225.37 365.21 224.45 366.34 222.84 Z" id="56099"/>
            <path d="M 672.62 88.4 669.07 89.94 670.15 93.17 667.43 94.03 668.48 96.9 667.91 98.58 665.35 100.27 663.67 98.58 663.16 96.44 659.95 93.76 657.28 92.57 654.95 92.58 653.7 90.84 657.97 90.06 666.4 89.47 672.62 88.4 Z" id="35078"/>
            <path d="M 574.36 193.31 576.35 192.54 579.31 190.09 582.43 191.11 584.45 190.73 588.15 193.08 590.17 195.46 590.67 197.78 588.91 200.95 584.31 203.12 581.72 202.07 574.25 202.99 573.17 203.7 571.83 200.75 572.76 197.83 572.57 194.31 574.36 193.31 Z" id="35234"/>
            <path d="M 305.61 211 309.92 209.89 314.7 211.26 314.44 208.84 317.97 209.21 319.17 213.08 314.12 214.31 308.32 214.04 306 212.24 305.61 211 Z" id="22373"/>
            <path d="M 388.22 59.03 390.33 56.09 389.76 54.52 392.17 55.17 393.17 54.77 393.97 56.06 394.81 55.9 396.35 57.33 398.07 57.59 401.04 58.85 400.92 61.87 403.72 61.47 406 62.47 404.06 64.1 401.48 65.72 396.72 66.98 395.74 68.94 393.02 69.57 389.61 69.48 390.06 67.12 388.15 64.12 385.93 63.7 385.26 60.12 388.22 59.03 Z" id="22256"/>
            <path d="M 395.4 42.93 399.73 44.31 404.01 43.85 406.69 46.7 406.65 49.53 405.37 50.13 404.95 52.13 401.04 58.85 398.07 57.59 394.69 55.74 393.91 55.69 393.25 54.58 392.53 54.73 389.96 54.05 387.33 50.81 388.66 47.85 392.11 45.72 392.75 43.45 395.4 42.93 Z" id="22233"/>
            <path d="M 390.06 214.76 388.73 213.14 390.01 208.09 392.3 207.87 394.17 205.82 396.91 207.33 395.27 211.77 396.08 212.77 399.72 213.78 399.77 216.09 401.37 217.17 398.89 217.7 398.54 219.18 395.57 222.01 390.6 221.35 389.29 223.55 390.06 214.76 Z" id="56209"/>
            <path d="M 419.2 219.38 422.35 216.88 426.78 216.33 428.29 217.43 436.27 218.36 438.91 222.33 438.72 225.54 436.28 228.81 433.56 228.21 433.24 229.63 431.98 231.51 430.22 230.32 427.36 231.08 423.75 229.78 423.03 225.69 420.32 223.54 419.2 219.38 Z" id="56093"/>
            <path d="M 377.16 27.52 377.76 30.56 381.16 31.09 380.57 35.64 378.41 38.12 377.74 42.7 376.72 42.92 373.98 41.82 372.48 39.64 373.1 38.39 372.05 35.73 372.16 33.71 376.33 29.14 377.16 27.52 Z" id="22347"/>
            <path d="M 300.37 95.84 300.77 93.51 304.06 94.66 304.46 96.68 306.17 96.91 309.21 93.41 310.84 95.02 313.16 95.1 313.53 98.33 317.35 98.76 317.14 99.99 318.53 104.9 319.46 106.54 316.21 106.54 315.45 105.55 311.77 108.11 310.87 109.59 308.27 108.88 307.17 106.66 304.96 104.86 300.49 104.87 299.36 103.69 300.96 102.29 302.3 99.08 300.37 95.84 Z" id="22227"/>
            <path d="M 544.18 119.1 546.52 117.94 549.91 119.38 553.04 121.83 552.81 122.69 548.39 125.36 548.74 127.41 543.6 128.75 541.34 126.38 541.54 124.4 543.24 123.19 544.18 119.1 Z" id="22200"/>
            <path d="M 552.81 157.32 557.14 157.13 558.69 159.14 560.87 160.32 562.08 161.41 560.36 163.93 564.19 165.85 564.44 167.18 560.57 167.73 559.04 169.59 557.02 169.82 553.77 168.58 551.15 169.51 549.73 166 553.01 164 554.19 160.4 552.81 157.32 Z" id="22369"/>
            <path d="M 366.27 37.54 367.98 37.48 368.95 38.82 372.22 38.64 371.46 41.7 369.79 42.97 369.03 44.86 367.32 45.01 365.45 47.2 364.04 46.86 363.22 47.92 358.21 44.65 358.8 43.33 360.65 40.25 366.27 37.54 Z" id="22152"/>
            <path d="M 312.11 25.84 313.72 26.38 314.53 24.16 316.88 22.52 318.14 24.3 320.31 24.68 320.36 26.3 319.7 29.87 319.98 34.09 317.73 34.09 316.73 31.88 317.89 30.36 316.99 28.89 313.6 28.89 312.11 25.84 Z" id="22353"/>
            <path d="M 603.77 120.1 605.75 121.02 609.01 120.4 610.71 121.03 608.46 123.32 609.14 124.99 607.94 126.64 604.65 125.33 600.51 125.29 600.25 122.27 603.77 120.1 Z" id="22327"/>
            <path d="M 607.22 147.33 610.57 147.21 611.53 148.28 616.1 146.48 618.35 147.93 620.18 146.87 619.76 149.7 622.23 151.6 620.35 153.96 613.91 154.85 615.12 166.52 614.24 166.28 613.97 164.05 609.97 165.2 609.07 162.3 608.33 158.13 610.7 156.29 606.01 156.01 604.04 152.41 605.76 152.26 606.79 150.44 607.22 147.33 Z" id="22056"/>
            <path d="M 488.75 115.44 489.99 119.23 490.19 121.38 491.53 123.81 490.41 125.46 488.12 128.89 484.02 128.17 483.76 125.28 478.76 125.1 478.1 123.42 476.29 121.29 477.97 118.37 477.94 115.34 476.73 111.43 478.75 110.65 480.5 111.22 482.11 113.41 484.74 113.97 486.93 113.21 488.75 115.44 Z" id="22081"/>
            <path d="M 433.39 212.3 434.6 217.17 436.98 217.14 436.27 218.36 428.29 217.43 426.78 216.33 422.35 216.88 424.38 215.46 424.13 213.06 425.82 211.85 425.47 208.69 428.16 207.69 430.21 209.76 432.07 209.33 433.39 212.3 Z" id="22285"/>
            <path d="M 330.28 190.4 334.48 190.9 336.3 189.75 339.34 191.44 340.41 189.33 344.06 189.43 345.06 190.9 345.55 194.23 348.05 196.57 346.78 198.73 347 201.6 348.42 201.35 351.52 204.86 352.56 208.57 354.09 210.33 352.1 212.01 350.06 216.31 347.38 218.41 345.7 217.96 342.29 219.82 339.81 221.91 337.09 220.86 336.26 223.02 334.45 223.82 330.83 221.06 330.46 219.05 334.01 215.67 329.15 211.9 329.34 208.79 331.05 206.61 330.17 203.04 332.6 202.73 333.46 197.21 333.08 194.87 329.79 191.93 330.28 190.4 Z" id="22061"/>
            <path d="M 308.73 136.01 311.38 137.28 313.49 136.22 317.48 136.93 317.32 141.71 315.92 143.37 314.1 147.23 313.65 151.75 314.18 152.43 310.13 154.01 304.03 151.16 302.98 150.07 299.18 149.3 299.11 143.54 302.53 140.16 307.64 137.37 308.73 136.01 Z" id="22231"/>
            <path d="M 304.26 69.49 304.51 66.15 306.97 65.24 307.22 67.34 309.29 67.42 310.79 72.74 307.74 73.39 307.33 74.56 306.24 71.72 304.26 69.49 Z" id="22319"/>
            <path d="M 233.53 87.36 231.34 85.48 231.24 83.52 231.85 80.26 231.17 76.81 232.99 75.33 234.38 78.28 237.25 78.71 242 80.26 240.54 82.8 238.13 83.1 237.64 85.91 236.08 86.08 235.42 88.08 233.53 87.36 Z" id="29079"/>
            <path d="M 208.99 79.03 212.86 77.48 210.76 70.92 210.98 70.47 210.72 69.36 210.41 69.13 209.6 67.56 209.03 67.2 208.93 66.35 211.29 66.56 213.92 65.79 217.09 66.27 218.16 68.92 216.65 74.36 219.92 75.37 220.53 76.54 219.99 78.41 218.08 78.8 216.82 80.51 213.96 79.76 213.87 83.16 212.47 83.24 207.02 85.84 206.83 85.69 206.5 83.34 209.74 80.42 208.99 79.03 Z" id="29192"/>
            <path d="M 535.28 410.82 539.56 407.66 541.82 405.68 545.67 406.76 549.07 404.66 551 405.38 551.42 406.42 549.97 412.36 548.15 413.99 548.45 419.93 545.54 421.75 543.41 421.43 540.58 422.95 537.49 420.14 535.7 420.64 536.19 416.85 535.42 414.3 535.28 410.82 Z" id="56058"/>
            <path d="M 398.76 29.98 401.34 26.93 402.98 26.5 405.04 28.42 407.56 27.3 412.49 26.55 413.69 27.54 411.97 29.55 412.69 30.96 410.43 35.43 406.45 36.46 404.68 37.11 401.99 35.93 401.42 32.81 398.76 29.98 Z" id="22210"/>
            <path d="M 381.7 20.56 382.3 24.64 383.17 25.84 382.63 28.4 381.16 31.09 377.76 30.56 377.16 27.52 376.63 26.87 378.28 22.32 379.97 20.15 381.7 20.56 Z" id="22085"/>
            <path d="M 299.49 121.24 302.25 119.5 303.97 116.73 310.87 109.59 311.77 108.11 311.51 109.57 313.47 113.55 310.89 115.6 312.76 118.81 310.9 122.25 311.71 124.29 310.01 127.86 306.69 131.07 304.91 130.47 303.32 131.61 300.55 131.32 299.2 129.34 298.3 122.94 299.49 121.24 Z" id="22217"/>
            <path d="M 179.05 175.08 174.57 176.25 169.15 176.5 167.24 174.98 170.21 173.69 172.4 171.1 171.08 169.64 173.51 167.42 176.92 167.58 179.57 166.56 181.02 166.46 182.22 171.52 179.05 175.08 Z" id="29080"/>
            <path d="M 560.7 206.58 563.72 203.97 564.7 204.9 565.37 205.7 566.08 210.75 565.25 216.66 564.58 218.2 560.68 219.91 560.66 221.81 563.2 222.95 563.1 224.21 559.63 224.2 558.64 222.28 557.07 222.11 553.99 223.94 551.94 221.21 553.36 219.84 555.59 213.84 557.73 212.43 559.19 210.33 560.7 206.58 Z" id="22133"/>
            <path d="M 320.36 26.3 320.32 23.49 322.33 21.91 323.44 22.73 324.68 26.07 327.32 28.36 330.78 26.95 332.59 28.03 330.62 29.8 330.09 33.47 329.93 33.86 327.95 33.86 327.33 35.83 325.17 36.84 319.98 34.09 319.7 29.87 320.36 26.3 Z" id="22168"/>
            <path d="M 310.88 52.87 313.61 53.3 316.12 52.3 319.9 54.11 320.38 57.02 322.96 57.21 325.75 56.05 326.09 58.29 324.54 59.34 317.42 62.21 315.36 61.22 315.91 58.29 314.03 55.13 311.58 54.08 310.88 52.87 Z" id="22224"/>
            <path d="M 232.99 75.33 234.92 74.75 236.97 69.51 238.9 71.03 241.98 70.63 239.97 73.56 241.5 77.78 244.87 80.04 242 80.26 237.25 78.71 234.38 78.28 232.99 75.33 Z" id="29023"/>
            <path d="M 201.37 300.44 203.84 300.2 207.34 297.61 209.9 298.62 212.94 297.81 215.75 298.37 214.54 300.55 208.71 303.44 204.18 306.2 202.71 305.8 201.91 303.43 199.22 301.63 201.37 300.44 Z" id="29006"/>
            <path d="M 584.56 93.53 589.72 91.32 592.3 93.39 593.37 90.54 597.57 86.86 597.96 90.51 601.87 91.07 603.87 90.32 606.1 95.24 603.35 97.15 605.05 103.58 601.7 106.1 601.28 108.51 601.23 108.51 600.12 108.57 598.6 105.6 600.04 104.01 600.09 102.01 597.47 100.26 593.14 101.01 590.39 100.04 587.82 96.46 585.26 95.17 584.56 93.53 Z" id="35228"/>
            <path d="M 213.92 65.79 210.97 66.22 210.91 61.95 211.76 60.8 214.38 60.8 214.8 58.88 216.96 59.16 219.53 57.79 220.34 58.09 220.5 63.26 217.09 66.27 213.92 65.79 Z" id="29273"/>
            <path d="M 174.42 77.02 175.99 83.89 177.89 85.23 177.41 88.04 175.79 88.99 175.79 90.97 173.44 91.3 170.68 91.33 168.78 88.63 168.99 84.97 169.58 82.79 166.59 82.13 165.01 80.94 168.55 77.15 174.42 77.02 Z" id="29287"/>
            <path d="M 159.79 161.57 159.74 159.96 157.61 158.65 159.07 156.19 156.37 154.34 154.38 149.33 155.87 148.76 160.93 145.27 161.83 145.08 166.36 149.93 167.51 155.2 165.34 155.82 162.86 158.13 162.3 160.13 163.27 162.92 163.35 166.28 160.08 166.48 157.82 165.34 159.79 161.57 Z" id="29140"/>
            <path d="M 297.67 153.31 298.98 151.81 298.63 149.9 299.18 149.3 302.98 150.07 304.03 151.16 310.13 154.01 314.18 152.43 317.06 155.28 319.27 156.08 320.55 158.57 322.79 160.05 322.67 164.19 322.02 164.66 320.58 166.96 318.97 167.88 317.78 169.5 317.01 172.5 315.89 173.61 315.01 173.67 313.7 172.22 310.94 175.04 308.69 174.75 309.15 172.91 307.49 168.54 307.63 165.88 306.18 165.48 307.21 163.82 307.05 159.9 308.53 158.34 307.63 156.04 301.33 155.18 300.36 153.98 297.67 153.31 Z" id="22031"/>
            <path d="M 93.39 99.86 96.74 99.75 98.34 105.21 100.06 107.29 100.62 110.72 97.26 109.52 94.62 107.63 93.28 104.45 93.39 99.86 Z" id="29099"/>
            <path d="M 477.3 400.85 478.86 403.6 480.43 404.39 479.99 407.3 476.34 411 470.26 408.21 466.29 408.43 462.28 410.71 457.92 411.1 455.5 413.13 453.01 413.17 453.86 406.81 449.7 403.66 448.38 401.22 444.38 399.96 444.45 398.55 445.52 397.16 450.28 396.15 452.58 394.83 454.02 396.27 456.83 394.94 458.56 396.48 457.69 398.54 463.17 399.09 467.19 395.6 470.87 395.6 473.09 396.68 473.53 393.68 475.19 392.95 475.52 394.55 477.3 400.85 Z" id="56240"/>
            <path d="M 81.51 127.79 78.38 128.8 76.62 128.28 74.89 129.43 73.47 126.54 75.05 125.48 77.92 126.42 79.86 125.37 80.72 123.51 82 122.51 85.78 123.23 86.78 124.74 81.51 127.79 Z" id="29112"/>
            <path d="M 615.16 166.56 619.15 166.72 622.49 165.38 621.91 163.18 624.25 164.76 626.66 163.96 626.86 167.48 627.69 170.01 625.13 172.6 623.8 173.41 620.27 172.23 617.38 173.61 615.16 166.56 Z" id="35318"/>
            <path d="M 395.29 385.96 389.94 382.38 390.01 388.57 391.24 389.28 390.74 392.38 388.45 392.87 387.99 386.93 386.26 382.69 384.15 379.45 388.18 377.19 388.87 375.24 392.23 373.92 394.67 373.94 398.17 379.86 396.95 382.56 397.22 384.47 395.29 385.96 Z" id="56168"/>
            <path d="M 507.17 382.95 510.2 383.37 511.21 382 514.12 383.26 515.5 385.48 514.43 387.43 515.31 389.03 519.66 388.31 519.03 391.01 521.42 392.33 524.27 392.28 524.79 395.25 527.7 393.3 529.14 394.92 526.67 399.13 526.93 401.9 523.13 403.29 522.42 404.6 523.26 406.88 523.24 410.17 518.61 406.55 520.94 405.9 519.66 402.76 517.05 402.13 516.62 399.93 513.76 399.64 512.96 395.56 513.19 393.55 507.46 392.96 508.1 389 505.25 388.83 505.82 384.11 507.17 382.95 Z" id="56143"/>
            <path d="M 617.06 99.37 617.63 97.83 615.37 95.78 616.1 93.67 616.45 91.89 619.25 92.38 622.78 89.89 623.3 87.18 624.78 86.86 626.74 89.39 626.39 93.47 627.25 94.39 626.06 96.31 628.77 101.77 625.06 102.46 622.47 101.82 620.01 105.05 618.98 102.04 619.02 100.16 617.06 99.37 Z" id="35306"/>
            <path d="M 418.16 385.58 415.7 385.6 416.84 389.47 413.76 390.43 411.58 384.65 412.19 381.95 413.8 380.26 418.88 380.45 419.19 382.41 418.16 385.58 Z" id="56233"/>
            <path d="M 626.24 337.09 630.27 337.37 634.07 338.43 634.48 333.63 637.46 332.74 639.4 334.31 642.8 333.34 644 334.67 642.05 338.55 639.19 340.99 639.24 343.07 640.78 346.74 640.12 350.65 635.45 350.35 632.5 353.85 630.94 353.7 630.14 350.4 626.82 349.21 626.62 347.46 624.16 346.94 624.86 340.72 624.26 339.32 624.47 337.23 626.24 337.09 Z" id="35145"/>
            <path d="M 606.02 184.98 608.38 183.49 607.81 181.48 609.11 179.92 613.48 179.25 615.19 181.68 616.84 181.12 617.23 182 616.73 186.32 618.43 187.24 616.41 187.16 613.26 189.85 610.91 187.94 608.57 188.99 605.63 188.7 606.02 184.98 Z" id="35307"/>
            <path d="M 163.64 323.73 164.37 322.12 168.56 321.62 169.08 323.58 167.1 325.77 163.64 323.73 Z" id="29072"/>
            <path d="M 186.04 300.49 186.04 302.16 183.73 305.82 184.37 308.49 181.56 309.43 181.61 311.49 179.15 310.8 176.47 312.28 174.18 310.74 172.91 305.29 171.84 303.61 177.52 304.44 177.47 300.99 180.77 296.44 181.59 296.88 186.04 300.49 Z" id="29220"/>
            <path d="M 471.96 386.77 472.72 384.05 475.12 384.19 476.24 384.07 479.58 387.38 475.72 388.69 475.02 391.13 471.57 388.01 471.96 386.77 Z" id="56084"/>
            <path d="M 441.93 390.21 442.87 386.69 444.16 387.69 443.44 391.06 441.93 390.21 Z" id="56087"/>
            <path d="M 614.07 354.26 613.89 354.15 612.5 352.46 613.47 350.44 610.72 345.3 608.48 346.41 605.54 346.25 601.66 345.31 602.73 340.58 608.51 343.06 611.26 343.27 611.96 340.56 616.69 338.37 618.54 338.31 621.99 339.97 624.26 339.32 624.86 340.72 624.16 346.94 623.67 348.54 620.6 347.92 618 350.2 615.53 350.5 614.07 354.26 Z" id="35237"/>
            <path d="M 96.74 99.75 99.2 98.26 103.31 99.5 106.35 99.82 107.5 103.78 106.78 105.19 102.48 106.96 100.06 107.29 98.34 105.21 96.74 99.75 Z" id="29257"/>
            <path d="M 164.68 212.65 162.98 209.17 161.46 208.95 159.66 206.99 162.37 203.85 162.97 201.17 166.02 199.32 170.24 199.55 174.42 201.59 175.17 205.64 170.67 208 166.34 210.86 164.68 212.65 Z" id="29256"/>
            <path d="M 142.22 281.08 144.48 280.53 147.01 278.28 147.11 275.5 148.5 273.79 148.6 271.8 151.56 271.34 152.46 272.98 155.77 272.76 156.91 273.85 159.66 272.37 159.79 277.18 156.66 278.57 151.16 281.97 146.8 282.59 144.44 284.32 142.22 281.08 Z" id="29225"/>
            <path d="M 85.22 252.62 83.73 250.8 83.21 247.85 90.76 248.43 93.58 246.11 97.8 246.7 101.55 246.14 103.32 250.15 104.19 253.73 103.25 255.02 99.02 254.97 89.97 253.05 85.22 252.62 Z" id="29028"/>
            <path d="M 296.91 131.82 296.72 130.4 299.2 129.34 300.55 131.32 303.32 131.61 305.36 134.75 308.19 134.37 308.73 136.01 307.64 137.37 302.53 140.16 299.11 143.54 299.18 149.3 298.63 149.9 297.23 146.27 297.27 144.24 294.37 140.16 295.81 136.21 297.59 134.02 296.91 131.82 Z" id="29012"/>
            <path d="M 73.47 126.54 71.42 121.86 71.78 118.96 73.33 118.19 72.86 116.04 74.07 115.14 76.7 115.99 80.67 119.13 80.12 121.39 80.72 123.51 79.86 125.37 77.92 126.42 75.05 125.48 73.47 126.54 Z" id="29221"/>
            <path d="M 97.81 148.44 99.4 151.88 99.29 155.27 101.53 157.22 101.71 161.17 102.39 162.29 101.34 165.07 101.51 167.98 100.28 168.13 97.76 165.87 95.95 166 94.27 164.44 90.34 162.83 90.04 160.15 91.24 156.43 89.14 155.35 89.25 152.13 90.92 151.17 92.79 152.53 94.58 149.42 97.81 148.44 Z" id="29130"/>
            <path d="M 34.65 150.47 36.18 149.53 37.23 151.85 35.16 152.18 34.65 150.47 Z" id="29084"/>
            <path d="M 126.59 254.79 128.7 255.27 130.16 257.71 132.21 257.55 134.89 259.55 134.85 261.49 138.4 263.43 138.43 265.66 133.09 268.99 132.05 270.53 129.6 270.06 129.07 268.75 124.77 265.51 122.08 264.38 119.39 264.29 117.78 262.66 118.62 261.02 119.25 255.72 124.18 255.73 126.59 254.79 Z" id="29197"/>
            <path d="M 742.79 300.89 744 296.75 745.69 296.52 748.53 297.86 750.54 296.86 753.34 298.15 760.21 296.08 758.2 293.67 754.1 291.64 752.68 289.2 750.41 286.85 747.38 285.44 747.64 282.89 752.38 285.42 755.37 285.52 759.12 286.7 760.91 284.63 761.16 282.67 764.06 282.28 766.69 282.58 767.54 284.27 769.85 285.1 770.6 286.58 769.7 290.26 768.33 291.5 768.14 295.61 767.06 297.24 764.19 298.08 764.1 301.46 765.64 304.44 762.97 307.28 761.12 307.29 758.33 305.88 755.64 303.25 753.95 302.9 751.45 304.43 748.6 304.73 747.06 305.53 744.99 304.6 744.66 302.3 742.79 300.89 Z" id="35235"/>
            <path d="M 617.06 99.37 619.02 100.16 618.98 102.04 616.5 102.63 615.96 104.42 614.38 103.98 613.77 100.78 612.35 99.59 611.33 96.06 614.02 96.57 617.06 99.37 Z" id="35314"/>
            <path d="M 263.47 293.48 265.47 294.74 268.89 294.64 273.54 299.72 274.82 303.04 273.79 309.24 270.58 309.91 269.83 310.21 269.91 310.8 267.97 311.1 266.16 309.95 262.49 311.38 261.04 308.58 261.19 302.96 261.47 297.56 263.47 293.48 Z" id="29217"/>
            <path d="M 645.88 102.08 647.34 99.87 650.01 99.3 650.7 95.83 653.59 90.87 653.7 90.84 654.95 92.58 657.28 92.57 659.95 93.76 663.16 96.44 663.67 98.58 665.35 100.27 662.04 103.7 655.3 105.72 655.09 107.69 652.99 107.58 650.72 109.1 649.21 105.16 646 103.07 645.88 102.08 Z" id="35186"/>
            <path d="M 283.19 75.06 286.89 75.82 288.76 86.54 289.62 88.53 291.66 88.05 291.35 92.71 290.08 93.92 287.85 92.74 283.92 92.5 282.81 91.5 283.09 87.34 280.52 86.14 280.82 83.52 279.72 82.17 282.99 77.82 283.19 75.06 Z" id="29182"/>
            <path d="M 564.7 204.9 567.59 204.01 569.46 205.68 573.18 205.08 573.17 203.7 574.25 202.99 574.83 205.57 577.1 205.27 579.27 206.24 577.62 208.82 577.68 210.21 575.78 211.12 573.55 214.04 573.1 215.79 571.19 217.68 568.86 217.51 565.25 216.66 566.08 210.75 565.37 205.7 564.7 204.9 Z" id="35297"/>
            <path d="M 111.91 181.41 110.83 179.2 111.45 177.79 109.7 173.69 110.05 171.65 112.96 169.82 114.34 167.94 118.88 167.56 116.34 171.92 116.04 176.08 114.2 177.84 114.71 180.49 111.91 181.41 Z" id="29238"/>
            <path d="M 124.47 184.53 127.47 183.98 133.01 181.09 139.28 181.64 142.81 186.11 147.41 186.25 147.27 188.46 144.81 188.57 136.69 191.17 129.96 189.74 130.03 187.04 127.19 186.96 124.47 184.53 Z" id="29120"/>
            <path d="M 377.76 448.33 375.37 449.39 374.46 451.1 375.94 452.14 376.2 456.05 377.25 456.99 375.71 457.9 373.4 457 372.17 458.12 371.43 461.06 369.4 460.24 367.2 458.45 365.46 454.35 365.54 452.71 364.48 451.29 364.95 447.88 365.18 446.05 366.87 444.57 368 442.1 369.81 444.43 373.04 444.47 373.06 446.14 376.13 446.54 377.76 448.33 Z" id="56241"/>
            <path d="M 174.42 201.59 182.62 199.44 181.94 196.99 182.4 195.05 184.47 194.51 192.44 195.29 195.08 197.58 196.48 197.36 198.04 195.33 200.13 196.59 198.95 203.95 200.33 206.17 200.76 206.29 199.86 208.27 200.28 210.37 197.45 211.45 197.35 212.83 191.23 212.31 187.09 210.15 184.88 207.53 175.17 205.64 174.42 201.59 Z" id="29044"/>
            <path d="M 479.25 397.3 479 399.14 485.18 401.11 485.96 403.14 485.33 405.16 480.77 404.74 477.3 400.85 475.52 394.55 479.25 397.3 Z" id="56252"/>
            <path d="M 484.65 245.72 488.14 242.1 493.05 242.4 493.06 243.46 496.4 246.37 496.2 247.47 493.33 251.02 488.99 254.23 487.31 256.08 484.17 254.77 481.9 256.7 480.77 254.93 481.15 253.08 482.76 251.38 484.28 251.88 484.65 245.72 Z" id="22027"/>
            <path d="M 410.13 378.85 409.34 381.11 410.76 383.44 409.79 384.37 410.9 386.91 409.51 388.9 407.39 387.23 405.15 387.85 404.63 384.21 405.18 382.27 406.97 381.45 406.35 379.24 410.13 378.85 Z" id="56258"/>
            <path d="M 514.51 163.55 516.6 162.96 519.08 159.34 517.48 156.2 515.09 155.15 515.94 153.85 520.07 154.66 521.59 153.46 524.05 153.67 526.83 152.3 527.96 150.28 529.43 152.71 532.2 153.92 539.49 152.54 538.33 154.89 538.69 157.01 540.95 156.45 543.39 158.22 542.79 159.37 541.5 161.3 536.83 164.87 536.45 167.73 535.28 170.36 533.75 171.68 533.53 174.1 530.14 174.19 525.98 176.54 523.08 176.44 522.69 174.07 519.81 173.31 520.09 168.28 519.3 164.82 514.98 164.54 514.51 163.55 Z" id="22185"/>
            <path d="M 519.77 205.29 520.63 204.97 523.27 205.34 522.99 202.73 526.48 199.98 528.34 199.27 531.25 199.06 533.75 199.8 535.43 201.84 539 200.33 540.95 202.78 543.63 204.41 542.98 212.03 541.75 215.28 540.39 216.13 538.98 217.67 537.41 222.4 535.9 225.2 533.87 223.7 530.61 219.8 530.23 217.53 527.74 216.01 522.21 216.11 520.86 213.3 521.24 211.89 518.86 210.69 519.77 205.29 Z" id="22147"/>
            <path d="M 348.88 240.76 351.12 240.92 352.93 239.11 353.19 235.38 350.89 234.57 347.95 230.78 349.71 230.8 351.67 228.88 353.81 223.19 354.22 221.05 355.78 220.88 359.32 222.97 360.58 225.14 362.94 225.37 367.21 229.5 364.99 229.89 363.85 232.13 364.71 234.64 368.08 236.12 375.19 241.91 374.54 245.33 373.42 248.01 372.2 250.16 369.84 249.69 368.3 248.11 362.01 249.72 361.59 252.18 359.21 250.31 355.47 253.03 350.93 254.29 348.71 251.77 348.54 248.97 346.95 246.2 348.58 245.61 348.88 240.76 Z" id="56163"/>
            <path d="M 535.82 183.05 537.82 181.47 542.71 181.02 545.94 180.09 550.05 180.97 548.78 182.98 548.9 185.73 547.57 187.23 547.31 190.49 543.53 193.43 541.15 195.11 539.65 192.83 535.19 189.29 534.94 187.35 535.82 183.05 Z" id="22053"/>
            <path d="M 511.62 112.48 512.83 111.05 517.39 110.35 518.11 107.79 516.86 105.41 518.76 101.28 525.39 100.08 525.53 101.33 529.76 105.29 528.69 106.92 531.33 110.61 528.9 112.73 525.53 114.89 523.47 117.52 521.08 119.21 517.99 119.67 516.96 116.53 514.99 114.01 511.62 112.48 Z" id="22077"/>
            <path d="M 540.79 100.21 541.63 98.55 543.83 97.69 544.55 94.51 547.32 96.11 547.79 98.46 547.72 102.3 545.62 106.08 544.25 110.44 542.86 112.18 539.47 103.99 539.57 101.66 540.79 100.21 Z" id="22268"/>
            <path d="M 568.86 185.88 571.55 185.41 575.09 186.85 579.54 185.73 579.31 190.09 576.35 192.54 574.36 193.31 573.33 192.02 568.37 188.32 568.86 185.88 Z" id="22305"/>
            <path d="M 354.33 339.64 357.16 340.23 356.97 340.57 351.29 345.83 349.5 348.06 348.93 348 347.74 345.9 348.14 343.01 352.86 342.49 354.33 339.64 Z" id="56118"/>
            <path d="M 575.41 116.72 578.18 114.65 579.71 111.27 580.22 112.64 587.02 112.33 587.15 115.75 590.25 117.35 589.64 118.33 589.37 120.13 589.08 121.79 584.37 123.24 583.52 122.12 579.06 121.8 578.86 119.8 576 118.81 575.41 116.72 Z" id="22105"/>
            <path d="M 563.4 150 565.07 147.32 568.1 150.47 570.95 148.17 571.98 147.98 571.95 150.12 574.2 151.54 574.05 154.27 572.47 155.67 569.59 156.47 566.3 153.39 564.6 153.63 563.4 150 Z" id="22104"/>
            <path d="M 380.86 219.37 383.24 219.28 385.28 220.18 388.34 218.56 390.06 214.76 389.29 223.55 390.05 226.41 388.35 227.86 385.47 227.38 381.68 230.98 379.08 231.64 375.93 230.86 375.8 228.67 375.61 226.08 374.35 224.81 373.53 221.92 375.54 219.77 378.26 218.34 380.86 219.37 Z" id="56245"/>
            <path d="M 608.46 123.32 610.15 121.65 613.26 121.18 615.49 122.89 616.22 125.91 612.58 125.83 609.25 127.43 607.94 126.64 609.14 124.99 608.46 123.32 Z" id="22385"/>
            <path d="M 529.31 80.9 530.75 80.29 532.7 82.06 534.91 82.24 536.1 85.01 536.1 87.9 537.44 89.56 540.3 89.55 539.45 91.96 533.75 95.35 532.86 94.71 529.43 96.05 525.33 93.67 525.75 90.05 523.85 89.01 527.9 82.11 529.31 80.9 Z" id="22242"/>
            <path d="M 481.68 225.32 481.7 228.97 482.58 231.35 482.61 231.62 479.55 227.58 477.96 227.99 477.09 226.91 478.93 224.43 481.68 225.32 Z" id="22039"/>
            <path d="M 310.66 192.78 314.04 191.96 317.81 191.61 318.7 192.42 319.86 200.47 320.92 200.56 322.13 206.24 321.6 210.73 322.46 212.17 319.17 213.08 317.97 209.21 314.44 208.84 313.73 206.69 310.87 206.1 308.11 201.95 307.35 199.82 308.4 198.11 307.71 195.94 310.45 195.55 310.66 192.78 Z" id="22202"/>
            <path d="M 396.91 207.33 398.86 206.8 400.36 205.16 401.32 206.53 404.38 206.1 405.85 207.31 408.27 207.03 409.87 209.38 412.21 208.65 411.46 210.65 412.66 211.97 412.9 215.91 416.2 216.62 415.31 219.52 412.94 221.16 408.42 222.82 407.47 219.02 404.95 217.4 401.37 217.17 399.77 216.09 399.72 213.78 396.08 212.77 395.27 211.77 396.91 207.33 Z" id="56203"/>
            <path d="M 436.28 228.81 438.72 225.54 438.91 222.33 444.4 225.15 445.84 227.35 442.65 229.77 439.77 230.67 436.28 228.81 Z" id="56049"/>
            <path d="M 483.45 175.89 484.53 173.87 488.34 173.11 488.76 171.84 495.05 172.13 500.16 176.84 502.19 177.25 504.06 177.46 504.65 174.6 507.5 169.29 509.9 168.55 511.61 164.83 514.51 163.55 514.98 164.54 519.3 164.82 520.09 168.28 519.81 173.31 522.69 174.07 523.08 176.44 525.98 176.54 530.14 174.19 529.9 175.77 532.61 178.85 532.5 180.7 534.17 182.62 535.82 183.05 534.94 187.35 533.8 187.32 528.56 190.71 528.25 192.72 524.53 189.72 522.93 186.24 521.57 186.08 518.6 187.64 517.57 189.85 511.05 193.04 507.42 195.24 503.76 198.26 499.57 202.48 496.12 200.43 492.65 203.2 490.56 204 489.59 202.28 486.3 203.25 486.33 200.04 484.55 197.72 484.34 194.24 483.71 187.57 485.37 185.51 488.32 185.24 486.85 182.94 486.2 177.04 483.45 175.89 Z" id="22046"/>
            <path d="M 441.64 94.57 442.82 91.39 444.25 90.54 446.55 91.33 446.75 94.94 449.61 95.58 452.44 93.79 454.14 95.47 456.94 95.77 459.18 98.33 459.35 100.24 454.99 102.93 453.58 106.57 451.83 107.84 448.15 108.79 445.35 110.56 444.08 110.46 442.69 110.64 438.97 107.55 440.93 104.84 440.93 101.76 441.88 97.9 441.64 94.57 Z" id="22251"/>
            <path d="M 154.19 315.04 156.76 316.33 158.6 315.93 162.7 318.13 163 321.25 164.37 322.12 163.64 323.73 159.53 324.57 155.27 323.95 150.71 325.09 149.21 322.62 149.71 320.49 147.95 318.26 149.01 316.81 151.91 316.33 154.19 315.04 Z" id="29158"/>
            <path d="M 153.65 305.63 155.19 304.79 156.32 305.45 162.27 303.01 163.5 303.5 166.99 300.84 170.37 304.16 168.54 304.94 167.08 308.43 164.16 308.57 162.02 307.53 158.42 308.17 154.51 309.63 153.65 305.63 Z M 161.64 296.34 164.18 297.39 163.23 300.87 158.48 303.47 157.45 301 161.64 296.34 Z" id="29252"/>
            <path d="M 537.99 246.88 539.37 243.36 543.2 242.52 544.15 244.14 546.75 242.79 543.24 238.9 542.3 235.64 539.54 233.9 540.68 229.83 543.48 229.84 545.88 230.97 546.63 227.54 549.24 226.44 551.73 227.73 553.19 227.47 553.99 227.79 559.73 235.26 559.28 238.08 557.03 238.37 555.45 239.96 556 241.35 559.72 240.12 560.56 241.7 558.92 245 559.22 247.82 558.87 249.16 560.48 252.24 558.17 254.05 555.92 257.07 552.86 253.62 549.56 253.78 542.67 253.37 540.83 254.2 539.26 252.66 537.89 252.09 536.95 248.05 537.99 246.88 Z" id="56127"/>
            <path d="M 132.98 142.7 136.32 140.43 136.96 136.04 138.32 136.48 138.42 132.7 143.75 132.14 146.83 132.31 149.32 134.46 151.71 133.34 153.89 135.14 155.79 137.91 155.58 139.79 156.19 143.61 158.23 144.75 153.71 147.33 151.34 147.6 148.94 149.88 146.77 145.49 143.78 145.56 141.96 146.97 140 150.89 137.89 151.15 135.02 148.86 132.98 142.7 Z" id="29075"/>
            <path d="M 144.44 284.32 146.8 282.59 151.16 281.97 156.66 278.57 159.79 277.18 160.13 278.08 158.16 279.9 157.73 282.45 155.89 283.87 156.03 287.46 155.42 290.29 149.56 293.32 146.87 287.35 144.44 284.32 Z" id="29214"/>
            <path d="M 99.02 254.97 103.25 255.02 106.83 255.39 108.24 254.68 109.96 256.36 110.33 259.64 106.26 260.39 105.31 261.74 101.46 261.02 99.25 257.57 99.02 254.97 Z" id="29228"/>
            <path d="M 269.51 136.2 274.27 135.66 278.28 135.89 279.69 134.2 281.8 134.81 285.09 130.78 288.57 130.79 291.3 128.11 294.1 128.8 294.84 131.41 296.91 131.82 297.59 134.02 295.81 136.21 294.37 140.16 297.27 144.24 297.23 146.27 298.63 149.9 298.98 151.81 297.67 153.31 296.27 155.17 291.1 155.08 290.41 154.04 286.22 152.95 283.89 157.86 282.36 154 282.96 152.23 282.64 148.62 276.5 145.06 275.12 142.22 271.7 144.04 269.07 142.59 268.67 140.99 266.69 140.74 265.69 138.42 269.51 136.2 Z" id="29275"/>
            <path d="M 386.46 51.06 389.34 53.48 390.04 56.09 388.22 59.03 385.26 60.12 382.55 63.62 380.73 64.9 375.87 64.18 373.65 62.36 372.4 62.79 370.65 60.06 373.82 56.03 375.25 52.92 376.81 52.95 378.71 49.23 379.79 50.56 382.41 50.21 384.11 51.22 386.46 51.06 Z" id="22204"/>
            <path d="M 412.58 18.2 414.24 17.83 416.46 18.84 415.58 21.72 413.52 21.12 412.58 18.2 Z" id="22016"/>
            <path d="M 114.53 161.27 113.75 163.14 112.09 163.09 111.06 164.75 108.56 165.12 104.02 168.01 101.51 167.98 101.34 165.07 102.39 162.29 101.71 161.17 101.53 157.22 99.29 155.27 99.4 151.88 97.81 148.44 97.05 146.97 99.11 146.21 103.78 146.71 104.1 148.2 107.41 146.9 109.85 148.94 110.25 151.54 111.74 152.06 113.19 154.89 113.32 157.56 112.37 160.2 114.53 161.27 Z" id="29212"/>
            <path d="M 270.65 59.97 275.42 60.4 276.25 62.02 279.69 65.19 279.41 66.75 280.64 68.89 284.26 66.45 284.86 69.01 288.37 70.45 288.83 72.11 287.3 75.29 286.89 75.82 283.19 75.06 280.61 71.7 278.61 71.87 275.44 75.09 274.41 73.61 274.06 70.76 272.68 68.94 271.75 64.09 270.58 62.44 270.65 59.97 Z" id="29073"/>
            <path d="M 527.18 412.05 530.42 411.65 532.45 409.28 534.75 410.03 535.28 410.82 535.42 414.3 536.19 416.85 535.7 420.64 533.28 422.28 531.4 422.56 528.13 421.34 526.9 416.47 527.18 412.05 Z" id="56030"/>
            <path d="M 755.78 323.12 756.12 323.8 756.16 323.83 754.64 330.47 745.94 327.88 740.67 326 739.4 326.05 733.77 324.41 731.14 321.73 731.07 321.71 729.38 319.64 728.4 317.07 726.1 315.37 723.08 315.9 723.84 309.97 725.91 309.09 725.99 307.07 729.58 306.01 730.86 302.81 732.11 301.51 733.57 300.89 739.09 301.83 740.36 303.14 741.11 306.45 744.05 306.94 745.3 308.33 748.48 309.36 748.86 311.53 748.09 313.38 749.15 316.46 748.66 320.99 750.45 321.46 750.67 324.82 753.1 324.86 755.78 323.12 Z" id="35167"/>
            <path d="M 415.69 200.39 420.53 199.93 419.82 201.72 420.6 204.96 424.18 201.38 426.24 200.57 432.13 200.74 437.53 199.06 437.27 201.65 440.57 202.18 437.04 206.1 436.12 211.39 433.39 212.3 432.07 209.33 430.21 209.76 428.16 207.69 425.47 208.69 425.82 211.85 424.13 213.06 424.38 215.46 422.35 216.88 419.2 219.38 415.31 219.52 416.2 216.62 412.9 215.91 412.66 211.97 411.46 210.65 412.21 208.65 412.8 206.43 410.39 203.19 411.56 200.7 415.05 202.17 415.69 200.39 Z" id="22158"/>
            <path d="M 288.76 86.54 290.75 85.65 294.54 87.19 296.13 85.37 297.7 86.36 300.04 83.68 301.29 84.24 300.62 87.2 298.46 90.71 300.77 93.51 300.37 95.84 299.16 96.36 295.74 94.44 293.47 95.11 292.08 93.51 291.35 92.71 291.66 88.05 289.62 88.53 288.76 86.54 Z" id="22366"/>
            <path d="M 367.98 37.48 370.98 35.29 372.22 38.64 368.95 38.82 367.98 37.48 Z" id="22362"/>
            <path d="M 113.73 191.48 108.79 192.82 106.56 192.1 104.97 190.5 102.42 190.33 102.73 186.54 104.38 183.1 106.82 186.18 110.09 185.4 112.02 183.31 112.86 185.96 115.81 188.09 113.73 191.48 Z" id="29022"/>
            <path d="M 420.47 365.55 418.93 365.31 412.84 364.18 412.8 362.04 414.19 357.86 419.2 358.79 421.91 362.25 421.72 364.71 420.47 365.55 Z" id="56007"/>
            <path d="M 618.7 136.41 620.61 136.15 621.47 138.76 625.16 140.09 624.78 143.74 625.35 146.22 625.01 149.29 625.5 151.58 623.36 154.74 622.23 151.6 619.76 149.7 620.18 146.87 621.07 144.22 617.96 141.11 618.7 136.41 Z" id="35225"/>
            <path d="M 304.51 66.15 302.42 65.33 303.1 63.22 302.37 60.24 302.85 56.08 307.81 54.01 309.89 56.95 310.79 63.02 306.97 65.24 304.51 66.15 Z" id="22349"/>
            <path d="M 234.35 92.08 232.88 88.34 233.53 87.36 235.42 88.08 236.08 86.08 237.64 85.91 238.13 83.1 240.54 82.8 242 80.26 244.87 80.04 248.03 81.46 249.22 83.09 246.74 83.82 246.72 85.49 249.27 86.17 248.14 89.12 246.36 90.7 246.03 95.9 246.52 96.71 243.5 97.12 243.48 98.76 241.15 99.18 239.03 103.03 236.18 97.04 236.76 95.19 234.28 92.09 234.35 92.08 Z" id="29279"/>
            <path d="M 203.28 66.36 204 64.62 206.87 65.18 208.87 67.49 209.57 68.57 210.61 69.54 210.76 70.92 212.86 77.48 208.99 79.03 205.8 80.02 204.34 79.51 206.67 76.7 205.27 75.86 205.55 72.72 204.89 69.8 203.28 66.36 Z" id="29276"/>
            <path d="M 168.03 166.09 171.57 166.75 174.03 165.46 173.51 167.42 171.08 169.64 172.4 171.1 168.34 172.94 165.73 175.42 160.91 174.43 157.4 174.88 160.82 170.63 160.41 168.86 164.17 168.02 168.03 166.09 Z" id="29137"/>
            <path d="M 164.38 239.45 166.76 234.81 169.86 234.24 171.75 234.76 173.37 237.5 176.27 238.48 175.5 240.97 177.13 243.15 172.23 245.32 169.64 243.4 168.37 241.58 164.38 239.45 Z" id="29090"/>
            <path d="M 616.1 93.67 613.92 94.76 612.92 93.54 608.46 93.24 609.19 90.63 610.68 88 612.92 87.42 618.6 90.16 619.56 89 619.24 86.56 620.4 85.18 623.3 87.18 622.78 89.89 619.25 92.38 616.45 91.89 616.1 93.67 Z" id="35284"/>
            <g/>
            <path d="M 677.58 346.2 673.85 349.6 672.98 348.95 669.18 348.81 669.05 348.79 669.03 348.79 668.84 348.74 667.91 349.37 665.85 349.85 665.8 349.86 665.77 349.87 665.75 349.87 665.04 350.09 665.02 350.1 664.65 352.43 661.14 352.81 660.11 351.54 656.8 350.42 653.11 353.07 650.21 352.64 651.42 350.69 651.91 345.63 651.63 339.45 652.89 337.58 651 336.59 651.65 331.91 654.28 331.62 656.16 330.23 658.64 331.11 660.69 331.45 663.22 333.3 664.92 332.48 664.36 336.98 665.74 337.43 666.62 340.14 668.08 339.91 671.31 341.71 673.39 341.61 677.05 343.83 677.58 346.2 Z" id="35124"/>
            <path d="M 518.61 406.55 515.92 406.2 512.28 404.81 513.12 402.49 512.33 400.82 513.76 399.64 516.62 399.93 517.05 402.13 519.66 402.76 520.94 405.9 518.61 406.55 Z" id="56018"/>
            <path d="M 168.03 166.09 165.88 164.64 169.87 162.47 168.32 158.33 170.72 158.63 171.91 157.65 173.12 159.79 174.3 162.5 172.92 163.89 174.03 165.46 171.57 166.75 168.03 166.09 Z" id="29043"/>
            <path d="M 553.99 223.94 557.07 222.11 558.64 222.28 559.63 224.2 563.1 224.21 563.2 222.95 560.66 221.81 560.68 219.91 564.58 218.2 565.25 216.66 568.86 217.51 570.25 221.79 571.35 228.29 572.73 231.46 572.31 233.07 573.6 242.12 572.37 242.65 566.3 242.65 563.77 244.2 563.4 247.76 559.22 247.82 558.92 245 560.56 241.7 559.72 240.12 559.28 238.08 559.73 235.26 553.99 227.79 553.19 227.47 553.99 223.94 Z" id="35117"/>
            <path d="M 169.87 162.47 166.4 163.74 163.27 162.92 162.3 160.13 162.86 158.13 165.34 155.82 167.51 155.2 166.36 149.93 161.83 145.08 160.93 145.27 163.74 142.97 165.06 140.41 166.26 139.53 167.29 139.68 168.17 138.99 169.59 136.92 169.96 139.04 175.79 142.36 177.49 140.61 180.64 143.15 175.86 145.64 176.66 149.9 175.71 153.31 172.35 154.72 171.91 157.65 170.72 158.63 168.32 158.33 169.87 162.47 Z" id="29045"/>
            <path d="M 86.78 124.74 88.61 122.27 90.67 123.74 92.41 127.35 92.11 128.95 95.94 128.17 94.21 131.32 93.45 134.53 91.83 135.12 89.26 133.16 85.59 133.69 83.14 129.91 82.82 127.94 81.51 127.79 86.78 124.74 Z" id="29017"/>
            <path d="M 155.58 139.79 157.59 138.14 161.31 136.21 163.5 136.5 165.67 136.47 168.16 137.99 167.49 139.41 166.09 139.4 165.02 140.16 163.53 142.54 159.1 145.1 158.23 144.75 156.19 143.61 155.58 139.79 Z" id="29056"/>
            <path d="M 636.55 84.23 638.63 86.75 635.82 90.38 635.34 90.98 633.02 88.43 633.59 86.51 636.55 84.23 Z" id="35255"/>
            <path d="M 658.64 331.11 660.66 330.77 663.91 328.44 666.94 328.45 667.07 326.76 669.57 325.9 672.88 326.24 676.55 325.52 678 331.02 676.77 333.33 676.82 335.47 680.87 335.39 680.72 337.44 679.1 341.37 678.44 345.03 677.58 346.2 677.05 343.83 673.39 341.61 671.31 341.71 668.08 339.91 666.62 340.14 665.74 337.43 664.36 336.98 664.92 332.48 663.22 333.3 660.69 331.45 658.64 331.11 Z" id="35098"/>
            <path d="M 242.55 308.78 239.38 309.33 238.65 307.95 240.59 306.72 238.09 303.79 235.39 303.24 235.18 300.81 232.02 298.71 231.52 297.08 232.26 293.48 231.51 288.84 235.13 286.63 233.65 283.64 236.79 281.82 238.52 284 240.79 283.76 244.88 288.64 242.2 291.6 245.44 297.73 245.64 299.33 251.09 300.9 251.06 303.6 248.94 303.42 246.89 306.38 243.92 306.77 242.55 308.78 Z" id="29039"/>
            <path d="M 299.03 196.04 302.16 195.1 307.91 192.32 310.66 192.78 310.45 195.55 307.71 195.94 308.4 198.11 307.35 199.82 308.11 201.95 310.87 206.1 313.73 206.69 314.44 208.84 314.7 211.26 309.92 209.89 305.61 211 302.38 207.56 302.62 203.13 300.61 201.04 301.18 198.92 299.17 197.45 299.03 196.04 Z" id="29152"/>
            <path d="M 74.77 132.51 75.05 135.79 77.69 136.32 76.27 138.57 73.51 139.18 71.64 137.19 70.96 135.18 74.77 132.51 Z" id="29098"/>
            <path d="M 471.11 367.78 469.86 374.49 472.01 375.57 474.17 378.13 471.61 381.12 469.27 380.32 467.29 381.64 462.09 377.09 458.15 375.83 458.72 373.98 463.33 376.25 465.38 375.84 464.62 372.05 464.11 370.54 466 368.61 466.23 366.27 468.15 365.21 471.45 367.11 471.11 367.78 Z" id="56243"/>
            <path d="M 467.19 395.6 468.27 391.92 467.74 390.42 469.66 389.35 470.65 386.99 471.96 386.77 471.57 388.01 475.02 391.13 475.19 392.95 473.53 393.68 473.09 396.68 470.87 395.6 467.19 395.6 Z" id="56205"/>
            <path d="M 420.72 379.34 424.15 382.05 422.25 383.95 423.76 387.16 425.44 387.25 426.51 390.44 429.35 393.45 429.01 394.52 424.71 393.9 422.04 394.33 418.71 387.44 418.16 385.58 419.19 382.41 418.88 380.45 420.72 379.34 Z" id="56116"/>
            <path d="M 296.71 194.44 297.79 193.63 297.8 189.71 299.16 187.72 298.4 184.85 299.07 183.75 303 184.44 303.9 181.52 306.12 181.74 308.44 179.19 311.19 182.98 313.4 183 315.06 184.23 313.71 186.55 314.04 191.96 310.66 192.78 307.91 192.32 302.16 195.1 299.03 196.04 296.71 194.44 Z" id="29024"/>
            <path d="M 384.42 469.06 381.36 466.67 379.88 468.49 372.92 469.27 371.11 468.31 367.48 463.82 368.02 461.27 369.4 460.24 371.43 461.06 372.17 458.12 373.4 457 375.71 457.9 377.25 456.99 381.43 460.4 385.59 459.46 387.9 460.67 389.1 462.7 386.97 465.52 386.1 467.78 384.42 469.06 Z" id="56009"/>
            <path d="M 596.71 358.96 598.6 355.93 601.68 353.39 604.08 348.2 605.54 346.25 608.48 346.41 610.72 345.3 613.47 350.44 612.5 352.46 613.89 354.15 611.19 357.89 606.28 362.29 600.4 362.39 598.57 359.9 596.71 358.96 Z" id="35294"/>
            <path d="M 119.6 255.09 118.55 255.85 118.31 260.52 116.87 262.94 114.31 263.38 113.71 266.04 108.78 264.71 105.31 261.74 106.26 260.39 110.33 259.64 109.96 256.36 108.24 254.68 111.2 252.02 112.54 250.01 117.45 251.86 119.6 255.09 Z" id="29003"/>
            <g/>
            <path d="M 276.55 157.55 277.13 153.15 282.36 154 283.89 157.86 286.22 152.95 290.41 154.04 291.1 155.08 296.27 155.17 297.67 153.31 300.36 153.98 301.33 155.18 307.63 156.04 308.53 158.34 307.05 159.9 307.21 163.82 306.18 165.48 302.61 167.01 300.53 172.42 297.65 175.08 296.81 177.54 293.09 177.74 287.86 181.23 283.77 180.25 281.07 177.88 281.36 176.28 283.86 174.15 283.53 172.69 281.41 171.18 275.85 165.55 272.7 165.8 274.63 163.78 275.5 160.75 277.21 160.69 276.55 157.55 Z" id="29227"/>
            <path d="M 208.55 203.17 205.6 203.33 203.55 204.49 202.97 204.81 204.3 201.55 207.09 201.06 208.55 203.17 Z" id="29222"/>
            <path d="M 463.44 232.35 464.11 230.16 465.35 228.51 467.73 223.28 469.66 220.87 473.51 221.12 475.53 219.06 477.9 218.33 483.33 220.8 481.68 225.32 478.93 224.43 477.09 226.91 477.96 227.99 472.87 231.73 472.62 233.52 465.02 233.18 463.44 232.35 Z" id="22275"/>
            <path d="M 535.9 225.2 537.41 222.4 538.98 217.67 540.39 216.13 542.21 217.3 545.58 216.63 546.77 217.16 549.07 220.15 551.94 221.21 553.99 223.94 553.19 227.47 551.73 227.73 549.24 226.44 546.63 227.54 545.88 230.97 543.48 229.84 540.68 229.83 539.54 233.9 538.69 233.16 536.12 229.51 536.21 228.13 535.9 225.2 Z" id="22083"/>
            <path d="M 521.08 119.21 523.47 117.52 525.53 114.89 528.9 112.73 529.73 115.07 531.05 115.6 531.88 119.04 527.99 120.69 526.42 123.14 524.7 122.18 524.84 119 521.08 119.21 Z" id="22261"/>
            <path d="M 497.49 98.6 501.69 93.56 506.15 91.97 512.06 89.23 513.16 92.93 512.24 95.9 511.41 98.76 507.74 101.52 502.46 101.96 500.21 99.14 498.78 99.48 497.49 98.6 Z" id="22186"/>
            <path d="M 184.37 308.49 187.96 313.94 190.47 313.68 190.8 317.6 190.17 320.12 184.51 325.19 181.51 323.38 183.32 321.61 183.88 319.24 182.55 317.23 181 312.5 181.61 311.49 181.56 309.43 184.37 308.49 Z" id="29135"/>
            <path d="M 149.56 293.32 155.42 290.29 156.03 287.46 159.78 286.7 161.57 287.94 160.81 292.27 158.49 294.08 155.8 295.13 151.54 298.19 149.56 293.32 Z" id="29298"/>
            <path d="M 101.55 246.14 104.23 244.19 106.77 244.13 108.98 242.96 109.76 245.94 112.93 246.73 112.54 250.01 111.2 252.02 108.24 254.68 106.83 255.39 103.25 255.02 104.19 253.73 103.32 250.15 101.55 246.14 Z" id="29063"/>
            <path d="M 287.97 112.96 290.62 107.16 292.9 105 294.04 108.58 295.92 110.91 295.79 115.9 296.93 116.48 299.49 121.24 298.3 122.94 299.2 129.34 296.72 130.4 296.91 131.82 294.84 131.41 294.1 128.8 291.3 128.11 289.93 125.99 292.04 123.09 290.83 121.9 289.38 118.47 288.26 118.08 287.64 115.15 287.97 112.96 Z" id="29014"/>
            <path d="M 540.99 115.37 542.69 112.62 543.35 112.36 543.71 114.58 545.73 115.18 546.52 117.94 544.18 119.1 541.21 117.98 540.99 115.37 Z" id="22096"/>
            <path d="M 579.54 185.73 579.59 183.88 584.45 184.81 587.41 186.3 586.97 187.95 589.87 189.08 588.15 193.08 584.45 190.73 582.43 191.11 579.31 190.09 579.54 185.73 Z" id="22036"/>
            <g/>
            <path d="M 154.23 69.15 158.61 69.7 164.53 72.56 161.39 79.62 161.44 80.75 161.19 81.04 159.21 81.28 156.59 84.08 154.28 83.65 153.1 77.32 154.35 74.3 154.23 69.15 Z" id="29021"/>
            <path d="M 279.69 65.19 282 64.69 284.49 66 288.42 63.21 290.59 64.66 288.64 67.6 291.64 70.05 288.83 72.11 288.37 70.45 284.86 69.01 284.26 66.45 280.64 68.89 279.41 66.75 279.69 65.19 Z" id="29133"/>
            <path d="M 183.91 181.49 186.53 182.3 186.93 183.68 189.73 184.11 189.01 180.38 190.79 179.44 192.29 180.17 193.38 183.9 192.7 184.57 191.33 185.73 193.64 188.53 194.6 191.02 192.69 193.65 190.72 194.38 183.73 193.34 182.24 193.83 180.27 190.51 178.48 190.57 175.97 192.47 173.67 189.16 167.81 185.52 170.95 185.06 176.6 181.45 177.98 182.49 180.64 182.07 182.65 180.75 183.91 181.49 Z" id="29240"/>
            <path d="M 157.56 206.29 150.51 206.63 148.72 202.71 144.39 202.31 143.49 201.53 145.2 199.29 144.91 195.57 147.19 194.93 147.08 192.54 149.99 191.95 151.1 188.85 152.86 188.35 153.24 189.92 158.15 190.21 157.05 193.54 157.62 195.73 159.54 197.39 160.34 200.69 159.84 203.74 157.56 206.29 Z" id="29280"/>
            <path d="M 137.89 151.15 139.02 151.6 137.57 154.78 131.37 155.51 129.47 154.86 124.9 156.39 117.74 162.4 114.53 161.27 112.37 160.2 113.32 157.56 113.19 154.89 111.74 152.06 112.91 150.47 118.35 149.4 120.26 147.27 124.97 139.62 123.41 136.5 121.27 135.64 123.11 133.41 124.67 133.92 126.52 134.23 130.32 141.11 132.98 142.7 135.02 148.86 137.89 151.15 Z" id="29019"/>
            <path d="M 544.7 136.9 545.73 136.19 547.1 132.39 548.53 132.18 548.07 128.84 548.74 127.41 548.39 125.36 552.81 122.69 553.75 124.21 555.84 127.53 555.47 129.28 552.47 131.55 553.56 134.59 552.62 135.92 552.44 138.96 548.83 139.26 544.7 136.9 Z" id="22205"/>
            <path d="M 569.07 163.54 570.29 162.3 569.59 156.47 572.47 155.67 574.05 154.27 576.91 154.67 578.09 152.85 580.21 152.39 580.83 153.19 579.79 155.73 580.83 158.69 582.25 159.8 582.44 162.88 584.66 167.22 585.02 169.8 583.98 169.43 580.04 171.3 578.41 170.58 573.73 176.01 568.84 176.88 569.36 172.09 570.56 166.95 569.13 166.13 569.07 163.54 Z" id="22391"/>
            <path d="M 593.14 101.01 597.47 100.26 600.09 102.01 600.04 104.01 598.6 105.6 596.38 106.31 594.41 105.59 593.14 101.01 Z" id="22368"/>
            <path d="M 613.26 121.18 612.38 118 614.8 116.61 614.47 114.43 615.62 112.98 617.53 114.28 619.17 109.04 621.95 105.94 623.89 108.04 625.06 114.52 626.09 116.35 625.84 119.03 623.69 119.31 624.07 123.07 625.36 123.51 626.34 126.16 622.81 127.52 620.04 126.65 618.43 124.98 616.22 125.91 615.49 122.89 613.26 121.18 Z" id="22197"/>
            <path d="M 318.7 192.42 321.02 192.07 322.52 189.1 326.2 187.81 329.04 187.48 330.28 190.4 329.79 191.93 333.08 194.87 333.46 197.21 332.6 202.73 330.17 203.04 331.05 206.61 329.34 208.79 329.15 211.9 327.7 214.36 323.6 213.83 322.46 212.17 321.6 210.73 322.13 206.24 320.92 200.56 319.86 200.47 318.7 192.42 Z" id="22163"/>
            <path d="M 126.59 254.79 119.6 255.09 117.45 251.86 120.39 251.32 124.44 251.47 124.19 249.73 126.1 248.38 127.12 250.52 128.8 249.37 131.64 250.8 131.08 255 128.7 255.27 126.59 254.79 Z" id="29218"/>
            <path d="M 166.76 234.81 167.54 231.81 166.57 229.52 169.44 227.16 169.17 223.44 172.89 223.13 174.74 221.71 177.7 221.05 179.79 221.39 182.73 223.22 182.67 225.96 184.69 228.3 181.46 231.7 182.71 233.77 182.42 235.18 176.57 234.55 176.27 238.48 173.37 237.5 171.75 234.76 169.86 234.24 166.76 234.81 Z" id="29176"/>
            <path d="M 198.49 215.32 199.52 214.53 200.28 210.37 199.86 208.27 200.76 206.29 201.95 205.75 203.55 204.49 205.6 203.33 208.55 203.17 208.03 201.45 209.01 199.4 213.17 197.54 214.58 200.63 213.28 202.24 215.65 203.57 214.42 205.15 214.93 211.29 212.96 213.55 211.5 212.28 212.41 207.86 209.47 206.77 206.71 210.84 204.06 211.05 202.62 213.52 203.16 215.29 201.39 217.34 198.49 215.32 Z" id="29026"/>
            <path d="M 425.45 54.43 426 56.16 428.03 56.78 429.31 59.17 434.47 62.67 436.35 62.51 437.28 64.79 438.28 69.68 437.05 73.83 435.71 76.37 432.95 78.85 430.57 77.62 428.57 76.46 424.64 75.42 423.55 73.34 421.22 72.58 419.59 70.48 420.11 67.92 417.56 67.12 419.02 65.61 420.19 62.54 423.59 59.99 423.69 56.84 421.93 56.95 423.44 54.64 425.45 54.43 Z" id="22222"/>
            <path d="M 287.3 202.95 288.59 200.79 291.98 200.61 292.71 198.63 295.06 197.58 295.45 195.12 296.71 194.44 299.03 196.04 299.17 197.45 301.18 198.92 300.61 201.04 302.62 203.13 302.38 207.56 305.61 211 306 212.24 302.8 213.32 301.44 214.57 295.18 216.48 294.45 215.59 294.22 208.55 292.85 206.58 288.84 205.9 287.3 202.95 Z" id="29250"/>
            <path d="M 392.87 40.11 391.47 41.49 391.44 44.89 389.81 47.02 387.31 47.82 386.46 51.06 384.11 51.22 382.41 50.21 379.79 50.56 378.71 49.23 379.49 48.1 378.28 45.59 379.92 42.9 380.91 40.97 382.94 40.28 383.91 38.63 386.44 37.49 388.72 37.62 391.6 37.6 392.87 40.11 Z" id="22196"/>
            <path d="M 368.67 45.8 370.59 42.82 372.58 41.21 374.37 43.41 373.06 47.83 368.67 45.8 Z" id="22383"/>
            <path d="M 302.98 70.58 304.3 74.57 303.56 77.9 302.44 80.5 302.49 82.52 301.29 84.24 300.04 83.68 297.7 86.36 296.13 85.37 294.54 87.19 290.75 85.65 288.76 86.54 286.89 75.82 287.3 75.29 288.78 72.61 291.99 70.17 292.05 67.64 295.65 66.7 296.73 70.47 300.07 71.32 302.98 70.58 Z" id="22194"/>
            <path d="M 310.88 52.87 308.76 52.54 308.92 48.63 311.39 46.06 314.51 46.5 318.85 47.06 322.51 44.4 321.07 42.71 320.81 39.89 324.43 37.99 325.17 36.84 325.91 38.94 327.73 40.95 330.29 42.01 332.74 42.09 337.38 45.92 337.83 49.37 335.45 51.49 335.31 54.45 338.42 56.1 340.27 58.14 338.98 59.33 336.13 59.64 334.56 60.97 333.33 57.83 331.5 57.29 327.24 54.7 325.75 56.05 322.96 57.21 320.38 57.02 319.9 54.11 316.12 52.3 313.61 53.3 310.88 52.87 Z" id="22113"/>
            <path d="M 372.86 23.04 375.97 22.59 375.36 28.41 373.09 32.23 366.27 37.54 360.65 40.25 359.54 34.36 364.05 30.25 366.98 27.23 370.7 24.2 372.86 23.04 Z" id="22221"/>
            <path d="M 192.7 184.57 193.38 183.9 192.29 180.17 198.57 178.35 200.28 176.56 202.2 177.27 204.62 176.12 207.69 176.06 209.56 178.32 208.62 184.35 209.31 189.78 207.55 191.3 205.45 195.06 205.45 196.93 201.34 197.44 200.46 196.48 198.29 194.85 196.5 196.97 194.69 197.05 192.69 193.65 194.6 191.02 193.64 188.53 191.33 185.73 192.7 184.57 Z" id="29302"/>
            <path d="M 308.92 48.63 306.71 45.14 303.28 44.46 301.98 39.53 303.08 36.66 304.96 35.85 305.81 33.92 308.02 35.46 309.71 38.61 311.19 39.39 311.89 42.56 314.51 46.5 311.39 46.06 308.92 48.63 Z" id="22343"/>
            <path d="M 438.28 69.68 441.07 70.57 442.59 69.58 444.21 71.91 442.43 75 441.95 77.12 439.97 78.5 437.52 78.45 435.71 76.37 437.05 73.83 438.28 69.68 Z" id="22377"/>
            <path d="M 482.71 234.12 484.97 233.69 487.43 231.67 490.98 231.84 492.56 233.29 491.12 236 487.35 236.21 485.39 238.7 484.65 245.72 484.28 251.88 482.76 251.38 481.15 253.08 479.03 251.36 478.83 248.55 480.49 244.35 480.72 242.26 482.26 240.63 483.02 237.55 481.67 235.21 482.71 234.12 Z" id="22288"/>
            <path d="M 528.25 192.72 528.56 190.71 533.8 187.32 534.94 187.35 535.19 189.29 539.65 192.83 541.15 195.11 538.37 196.21 534.49 195.72 534.25 198.61 531.25 199.06 528.34 199.27 526.64 196.05 528.4 194.1 528.25 192.72 Z" id="22148"/>
            <path d="M 230.11 77.56 231.42 80.57 230.63 83.32 231.31 85.85 232.11 86.41 234.35 92.08 234.28 92.09 232.64 94.73 230.12 96.66 227.95 95.79 225.6 93.5 222.42 91.89 223.02 87.84 220.93 87.37 220.62 84.34 213.87 83.16 213.96 79.76 216.82 80.51 218.08 78.8 219.99 78.41 220.53 76.54 221.09 75.79 225.53 76.88 227.03 78.42 230.11 77.56 Z" id="29184"/>
            <path d="M 190.39 65.29 192.88 64.47 199.49 63.63 203.52 64.61 203.28 66.36 204.89 69.8 205.55 72.72 205.27 75.86 206.67 76.7 204.34 79.51 202.72 79.31 201.35 82.68 197.29 84.39 194.61 86.16 193.35 85.27 190.79 82.6 191.24 80.86 188.78 77.65 192.66 78.38 192.46 73.21 190.97 71.52 190.39 65.29 Z" id="29030"/>
            <path d="M 530.14 174.19 533.53 174.1 533.75 171.68 535.28 170.36 539 171.93 542.36 176.03 544.81 177.83 545.94 180.09 542.71 181.02 537.82 181.47 535.82 183.05 534.17 182.62 532.5 180.7 532.61 178.85 529.9 175.77 530.14 174.19 Z" id="22267"/>
            <path d="M 528.9 112.73 531.33 110.61 534.75 111.26 535.57 113.82 540.99 115.37 541.21 117.98 537.66 117.75 534.8 119.27 531.88 119.04 531.05 115.6 529.73 115.07 528.9 112.73 Z" id="22286"/>
            <path d="M 542.69 112.62 542.86 112.18 544.25 110.44 545.62 106.08 547.72 102.3 547.79 98.46 551.73 99.7 552.02 97.98 554.16 97.29 557.76 94.39 557.99 99.01 559.68 100.49 558.79 103.48 555.71 106.62 553.53 110.95 548.35 108.84 547.01 109.54 547.59 112.39 543.35 112.36 542.69 112.62 Z" id="22323"/>
            <path d="M 579.59 183.88 582.65 180.1 586.25 177.69 588.13 177.78 589.59 176.67 591.3 177.93 594.61 178.98 597.61 176.28 598.46 179.57 595.83 180.83 593.27 181.05 594.28 183.41 591.78 186.29 587.41 186.3 584.45 184.81 579.59 183.88 Z" id="22071"/>
            <path d="M 555.63 146.43 558.8 146.51 559.73 147.89 561.87 148.34 563.4 150 564.6 153.63 564.5 156.41 562.38 157.01 560.87 160.32 558.69 159.14 557.14 157.13 552.81 157.32 550.68 156.23 549.38 154.56 547.65 150.25 551.68 149.65 552.76 148.02 555.63 146.43 Z" id="22145"/>
            <path d="M 587.02 112.33 588.54 111.75 589.11 109.09 591.74 108.65 592.63 105.44 594.41 105.59 596.38 106.31 598.6 105.6 600.12 108.57 601.23 108.51 601.14 113.33 603.54 115.4 602 117.63 599.67 118.04 597.57 116.98 596.75 118.8 593.42 117.61 590.86 119.56 589.64 118.33 590.25 117.35 587.15 115.75 587.02 112.33 Z" id="22190"/>
            <path d="M 601.28 108.51 601.7 106.1 605.05 103.58 607.56 100.91 610.16 103.45 609.69 105.74 604.93 108.73 601.28 108.51 Z" id="22103"/>
            <path d="M 607.94 126.64 609.25 127.43 612.58 125.83 616.22 125.91 618.43 124.98 620.04 126.65 622.81 127.52 621.99 129.73 623.5 132.08 622.64 134.52 620.61 136.15 618.7 136.41 615.75 135.78 611.39 134.59 611.11 130.43 608.71 128.72 607.94 126.64 Z" id="22299"/>
            <path d="M 132.21 83.59 134.1 81.17 136.24 79.87 137.72 81.22 140.36 80.48 144.88 80.84 147.94 83.65 149.11 83.16 153.09 84.9 154.26 86.85 150.53 86.72 146.79 89.11 146.85 91.73 144.8 95.49 140.89 93.87 139.02 91.78 137.61 92.3 134.36 88.31 134.02 86.49 135.26 84.89 132.21 83.59 Z" id="29077"/>
            <path d="M 365.96 218.02 370.7 216.54 370.79 215.04 375.16 214.13 379.15 211.42 381.8 210.89 383.07 208.83 385.34 211.05 382.8 214.07 384.46 216.8 380.78 218.25 380.86 219.37 378.26 218.34 375.54 219.77 373.53 221.92 374.35 224.81 371.85 222.99 368.67 222.2 366.34 222.84 365.44 220.82 365.96 218.02 Z" id="22124"/>
            <path d="M 481.38 263.76 482.62 263.22 482.69 259.23 481.9 256.7 484.17 254.77 487.31 256.08 488.99 254.23 493.33 251.02 496.2 247.47 496.4 246.37 499.91 246.45 500.11 244.64 502.7 243.97 502.66 244.04 504.25 249.84 507.08 252.89 510.53 258.89 512.53 260.5 510.41 262.13 509.29 261.35 507.19 262.89 507.75 268.07 509.79 268.52 508.83 270.97 504.15 273.65 503.98 275.7 501.67 276.59 500.91 278.29 498.83 278.44 497.41 279.82 494.38 277.68 491.7 277.23 489.39 278.31 488 275.14 486.51 274.98 485.88 270.76 483.38 270.55 481.92 267.76 482.7 265.28 481.38 263.76 Z" id="56102"/>
            <path d="M 106.9 99.23 109.1 97.4 112.36 98.46 114.24 97.21 115.48 97.97 116.58 94.91 119.71 93.17 120.96 95.6 124.62 95.26 125.09 96.62 128.09 99.64 132.36 100.11 134.09 100.24 129.92 103.81 128.65 106.07 124.51 105.39 124.26 105.51 123.9 105.3 123.36 105.29 123.32 105.6 122.64 105.95 120.36 104.43 118.53 104.63 117.24 103.28 115.58 103.82 113.34 103.19 108.2 102.59 106.9 99.23 Z" id="29117"/>
            <path d="M 163.41 135.68 164.74 134.8 165.58 132.3 165.15 128.48 166.88 129.28 168.61 131.1 171.98 129.88 173.67 132.26 176.55 132.43 177.12 137.17 174.65 138.04 175.79 142.36 169.96 139.04 169.59 136.92 168.16 137.99 165.67 136.47 163.5 136.5 163.41 135.68 Z" id="29103"/>
            <path d="M 77.37 159.56 77.36 159.52 79.35 156.5 81.61 154.62 82.72 156.35 82.42 159.65 80.99 161.06 78.9 161.3 77.62 162.71 76.54 161.63 77.37 159.56 Z" id="29282"/>
            <path d="M 380.75 197 382.98 196.16 385.77 192.44 385.4 190.36 386.48 187.82 390.94 187.8 390.74 189.51 392.85 190.48 397.47 190.63 398.72 193.89 401.53 193.7 402.42 195.95 404.33 195.96 404.99 199.49 404.25 201.31 402.45 201.5 400.36 205.16 398.86 206.8 396.91 207.33 394.17 205.82 392.3 207.87 390.01 208.09 388.73 213.14 390.06 214.76 388.34 218.56 385.28 220.18 383.24 219.28 380.86 219.37 380.78 218.25 384.46 216.8 382.8 214.07 385.34 211.05 383.07 208.83 385.91 207.33 387.96 205.11 387.59 203.89 384.81 204.53 383.84 202.29 381.07 200.97 380.75 197 Z" id="22107"/>
            <path d="M 315.06 184.23 317.25 183.77 321.75 181.73 326.04 181.97 328.41 181.25 328.32 184.02 324.46 184.93 326.2 187.81 322.52 189.1 321.02 192.07 318.7 192.42 317.81 191.61 314.04 191.96 313.71 186.55 315.06 184.23 Z" id="22157"/>
            <path d="M 638.63 86.75 644.44 88.97 649.2 89.76 649.79 94.12 650.7 95.83 650.01 99.3 647.34 99.87 645.88 102.08 645.91 97.4 644.08 90.67 642.51 89.16 641.02 90.08 637.98 89.23 635.82 90.38 638.63 86.75 Z" id="35132"/>
            <path d="M 417.14 43.88 422.14 43.95 424.59 43.29 426.24 45.44 428.51 46.75 426.47 49.18 428.06 51.24 425.45 54.43 423.44 54.64 421.93 56.95 419.58 59.3 418.09 59.59 413.03 58.47 412.67 57.51 412.49 56.46 412.36 52.56 410.75 49.64 411.21 47.03 412.77 45.53 415.21 46.76 417.36 46.08 417.14 43.88 Z" id="22214"/>
            <path d="M 396.01 23.91 399.22 23.62 398.28 29.31 396.23 31.55 395.91 37.26 392.87 40.11 391.6 37.6 388.72 37.62 389.34 36.63 388.96 32.09 392.3 30.83 393.67 29.53 395.64 24.04 396.01 23.91 Z" id="22127"/>
            <path d="M 304.26 69.49 306.24 71.72 307.33 74.56 308.2 79.47 306.19 80.79 303.56 77.9 304.3 74.57 302.98 70.58 304.26 69.49 Z" id="22350"/>
            <path d="M 339.98 31.96 341.49 28.17 344.48 27.48 345.89 31.34 347.94 34.95 347.68 37.17 345.31 37.46 342.17 35.35 340.68 34.16 339.98 31.96 Z" id="22363"/>
            <path d="M 363.04 22.84 364.45 22.68 368.28 15.56 369.98 13.16 372.75 13.35 374.73 18.74 372.86 23.04 370.7 24.2 366.98 27.23 364.05 30.25 362.55 29.17 362.1 26.46 363.04 22.84 Z" id="22218"/>
            <path d="M 308.02 35.46 311.65 32.42 311.99 30.94 310.88 28.06 312.11 25.84 313.6 28.89 316.99 28.89 317.89 30.36 316.73 31.88 317.73 34.09 319.98 34.09 325.17 36.84 324.43 37.99 320.81 39.89 321.07 42.71 322.51 44.4 318.85 47.06 314.51 46.5 311.89 42.56 311.19 39.39 309.71 38.61 308.02 35.46 Z M 300.98 32.48 303.07 31.11 305.55 30.59 306.43 32.1 303.67 34.3 300.98 32.48 Z" id="22198"/>
            <path d="M 303.32 131.61 304.91 130.47 306.69 131.07 310.01 127.86 312.41 129.74 315.2 127.31 320.03 126.08 322.47 127.26 322.47 128.93 320.11 130.07 319.03 133.31 317.48 136.93 313.49 136.22 311.38 137.28 308.73 136.01 308.19 134.37 305.36 134.75 303.32 131.61 Z" id="22132"/>
            <path d="M 440.62 401.28 437.21 399.14 433.91 398.82 432.12 397.64 433.51 392.88 435.72 394.27 438.37 391.55 439.33 394.39 442.71 395.4 442.05 398.41 440.62 401.28 Z" id="56005"/>
            <path d="M 436.8 380.35 436.81 383.39 435.4 386.29 432.59 383.53 431.83 382.44 432.91 379.93 436.8 380.35 Z" id="56106"/>
            <path d="M 600.4 362.39 598.29 367.1 594.45 366.35 592.85 366.98 590.07 372.27 588.81 371.26 590.47 370.03 590.44 368.32 587.12 368.1 587.5 364.81 588.84 362.24 588.64 359.32 589.31 358.8 596.71 358.96 598.57 359.9 600.4 362.39 Z" id="35236"/>
            <path d="M 214.02 48.84 217.33 46.86 220.57 46.79 222.99 50.28 218.86 49.41 218.23 50.62 214.91 49.85 214.02 48.84 Z" id="29082"/>
            <path d="M 226.91 61.11 226.43 62.45 228.45 64.73 227.54 66.23 227.75 69.2 229.76 70.11 231.07 72.98 230.11 77.56 227.03 78.42 225.53 76.88 221.09 75.79 220.53 76.54 219.92 75.37 216.65 74.36 218.16 68.92 217.09 66.27 220.5 63.26 222.44 63.47 224.15 61.31 226.91 61.11 Z" id="29259"/>
            <path d="M 190.39 65.29 190.97 71.52 192.46 73.21 192.66 78.38 188.78 77.65 184.21 76.33 179.79 74.99 180.45 71.59 186.91 66.81 190.39 65.29 Z" id="29185"/>
            <path d="M 154.23 69.15 154.35 74.3 153.1 77.32 154.28 83.65 153.09 84.9 149.11 83.16 147.94 83.65 144.88 80.84 144.89 80.82 144.88 80.67 141.09 79.43 137.89 77.53 138.61 76.03 144.34 75.3 148.85 72.88 154.23 69.15 Z" id="29091"/>
            <path d="M 132.21 83.59 135.26 84.89 134.02 86.49 134.36 88.31 137.61 92.3 139.02 91.78 140.89 93.87 140.25 95.7 137.97 98.17 134.97 100.29 134.09 100.24 132.36 100.11 127.76 98.85 125.35 96.15 125.04 94.92 123.79 95.19 121.1 94.84 120.88 93.5 115.78 91.05 114.41 88.99 111.85 88.94 112.91 85.08 116.94 84.39 116.34 82.45 120.01 82.74 120.83 81.12 123.08 81.96 122.55 83.59 123.8 85.01 125.74 84.36 129.12 84.55 132.21 83.59 Z" id="29195"/>
            <path d="M 776.45 224.17 778.62 222.92 783 224.4 783.53 222.98 788.01 221.09 787.85 219.93 789.83 219.91 790.56 222.57 790.75 229.67 787.56 230.06 786.89 227.99 784.64 227.49 779.58 228.23 776.78 227.39 776.45 224.17 Z" id="35061"/>
            <path d="M 106.9 99.23 104.43 98.78 105.02 95.37 104.1 92.52 106.01 89.16 108.51 90.89 109.11 93.2 112.86 91.99 119.71 93.17 116.58 94.91 115.48 97.97 114.24 97.21 112.36 98.46 109.1 97.4 106.9 99.23 Z" id="29101"/>
            <path d="M 74.07 115.14 74.6 111.79 76.97 110.05 76.7 107.95 79.64 106.06 82.08 103.45 84.74 104.55 85.5 106.07 86.32 109.33 83.38 113.67 82.61 117.84 77.67 115.48 76.7 115.99 74.07 115.14 Z" id="29109"/>
            <path d="M 72.57 168.8 72.83 165.17 70.94 163.69 71.43 161.33 76.54 161.63 77.62 162.71 77.72 166.26 72.57 168.8 Z M 77.37 159.56 75.6 160.49 74.49 156.4 77.36 159.52 77.37 159.56 Z" id="29040"/>
            <path d="M 1 135.62 3.8 132.35 7.64 130.68 7.59 128.83 13.71 128.16 15.16 125.94 16.71 127.89 16.99 130.45 21.45 131.34 20.34 133.2 18.65 132.78 16.75 135.51 13.08 135.17 11.16 138.06 6.68 139.55 6.92 137.59 10.56 135.33 9.17 133.45 2.82 135.9 1 135.62 Z" id="29155"/>
            <path d="M 184.44 179.8 177.54 180.05 175.95 179.18 175.1 176.96 179.05 175.08 182.22 171.52 181.02 166.46 179.57 166.56 183.69 163.7 186.04 165.31 190.16 164.76 191.86 163.5 194.55 162.32 199.43 162.24 200.75 163 205.83 163.59 208.38 163.31 210.42 164.02 212.11 162.4 212.9 165.39 211.58 172.5 209.6 175.06 207.69 176.06 204.62 176.12 200.95 173.29 197.12 172 193.65 173.55 192.65 176.43 190.42 178.33 188.26 178.79 186.69 177.55 184.44 179.8 Z" id="29078"/>
            <path d="M 649.2 89.76 653.59 90.87 650.7 95.83 649.79 94.12 649.2 89.76 Z" id="35361"/>
            <path d="M 621.95 105.94 625.06 102.46 628.77 101.77 629.3 102.25 630.17 103.52 632.81 104.27 634.45 105.86 634.8 108.17 633.87 111.35 634.89 115.44 634.65 118.25 633.07 119.62 634.07 123.35 631.02 122.84 630.08 127.14 626.34 126.16 625.36 123.51 624.07 123.07 623.69 119.31 625.84 119.03 626.09 116.35 625.06 114.52 623.89 108.04 621.95 105.94 Z" id="35179"/>
            <path d="M 396.01 23.91 395.64 24.04 395.33 23.9 393.46 21.02 393.71 17.74 397.11 15.57 399.08 18.76 396.01 23.91 Z" id="22111"/>
            <path d="M 453.01 413.17 451.57 413.7 444.26 410.38 444.91 406.52 440.62 401.28 442.05 398.41 444.45 398.55 444.38 399.96 448.38 401.22 449.7 403.66 453.86 406.81 453.01 413.17 Z" id="56214"/>
            <path d="M 408.23 370.16 409.98 364.05 409.51 361.57 412.05 360.98 412.8 362.04 412.84 364.18 418.93 365.31 420.76 369.62 422.38 371.44 423.76 376.42 421.67 379.5 420.72 379.34 418.88 380.45 413.8 380.26 412.19 381.95 411.26 381.54 409.11 374.6 408.36 370.43 408.23 370.16 Z" id="56046"/>
            <path d="M 614.07 354.26 615.53 350.5 618 350.2 620.6 347.92 623.67 348.54 624.16 346.94 626.62 347.46 626.82 349.21 630.14 350.4 630.94 353.7 628.42 353.82 621.24 356.34 617.82 360.39 614.65 360.12 614.74 355.45 614.07 354.26 Z" id="35064"/>
            <path d="M 766.69 282.58 770.98 279.72 771.23 283.84 769.85 285.1 767.54 284.27 766.69 282.58 Z" id="35325"/>
            <path d="M 482.61 231.62 482.58 231.35 487.23 229.85 488.63 227.38 493.13 226.55 494.05 223.57 497.44 222.21 499.03 224.78 498.59 227.99 500.56 227.51 501.28 230.15 501.24 234.6 502.98 236.76 501.57 237.73 502.6 240.91 502.7 243.97 500.11 244.64 499.91 246.45 496.4 246.37 493.06 243.46 493.05 242.4 488.14 242.1 484.65 245.72 485.39 238.7 487.35 236.21 491.12 236 492.56 233.29 490.98 231.84 487.43 231.67 484.97 233.69 482.71 234.12 482.61 231.62 Z" id="22241"/>
            <path d="M 296.81 177.54 297.65 175.08 300.53 172.42 302.61 167.01 306.18 165.48 307.63 165.88 307.49 168.54 309.15 172.91 308.69 174.75 310.94 175.04 313.7 172.22 315.01 173.67 312.81 174.48 310.58 179.4 308.44 179.19 306.12 181.74 303.9 181.52 303 184.44 299.07 183.75 297.29 181.5 296.81 177.54 Z" id="29205"/>
            <path d="M 629.59 63.49 632.27 63.54 637.95 61.77 638.42 65.13 640.36 68.27 637.66 70.67 635.52 71.62 635 74.82 631.05 73.2 631.39 66.42 629.59 63.49 Z" id="35049"/>
            <path d="M 616.24 67.78 618.72 67.33 618.99 65.36 620.77 63.89 626.98 65.11 629.59 63.49 631.39 66.42 631.05 73.2 623.74 73.26 620.84 74.38 618.8 73.02 615.67 69.65 616.24 67.78 Z" id="35263"/>
            <path d="M 584.68 81.15 585.97 84.68 588.99 88.74 589.72 91.32 584.56 93.53 584.48 92.25 584.87 90.74 582.64 88.12 581.81 84.22 579.45 83.21 582.93 81.19 584.68 81.15 Z" id="35256"/>
            <path d="M 559.68 100.49 562.2 98.97 566.43 100.28 566.76 105.04 565.43 106.09 566.01 108.8 564.39 113.46 562 113.8 560.45 112.13 561.29 110.1 560.38 108.44 561.9 106.41 561.78 103.6 558.79 103.48 559.68 100.49 Z" id="22311"/>
            <path d="M 569 98.78 571.73 93.25 570.9 91.9 571.51 89.21 573.13 89.86 573.35 93.88 572.81 97.29 570.64 98.05 570.2 99.55 569 98.78 Z" id="22302"/>
            <path d="M 543.47 85.82 546.7 86.38 546.7 87.6 546.86 90.76 549.19 90.47 550.03 93.4 547.88 94.08 548.6 96.46 550.11 96.17 552.02 97.98 551.73 99.7 547.79 98.46 547.32 96.11 544.55 94.51 543.83 97.69 541.63 98.55 540.79 100.21 539.82 97.6 538.15 97.06 539.45 91.96 540.3 89.55 543.47 85.82 Z" id="22174"/>
            <path d="M 566.76 105.04 567.48 100.74 569 98.78 570.2 99.55 570.51 104.14 572.47 106.06 574 103.95 576.4 109 579.71 111.27 578.18 114.65 575.41 116.72 572.25 114.67 570.49 116.45 568.28 111.29 566.01 108.8 565.43 106.09 566.76 105.04 Z" id="22049"/>
            <path d="M 597.57 86.86 600.79 87.37 602.47 85.55 601.87 91.07 597.96 90.51 597.57 86.86 Z" id="35241"/>
            <path d="M 540.73 73.52 542.93 75.81 543.93 80.9 545.83 83.45 543.47 85.82 540.3 89.55 537.44 89.56 536.1 87.9 536.1 85.01 534.91 82.24 532.7 82.06 530.75 80.29 529.31 80.9 532.47 78.86 534.07 76.14 538.54 75.41 540.73 73.52 Z" id="22179"/>
            <path d="M 592.86 80.96 593.79 79.55 597.77 79.72 599.85 80.54 598.18 83.72 602.51 84.09 602.47 85.55 600.79 87.37 597.57 86.86 593.37 90.54 593.97 84.88 592.86 80.96 Z" id="35093"/>
            <path d="M 546.7 87.6 548.57 87.68 551.3 83.85 552.95 83.68 554.78 84.18 556.2 89.84 558.54 93.05 557.76 94.39 554.16 97.29 552.02 97.98 550.11 96.17 548.6 96.46 547.88 94.08 550.03 93.4 549.19 90.47 546.86 90.76 546.7 87.6 Z" id="22143"/>
            <path d="M 579.02 95.29 576 92.4 576.46 90.33 579.02 88.67 581.2 88.35 584.87 90.74 584.48 92.25 582.45 92.76 580.99 95.03 579.02 95.29 Z" id="22094"/>
            <path d="M 552.95 83.68 554.02 81.69 558.89 78.68 559.61 81.98 561.95 84.6 561.23 86.53 564.09 88.46 565.73 90.89 565.37 93.05 567.34 93.6 568.11 95.24 567.99 98.52 566.43 100.28 562.2 98.97 559.68 100.49 557.99 99.01 557.76 94.39 558.54 93.05 556.2 89.84 554.78 84.18 552.95 83.68 Z" id="22282"/>
            <path d="M 584.68 81.15 586.19 80.14 590.25 81.62 592.86 80.96 593.97 84.88 593.37 90.54 592.3 93.39 589.72 91.32 588.99 88.74 585.97 84.68 584.68 81.15 Z" id="35287"/>
            <path d="M 545.83 83.45 543.93 80.9 542.93 75.81 540.73 73.52 545.32 69.8 546.26 67.33 549.2 71.19 553.54 71.7 551.8 74.08 551.23 76.24 549.01 78.52 548.37 81.52 545.83 83.45 Z" id="22201"/>
            <path d="M 609.19 90.63 606.34 92.08 607.4 88.82 605.09 85.87 606.2 84.12 604.39 81.31 602.68 76.86 605.63 75.89 609.42 73.59 611.69 69.38 611.32 67.5 616.11 66.04 616.24 67.78 615.67 69.65 618.8 73.02 620.84 74.38 617.95 77.51 618.15 80.85 619.91 82.53 620.4 85.18 619.24 86.56 619.56 89 618.6 90.16 612.92 87.42 610.68 88 609.19 90.63 Z" id="35288"/>
            <path d="M 570.2 99.55 570.64 98.05 572.81 97.29 574.73 98.45 577.63 95.54 579.02 95.29 580.99 95.03 582.45 92.76 584.48 92.25 584.56 93.53 585.26 95.17 587.82 96.46 590.39 100.04 593.14 101.01 594.41 105.59 592.63 105.44 591.74 108.65 589.11 109.09 588.54 111.75 587.02 112.33 580.22 112.64 579.71 111.27 576.4 109 574 103.95 572.47 106.06 570.51 104.14 570.2 99.55 Z" id="22209"/>
            <path d="M 537.46 144.56 538.98 141.93 541.69 138.97 544.7 136.9 548.83 139.26 552.44 138.96 552.73 143.1 553.69 144.63 555.83 144.11 555.63 146.43 552.76 148.02 551.68 149.65 547.65 150.25 549.38 154.56 550.68 156.23 549.2 157.21 546.21 162.03 542.79 159.37 543.39 158.22 540.95 156.45 538.69 157.01 538.33 154.89 539.49 152.54 537.96 150.11 537.24 146.59 537.46 144.56 Z" id="22084"/>
            <path d="M 449.61 79.14 448.74 80.8 449.99 82.33 449.47 84.49 451.25 88.6 449.33 91.55 449.85 93.23 452.44 93.79 449.61 95.58 446.75 94.94 446.55 91.33 444.25 90.54 442.82 91.39 441.6 89.45 441.88 86.61 442.91 84.52 442.84 82.35 445.28 79.58 444.91 78.24 445.88 79.02 449.61 79.14 Z" id="22055"/>
            <path d="M 364.15 49.05 363.58 47.69 366.58 47.08 368.67 45.8 373.06 47.83 374.37 43.41 376.72 42.92 377.74 42.7 379.92 42.9 378.28 45.59 379.49 48.1 378.71 49.23 376.81 52.95 375.25 52.92 373.82 56.03 370.65 60.06 372.4 62.79 369.13 64.82 366.96 63.76 362.98 60.53 362.66 59.36 363.03 58 364.22 55.87 362.03 53.19 364.15 49.05 Z" id="22264"/>
            <path d="M 620.61 136.15 622.64 134.52 623.5 132.08 621.99 129.73 622.81 127.52 626.34 126.16 630.08 127.14 631.02 122.84 634.07 123.35 637.78 127.85 639.88 127.83 642.36 128.96 643.03 131.55 645 131.14 643.66 137.14 642.84 138.83 640.68 139.26 639.23 137.1 636.95 137.07 635.55 140.76 627.85 140.75 625.16 140.09 621.47 138.76 620.61 136.15 Z" id="35308"/>
            <path d="M 488.75 115.44 486.15 111.87 486.34 109.75 492.32 105.11 497.49 98.6 498.78 99.48 499.55 102.87 501.03 103.46 502.37 108.66 504.91 111.89 510.85 113.74 511.62 112.48 514.99 114.01 516.96 116.53 517.99 119.67 521.08 119.21 524.84 119 524.7 122.18 526.42 123.14 527.33 127.29 529.64 131.68 527.23 134.37 525.84 135.07 523.96 133.99 523.27 132.08 518.14 131.17 516.72 133.92 514.04 135.02 514.54 137.84 516.35 141.91 516.09 146.37 511.8 147.92 509.85 149.41 509.39 151.12 508.11 149.41 508.08 148.26 509.46 144.18 508.73 141.71 506.44 139.67 502.57 138.24 500.36 138.32 497.44 141.47 494.37 141.62 494.59 144.35 493.1 145.66 490.58 145.47 491.23 144.11 489.86 141.98 490.74 138.93 493.96 135.93 490.7 131.21 492.99 129.42 495.36 129.53 497.26 128.51 499.14 125.67 501.44 125.45 502.61 127.47 507.18 126.92 508.81 123.25 507.87 118.77 506.21 120.06 502.26 120.3 500.92 118.58 499.06 118.18 493.95 119.79 492.95 120.34 489.99 119.23 488.75 115.44 Z" id="22093"/>
            <path d="M 262.26 97.04 264.12 97.11 269.52 95.19 271.77 93.9 270.77 91.7 270.83 88.38 275.18 88.31 277.84 86.66 280.52 86.14 283.09 87.34 282.81 91.5 283.92 92.5 287.85 92.74 290.08 93.92 291.35 92.71 292.08 93.51 290.57 95.56 290.44 97.37 291.74 100.05 292.9 105 290.62 107.16 287.97 112.96 285.86 113.7 284.36 112.46 281.44 112.89 279.84 109.61 278.56 109.16 275.11 103.28 271.02 101.99 266.53 102.61 264.69 101.33 260.29 101.03 258.61 98.03 262.26 97.04 Z" id="29199"/>
            <path d="M 711.68 222.17 712.64 226.46 717.09 226.03 717.92 226.83 719.05 233.3 717.12 234.53 718.31 238.72 716.43 238.02 714.22 238.51 711.82 237.35 711.48 232.83 708.34 229.95 709 227.98 708 225.07 711.68 222.17 Z" id="35327"/>
            <path d="M 749.46 184.65 750.56 186.18 752.5 185.17 755.16 185.01 756.74 187.55 753.69 192.81 752.17 193.15 749.45 191.65 745.18 192.06 744.44 190.97 744.43 189.14 747.48 188.53 749.46 184.65 Z" id="35086"/>
            <path d="M 775.87 170.09 777.87 170.38 780.33 168.99 780.16 164.09 782.35 162.28 787.59 159.41 789.55 159.42 791.46 161.03 790.51 163.01 791.39 167.28 793.52 170.94 791.12 174.54 791.01 174.67 790.91 174.96 790.32 175.78 784.96 176.38 780.18 174.72 779.02 174.4 775.87 170.09 Z" id="35062"/>
            <path d="M 781.1 151.03 782.11 148.26 784.59 145.75 786.66 145 789.09 148.85 789.08 148.87 789.18 149.4 789.21 149.42 791.98 155.51 791.19 156.04 787.14 156.12 781.32 155.02 782.32 152.97 781.1 151.03 Z" id="35157"/>
            <path d="M 642.03 235.19 644.16 235.5 645.98 233.72 647.97 234.3 647.46 231.26 648.87 230.34 651.06 231.12 657.06 235.22 658.44 237.25 656.86 239.47 656.82 242.38 655.32 245.35 652.76 245.47 649.94 242.54 648.62 242.67 648.19 240.03 641.19 236.76 642.03 235.19 Z" id="35240"/>
            <path d="M 778.17 193.55 778.64 190.52 782.83 188.25 783.06 193.74 784.79 196.74 784.5 198.62 786.63 202.45 785.35 205.2 783.12 202.65 779.83 204.42 779.31 199.88 778 197.62 778.17 193.55 Z" id="35232"/>
            <path d="M 743.46 142.21 745.65 141.28 748.68 138.53 749.83 142.23 752.1 143.18 752.18 145.04 754 146.87 757.95 149.27 761.46 149.14 763.05 153.93 760.74 154.42 760.11 155.76 756.44 157.76 755.22 155.85 752.92 154.75 748.99 157.91 748.06 159.96 746.27 160.23 745.44 157.46 743.4 156.65 742.48 155.52 743.84 151.63 743.64 149.07 744.67 148.29 744.4 143.68 743.46 142.21 Z" id="35273"/>
            <path d="M 683.38 214.12 687.12 214.11 686.92 217.12 691.07 217.29 692.43 216.63 695.98 218.94 693.49 220 693.31 223.54 694.34 227.58 692.35 229.87 691.78 226.5 688.1 226.53 688.44 222.81 687.33 220.84 683.76 219.61 681.58 217.19 683.38 214.12 Z" id="35334"/>
            <path d="M 667.02 132.77 668.1 131.51 670.45 134.14 675.24 129.9 676.48 131.25 680.64 129.63 682.91 130.55 681.8 135.17 682.21 136.01 680.23 136.58 678.3 141.15 678.76 142.98 676.28 143.79 677.83 147.76 676.61 149.82 674.48 148.81 673.56 146.57 669.8 145.97 670.97 142.53 670.79 141.04 669.8 136.91 670.21 135.41 667.61 134.93 667.02 132.77 Z" id="35092"/>
            <path d="M 756.44 157.76 760.11 155.76 760.74 154.42 763.05 153.93 763.7 154.7 764.21 155.94 764.05 158.47 762.19 159.24 760.73 161.9 760.59 164.29 761.77 165.39 759.94 170.26 759.18 169.76 755.92 167.35 755.71 162.84 756.44 157.76 Z" id="35150"/>
            <path d="M 684.18 164.07 686.33 164.43 687.99 163.15 697.6 163.99 696.39 161.58 697.88 160.57 704.05 158.1 704.25 155.89 704.61 160.66 704.1 163.2 702.75 163.48 699.75 172.41 697.71 176.17 693.8 180.1 690.58 180.71 690.22 179.47 693.22 176.44 691.21 174.05 689.11 173.41 688.53 170.79 685.8 169.12 685.66 167.25 684.18 164.07 Z" id="35326"/>
            <path d="M 690.47 120.21 691.53 116.94 694.9 117.41 696.59 114.55 698.57 114.97 699.09 119.47 697.45 121.52 698.86 125.05 698.54 127.21 697.63 125.9 694.96 124.83 694.35 123.57 691.59 123.82 690.47 120.21 Z" id="35354"/>
            <path d="M 639.03 193.44 640.93 191.38 646.19 189.99 646.94 188.18 650.69 188.98 650.03 190.47 650.12 194.03 648.63 194.74 643.47 194.77 641.25 195.36 639.03 193.44 Z" id="35146"/>
            <path d="M 667.5 174.82 671.25 173.83 670.59 171.38 673.08 170.88 675.16 171.67 678.86 175.82 679.34 181.91 678.03 182.78 675.91 180.82 672.59 180.05 671.51 182.66 669.13 184.09 666.48 178.76 667.5 174.82 Z" id="35195"/>
            <path d="M 757.46 187.68 759.73 185.27 761.12 181.72 760.03 179.56 763.71 179.95 766.07 182.12 767.05 184.32 768.73 185.61 771.29 190 771.71 192.19 769.15 194.88 767.15 191.56 765.42 193.31 763.72 193.57 762.63 192.07 760.41 191.34 757.46 187.68 Z" id="35214"/>
            <path d="M 642.15 184.94 643.92 187.28 646.94 188.18 646.19 189.99 640.93 191.38 639.18 188.53 640.23 185.5 642.15 184.94 Z" id="35276"/>
            <path d="M 748.25 118.07 750.06 113.78 749.56 110.83 753.51 108.01 754.56 109.03 760.16 109.96 762.99 109.33 763.83 111.61 765.74 112.4 766.04 113.85 763.15 117.33 764.06 119.26 759.37 122.96 758.66 125.88 760.34 127.48 758.18 129.38 756.24 127.17 753.83 126.25 752.78 124.4 751.68 121.45 748.25 118.07 Z" id="35271"/>
            <path d="M 697.71 176.17 699.75 172.41 702.75 163.48 704.1 163.2 704.61 160.66 709.68 160.94 711.58 163.81 713.06 166.49 711.25 170.06 709.64 172.49 709.45 174.66 708.42 173.75 705.13 176.05 702.44 176.54 700.25 177.99 697.71 176.17 Z" id="35355"/>
            <path d="M 759.18 169.76 759.94 170.26 764.29 167.43 767.69 168.18 768.67 169.25 768.78 176.33 764.63 179.94 763.71 179.95 760.03 179.56 755.74 177.53 753.75 174 758.27 171.78 759.18 169.76 Z" id="35137"/>
            <path d="M 641.99 225.61 643.22 225.96 644.44 228.29 648.65 229.37 648.87 230.34 647.46 231.26 647.97 234.3 645.98 233.72 644.16 235.5 642.03 235.19 641.97 231.81 640.33 230.05 641.99 225.61 Z" id="35131"/>
            <path d="M 638.8 212.16 638.86 209.93 639.97 209.86 641.74 207.27 643.56 207.56 644.92 212.67 642.64 215.57 640.86 215.76 638.8 212.16 Z" id="35216"/>
            <path d="M 746.27 160.23 748.06 159.96 748.99 157.91 752.92 154.75 755.22 155.85 756.44 157.76 755.71 162.84 755.92 167.35 759.18 169.76 758.27 171.78 753.75 174 750.36 176.05 746.99 177.13 746.32 170.99 745.04 166.67 746.39 163.15 746.27 160.23 Z" id="35243"/>
            <path d="M 671.61 155.65 671.99 153.46 674.48 148.81 676.61 149.82 678.48 152.78 677.84 156.22 676.25 157.61 671.61 155.65 Z" id="35286"/>
            <path d="M 698.57 114.97 700.77 112.09 702.03 112.09 702.4 112.08 702.51 111.91 704.55 116.33 706.29 118.41 706.72 120.72 706.86 121 707.44 123.9 708.02 125.93 705.46 127.15 701.37 123.97 698.86 125.05 697.45 121.52 699.09 119.47 698.57 114.97 Z" id="35329"/>
            <path d="M 731.65 217.25 734.2 216.16 732.55 212.95 734.21 207.91 735.91 205.75 739.38 203.92 741.42 201.57 744.07 203.2 746.03 204.06 748.82 201.71 751.27 202.07 754.81 203.7 756.9 203.53 758.03 204.92 755.89 207.28 753.08 208.64 752.74 211.68 751.26 212.07 749.76 215.04 748.65 216.52 746.05 217.11 742.42 221.67 742.59 222.52 739.7 221.95 738.3 220.25 735.47 220.59 730.93 218.77 731.65 217.25 Z" id="35347"/>
            <path d="M 745.68 176.75 746.99 177.13 750.36 176.05 753.75 174 755.74 177.53 760.03 179.56 761.12 181.72 759.73 185.27 757.46 187.68 756.74 187.55 755.16 185.01 752.5 185.17 750.56 186.18 749.46 184.65 749.38 183.26 746.76 182.37 748.01 179.65 745.68 176.75 Z" id="35025"/>
            <path d="M 658.44 237.25 660.21 236 662.11 230.8 659.08 228.05 661.94 224.34 666.33 224.43 667.35 226.51 670.69 227.03 674.56 224.63 676.51 225.23 678.49 222.25 681.37 221.19 679.05 226.81 680.65 230.69 679.53 233.07 679.81 236.31 681.39 237.15 680.64 240.53 676.53 242.95 670.93 243.73 668.55 242.51 666.91 242.83 668.87 238.15 664.76 238.63 661.36 241.5 658.76 242.94 656.82 242.38 656.86 239.47 658.44 237.25 Z" id="35238"/>
            <path d="M 681.37 221.19 683.76 219.61 687.33 220.84 688.44 222.81 688.1 226.53 691.78 226.5 692.35 229.87 695.52 231.2 694.58 237.54 692.23 239.26 689.52 239.38 686.77 241.01 685 240.62 683.46 237.36 681.39 237.15 679.81 236.31 679.53 233.07 680.65 230.69 679.05 226.81 681.37 221.19 Z" id="35051"/>
            <path d="M 608.37 210.65 609.68 211.09 612.05 209.62 611.2 207.85 611.53 204.39 614.81 203.87 617.34 203.15 617.7 200.41 620.22 200.23 624.87 198.91 625.37 201.31 624.72 204.59 626.87 206.46 627.27 208.3 622.05 208.97 621.57 212.93 622.28 214.96 625.44 216.36 624.87 219.42 623.5 222.67 621.22 226.12 618.86 224.6 616.18 224 614.14 222.31 613.11 220.25 615.9 220.49 615.67 215.29 612.11 215.75 610.81 217.7 608.97 214.97 611.32 213.18 608.98 212.27 608.37 210.65 Z" id="35023"/>
            <path d="M 789.26 132.41 789.1 135.64 786.52 137.99 786.08 140.09 786.96 144.62 785.83 142.88 782.21 143.56 780.82 145.29 778.96 145.49 775.68 142.98 775.26 140.39 773.96 138.35 774.07 134.86 774.7 131.54 776.23 131.72 781.26 129.83 783.94 130.04 787.23 132.93 789.26 132.41 Z" id="35018"/>
            <path d="M 748.25 118.07 751.68 121.45 752.78 124.4 748.62 127.73 747.72 130.28 745.19 130.01 743.56 130.78 740.42 130.17 739.95 127.77 738.31 124.84 739.11 121.09 740.75 121.93 744.06 121.08 746.61 117.99 748.25 118.07 Z" id="35111"/>
            <path d="M 683.38 214.12 686.23 208.98 690.21 209.4 693.03 205.43 694.76 204.07 694.91 201.16 697.49 199.34 697.1 197.04 700.59 195.9 703.62 196.44 706.29 197.96 709.26 196.55 713.02 198.64 713.25 199.94 718.93 199.97 720.81 198.55 722.95 200.56 725.06 201.37 724.77 204.36 723.9 205.44 722.57 203.63 720.08 203.02 721.38 206.14 719.73 207.94 718.61 206.41 714.89 207 714.63 205.45 712.3 203.56 709.45 205.06 709.08 206.38 710.99 208.3 707.78 210.4 707.56 212.18 705.25 213.4 705.79 215.81 704.36 217.35 700.97 216.2 698.5 217.09 695.98 218.94 692.43 216.63 691.07 217.29 686.92 217.12 687.12 214.11 683.38 214.12 Z" id="35152"/>
            <path d="M 717.09 226.03 718.1 226.07 722.03 223.45 722.91 221.15 725.77 221.68 727.75 223.33 730.66 226.3 731.41 228.32 730.96 233.04 730.14 235.35 725.5 237.89 727.05 239.93 724.6 241.66 721.6 241.11 718.31 238.72 717.12 234.53 719.05 233.3 717.92 226.83 717.09 226.03 Z" id="35068"/>
            <path d="M 642.7 217.42 645.64 216.55 645.73 213.67 647.65 212.49 650.86 213.34 652.01 211.85 654.94 212.03 655.31 215.39 654.85 217.87 658.05 219.41 659.98 218.2 659.94 221.55 661.65 221.73 661.94 224.34 659.08 228.05 656.5 228.26 655.49 230.13 651.95 229.91 651.06 231.12 648.87 230.34 648.65 229.37 644.44 228.29 643.22 225.96 645.51 221.21 643.16 219.34 642.7 217.42 Z" id="35210"/>
            <path d="M 695.98 218.94 698.5 217.09 700.97 216.2 704.36 217.35 705.79 215.81 709.8 218.93 711.68 222.17 708 225.07 709 227.98 708.34 229.95 706.07 231.22 702.72 231.4 700.64 227.91 697.88 228.5 695.52 231.2 692.35 229.87 694.34 227.58 693.31 223.54 693.49 220 695.98 218.94 Z" id="35001"/>
            <path d="M 723.9 205.44 724.77 204.36 728.99 204.52 731.38 206.88 734.21 207.91 732.55 212.95 734.2 216.16 731.65 217.25 727.31 213.59 725.42 213.14 722.55 210.13 723.74 209.07 723.9 205.44 Z" id="35101"/>
            <path d="M 693.01 181.78 693.8 180.1 697.71 176.17 700.25 177.99 702.44 176.54 705.13 176.05 708.42 173.75 709.45 174.66 708.66 178.28 709.87 179.84 710.24 183.39 705.9 183.66 703.16 182.72 702.21 185.23 703.15 187.07 700.27 187.08 698.67 189.66 695.39 191.5 694.08 193.11 693.47 190.59 691.12 185.98 693.01 181.78 Z" id="35118"/>
            <path d="M 763.7 154.7 765.56 151.12 766.11 147.85 769.84 144.01 767.92 138.64 768.71 135.87 770.84 136 774.07 134.86 773.96 138.35 775.26 140.39 775.68 142.98 778.96 145.49 780.82 145.29 782.21 143.56 785.83 142.88 786.96 144.62 786.66 145 784.59 145.75 782.11 148.26 781.1 151.03 776.98 150.82 775.05 152.05 774.38 153.76 770.93 154.92 768.72 152.1 765.99 152.52 764.21 155.94 763.7 154.7 Z" id="35142"/>
            <path d="M 738.25 193.01 741.95 191.29 744.44 190.97 745.18 192.06 749.45 191.65 752.17 193.15 753.69 192.81 753.66 194.98 751.98 197.51 752.32 201.36 751.27 202.07 748.82 201.71 746.03 204.06 744.07 203.2 745.9 202.1 745.22 200.02 743.2 199.35 741.81 196.67 739.52 195.43 738.25 193.01 Z" id="35170"/>
            <path d="M 730.93 218.77 735.47 220.59 738.3 220.25 739.7 221.95 738.58 223.97 739.04 227.99 737.06 228.34 732.99 227.66 730.66 226.3 727.75 223.33 729.15 219.01 730.93 218.77 Z" id="35166"/>
            <path d="M 675.11 205.22 677.19 206.41 679.13 208.86 681.3 209.12 681.09 206.41 686.47 208.49 686.23 208.98 683.38 214.12 681.58 217.19 683.76 219.61 681.37 221.19 678.49 222.25 671.17 218.25 671.61 215.42 667.39 213.64 671.72 207.78 673.92 208.08 675.11 205.22 Z" id="35024"/>
            <path d="M 760.85 196.53 762.88 196.16 763.72 193.57 765.42 193.31 767.15 191.56 769.15 194.88 771.71 192.19 774.58 193.39 778.17 193.55 778 197.62 779.31 199.88 779.83 204.42 776.52 206.31 772.37 206.48 766.98 206.78 765.21 203.91 761.72 204.02 760.12 203.29 760.91 200.01 760.85 196.53 Z" id="35072"/>
            <path d="M 631.13 228.76 633.32 228.97 634.89 227.3 641.99 225.61 640.33 230.05 640.16 231.65 636.33 232.69 631.66 233.6 630.62 230.51 631.13 228.76 Z" id="35065"/>
            <path d="M 765.74 112.4 769.99 111.97 770.03 112.08 770.3 112.36 770.33 112.35 770.9 113.69 770.93 118.4 772.75 121.5 770.15 123.81 768.78 122.2 768.87 120.11 764.06 119.26 763.15 117.33 766.04 113.85 765.74 112.4 Z" id="35190"/>
            <path d="M 751.27 202.07 752.32 201.36 751.98 197.51 753.66 194.98 754.22 197.29 755.56 198.12 760.85 196.53 760.91 200.01 760.12 203.29 761.72 204.02 760 205.58 758.03 204.92 756.9 203.53 754.81 203.7 751.27 202.07 Z" id="35260"/>
            <path d="M 642.4 181.39 645.99 180.04 649.3 184.14 652.65 184.46 650.69 188.98 646.94 188.18 643.92 187.28 642.15 184.94 641.44 182.42 642.4 181.39 Z" id="35317"/>
            <path d="M 650.69 188.98 652.65 184.46 655.24 184.62 659.53 189.39 660.07 191.67 657.61 196.33 655.82 197.29 651.67 197.78 649.43 196.67 648.63 194.74 650.12 194.03 650.03 190.47 650.69 188.98 Z" id="35356"/>
            <path d="M 723.9 205.44 723.74 209.07 722.55 210.13 725.42 213.14 727.31 213.59 731.65 217.25 730.93 218.77 729.15 219.01 727.75 223.33 725.77 221.68 722.91 221.15 722.03 223.45 718.1 226.07 717.09 226.03 712.64 226.46 711.68 222.17 709.8 218.93 705.79 215.81 705.25 213.4 707.56 212.18 707.78 210.4 710.99 208.3 709.08 206.38 709.45 205.06 712.3 203.56 714.63 205.45 714.89 207 718.61 206.41 719.73 207.94 721.38 206.14 720.08 203.02 722.57 203.63 723.9 205.44 Z" id="35031"/>
            <path d="M 694.08 193.11 695.39 191.5 698.67 189.66 700.27 187.08 703.15 187.07 709.08 187.04 709.03 188.76 706.15 189.52 705.39 190.95 708.14 193.28 709.26 196.55 706.29 197.96 703.62 196.44 700.59 195.9 697.1 197.04 696.86 194.89 693.82 194.35 694.08 193.11 Z" id="35107"/>
            <path d="M 617.23 182 618.84 181.61 621.34 179.42 623.56 177.3 627.16 179.53 625.23 183.51 626.78 185 627.78 188.49 627.12 190.67 626.95 193.09 622.07 188.32 618.43 187.24 616.73 186.32 617.23 182 Z" id="35180"/>
            <path d="M 631.11 220.94 632.1 218.87 631.55 217.05 633.36 215.42 635.57 214.81 636.18 217.76 637.51 217.79 640.86 215.76 642.64 215.57 642.7 217.42 643.16 219.34 645.51 221.21 643.22 225.96 641.99 225.61 634.89 227.3 633.32 228.97 631.13 228.76 629.37 227.09 631.11 220.94 Z" id="35275"/>
            <path d="M 613.26 189.85 616.41 187.16 618.43 187.24 622.07 188.32 626.95 193.09 625.05 196.04 624.87 198.91 620.22 200.23 617.7 200.41 617.34 203.15 614.81 203.87 615.15 201.98 612.81 198.88 610.35 197.74 612.01 195.34 611.16 192.66 613.81 191.7 613.26 189.85 Z" id="35135"/>
            <path d="M 710.24 183.39 720.95 187.08 722.82 187.1 724.22 190.08 731.69 191.21 732.92 190.43 734.7 192.21 738.25 193.01 735.04 193.83 732.43 193.32 730.88 196.63 728.72 196.3 725.95 197.48 725.06 201.37 722.95 200.56 720.81 198.55 719.78 192.73 718.26 190.22 715.51 189.75 715.97 187.56 709.08 187.04 703.15 187.07 702.21 185.23 703.16 182.72 705.9 183.66 710.24 183.39 Z" id="35253"/>
            <path d="M 711.25 170.06 715.04 168.57 716 168.96 719.48 167.08 723.34 165.28 723.51 167.44 725.59 168.22 727.37 170.7 726.27 174.17 724.44 174.14 722.55 175.91 713.86 173.13 709.64 172.49 711.25 170.06 Z" id="35304"/>
            <path d="M 709.08 187.04 715.97 187.56 715.51 189.75 718.26 190.22 719.78 192.73 720.81 198.55 718.93 199.97 713.25 199.94 713.02 198.64 709.26 196.55 708.14 193.28 705.39 190.95 706.15 189.52 709.03 188.76 709.08 187.04 Z" id="35121"/>
            <path d="M 709.45 174.66 709.64 172.49 713.86 173.13 722.55 175.91 723.74 178.23 726.77 177.93 726.17 181.32 726.93 182.57 722.82 187.1 720.95 187.08 710.24 183.39 709.87 179.84 708.66 178.28 709.45 174.66 Z" id="35178"/>
            <path d="M 682.91 130.55 685.07 130.21 686.53 128.46 689.7 128 692.15 125.44 691.59 123.82 694.35 123.57 694.96 124.83 697.63 125.9 698.54 127.21 698.28 129.12 699.96 130.61 701.14 135.63 704.38 138.75 702.67 140.2 704.25 143.96 702.56 144.84 704.18 148.11 700.34 146.99 697.19 148.84 695.95 150.6 693.92 150.52 690.64 148.67 688.06 149.74 683.33 150.62 684.61 148.53 687.97 145.58 686.05 144.21 684.28 141.21 682.21 136.01 681.8 135.17 682.91 130.55 Z" id="35019"/>
            <path d="M 693.92 150.52 695.95 150.6 697.19 148.84 700.34 146.99 704.18 148.11 704.69 148.6 703.73 151.79 705.42 153.43 704.25 155.89 704.05 158.1 697.88 160.57 696.57 159.77 696.75 155.97 696.02 152.78 693.92 150.52 Z" id="35242"/>
            <g/>
            <path d="M 627.12 190.67 627.78 188.49 631.45 187.45 632.8 185.77 633.88 184.47 637.44 183.33 641.44 182.42 642.15 184.94 640.23 185.5 639.18 188.53 640.93 191.38 639.03 193.44 635.35 193.22 633.54 191.94 632.65 193.9 628.67 192.52 627.12 190.67 Z" id="35058"/>
            <path d="M 688.06 149.74 690.64 148.67 693.92 150.52 696.02 152.78 696.75 155.97 696.57 159.77 697.88 160.57 696.39 161.58 697.6 163.99 687.99 163.15 689.94 160.45 689.93 153.16 686.31 151.79 688.06 149.74 Z" id="35309"/>
            <path d="M 737.29 173.78 738.75 172.61 741.7 173.78 743.41 172.12 746.32 170.99 746.99 177.13 745.68 176.75 742.65 178.8 741.58 178.29 739.85 181.67 737.43 181.48 736.68 179.3 736.6 174.05 737.29 173.78 Z" id="35063"/>
            <g/>
            <path d="M 639.03 193.44 641.25 195.36 640.52 196.49 643.7 200.18 642.37 203.14 639.97 203.05 637.29 200.63 637.79 198.91 636.43 195.61 634.49 195.65 632.65 193.9 633.54 191.94 635.35 193.22 639.03 193.44 Z" id="35144"/>
            <g/>
            <path d="M 624.87 198.91 625.05 196.04 626.95 193.09 627.12 190.67 628.67 192.52 632.65 193.9 634.49 195.65 636.43 195.61 637.79 198.91 637.29 200.63 639.97 203.05 642.37 203.14 641.74 207.27 639.97 209.86 638.86 209.93 638.56 207.51 627.27 208.3 626.87 206.46 624.72 204.59 625.37 201.31 624.87 198.91 Z" id="35245"/>
            <path d="M 725.06 201.37 725.95 197.48 728.72 196.3 730.88 196.63 732.43 193.32 735.04 193.83 738.25 193.01 738.25 193.01 739.52 195.43 741.81 196.67 743.2 199.35 745.22 200.02 745.9 202.1 744.07 203.2 741.42 201.57 739.38 203.92 735.91 205.75 734.21 207.91 731.38 206.88 728.99 204.52 724.77 204.36 725.06 201.37 Z" id="35154"/>
            <path d="M 682.21 136.01 684.28 141.21 686.05 144.21 687.97 145.58 684.61 148.53 683.33 150.62 678.92 151.4 678.48 152.78 676.61 149.82 677.83 147.76 676.28 143.79 678.76 142.98 678.3 141.15 680.23 136.58 682.21 136.01 Z" id="35205"/>
            <g/>
            <path d="M 676.25 157.61 677.84 156.22 678.48 152.78 678.92 151.4 683.33 150.62 688.06 149.74 686.31 151.79 689.93 153.16 689.94 160.45 687.99 163.15 686.33 164.43 684.18 164.07 684.17 164.08 679.68 162.27 676.15 162.27 676.25 157.61 Z" id="35164"/>
            <path d="M 621.22 226.12 623.5 222.67 624.87 219.42 627.15 218.72 631.11 220.94 629.37 227.09 631.13 228.76 630.62 230.51 627.75 231.05 624.77 233.24 623.02 232.97 622.98 231 622.73 229.27 619.85 228.84 621.22 226.12 Z" id="35040"/>
            <path d="M 633.36 215.42 633.64 211.91 637.07 211.14 638.8 212.16 640.86 215.76 637.51 217.79 636.18 217.76 635.57 214.81 633.36 215.42 Z" id="35081"/>
            <path d="M 716.19 162.73 720.28 162.68 724.15 165 723.34 165.28 719.48 167.08 717.23 166.17 716.19 162.73 Z" id="35336"/>
            <path d="M 659.98 218.2 660.92 217.77 664.56 219.45 666.67 221.86 666.33 224.43 661.94 224.34 661.65 221.73 659.94 221.55 659.98 218.2 Z" id="35189"/>
            <path d="M 651.06 231.12 651.95 229.91 655.49 230.13 656.5 228.26 659.08 228.05 662.11 230.8 660.21 236 658.44 237.25 657.06 235.22 651.06 231.12 Z" id="35353"/>
            <path d="M 662.18 213.33 664.37 212.9 667.39 213.64 671.61 215.42 671.17 218.25 678.49 222.25 676.51 225.23 674.56 224.63 670.69 227.03 667.35 226.51 666.33 224.43 666.67 221.86 664.56 219.45 662.79 216.01 662.18 213.33 Z" id="35278"/>
            <path d="M 680.62 120.29 686.36 118.33 687.12 119.5 690.47 120.21 691.59 123.82 692.15 125.44 689.7 128 686.53 128.46 685.07 130.21 682.91 130.55 680.64 129.63 680.24 127.32 680.98 125.39 679.9 123.35 680.62 120.29 Z" id="35339"/>
            <path d="M 652.82 162.28 655.84 160.1 659.02 160.19 661.44 159.51 663.07 157.83 666.27 158.25 666.87 155.62 668.74 154.79 671.61 155.65 676.25 157.61 676.15 162.27 679.68 162.27 684.17 164.08 682.21 165.46 680.22 165.34 674.04 166.21 673.08 170.88 670.59 171.38 671.25 173.83 667.5 174.82 666.11 171.96 664.17 172.73 658.8 172.58 657.71 179.29 656.81 179.22 655.26 177.2 657.6 175.21 656.79 173.48 653.04 172.15 652.4 168.88 648.96 168.05 652.82 162.28 Z" id="35094"/>
            <path d="M 781.32 155.02 787.14 156.12 791.19 156.04 789.55 159.42 787.59 159.41 782.35 162.28 780.16 164.09 780.33 168.99 777.87 170.38 775.87 170.09 775.82 170.05 776.12 168.55 774.12 167.6 775.64 163.51 773.49 159.95 773.88 158.48 776.21 157.85 781.32 155.02 Z" id="35112"/>
            <g/>
            <path d="M 708.25 150.88 710.23 152.45 711.76 150.7 713.28 152.59 718.7 151.1 719.82 149.73 721.96 151.06 723.55 152.55 720.86 155.2 718.38 156.05 717.88 160.38 716.19 162.73 717.23 166.17 711.58 163.81 709.68 160.94 710.29 158.71 709.86 155.13 707.9 152.59 708.25 150.88 Z" id="35075"/>
            <path d="M 711.25 170.06 713.06 166.49 711.58 163.81 717.23 166.17 719.48 167.08 716 168.96 715.04 168.57 711.25 170.06 Z" id="35261"/>
            <path d="M 724.92 141.33 730.44 141.96 731.13 140.6 734.21 139.93 736.75 140.83 738.23 143.6 737.83 145.81 740.23 145.62 740.94 142.36 743.46 142.21 744.4 143.68 744.67 148.29 743.64 149.07 743.84 151.63 742.48 155.52 743.4 156.65 740.45 159.24 739.54 159.04 735.42 161.62 732.66 161.25 731.8 158.73 731.62 154.28 729.69 153.5 728.92 150.35 726.96 149.4 724.76 150.01 724.8 146.39 726.28 144.19 724.92 141.33 Z" id="35257"/>
            <path d="M 722.55 175.91 724.44 174.14 726.27 174.17 729.94 174.88 731.84 174.26 733.63 172.22 736.81 173.29 737.29 173.78 736.6 174.05 736.68 179.3 737.43 181.48 739.85 181.67 741.58 178.29 742.65 178.8 745.68 176.75 748.01 179.65 746.76 182.37 749.38 183.26 749.46 184.65 747.48 188.53 744.43 189.14 744.44 190.97 741.95 191.29 738.25 193.01 738.25 193.01 734.7 192.21 732.92 190.43 731.69 191.21 724.22 190.08 722.82 187.1 726.93 182.57 726.17 181.32 726.77 177.93 723.74 178.23 722.55 175.91 Z" id="35282"/>
            <path d="M 724.94 132.17 728.44 133.42 730.36 132.51 731.43 130.77 733.85 130.13 735.56 128.63 734.65 126.03 736.91 123.34 738.31 124.84 739.95 127.77 740.42 130.17 743.56 130.78 745.19 130.01 747.72 130.28 749.21 135.56 748.68 138.53 745.65 141.28 743.46 142.21 740.94 142.36 740.23 145.62 737.83 145.81 738.23 143.6 736.75 140.83 734.21 139.93 731.13 140.6 730.44 141.96 724.92 141.33 723.53 139.77 725.96 135.02 724.94 132.17 Z" id="35191"/>
            <g/>
            <path d="M 790.32 175.78 786.2 179.77 784.28 184.14 784.11 187 782.83 188.25 778.64 190.52 778.17 193.55 774.58 193.39 771.71 192.19 771.29 190 768.73 185.61 767.05 184.32 766.07 182.12 763.71 179.95 764.63 179.94 768.78 176.33 773.16 175.21 774.6 176.69 778.11 176.88 780.18 174.72 784.96 176.38 790.32 175.78 Z" id="35163"/>
            <path d="M 707.44 123.9 710.61 125.08 713.34 125.24 714.92 124.37 718.1 127.24 716.92 128.97 718.66 130.74 718.7 130.78 720.6 132.36 722.03 131.67 724.94 132.17 725.96 135.02 723.53 139.77 724.92 141.33 726.28 144.19 724.8 146.39 724.76 150.01 721.96 151.06 719.82 149.73 718.7 151.1 713.28 152.59 711.76 150.7 710.23 152.45 708.25 150.88 706.06 150.58 704.69 148.6 704.18 148.11 702.56 144.84 704.25 143.96 702.67 140.2 704.38 138.75 701.14 135.63 699.96 130.61 698.28 129.12 698.54 127.21 698.86 125.05 701.37 123.97 705.46 127.15 708.02 125.93 707.44 123.9 Z" id="35004"/>
            <path d="M 721.96 151.06 724.76 150.01 726.96 149.4 728.92 150.35 729.69 153.5 731.62 154.28 731.8 158.73 732.66 161.25 730.14 161.87 729.22 163.36 724.15 165 720.28 162.68 716.19 162.73 717.88 160.38 718.38 156.05 720.86 155.2 723.55 152.55 721.96 151.06 Z" id="35292"/>
            <path d="M 656.81 179.22 657.71 179.29 658.8 172.58 664.17 172.73 666.11 171.96 667.5 174.82 666.48 178.76 669.13 184.09 668 187.34 665.73 189.12 659.53 189.39 655.24 184.62 655.02 183.12 656.81 179.22 Z" id="35128"/>
            <path d="M 766.21 133.17 767.31 131.78 768.63 130.75 771.71 125.52 770.15 123.81 772.75 121.5 770.93 118.4 770.9 113.69 773.7 115.59 779.48 114.44 781.85 116.74 784.59 117.73 786.43 117.43 789.29 119.39 787.63 120.52 787.13 124.12 788.72 124.91 787.65 127.24 789.87 130.35 789.26 132.41 787.23 132.93 783.94 130.04 781.26 129.83 776.23 131.72 774.7 131.54 774.07 134.86 770.84 136 768.71 135.87 766.21 133.17 Z" id="35162"/>
            <path d="M 753.66 194.98 753.69 192.81 756.74 187.55 757.46 187.68 760.41 191.34 762.63 192.07 763.72 193.57 762.88 196.16 760.85 196.53 755.56 198.12 754.22 197.29 753.66 194.98 Z" id="35192"/>
            <path d="M 627.27 208.3 638.56 207.51 638.86 209.93 638.8 212.16 637.07 211.14 633.64 211.91 633.36 215.42 631.55 217.05 632.1 218.87 631.11 220.94 627.15 218.72 624.87 219.42 625.44 216.36 622.28 214.96 621.57 212.93 622.05 208.97 627.27 208.3 Z" id="35227"/>
            <path d="M 704.69 148.6 706.06 150.58 708.25 150.88 707.9 152.59 709.86 155.13 710.29 158.71 709.68 160.94 704.61 160.66 704.25 155.89 705.42 153.43 703.73 151.79 704.69 148.6 Z" id="35244"/>
            <path d="M 723.34 165.28 724.15 165 729.22 163.36 730.14 161.87 732.66 161.25 735.42 161.62 736.99 163.37 737.62 166.24 736.11 169.89 737.35 170.38 736.81 173.29 733.63 172.22 731.84 174.26 729.94 174.88 726.27 174.17 727.37 170.7 725.59 168.22 723.51 167.44 723.34 165.28 Z" id="35280"/>
            <path d="M 743.4 156.65 745.44 157.46 746.27 160.23 746.39 163.15 745.04 166.67 746.32 170.99 743.41 172.12 741.7 173.78 738.75 172.61 737.29 173.78 736.81 173.29 737.35 170.38 736.11 169.89 737.62 166.24 736.99 163.37 735.42 161.62 739.54 159.04 740.45 159.24 743.4 156.65 Z" id="35310"/>
            <path d="M 653.04 172.15 656.79 173.48 657.6 175.21 655.26 177.2 656.81 179.22 655.02 183.12 655.24 184.62 652.65 184.46 649.3 184.14 645.99 180.04 647.61 174.62 653.04 172.15 Z" id="35130"/>
            <path d="M 684.17 164.08 684.18 164.07 685.66 167.25 685.8 169.12 688.53 170.79 689.11 173.41 686.62 176.57 681.81 178.07 678.86 175.82 675.16 171.67 673.08 170.88 674.04 166.21 680.22 165.34 682.21 165.46 684.17 164.08 Z" id="35110"/>
            <path d="M 133.26 237.98 137.98 238.01 141.93 235.04 143.12 236.04 148.52 234.92 149.92 236.59 149.63 238.5 150.71 241.09 149.09 244.13 150.26 246.45 152.86 246.35 153.45 247.8 152.59 249.01 153.7 250.94 152.31 252.61 150.82 250.37 148.6 253.83 144.16 253.34 144.08 251.3 140.76 250.14 139.93 242.32 136.75 243.01 133.26 237.98 Z" id="29226"/>
            <path d="M 148.52 234.92 151.74 234.5 153.3 236.29 155.74 236.89 159.57 239.36 162.84 240.21 164.38 239.45 168.37 241.58 169.64 243.4 167.08 244.05 163.99 252.05 159.99 249.39 157.69 245.14 155.39 247.27 153.45 247.8 152.86 246.35 150.26 246.45 149.09 244.13 150.71 241.09 149.63 238.5 149.92 236.59 148.52 234.92 Z" id="29046"/>
            <path d="M 108.98 242.96 112.22 242.33 114.87 243.9 116.97 242.53 117.46 240.47 120.93 241.04 122.57 240.08 127.23 241.03 129.63 238.39 133.26 237.98 136.75 243.01 135.19 244.02 134.03 246.83 134.52 248.34 131.64 250.8 128.8 249.37 127.12 250.52 126.1 248.38 124.19 249.73 124.44 251.47 120.39 251.32 117.45 251.86 112.54 250.01 112.93 246.73 109.76 245.94 108.98 242.96 Z" id="29008"/>
            <path d="M 641.25 195.36 643.47 194.77 648.63 194.74 649.43 196.67 651.67 197.78 652.5 201.52 654.36 202.92 653.42 204.27 657.53 206.41 653.52 207.35 650.96 209.49 652.01 211.85 650.86 213.34 647.65 212.49 645.73 213.67 645.64 216.55 642.7 217.42 642.64 215.57 644.92 212.67 643.56 207.56 641.74 207.27 642.37 203.14 643.7 200.18 640.52 196.49 641.25 195.36 Z" id="35120"/>
            <path d="M 651.67 197.78 655.82 197.29 657.61 196.33 659.02 197.2 661.06 201.11 660.53 203.9 662.19 205.94 661.46 208.17 662.94 210 661.34 210.96 657.73 209.69 654.94 212.03 652.01 211.85 650.96 209.49 653.52 207.35 657.53 206.41 653.42 204.27 654.36 202.92 652.5 201.52 651.67 197.78 Z" id="35177"/>
            <path d="M 485.56 161.54 487.11 160.81 487.29 163.29 485.56 161.54 Z" id="22153"/>
            <path d="M 405.37 50.13 406.65 49.53 406.69 46.7 408.18 47.82 411.21 47.03 410.75 49.64 412.36 52.56 412.49 56.46 409.77 54.39 407.05 54.65 406.89 52.01 405.37 50.13 Z" id="22086"/>
            <path d="M 749.76 215.04 751.26 212.07 752.74 211.68 753.08 208.64 755.89 207.28 758.03 204.92 760 205.58 760 209.99 758.29 210.87 758.84 215.04 759.54 218.12 756.24 219.57 752.21 216.71 751.23 216.91 749.76 215.04 Z" id="35330"/>
            <path d="M 768 161.03 772.29 162.33 773.49 159.95 775.64 163.51 774.12 167.6 776.12 168.55 775.82 170.05 772.93 171.44 770.55 168.19 768.64 167.74 768 161.03 Z" id="35021"/>
            <path d="M 260.96 157.9 265.91 160.02 266.59 157.46 268.1 156.58 271.91 156.5 273.23 159.28 276.55 157.55 277.21 160.69 275.5 160.75 274.63 163.78 272.7 165.8 270.49 164.9 266 165.48 263.23 163.7 260.03 160.73 258.69 158.58 260.96 157.9 Z" id="29081"/>
            <path d="M 421.19 183.24 425 184.71 426.69 184.38 426.85 186.97 425.14 188.4 426.16 191.41 422.65 192.78 424.61 195.97 426.27 197.41 426.24 200.57 424.18 201.38 420.6 204.96 419.82 201.72 420.53 199.93 415.69 200.39 414.83 198.14 414.17 196.56 417.52 193.85 418.24 192.46 416.85 188.29 419.5 187.7 418.21 184.63 421.19 183.24 Z" id="22295"/>
            <path d="M 760 205.58 761.72 204.02 765.21 203.91 766.98 206.78 772.37 206.48 772.27 210.02 775.33 210.06 774.64 211.4 773.79 217.59 773.82 220.5 772.53 221.54 772.22 224.56 769.73 226.34 768.68 224.86 763.29 223.91 761.89 222.07 762.31 219.2 761.44 217.13 758.84 215.04 758.29 210.87 760 209.99 760 205.58 Z" id="35015"/>
            <path d="M 381.08 182.65 385.14 183.96 387.86 183.97 386.48 187.82 385.4 190.36 385.77 192.44 382.98 196.16 380.75 197 378.64 194.34 378.45 192.67 381.13 190.27 378.7 189 378.62 186.52 381.08 182.65 Z" id="22331"/>
            <path d="M 687.2 257.42 688.61 257.79 689.98 260.15 692.17 260.07 695.02 261.24 695.39 263.92 694.22 263.74 690.8 265.97 688.05 266.2 687.2 265.94 686.08 261.75 685.85 259.16 687.2 257.42 Z" id="35250"/>
            <path d="M 418.47 140.68 421.45 139.49 423.81 140.91 426.49 141.49 423.81 144.44 424.24 146.36 421.41 147.54 420.03 143.81 418.63 143.35 418.47 140.68 Z" id="22126"/>
            <path d="M 502.19 177.25 504.59 172.5 504.97 168.19 507.47 163.12 505.87 160.66 506.32 159.59 506.25 158.18 507.8 154.82 507.39 153.79 510.55 154.13 511.25 156.31 510.13 158.59 511.39 160.59 510.89 162.82 511.61 164.83 509.9 168.55 507.5 169.29 504.65 174.6 504.06 177.46 502.19 177.25 Z" id="22296"/>
            <path d="M 672.58 299.87 672.19 297.75 672.45 293.36 674.71 292.14 675.46 290.61 678.49 290.94 679.11 289.36 679.21 291.19 682.55 291.31 680.92 296.42 682.34 297.12 682.86 301.34 682.1 306.9 679.93 306.59 679 304.13 677.21 305.64 674.8 303.06 672.24 301.77 672.58 299.87 Z" id="35212"/>
            <path d="M 736.05 258.09 735.91 256.25 736.6 254.96 740.69 254.31 743.79 252.1 746.61 253.06 748.3 254.85 748.18 257.44 750.65 258.83 751.37 261.14 753.53 262.57 752.13 264.04 752.22 267.05 745.76 267.22 745.47 269.55 743.77 270.66 743.67 272.41 740.12 274.9 735.5 271.08 736.99 269.28 735.19 265.49 735.6 263.69 737.28 262.29 736.05 258.09 Z" id="35014"/>
            <path d="M 767.69 168.18 768.64 167.74 770.55 168.19 772.93 171.44 775.82 170.05 775.87 170.09 779.02 174.4 780.18 174.72 778.11 176.88 774.6 176.69 773.16 175.21 768.78 176.33 768.67 169.25 767.69 168.18 Z" id="35324"/>
            <path d="M 375.8 228.67 375.93 230.86 379.08 231.64 381.68 230.98 385.47 227.38 388.35 227.86 390.05 226.41 389.9 231.28 391.83 232.61 394.54 236.25 398.14 237.73 399.61 239.44 394.53 242.15 392.84 244.77 391.61 244.95 388.16 246.36 385.29 243.15 384.22 238.82 382.64 237.31 381.07 241.15 376.5 241.09 374.91 235.95 373.83 234.11 373.69 231.08 375.8 228.67 Z" id="56242"/>
            <path d="M 764.21 155.94 765.99 152.52 768.72 152.1 770.93 154.92 774.38 153.76 775.05 152.05 776.98 150.82 781.1 151.03 782.32 152.97 781.32 155.02 776.21 157.85 773.88 158.48 773.49 159.95 772.29 162.33 768 161.03 768.34 158.86 766.34 157.68 764.05 158.47 764.21 155.94 Z" id="35138"/>
            <path d="M 664.3 294.26 666.31 293.18 669.44 293.47 670.45 289.92 671.75 289.34 675.46 290.61 674.71 292.14 672.45 293.36 672.19 297.75 672.58 299.87 669.45 300.54 667.09 298.34 665.05 298.75 663.91 297.15 664.3 294.26 Z" id="35231"/>
            <path d="M 465.55 275.01 470.74 273.25 471.72 272.76 475.55 274.16 476.14 277.7 478.17 278.38 475.73 281.86 475.64 285.13 473.81 288.13 470.18 288.25 467.88 288.98 466.31 286.26 465.54 285.62 466.3 282.5 464.98 280.34 465.55 275.01 Z" id="56189"/>
            <path d="M 695.59 305.18 696.04 303.35 698.67 302.32 702.73 303.65 704.99 302.09 706.33 301.44 708.33 302.77 706.01 306.35 704.14 312.19 703.04 315.05 701.28 314.15 698.6 314.66 696.33 311.68 696.62 309.67 695.59 305.18 Z" id="35140"/>
            <path d="M 733.15 246.29 736.86 245.13 736.96 246.4 739.38 247.81 742.61 246.37 743.79 252.1 740.69 254.31 736.6 254.96 735.91 256.25 733.32 254.95 729.57 254.78 730.24 250.98 731.61 247.79 733.15 246.29 Z" id="35161"/>
            <path d="M 758.13 139.06 760.87 139.12 761.13 137.01 762.74 134.02 765.61 134.4 766.21 133.17 768.71 135.87 767.92 138.64 769.84 144.01 766.11 147.85 765.56 151.12 763.7 154.7 763.05 153.93 761.46 149.14 759.32 144.17 757.32 141.95 758.13 139.06 Z" id="35215"/>
            <path d="M 639.65 298.77 643.36 296.54 645.33 298.65 650.18 299.04 651.68 301.64 652.15 307.08 650.95 310.13 650.83 312.08 648.99 313.63 647.46 313.32 645.84 308.59 646.5 307.14 641.68 306.49 640.06 304.15 639.29 300.59 639.65 298.77 Z" id="35289"/>
            <path d="M 532.54 377.03 533.76 377.66 534.27 380.38 532.82 381.99 536.06 383.82 535.25 387.06 532.69 389.46 529.77 389.35 527.98 387.43 527.76 382.68 529.52 380.98 530.46 377.79 532.54 377.03 Z" id="56077"/>
            <path d="M 441.55 290.02 443.08 285.99 442.26 283.8 443.06 279.66 447.39 275.57 450.38 276.26 456.08 276.83 459 275.82 461.65 275.91 463.44 274.65 465.55 275.01 464.98 280.34 466.3 282.5 465.54 285.62 466.31 286.26 461.56 290.82 460.49 289.39 458.45 290.5 459.5 293.15 454.04 295.36 452 295.18 449.5 297.25 447.97 297.63 444.67 296.33 441.82 292.9 441.55 290.02 Z" id="56140"/>
            <path d="M 730.66 226.3 732.99 227.66 737.06 228.34 739.04 227.99 741.57 229.05 744.39 231.36 744.35 232.66 741.48 232.01 738.71 234.58 738.46 234.61 736.37 235.59 732.13 233.09 730.96 233.04 731.41 228.32 730.66 226.3 Z" id="35283"/>
            <path d="M 567.55 282.89 567.55 282.88 569.33 283.64 573.04 283.12 575.68 283.49 575.64 286.12 578.11 292.62 575.84 292.33 574.37 293.48 566.51 295.21 565.31 294.09 566.14 288.55 568.54 286.78 567.55 282.89 Z" id="56180"/>
            <path d="M 458.1 326.29 457.9 324.59 460.48 322.87 463.4 324.26 463.1 331.2 466.43 332.96 467.15 336.84 466.82 339.69 465.06 343.05 462.88 344.75 459.59 341.48 458.83 338.84 459.84 336.64 458.54 334.95 458.56 331.52 455.69 329.82 458.07 327.85 458.1 326.29 Z" id="56120"/>
            <path d="M 295.69 294.21 295.85 300.06 297.09 302.3 294.15 300.89 290.14 301.47 288.64 302.99 284.47 305.35 280.46 296.91 280.39 295.18 282.75 294.45 284.82 292.25 285.76 293.14 288.95 293.44 291.29 292.63 293.73 294.08 295.69 294.21 Z" id="29300"/>
            <path d="M 296.58 303.6 297.54 304.58 300.65 305.14 301.31 307.96 297.08 311.75 295.34 310.62 292.86 312.06 293.77 308.11 293.3 304.88 296.58 303.6 Z" id="29005"/>
            <path d="M 171.38 263.33 173.05 261.39 173.26 258.97 175.38 256.72 178.47 257.51 182.38 256.19 183.92 256.87 183.76 258.87 186.61 259.92 188.58 262.86 191.01 264.05 192.33 269.79 190.38 269.4 187.67 266.62 184.84 266.28 182.99 268.4 181.09 266.42 179.88 268.36 177.74 266.48 175.14 267.1 173.55 266.15 173.61 263.45 171.38 263.33 Z" id="29173"/>
            <path d="M 184.79 285.33 185.29 286.42 182.95 289.5 182.63 293.69 181.59 296.88 180.77 296.44 177.47 300.99 176.38 300.79 175.22 296.18 175.65 293.56 174.46 291.08 177.38 291.07 178.04 288.26 182.29 285.98 184.79 285.33 Z" id="29296"/>
            <path d="M 131.08 255 136.23 253.96 138.64 254.09 141.93 255.4 144.13 255.26 144.16 253.34 148.6 253.83 150.82 250.37 152.31 252.61 150.48 252.99 150.24 256.08 148.07 255.78 146.18 259.61 146.59 263.05 145.76 263.97 141.56 265.62 139.4 263.17 138.4 263.43 134.85 261.49 134.89 259.55 132.21 257.55 130.16 257.71 128.7 255.27 131.08 255 Z" id="29143"/>
            <path d="M 409.73 137.54 410.93 135.99 415.39 137.33 416.77 140.84 418.47 140.68 418.63 143.35 420.03 143.81 421.41 147.54 415.82 148.16 414.16 149.05 409.92 148.93 408.57 146.83 408.63 144.39 410.33 143.2 408.44 140.66 409.73 137.54 Z" id="22291"/>
            <path d="M 413.96 159.78 418.84 159.01 419.06 160.03 424.3 160.94 425.2 162.84 427.27 163.61 428.12 165.48 427.89 168.87 425.66 171.81 424.94 171.34 420.52 172.41 418.55 171.53 416.95 165.33 413.96 159.78 Z" id="22073"/>
            <path d="M 522.1 137.56 523.96 133.99 525.84 135.07 527.23 134.37 530.9 136.61 531.02 139.86 528.73 141.45 525.19 138.95 522.1 137.56 Z" id="22326"/>
            <path d="M 164.92 262.31 166.45 259.29 165.34 257.4 167.02 252.93 168.55 254.67 170.39 254.09 175.01 256.25 175.38 256.72 173.26 258.97 173.05 261.39 171.38 263.33 165.95 263.21 164.92 262.31 Z" id="29065"/>
            <path d="M 159.66 272.37 163.6 271.63 166.34 269.51 164.73 267.96 164.98 265 165.95 263.21 171.38 263.33 173.61 263.45 173.55 266.15 175.14 267.1 177.74 266.48 176.39 267.19 175.55 271.65 177.22 273.28 179.84 273.44 180.09 278.36 180.75 279.51 179.46 282.09 177.27 282.59 172.72 281.1 173.53 279.91 172.14 276.04 168.57 275.28 165.14 275.75 164.54 277.83 160.13 278.08 159.79 277.18 159.66 272.37 Z" id="29167"/>
            <path d="M 508.08 148.26 506.23 148.18 505.51 150.82 503.77 150.83 503.71 147.64 502.22 145.05 499.51 144.23 497.63 143.47 497.44 141.47 500.36 138.32 502.57 138.24 506.44 139.67 508.73 141.71 509.46 144.18 508.08 148.26 Z" id="22098"/>
            <path d="M 494.07 161.22 495.86 158.91 498.81 156.8 502.28 156.05 506.32 159.59 505.87 160.66 507.47 163.12 504.97 168.19 504.59 172.5 502.19 177.25 500.16 176.84 495.05 172.13 496.91 167.87 496.56 163.95 494.07 161.22 Z" id="22345"/>
            <path d="M 442.6 201.78 444.13 200.78 446.78 200.66 449.57 199.47 451.19 197.37 457.13 197.31 459.54 196.28 461.77 198.31 462.5 200.88 460.87 203.07 459.55 206.28 453.99 207.52 449.7 211.27 447.25 212.11 445.04 209.56 442.6 201.78 Z" id="22376"/>
            <path d="M 150.24 256.08 151.78 259.01 157.63 259.16 158.49 262.23 157.25 265.19 153.29 265.66 150.16 264.62 146.59 263.05 146.18 259.61 148.07 255.78 150.24 256.08 Z" id="29070"/>
            <path d="M 511.05 193.04 517.57 189.85 518.6 187.64 521.57 186.08 522.93 186.24 524.53 189.72 528.25 192.72 528.4 194.1 526.64 196.05 528.34 199.27 526.48 199.98 522.99 202.73 523.27 205.34 520.63 204.97 515.17 199.29 512.42 198.45 511.92 194.73 511.05 193.04 Z" id="22333"/>
            <path d="M 150.16 264.62 153.29 265.66 157.25 265.19 158.49 262.23 157.63 259.16 160.93 259.82 162.65 261.72 164.92 262.31 165.95 263.21 164.98 265 164.73 267.96 166.34 269.51 163.6 271.63 159.66 272.37 156.91 273.85 155.77 272.76 152.46 272.98 151.56 271.34 148.6 271.8 150.05 268.77 150.16 264.62 Z" id="29108"/>
            <path d="M 593.55 142.23 593.4 146.06 593.32 146.84 590.99 148.3 589.84 150.07 586.36 145.8 586.09 144.69 588.35 142.53 590.39 142.42 592.73 143.79 593.55 142.23 Z" id="22008"/>
            <path d="M 600.26 168.72 601.85 166.46 603.82 165.62 604.66 162.54 608.11 169.62 603.74 171.86 600.66 171.22 600.26 168.72 Z" id="22352"/>
            <path d="M 388.02 161.13 392.58 162.06 393.93 161.42 397.72 164.07 400.5 160.65 402.27 159.69 404.99 156.56 406.43 156.32 408.57 159.24 405.04 164.3 401.3 166.88 398.81 170.94 401.7 175.12 398.5 178.1 395.26 179.79 392.59 179.6 393.52 177.71 390.73 177.42 389.66 171.96 388.8 170.57 388.97 163.55 388.02 161.13 Z" id="22029"/>
            <path d="M 250.84 254.96 257.21 251.27 258.53 246.8 261.15 246.98 261.21 249.13 260.69 252.54 259.25 255.1 259.47 258.76 258.24 261.26 258.03 267.12 257.03 269.46 253.33 267.42 251.64 265.46 250.74 261.41 251.93 257.78 250.84 254.96 Z" id="29281"/>
            <path d="M 437.24 137.41 438.96 135.79 439.25 133.4 442.23 131.93 445.8 128.68 447.75 132.82 445.97 138.45 446.73 139.34 445.75 143 443.33 144.23 440.21 142.24 435.78 142.53 437.24 137.41 Z" id="22170"/>
            <path d="M 458.04 154.04 459.19 152.88 459.28 152.86 459.69 152.61 459.75 152.53 459.71 151.23 459.66 151.16 458.1 149.66 458.5 146.59 461.97 143.75 468.65 144.07 470.42 145.71 470.05 147.54 468.18 148.29 468.29 151.35 465.87 152.3 461.53 155.19 458.04 154.04 Z" id="22281"/>
            <path d="M 429.97 129.23 432.82 129.51 433.85 126.32 436.55 124.81 438.23 125.01 440.5 123.32 443.07 123.69 445.78 123.64 446.34 128.17 445.8 128.68 442.23 131.93 439.25 133.4 438.96 135.79 437.24 137.41 435.56 137.47 432.05 139.72 429.06 136.64 430.57 132.67 429.97 129.23 Z" id="22287"/>
            <path d="M 152.31 252.61 153.7 250.94 152.59 249.01 153.45 247.8 155.39 247.27 157.69 245.14 159.99 249.39 163.99 252.05 167.02 252.93 165.34 257.4 166.45 259.29 164.92 262.31 162.65 261.72 160.93 259.82 157.63 259.16 151.78 259.01 150.24 256.08 150.48 252.99 152.31 252.61 Z" id="29224"/>
            <path d="M 163.99 252.05 167.08 244.05 169.64 243.4 172.23 245.32 173.27 247.79 174.58 248.51 174.69 249.94 176.9 251.14 176.6 253.9 175.01 256.25 170.39 254.09 168.55 254.67 167.02 252.93 163.99 252.05 Z" id="29087"/>
            <path d="M 435.79 168.91 438.06 167.64 439.74 169.24 441.34 166.09 443.42 168.55 441.94 169.89 442.36 172.18 446.65 171.96 448.45 173.43 449.02 176.45 448.13 179.93 448.81 181.39 444.29 182.27 440.31 182.54 439.21 183.74 438.16 182.8 436.28 178.22 434.43 179.14 432.19 176.9 433.09 173.07 436.2 171.12 435.79 168.91 Z" id="22001"/>
            <path d="M 428.42 154.24 429.6 154.1 430.32 155.39 433.68 153.92 437.33 150.56 437.08 153.92 438.61 157.83 438.87 160.05 440.28 163.19 437.73 164.98 436.64 166.67 431.7 166.5 430.16 165.86 428.12 165.48 427.27 163.61 425.2 162.84 424.3 160.94 424.74 157.72 427.49 156.16 428.42 154.24 Z" id="22099"/>
            <path d="M 426.49 141.49 427.81 140.03 429.06 136.64 432.05 139.72 435.56 137.47 437.24 137.41 435.78 142.53 440.21 142.24 443.33 144.23 440.38 147.79 439.55 146.74 435.08 146.93 431.26 147.29 430.27 149.86 431.16 151.78 429.6 154.1 428.42 154.24 427.39 154.05 426.25 150.62 423.95 151.21 425.31 147.59 424.24 146.36 423.81 144.44 426.49 141.49 Z" id="22059"/>
            <path d="M 406.26 149.76 406.1 148.69 408.57 146.83 409.92 148.93 414.16 149.05 415.82 148.16 421.41 147.54 424.24 146.36 425.31 147.59 423.95 151.21 426.25 150.62 427.39 154.05 423.89 153.26 421.71 154.04 416.18 157.34 414.33 157.67 413.1 158.75 408.57 159.24 406.43 156.32 404.99 156.56 404.35 154.7 406.26 149.76 Z" id="22386"/>
            <path d="M 413.96 159.78 414.33 157.67 416.18 157.34 421.71 154.04 423.89 153.26 427.39 154.05 428.42 154.24 427.49 156.16 424.74 157.72 424.3 160.94 419.06 160.03 418.84 159.01 413.96 159.78 Z" id="22276"/>
            <path d="M 398.5 178.1 402.26 179.48 407.29 178.29 407.41 180.91 409.29 182.48 409.11 185.35 406.2 188.17 406.7 191.11 405.53 193.69 401.53 193.7 398.72 193.89 397.47 190.63 398.4 188.18 397.3 186.24 397.98 183.59 395.26 179.79 398.5 178.1 Z" id="22244"/>
            <path d="M 488.97 151.05 490.92 147.96 490.58 145.47 493.1 145.66 494.59 144.35 494.37 141.62 497.44 141.47 497.63 143.47 499.51 144.23 499.57 148.27 501.31 149.38 502.22 151.45 502.28 156.05 498.81 156.8 495.86 158.91 494.07 161.22 490.75 158.29 487.13 157.9 486.74 155.35 487.95 151.98 488.97 151.05 Z" id="22015"/>
            <path d="M 489.99 119.23 492.95 120.34 493.95 119.79 495.54 121.79 497.5 122.58 497.3 124.71 499.14 125.67 497.26 128.51 495.36 129.53 492.99 129.42 491.81 126.52 490.41 125.46 491.53 123.81 490.19 121.38 489.99 119.23 Z" id="22044"/>
            <path d="M 514.54 137.84 518.6 138.86 520.65 137.34 522.1 137.56 525.19 138.95 528.73 141.45 531.02 139.86 533.03 141.03 533.53 143.31 537.46 144.56 537.24 146.59 533.05 146.02 529.09 147.32 527.96 150.28 526.83 152.3 524.05 153.67 521.59 153.46 520.07 154.66 515.94 153.85 514.98 151.12 516.09 146.37 516.35 141.91 514.54 137.84 Z" id="22193"/>
            <path d="M 459.32 189.27 460.88 189.25 462.83 192.07 467.84 191.92 467.61 193.48 469.53 194.93 471.06 193.87 473.25 194.95 475.38 193.48 481.05 195.23 484.34 194.24 484.55 197.72 486.33 200.04 486.3 203.25 485.93 204.02 484.27 203.9 481.53 205.14 481.08 207.2 479.69 206.41 476.42 207.12 473.18 205.29 469.4 206.1 467.68 204.54 464.69 203.3 462.78 203.98 462.5 200.88 461.77 198.31 459.54 196.28 458.06 191.26 459.32 189.27 Z" id="22155"/>
            <path d="M 447.75 132.82 449.01 132.21 452 134.11 453.48 133.82 455 134.72 455.11 137.63 452.98 137.62 451.26 139.28 446.73 139.34 445.97 138.45 447.75 132.82 Z" id="22307"/>
            <path d="M 470.42 145.71 471.42 144.89 474.74 145.65 478.32 147.64 482.33 146.47 484.71 147.26 485.48 148.79 488.97 151.05 487.95 151.98 486.74 155.35 487.13 157.9 487.11 160.81 485.56 161.54 480.97 159.99 476.86 161.7 472.32 159.85 468.77 159.48 466.56 157.81 463.7 158.09 461.53 155.19 465.87 152.3 468.29 151.35 468.18 148.29 470.05 147.54 470.42 145.71 Z" id="22079"/>
            <path d="M 499.51 144.23 502.22 145.05 503.71 147.64 503.77 150.83 505.51 150.82 506.23 148.18 508.08 148.26 508.11 149.41 506.89 151.69 507.39 153.79 507.8 154.82 506.25 158.18 506.32 159.59 502.28 156.05 502.22 151.45 501.31 149.38 499.57 148.27 499.51 144.23 Z" id="22332"/>
            <path d="M 523.96 133.99 522.1 137.56 520.65 137.34 518.6 138.86 514.54 137.84 514.04 135.02 516.72 133.92 518.14 131.17 523.27 132.08 523.96 133.99 Z" id="22160"/>
            <path d="M 349.71 150.94 351.44 150.72 354.05 146.03 355.28 145.62 357.21 142.61 358.27 142.67 359.95 139.79 359.22 134.54 364.23 134.79 365.3 140.11 363.48 140.63 363.27 145.76 366.61 152.73 364.98 152.66 361.81 159.27 359.58 159.63 357.73 158.36 355.23 159.13 353.89 157.73 351.13 153.82 349.71 150.94 Z" id="22138"/>
            <path d="M 580.83 153.19 583.56 154.29 585.31 155.97 587.54 155.32 590.22 158.65 591.35 159.24 591.6 162.03 589.81 162.5 590.87 165.21 590.42 167.67 591.92 168.52 590.81 171.03 592.19 171.67 589.71 173.61 588 171.18 585.02 169.8 584.66 167.22 582.44 162.88 582.25 159.8 580.83 158.69 579.79 155.73 580.83 153.19 Z" id="22239"/>
            <path d="M 571.98 147.98 574.87 148.25 573.53 145.63 575.92 144.21 577.51 140.82 578.27 143.24 582.17 145.27 582.76 147.74 579.63 148.73 580.21 152.39 578.09 152.85 576.91 154.67 574.05 154.27 574.2 151.54 571.95 150.12 571.98 147.98 Z" id="22342"/>
            <path d="M 571.39 137.82 574.24 136.07 574.23 138.1 577.73 139.82 577.51 140.82 575.92 144.21 573.53 145.63 574.87 148.25 571.98 147.98 570.95 148.17 569.81 146.16 571.42 144.06 570.24 140.68 571.39 137.82 Z" id="22097"/>
            <path d="M 561.14 136.15 562.2 136.37 568.96 133.7 570.9 135.98 571.39 137.82 570.24 140.68 571.42 144.06 569.81 146.16 570.95 148.17 568.1 150.47 565.07 147.32 563.4 150 561.87 148.34 559.73 147.89 558.8 146.51 558.68 142.9 560 141.73 561.14 136.15 Z" id="22180"/>
            <path d="M 582.76 147.74 586.36 145.8 589.84 150.07 590.02 151.73 591.85 153.69 590.22 158.65 587.54 155.32 585.31 155.97 583.56 154.29 580.83 153.19 580.21 152.39 579.63 148.73 582.76 147.74 Z" id="22021"/>
            <path d="M 574.24 136.07 577.14 134.75 579.03 135.04 580.58 135.79 580.75 138.73 583.51 138.28 584.07 141.88 581.92 143.04 582.17 145.27 578.27 143.24 577.51 140.82 577.73 139.82 574.23 138.1 574.24 136.07 Z" id="22388"/>
            <path d="M 593.55 142.23 593.88 140.56 596.09 139.77 598.44 141.87 600.66 142.46 602.07 141.25 604.12 141.66 603.34 144.3 601.7 145.25 600.41 148.58 596.76 148.91 595.5 146.42 593.4 146.06 593.55 142.23 Z" id="22280"/>
            <path d="M 590.22 158.65 591.85 153.69 592.96 152.7 595.36 153.18 596.31 151.69 593.42 150.21 594.22 148 593.32 146.84 593.4 146.06 595.5 146.42 596.76 148.91 600.41 148.58 600.53 153.07 599.65 152.83 596.34 157.66 592.6 159.34 591.35 159.24 590.22 158.65 Z" id="22380"/>
            <path d="M 586.88 133.19 589.15 136.5 583.51 138.28 580.75 138.73 580.58 135.79 580.61 133.51 581.68 130.99 584.81 130.95 586.88 133.19 Z" id="22003"/>
            <path d="M 342.63 175.62 346.83 175.26 349.72 172.69 349.97 168.8 352.67 169.42 357.39 166.94 358.6 171.04 364.21 175.64 364.39 178.02 362.51 176.52 358.74 177.43 359.56 181.87 357.49 183.72 357.52 188.35 358.21 190.58 357.39 191.68 352.88 190 352.83 191.93 351.12 192.72 347.94 192.52 345.55 194.23 345.06 190.9 344.06 189.43 344.6 185.67 343.98 179.25 344.81 177.81 342.63 175.62 Z" id="22087"/>
            <path d="M 322.02 164.66 325.07 165.61 330.84 164.17 335.75 164.17 338.74 166.08 341.69 166.42 344.17 165.73 346.22 168.68 349.97 168.8 349.72 172.69 346.83 175.26 342.63 175.62 339.92 174.53 337.65 175.2 330.07 172.38 328.82 172.65 326.91 171.12 318.97 167.88 320.58 166.96 322.02 164.66 Z" id="22128"/>
            <path d="M 591.35 159.24 592.6 159.34 596.34 157.66 599.65 152.83 600.53 153.07 601.7 155 603.41 156.63 603.49 159.58 605.59 161.36 604.66 162.54 603.82 165.62 601.85 166.46 597.84 165.27 594.49 166.12 590.87 165.21 589.81 162.5 591.6 162.03 591.35 159.24 Z" id="22308"/>
            <path d="M 487.11 160.81 487.13 157.9 490.75 158.29 494.07 161.22 496.56 163.95 496.91 167.87 495.05 172.13 488.76 171.84 486.98 168.59 487.95 166.83 487.29 163.29 487.11 160.81 Z" id="22346"/>
            <path d="M 315.89 173.61 317.01 172.5 317.78 169.5 318.97 167.88 326.91 171.12 328.82 172.65 329.77 174.99 329.41 179.09 328.41 181.25 326.04 181.97 321.75 181.73 317.25 183.77 316.07 181.61 315.89 173.61 Z" id="22344"/>
            <path d="M 351.13 153.82 353.89 157.73 350.9 159.62 350.93 160.99 353.41 163.67 356.41 163.47 357.89 165.6 357.39 166.94 352.67 169.42 349.97 168.8 346.22 168.68 344.17 165.73 341.69 166.42 340.25 163.72 343.68 162.87 345.31 161.37 345.4 159.67 349.22 156.98 349.71 154.47 351.13 153.82 Z" id="22320"/>
            <path d="M 468.77 159.48 472.32 159.85 476.86 161.7 480.97 159.99 485.56 161.54 487.29 163.29 487.95 166.83 486.98 168.59 488.76 171.84 488.34 173.11 484.53 173.87 483.45 175.89 481.62 176.01 478.88 174.51 476.72 176.11 473.3 177.44 472.7 175.91 469.7 175.63 468.44 174.44 469.15 172.1 471.1 169.63 468.37 166.35 468.13 163.56 468.77 159.48 Z" id="22184"/>
            <path d="M 483.22 133.05 484.02 128.17 488.12 128.89 490.41 125.46 491.81 126.52 492.99 129.42 490.7 131.21 493.96 135.93 490.74 138.93 487.06 139.2 485.58 136.54 483.25 134.18 483.22 133.05 Z" id="22246"/>
            <path d="M 401.04 58.85 404.95 52.13 405.37 50.13 406.89 52.01 407.05 54.65 409.77 54.39 412.49 56.46 412.67 57.51 411.26 60.17 409.02 62.44 406 62.47 403.72 61.47 400.92 61.87 401.04 58.85 Z" id="22390"/>
            <path d="M 401.53 193.7 405.53 193.69 406.7 191.11 406.2 188.17 409.11 185.35 409.29 182.48 410.41 181.89 412.02 178.7 414.57 178.47 417.51 179.87 421.19 183.24 418.21 184.63 419.5 187.7 416.85 188.29 418.24 192.46 417.52 193.85 414.17 196.56 414.83 198.14 413.34 199.18 407.49 200.61 407.64 198.46 405.21 195.43 404.33 195.96 402.42 195.95 401.53 193.7 Z" id="22316"/>
            <path d="M 416.49 85.82 418.06 84.26 420.77 83.4 424.07 87.35 422.45 89.72 421.69 92.98 419.51 94.65 417.67 92.31 416.49 85.82 Z" id="22375"/>
            <path d="M 418.09 59.59 419.58 59.3 421.93 56.95 423.69 56.84 423.59 59.99 420.19 62.54 418.69 61.65 418.09 59.59 Z" id="22109"/>
            <path d="M 408.57 159.24 413.1 158.75 414.33 157.67 413.96 159.78 416.95 165.33 418.55 171.53 413.47 172.61 410.76 171.4 406.36 171.61 401.7 175.12 398.81 170.94 401.3 166.88 405.04 164.3 408.57 159.24 Z" id="22074"/>
            <path d="M 509.39 151.12 509.85 149.41 511.8 147.92 516.09 146.37 514.98 151.12 515.94 153.85 515.09 155.15 513.22 153.69 510.48 153.31 509.39 151.12 Z" id="22140"/>
            <path d="M 499.57 202.48 503.76 198.26 507.42 195.24 511.05 193.04 511.92 194.73 512.42 198.45 515.17 199.29 520.63 204.97 519.77 205.29 515.97 204.64 514.64 205.28 512.55 208.89 505.41 213.5 504.43 215.27 502.21 210.78 500.42 206.09 499.57 202.48 Z" id="22122"/>
            <path d="M 583.59 223.77 583.44 223.38 586.69 222.64 591 223.77 591.1 223.72 594.78 226.27 593.83 230.16 591.38 230.15 589.9 229.03 588.44 230.17 586.28 229.52 585.52 227.54 583.32 225.53 583.59 223.77 Z" id="35295"/>
            <path d="M 568.86 217.51 571.19 217.68 573.1 215.79 573.55 214.04 575.78 211.12 577.68 210.21 582.26 208.85 585.25 209.63 587.29 212.35 585.58 213.25 583.88 215.79 584.96 218.72 584.55 220.73 583.44 223.38 583.59 223.77 580.67 223.71 578.74 224.49 577.1 223 575.24 223.63 572.93 220.95 570.25 221.79 568.86 217.51 Z" id="35302"/>
            <path d="M 588.13 177.78 588.15 174.73 589.71 173.61 592.19 171.67 595.94 171.53 597.05 172.57 597.61 176.28 594.61 178.98 591.3 177.93 589.59 176.67 588.13 177.78 Z" id="22069"/>
            <path d="M 568.96 133.7 571.32 131.85 572.55 130.75 575.38 131.32 576.48 133.88 579.03 135.04 577.14 134.75 574.24 136.07 571.39 137.82 570.9 135.98 568.96 133.7 Z" id="22315"/>
            <path d="M 232.35 246.58 234.71 244.45 234.85 240.86 234.07 237.79 236.81 238.16 240.94 237.69 241.32 242.19 245.32 241.08 246.84 242.66 244.84 243.7 244.68 245.19 238.16 250.18 233.42 251.94 231.24 249.58 232.35 246.58 Z" id="29110"/>
            <path d="M 207.02 85.84 212.47 83.24 213.87 83.16 220.62 84.34 220.93 87.37 223.02 87.84 222.42 91.89 221.78 91.14 217.32 91.77 217.7 89.86 215.37 86.7 213.4 87 211.04 91.37 210.02 90.43 207.02 85.84 Z" id="29148"/>
            <path d="M 194.61 86.16 197.29 84.39 201.35 82.68 202.72 79.31 204.34 79.51 205.8 80.02 208.99 79.03 209.74 80.42 206.5 83.34 206.83 85.69 205.53 86.42 201.78 85.77 199.05 84.5 197.57 85.17 198.6 89.88 196.3 89.08 194.61 86.16 Z" id="29285"/>
            <path d="M 590.87 165.21 594.49 166.12 597.84 165.27 601.85 166.46 600.26 168.72 597.27 169.82 595.94 171.53 592.19 171.67 590.81 171.03 591.92 168.52 590.42 167.67 590.87 165.21 Z" id="22312"/>
            <path d="M 387.86 183.97 388.57 182.31 390.5 182.16 392.59 179.6 395.26 179.79 397.98 183.59 397.3 186.24 398.4 188.18 397.47 190.63 392.85 190.48 390.74 189.51 390.94 187.8 386.48 187.82 387.86 183.97 Z" id="22334"/>
            <path d="M 141.31 102.98 142.97 103.37 145.56 104.68 144.07 106.94 142.98 107.04 140.77 105.25 141.31 102.98 Z" id="29126"/>
            <path d="M 590.43 279.7 592.63 276.78 596.4 277.31 597.95 272.76 597.53 270.64 601.84 268.76 606.49 264.85 606.06 263.36 609.31 261.48 612.19 260.82 614.15 262.08 615.93 273.57 610.99 277.2 600.84 281.05 596.94 280.46 595.93 281.16 594.44 282.18 590.43 279.7 Z" id="35169"/>
            <path d="M 610.99 277.2 615.93 273.57 619.64 272.85 622.48 273.24 623.9 274.46 621.88 276.66 620.32 280.78 618.4 283 619 286.35 616.37 283.75 613.64 283.86 610.94 283.1 610.99 277.2 Z" id="35035"/>
            <path d="M 238.16 250.18 244.68 245.19 244.84 243.7 246.84 242.66 248.71 240.56 249.05 238.85 250.79 237.22 253.58 236.27 253.61 236.27 255.97 239.53 256.87 243.57 258.58 243.96 258.53 246.8 257.21 251.27 250.84 254.96 247.85 255.42 246.93 253.35 244.09 252.53 239.31 252.93 238.16 250.18 Z" id="29041"/>
            <path d="M 201.78 85.77 205.53 86.42 206.83 85.69 207.02 85.84 210.02 90.43 208.23 91.65 202.63 89.77 201.51 87.96 201.78 85.77 Z" id="29301"/>
            <path d="M 322.79 160.05 323.19 157.23 325.23 155.54 327.99 155.21 334.25 154.97 336.96 155.65 339.44 157.98 338.41 159.31 339.79 162.27 338.74 166.08 335.75 164.17 330.84 164.17 325.07 165.61 322.02 164.66 322.67 164.19 322.79 160.05 Z" id="22052"/>
            <path d="M 605.07 201.43 607.88 198.77 610.35 197.74 612.81 198.88 615.15 201.98 614.81 203.87 611.53 204.39 611.2 207.85 612.05 209.62 609.68 211.09 608.37 210.65 607.27 206.62 605.07 201.43 Z" id="35060"/>
            <path d="M 327.96 325.28 329.07 326.34 333.59 323.12 333.13 321.43 334.5 320.13 336.43 321.42 338.92 321.48 340.04 324.19 341.55 325.24 342.34 328.59 343.93 330.33 343.98 332.16 341.3 332.64 338.69 331.14 337.06 331.21 335.96 333.55 332.61 333.49 332.05 334.53 327.59 334.67 325.43 331.44 326.62 328.38 325.18 327.07 326.53 324.6 327.96 325.28 Z" id="56185"/>
            <path d="M 307.49 290.92 309.55 290.79 310.85 287.94 315.32 288.34 316.68 290.67 319.92 294.38 318.69 298.34 317.96 299.63 314.36 299.22 313.22 300.55 310.26 299.76 309.38 297.83 309.38 294.08 307.49 290.92 Z" id="29297"/>
            <path d="M 295.32 287.17 296.96 285.72 299.84 287.07 302.61 287.58 307.49 290.92 309.38 294.08 309.38 297.83 304.56 300.53 304.62 301.7 300.65 305.14 297.54 304.58 296.58 303.6 297.09 302.3 295.85 300.06 295.69 294.21 296.76 288.77 295.32 287.17 Z" id="29147"/>
            <path d="M 338.92 321.48 340.54 319.11 339.7 317.3 343.66 316.03 342.98 313.91 348.1 310.74 349.06 308.74 350.9 309.53 354.07 309.14 357.99 313.23 358.34 316.18 360.88 316.15 357.47 317.39 357.33 319.3 355.15 322.54 357.22 324.97 354.38 328.7 351.63 330.47 349.81 328.32 345.4 330.78 344.5 333.16 343.98 332.16 343.93 330.33 342.34 328.59 341.55 325.24 340.04 324.19 338.92 321.48 Z" id="56036"/>
            <path d="M 353.8 307.3 355.73 306.53 358.73 302.23 359.22 301.71 363.82 302.95 367.64 305.38 366.86 301.07 367.7 294.98 371.58 295.6 374.93 298.75 376.81 299.25 375.9 300.9 377.32 303.03 376.73 305.04 373.59 308.51 373.55 310.28 375.53 312.34 373.86 315.34 370 314.87 366.49 316.87 366.11 317.96 362.1 317.42 360.88 316.15 358.34 316.18 357.99 313.23 354.07 309.14 353.8 307.3 Z" id="56090"/>
            <path d="M 356.97 340.57 357.16 340.23 361.85 340.29 366.85 340.95 368.96 339.91 371.33 340.05 373.09 338.02 376.41 336.74 378.31 339.09 379.11 342.29 376.21 342.54 374.84 342.56 371.87 344.89 369.93 344.49 367.97 347.1 364.09 351.08 360.9 348.08 361.53 344.08 359.74 343.69 359.91 341.57 356.97 340.57 Z" id="56130"/>
            <path d="M 310.23 241.45 314.66 241.54 315.46 243.44 318.82 244.83 322.81 245.46 324.21 247.6 322.4 252.77 323.29 257.6 324.93 261.39 322.38 264.22 323.11 265.67 321.49 264.14 319.02 265.84 318.6 260.83 316.95 260.55 315.8 257.77 313.29 257.24 312.51 256.02 308.95 252.56 307.68 252.19 308.21 248.14 309.24 246.93 309.62 242.47 310.23 241.45 Z" id="56057"/>
            <path d="M 412.65 240.19 414.07 237.97 415.99 236.99 417.16 239.48 420.39 239.28 421.8 238.07 425.83 240.29 430.89 243.7 427.34 246.25 425.78 252.01 422.83 254.35 421.12 256.81 421.1 255.18 423.17 252.76 422.62 250.5 419.39 251.06 417.5 250.32 416.03 248.09 414.4 247.03 414.99 245.09 412.68 243.47 411.73 241.62 412.65 240.19 Z" id="56178"/>
            <path d="M 389.29 223.55 390.6 221.35 395.57 222.01 398.54 219.18 398.89 217.7 401.37 217.17 404.95 217.4 407.47 219.02 408.42 222.82 412.94 221.16 414.75 224.11 413.12 225.94 414.46 228.04 412.52 228.9 412.98 230.92 416.18 230.53 416.6 232.28 414.54 233.77 415.99 236.99 414.07 237.97 412.65 240.19 408.52 237.88 404.8 239.2 401.31 239.44 398.14 237.73 394.54 236.25 391.83 232.61 389.9 231.28 390.05 226.41 389.29 223.55 Z" id="56041"/>
            <path d="M 763.62 253.95 765.95 252.78 765.35 250.99 769.14 250.98 769.68 246.9 770.54 244.8 773.01 243.38 775.39 241.91 777.66 243.36 780.47 242.95 780.84 244.17 783.57 245.01 783.85 248.8 786.12 249.89 785.18 252.19 787.18 254.9 784.43 258.03 785.21 260.71 781.95 258.08 778.72 256.49 776.74 256.99 775.67 256.1 772.23 258.64 772.2 260.62 765.85 260.64 764.51 260.08 763.33 256.04 763.62 253.95 Z" id="35006"/>
            <path d="M 759.94 170.26 761.77 165.39 760.59 164.29 760.73 161.9 762.19 159.24 764.05 158.47 766.34 157.68 768.34 158.86 768 161.03 768.64 167.74 767.69 168.18 764.29 167.43 759.94 170.26 Z" id="35115"/>
            <path d="M 614.14 222.31 616.18 224 618.86 224.6 621.22 226.12 619.85 228.84 622.73 229.27 622.98 231 619.83 231.29 615.13 236.32 611.61 234.54 610.29 231.64 610.62 229.35 613.29 225.46 614.14 222.31 Z" id="35188"/>
            <path d="M 609.47 308.21 611.35 307.58 612.14 309.96 614.4 312.47 617.34 311.93 619.16 313.23 621.4 313.13 622.3 314.32 624.76 313.87 627.07 315.74 628.95 315.27 632.22 317.53 633.04 320.14 633.39 327.05 628.9 326.77 626.59 328.13 625.88 330.4 621.77 331.34 616.29 328.37 612.98 329.75 612.72 327.29 615.25 324.22 614.07 322.4 613.55 319.5 609.99 316.31 609.03 314.02 606.73 311.65 609.47 308.21 Z" id="35219"/>
            <path d="M 497.41 279.82 498.83 278.44 500.91 278.29 501.67 276.59 503.98 275.7 504.32 277.44 506.41 280.27 504.26 282.58 500.63 281.85 499.39 279.4 497.41 279.82 Z" id="56091"/>
            <path d="M 449.5 297.25 452 295.18 454.04 295.36 459.5 293.15 460.98 294.8 460.62 296.74 463.04 298.39 465.64 298.77 469.34 295.38 471.84 294.45 474.61 292.39 476.67 295.49 478.55 295.25 478.61 298.25 475.49 299.25 475.32 300.41 471.99 305.02 467.57 305.6 463.81 304.95 462.91 308.2 461.49 308.33 459.65 310.41 459.44 312.7 454.61 312.06 454.03 311.38 452.27 306.2 449.8 302.65 448.63 299.32 449.5 297.25 Z" id="56017"/>
            <path d="M 482.52 283.79 483.67 279.19 485.73 278.65 485.73 276.04 486.51 274.98 488 275.14 489.39 278.31 491.7 277.23 494.38 277.68 497.41 279.82 499.39 279.4 500.63 281.85 504.26 282.58 504.49 284.29 506.58 285.99 506.16 287.63 507.3 290.25 505.91 291.13 506.9 294.24 505.54 296.09 503.64 296.62 503.31 299.09 501.95 300.38 498.8 301.07 494.85 299.85 494.25 296.86 496.23 294.55 496.2 292.46 493.82 293.24 490.63 289.87 485.91 288.64 482.66 290.41 481.85 288.92 482.52 283.79 Z" id="56070"/>
            <path d="M 478.77 308.99 479.57 307.9 483.7 307.58 483.83 306.58 488.19 307.88 490.64 306.3 492.51 304.82 495.55 305.09 496.72 307.76 499.08 308 498.51 310.54 496.84 312.28 498 314.04 498.61 317.83 500.93 317.48 502.76 320.52 502.31 322.48 500.44 324.24 496.5 323.44 494.64 321.54 492.28 321.73 488.18 323.42 488.18 326.32 485.02 326.88 481.08 327.54 477.69 326.38 479.1 322.84 478.4 320.68 476.4 320.33 478.1 316.61 475.8 315.33 473.86 314.85 474.56 312.55 477.4 313.1 479.08 311.64 478.77 308.99 Z" id="56172"/>
            <path d="M 532.93 318.09 534.65 318 537.65 319.94 537.61 321.57 536.29 322.72 534.16 325.63 532.2 324.59 530.23 325.59 530.98 320.98 532.93 318.09 Z" id="56124"/>
            <path d="M 533.76 377.66 534.32 376.67 539.39 379.92 542.58 379.55 543.47 378.4 546.38 378.58 546.94 377.24 548.87 378.54 551.06 381.51 551.05 384.39 552.73 385.93 553.97 388.48 554.57 392.37 551.38 393.3 550.27 394.86 543.54 393.03 539.04 393.42 536.97 393.07 535.55 389.62 535.25 387.06 536.06 383.82 532.82 381.99 534.27 380.38 533.76 377.66 Z" id="56153"/>
            <path d="M 511.61 362.12 513.49 360.56 515.06 354.87 516.3 352.39 518.5 349.99 522.26 349.1 525.63 350.91 528.07 351.5 530.19 355.75 532.45 357.74 535.69 359.3 536.43 359.49 537.49 361.09 535.74 364.22 535.36 367.29 531.84 369.76 534.74 372.32 532.73 373.12 531.43 375.75 528.91 376.66 527.37 375.3 524.57 376.28 524.02 372.53 519.96 370.58 518.48 372.06 514.56 371.5 514.83 369.34 512.87 364.46 511.61 362.12 Z" id="56184"/>
            <path d="M 490.49 365.97 491.71 363.55 495.05 362.18 494.78 358.89 496.18 358.35 497.95 355.5 501.63 355.24 501.14 358.07 505.91 358.93 507.39 360.07 509.11 359.48 511.61 362.12 512.87 364.46 510.44 364.06 507.91 365.29 504.02 365.79 503.44 367.04 500.85 366.41 497.06 370.41 496.71 372.34 499.36 373.11 496.17 376.67 494.6 371.73 492.08 370.63 492.83 367.51 490.49 365.97 Z" id="56247"/>
            <path d="M 444.67 296.33 447.97 297.63 449.5 297.25 448.63 299.32 449.8 302.65 445.54 304.65 442.99 304.02 445.76 299.02 444.67 296.33 Z" id="56117"/>
            <path d="M 498.51 310.54 500.84 310.38 503.16 311.42 509.29 307.42 515.43 307.72 518.89 306.5 520.2 308.89 526.15 312.67 526.25 312.73 525.34 314.76 526.2 320.28 522.85 321.58 523.22 323.61 520.55 322.07 518.57 326.03 516.07 326.92 513.93 324.64 512.23 325.69 507.88 325.55 506.74 324.35 503.88 325.66 500.44 324.24 502.31 322.48 502.76 320.52 500.93 317.48 498.61 317.83 498 314.04 496.84 312.28 498.51 310.54 Z" id="56244"/>
            <path d="M 480.16 334.6 483.03 336.11 484.64 335.54 486.89 332.07 489.66 331.4 490.52 333.26 493.34 333.7 499.62 333.55 501.46 336.67 504.28 335.06 505.49 336.15 504.36 340.05 505.37 341.85 507.71 343.09 506.25 346.06 504.43 346.72 504.91 349.85 503.89 351.71 501.6 352.18 501.63 355.24 497.95 355.5 496.7 354.87 491.79 354.9 491.16 352.46 489.08 352.52 488.29 349.62 486.56 352.34 485.86 346.65 484.74 344.76 481.78 342.05 481.53 339.96 479.85 339.89 479.33 338.73 480.16 334.6 Z" id="56053"/>
            <path d="M 473.9 351.41 478.71 351.11 480.26 346.37 479.21 341.25 479.85 339.89 481.53 339.96 481.78 342.05 484.74 344.76 485.86 346.65 486.56 352.34 488.29 349.62 489.08 352.52 486.23 353.6 484.72 358.66 478.06 359.75 477.06 360.88 476.87 361 474.11 360.38 471.89 361.67 470.35 359.68 469.69 359.09 473.28 355.03 473.9 351.41 Z" id="56231"/>
            <path d="M 385.91 106.67 386.39 105.32 388.74 104.94 388.83 103.38 390.68 103.39 394.36 107.19 397.35 106.56 401.7 106.95 403.68 104.68 404.79 107.33 403.12 111.01 402.52 116.76 399.9 117.43 397.97 119.65 394.54 118.88 392.75 121.15 389.8 116.7 388.55 115.64 385.94 114.34 384.01 114.37 383.33 111.55 386.09 108.31 385.91 106.67 Z" id="22225"/>
            <path d="M 456.31 348.92 457.75 348.42 460.64 349.48 462.88 344.75 465.06 343.05 466.72 343.26 466.24 347.34 469.67 349.85 472.97 350.37 473.9 351.41 473.28 355.03 469.69 359.09 470.35 359.68 467.34 361.61 464.24 360.43 463.71 357.92 461.24 358.68 460.49 355.46 458.44 354.46 455.54 352.35 456.31 348.92 Z" id="56206"/>
            <path d="M 459.44 312.7 459.65 310.41 461.49 308.33 462.91 308.2 463.81 304.95 467.57 305.6 471.99 305.02 470.85 308.87 476.05 307.33 477.82 305.81 478.77 308.99 479.08 311.64 477.4 313.1 474.56 312.55 473.86 314.85 475.8 315.33 474.23 318.87 474.41 323.02 470.91 323.06 471.43 324.77 463.4 324.26 460.48 322.87 458.7 321.7 460.47 316.7 459.44 312.7 Z" id="56222"/>
            <path d="M 269.91 274.39 273.77 274.48 275.87 277.61 278.85 278.64 282.33 278.82 282.88 276.76 281.01 276.14 279.26 272.49 281.44 269.25 286.02 269.89 290.45 271.44 291.63 273.47 292.13 276.31 287.91 279.79 289.33 282.87 293.04 284.62 295.32 287.17 296.76 288.77 295.69 294.21 293.73 294.08 291.29 292.63 288.95 293.44 285.76 293.14 284.82 292.25 282.75 294.45 280.39 295.18 276.05 297.72 275.87 299.14 273.54 299.72 268.89 294.64 269.79 292.78 268.19 290.63 267.42 286.69 269.45 285.03 269.4 282.93 267.64 277.88 267.59 275.8 269.91 274.39 Z" id="29004"/>
            <path d="M 747.64 282.89 748.67 282.42 750.06 279.28 752.25 277.92 754.67 277.36 756.79 277.84 756.94 280.11 758.86 280.33 761.21 282.3 764.06 282.28 761.16 282.67 760.91 284.63 759.12 286.7 755.37 285.52 752.38 285.42 747.64 282.89 Z" id="35125"/>
            <path d="M 730.96 233.04 732.13 233.09 736.37 235.59 738.46 234.61 739.48 237.91 736.64 241.93 735.83 244.22 736.86 245.13 733.15 246.29 731.74 242.84 729.93 242.84 729.4 240.85 727.05 239.93 725.5 237.89 730.14 235.35 730.96 233.04 Z" id="35264"/>
            <path d="M 245.9 276.84 246.93 277.69 249.67 277.25 249.62 272.21 253.33 267.42 257.03 269.46 258.03 267.12 261.15 266.03 262.96 266.85 268.32 266.83 270.21 271.57 269.91 274.39 267.59 275.8 267.64 277.88 269.4 282.93 269.45 285.03 267.42 286.69 268.19 290.63 269.79 292.78 268.89 294.64 265.47 294.74 263.47 293.48 261.81 292.37 260.47 287.51 260.76 285.76 258.89 283.21 254.04 283.17 251.84 280.53 246.79 280.08 242.97 281.13 242.04 278.82 245.69 278.85 245.9 276.84 Z" id="29241"/>
            <path d="M 679.66 90.42 681.17 93.13 683.06 93.08 684.91 94.85 684.51 98.49 682.25 100.51 680.02 101.2 679.31 98.99 676.58 94.44 676.42 92 679.66 90.42 Z" id="35291"/>
            <path d="M 465.06 343.05 466.82 339.69 467.48 340.42 471.93 339.75 473.51 338.78 474.51 334.51 478.82 333.8 480.16 334.6 479.33 338.73 479.85 339.89 479.21 341.25 480.26 346.37 478.71 351.11 473.9 351.41 472.97 350.37 469.67 349.85 466.24 347.34 466.72 343.26 465.06 343.05 Z" id="56137"/>
            <path d="M 731.21 285.91 737.35 285.33 738.04 282.71 739.8 281.28 743.02 281.7 743.71 283.19 741.5 289.41 744.64 289.8 745.31 293.28 745.47 295.51 744 296.75 742.79 300.89 741.65 303.24 740.36 303.14 739.09 301.83 733.57 300.89 732.11 301.51 730.46 300.15 727.48 299.85 727.45 298.5 723.19 296.96 726.46 290.55 730.31 288.56 731.21 285.91 Z" id="35239"/>
            <path d="M 240.79 283.76 242.97 281.13 246.79 280.08 251.84 280.53 254.04 283.17 258.89 283.21 260.76 285.76 260.47 287.51 261.81 292.37 263.47 293.48 261.47 297.56 261.19 302.96 258.35 305.2 255.66 299.47 251.09 300.9 245.64 299.33 245.44 297.73 242.2 291.6 244.88 288.64 240.79 283.76 Z" id="29146"/>
            <path d="M 225.53 270.78 228.19 270.9 232.99 272.3 235.13 272.33 238.65 274.85 240.86 275.12 241.92 276.61 245.9 276.84 245.69 278.85 242.04 278.82 242.97 281.13 240.79 283.76 238.52 284 236.79 281.82 233.65 283.64 229.84 284.08 229.83 282.37 226.78 281.34 225.02 282.66 226.2 279.44 224.32 275.04 225.51 273 225.53 270.78 Z" id="29272"/>
            <path d="M 177.74 266.48 179.88 268.36 181.09 266.42 182.99 268.4 184.84 266.28 187.67 266.62 190.38 269.4 192.33 269.79 195.16 271.39 197.11 276.28 193.24 276.07 189.94 278.17 187.47 280.86 185.96 284.49 185.72 286.81 185.29 286.42 184.79 285.33 184.03 283.27 179.46 282.09 180.75 279.51 180.09 278.36 179.84 273.44 177.22 273.28 175.55 271.65 176.39 267.19 177.74 266.48 Z" id="29216"/>
            <path d="M 172.23 245.32 177.13 243.15 178.5 242.71 178.97 239.91 180.66 240.15 186.99 237.42 188.16 236.72 193.79 236.13 196.45 238.32 202.08 235.43 203.05 236.48 203.3 239.46 202.42 245.1 201.16 247.35 201.56 250.83 198.37 253.55 193.19 256.83 191.51 256.08 188.77 253.25 185.43 247.77 177.59 247.33 174.58 248.51 173.27 247.79 172.23 245.32 Z" id="29169"/>
            <path d="M 210.51 256.05 211.32 256.66 215.41 256.08 217.07 257.7 220.94 255.2 224.38 254.48 226.94 254.83 230.63 256.64 230.17 262.42 228.19 270.9 225.53 270.78 222.51 269.35 218.78 269.42 216.46 268.25 214.28 268.56 208.9 267.53 209.65 264.84 209.25 262.85 209.74 259.45 211.22 258.26 210.51 256.05 Z" id="29051"/>
            <path d="M 226.94 254.83 228.02 253.83 230.96 253.79 233.42 251.94 238.16 250.18 239.31 252.93 244.09 252.53 246.93 253.35 247.85 255.42 250.84 254.96 251.93 257.78 250.74 261.41 251.64 265.46 253.33 267.42 249.62 272.21 249.67 277.25 246.93 277.69 245.9 276.84 241.92 276.61 240.86 275.12 238.65 274.85 235.13 272.33 232.99 272.3 228.19 270.9 230.17 262.42 230.63 256.64 226.94 254.83 Z" id="29049"/>
            <path d="M 435.08 146.93 435.6 148.16 433.85 151.54 431.16 151.78 430.27 149.86 431.26 147.29 435.08 146.93 Z" id="22262"/>
            <path d="M 471.42 144.89 472.78 141.23 471.51 140.32 473.27 137.91 473.65 136.02 476.39 135.23 480.35 136.09 483.22 133.05 483.25 134.18 485.58 136.54 487.06 139.2 490.74 138.93 489.86 141.98 491.23 144.11 490.58 145.47 490.92 147.96 488.97 151.05 485.48 148.79 484.71 147.26 482.33 146.47 478.32 147.64 474.74 145.65 471.42 144.89 Z" id="22258"/>
            <path d="M 461.54 122.16 463.54 124.82 466.15 121.54 468.89 122.84 471.96 125.21 475.61 125.49 473.07 128.2 467.67 128.6 465.97 128.73 463.77 130.37 460.18 130.42 453.71 133.54 453.33 132.44 456.79 131.54 458.34 129.91 457.77 128.26 459.15 125.31 457.96 124.01 459.46 121.76 461.54 122.16 Z" id="22360"/>
            <path d="M 445.78 123.64 446.59 123.4 448.4 119.67 448.66 115.3 449.46 112.46 449.81 112.84 452.35 115.79 453.3 115.57 456.54 119.65 457.99 119.4 461.54 122.16 459.46 121.76 457.96 124.01 459.15 125.31 457.77 128.26 458.34 129.91 456.79 131.54 453.33 132.44 453.71 133.54 453.48 133.82 452 134.11 449.01 132.21 447.75 132.82 445.8 128.68 446.34 128.17 445.78 123.64 Z" id="22215"/>
            <path d="M 561.47 116.77 562 113.8 564.39 113.46 566.01 108.8 568.28 111.29 570.49 116.45 571.58 120.28 569.55 120.1 569.18 122.06 566.69 122.57 564.35 122 563.96 119.76 561.89 118.49 561.47 116.77 Z" id="22172"/>
            <path d="M 446.73 139.34 451.26 139.28 452.98 137.62 455.11 137.63 458.15 138.96 456.63 141.4 457.14 144.64 458.5 146.59 458.1 149.66 459.66 151.16 457.54 150.64 453.09 155.34 451.17 156.25 451.03 158.43 448.81 159.22 446.83 156.22 446.85 154.49 448.61 151.63 448.09 148.56 446.55 147.58 445.42 144.24 445.75 143 446.73 139.34 Z" id="22171"/>
            <path d="M 560.09 130.86 562.64 124.12 564.35 122 566.69 122.57 569.18 122.06 569.55 120.1 571.58 120.28 570.49 116.45 572.25 114.67 575.41 116.72 576 118.81 578.86 119.8 579.06 121.8 583.52 122.12 584.37 123.24 589.08 121.79 589.37 120.13 592.47 123.42 589.53 125.62 588.44 128.51 589.24 129.51 586.88 133.19 584.81 130.95 581.68 130.99 580.61 133.51 580.58 135.79 579.03 135.04 576.48 133.88 575.38 131.32 572.55 130.75 571.32 131.85 570.84 130.83 566.77 128.18 562.57 127.81 561.84 131.34 560.09 130.86 Z" id="22048"/>
            <path d="M 357.39 191.68 358.21 190.58 360.42 189.38 362.76 189.8 364.45 191.87 367.65 192.22 370.25 191.11 372.37 188.88 374.02 189.64 377.76 189.66 378.7 189 381.13 190.27 378.45 192.67 375.33 193.6 375.06 197.33 373.91 200.55 375.78 202.8 373.06 206.23 370.02 205.9 367.87 210.18 366.3 209.57 364.7 209.97 361.36 207.45 360.8 206.23 362.15 203.76 361.7 202.13 358.37 198.62 357.39 196.92 357.39 191.68 Z" id="22220"/>
            <path d="M 155.19 304.79 158.48 303.47 163.23 300.87 164.18 297.39 161.64 296.34 158.49 294.08 160.81 292.27 161.57 287.94 161.82 286.55 166.01 285.47 169.53 283.92 170.91 281.28 172.72 281.1 177.27 282.59 179.46 282.09 184.03 283.27 184.79 285.33 182.29 285.98 178.04 288.26 177.38 291.07 174.46 291.08 175.65 293.56 175.22 296.18 176.38 300.79 177.47 300.99 177.52 304.44 171.84 303.61 170.37 304.16 166.99 300.84 163.5 303.5 162.27 303.01 156.32 305.45 155.19 304.79 Z" id="29174"/>
            <path d="M 382.55 63.62 385.26 60.12 385.93 63.7 382.57 63.89 382.55 63.62 Z" id="22250"/>
            <path d="M 367 121 368.09 118.26 371.9 117.63 373.22 115.59 375.33 115.7 376 118.36 375.16 120.11 379.34 121 382.89 117.51 387.4 117.59 387.29 123.21 385.97 125.67 386.39 127.47 389.08 129.87 390.12 129.63 387.34 135.98 385.14 135.96 382.19 139.42 383.42 143.74 381.48 145.86 379.83 145 376.06 144.95 372.97 143.75 370.3 140.53 365.3 140.11 364.23 134.79 364.48 133.27 367.07 131.75 366.27 127.81 366.73 125.22 368.86 123.1 367 121 Z" id="22013"/>
            <path d="M 386.71 90.92 387.39 88.68 390.33 94.19 391.22 96.62 391.48 98.34 388.72 99.67 388.13 102.62 386.47 101.97 385.37 99.69 383.86 99.44 383.61 96.11 386.5 94.93 386.71 90.92 Z" id="22161"/>
            <path d="M 382.31 101.12 383.86 99.44 385.37 99.69 386.47 101.97 388.13 102.62 388.83 103.38 388.74 104.94 386.39 105.32 385.91 106.67 383.24 104.48 382.31 101.12 Z" id="22070"/>
            <path d="M 218.78 269.42 222.51 269.35 225.53 270.78 225.51 273 224.32 275.04 226.2 279.44 225.02 282.66 222.53 287.33 221.16 288.51 219.61 287.12 217.86 288.35 213.64 286.13 212.62 284.2 214.7 279.82 214.83 276.84 217.48 274.3 219.43 271.43 218.78 269.42 Z" id="29247"/>
            <path d="M 157.32 98.38 158.3 97.78 157.41 94.05 159.11 90.57 161.39 93.21 165.49 95.49 166.71 99.2 165.21 100.31 162.04 100.4 160.17 102.82 158 102.58 156.46 99.07 157.32 98.38 Z" id="29124"/>
            <path d="M 688.54 296 692.43 294.21 692.77 291.68 695.04 289.21 697.42 292.62 696.76 294.43 700.65 295.62 700.91 296.88 705.44 300.96 704.99 302.09 702.73 303.65 698.67 302.32 696.04 303.35 693.69 301.56 691.33 301.71 687.76 300.84 688.54 296 Z M 688.1 308.31 690.51 305.63 692.59 306.24 695.59 305.18 696.62 309.67 696.33 311.68 693.53 310.89 691.64 312.03 689.58 311.77 688.1 310.18 688.1 308.31 Z" id="35343"/>
            <path d="M 650.72 109.1 652.99 107.58 655.09 107.69 655.3 105.72 662.04 103.7 663.69 105.89 665.35 110.18 663.5 113.31 663.3 115.56 659.94 117.68 658.78 117.64 659.28 112.41 658.11 112.72 653.59 111.77 651.16 112.44 650.72 109.1 Z" id="35095"/>
            <path d="M 657.78 310.71 660.31 309.39 661.72 306.9 663.46 306.93 667.7 305.57 670.27 306.4 671.53 304.72 673.9 304.64 674.8 303.06 677.21 305.64 679 304.13 679.93 306.59 682.1 306.9 683 307.21 683.13 310.26 682.49 313.18 683.72 314.42 683.32 318.92 681.33 320.2 680.28 324.73 676.55 325.52 672.88 326.24 669.57 325.9 667.07 326.76 665.63 324.3 662.91 323.99 662.41 321.72 660.08 316.58 657.7 316.46 657.78 310.71 Z" id="35012"/>
            <path d="M 272.68 68.94 274.06 70.76 274.41 73.61 275.44 75.09 278.61 71.87 280.61 71.7 283.19 75.06 282.99 77.82 279.72 82.17 280.82 83.52 280.52 86.14 277.84 86.66 275.18 88.31 270.83 88.38 271.32 84.73 270.04 82.41 269.12 78.77 270.43 70.38 272.68 68.94 Z" id="29113"/>
            <path d="M 429.6 154.1 431.16 151.78 433.85 151.54 435.6 148.16 435.08 146.93 439.55 146.74 440.38 147.79 443.33 144.23 445.75 143 445.42 144.24 446.55 147.58 448.09 148.56 448.61 151.63 446.85 154.49 446.83 156.22 448.81 159.22 448.06 161.85 443.58 160.4 442.26 159.39 438.87 160.05 438.61 157.83 437.08 153.92 437.33 150.56 433.68 153.92 430.32 155.39 429.6 154.1 Z" id="22277"/>
            <path d="M 453.48 133.82 453.71 133.54 460.18 130.42 463.77 130.37 465.97 128.73 467.67 128.6 468.56 132.05 470.56 134.8 474.04 134.3 474.7 132.52 478.42 131.55 476.39 135.23 473.65 136.02 473.27 137.91 471.51 140.32 472.78 141.23 471.42 144.89 470.42 145.71 468.65 144.07 461.97 143.75 458.5 146.59 457.14 144.64 456.63 141.4 458.15 138.96 455.11 137.63 455 134.72 453.48 133.82 Z" id="22176"/>
            <path d="M 568.84 176.88 573.73 176.01 578.41 170.58 580.04 171.3 583.98 169.43 585.02 169.8 588 171.18 589.71 173.61 588.15 174.73 588.13 177.78 586.25 177.69 582.65 180.1 579.59 183.88 579.54 185.73 575.09 186.85 571.55 185.41 568.86 185.88 566.09 184.8 565.89 182.99 568.84 176.88 Z" id="22032"/>
            <path d="M 372.92 106.86 375.27 105.63 377.58 105.69 378.96 102.29 380.91 100.49 382.31 101.12 383.24 104.48 385.91 106.67 386.09 108.31 383.33 111.55 381.67 112.94 377.99 113.67 376.45 112.93 376.38 110.76 374.12 110.51 372.92 106.86 Z" id="22067"/>
            <path d="M 324.92 142.61 328.06 138.46 332.21 135.92 337.36 137.55 340.34 135.39 341.68 133.31 342.78 133.54 345.49 135.97 344.5 136.79 343.69 140.7 341.29 144.3 340.52 147.01 337.66 148.27 335.86 149.96 336.11 151.28 334.25 154.97 327.99 155.21 329.16 153.1 331.16 151.81 330.8 149.86 327.56 148.23 325.94 143.58 324.92 142.61 Z" id="22025"/>
            <path d="M 430.16 165.86 431.7 166.5 436.64 166.67 437.73 164.98 440.28 163.19 438.87 160.05 442.26 159.39 443.58 160.4 448.06 161.85 448.81 159.22 451.03 158.43 451.17 156.25 453.09 155.34 457.54 150.64 459.66 151.16 459.71 151.23 459.75 152.53 459.69 152.61 459.28 152.86 459.19 152.88 458.04 154.04 461.53 155.19 463.7 158.09 466.56 157.81 468.77 159.48 468.13 163.56 468.37 166.35 471.1 169.63 469.15 172.1 468.44 174.44 466.19 176.45 464.16 174.35 461.51 174.63 459.38 173.82 457.96 175.36 458.03 178.42 455.23 179.25 459.34 183.33 456.26 186.97 454.11 184.06 451.87 179.95 449.45 181.45 448.81 181.39 448.13 179.93 449.02 176.45 448.45 173.43 446.65 171.96 442.36 172.18 441.94 169.89 443.42 168.55 441.34 166.09 439.74 169.24 438.06 167.64 435.79 168.91 431.18 167.61 430.16 165.86 Z" id="22203"/>
            <path d="M 584.31 203.12 588.91 200.95 590.67 197.78 592.55 199.72 594.38 199.91 596.92 197.13 598.64 200.19 601.65 200.53 605.07 201.43 607.27 206.62 608.37 210.65 608.98 212.27 611.32 213.18 608.97 214.97 605.26 213.94 602.94 212.91 600.29 213.64 596.42 211.62 594.37 214.52 591.83 214.26 587.29 212.35 585.25 209.63 582.26 208.85 583.23 204.78 584.31 203.12 Z" id="35184"/>
            <path d="M 483.33 220.8 485.42 218.68 485.13 215.84 487.38 214.81 488.02 210.71 489 208.57 487.51 206.68 487.69 204.9 485.93 204.02 486.3 203.25 489.59 202.28 490.56 204 492.65 203.2 496.12 200.43 499.57 202.48 500.42 206.09 502.21 210.78 504.43 215.27 504.12 217.51 505.35 220.6 501.06 220.66 499.54 222.15 497.44 222.21 494.05 223.57 493.13 226.55 488.63 227.38 487.23 229.85 482.58 231.35 481.7 228.97 481.68 225.32 483.33 220.8 Z" id="22183"/>
            <path d="M 629.61 307.62 632.49 304.32 632.78 300.55 633.75 298.48 634.41 298.09 639.65 298.77 639.29 300.59 640.06 304.15 641.68 306.49 646.5 307.14 645.84 308.59 647.46 313.32 648.99 313.63 650.83 312.08 650.95 310.13 653.47 310.8 655.06 309.71 657.78 310.71 657.7 316.46 656.26 318.71 653.34 320.26 653.61 322.15 652.48 324.04 649.39 326.39 652.35 331.11 651.62 331.72 648.58 333.27 644 334.67 642.8 333.34 639.4 334.31 637.46 332.74 634.48 333.63 635.17 330.98 633.39 327.05 633.04 320.14 632.22 317.53 628.95 315.27 627.07 315.74 624.76 313.87 624.75 312.42 629.2 312.35 630.02 311.54 629.61 307.62 Z" id="35176"/>
            <path d="M 410.45 110.13 411.51 108.9 412.09 105.44 413.68 104.42 414.55 102.19 416.22 100.89 418.93 100.84 420.87 99.14 423.24 101.42 423.35 103.33 420.75 104.5 419.85 107.23 422.07 110.87 424.41 113.04 425.12 114.84 424.16 117.12 421.47 120.39 417 121.35 415.74 124.19 412.13 121.33 410.95 118.34 412.3 112.07 410.45 110.13 Z" id="22206"/>
            <path d="M 330.11 316.09 332.09 318.47 334.5 320.13 333.13 321.43 333.59 323.12 329.07 326.34 327.96 325.28 326.99 323.73 328.18 321.25 329.06 317.49 330.11 316.09 Z" id="56063"/>
            <path d="M 753.97 227.53 755.77 226.03 758.2 227.02 759.83 224.05 763.29 223.91 768.68 224.86 769.73 226.34 772.22 224.56 774.01 224.64 774.25 227.13 772.46 230.84 770.79 232.81 772.13 234.71 770.73 242.18 770.11 243.89 765.98 244.29 764.31 242.37 757.44 239.25 759.28 236.22 756.93 235.37 758.6 233.25 753.97 227.53 Z" id="35360"/>
            <path d="M 710.15 301.56 712.57 299.64 715.89 298.58 719.27 299.28 719.24 297.93 723.19 296.96 727.45 298.5 727.48 299.85 730.46 300.15 732.11 301.51 730.86 302.81 729.58 306.01 725.99 307.07 725.91 309.09 723.84 309.97 722.9 308.23 720.76 308.35 716.86 307.36 716.6 305.55 713.81 305.24 710.15 301.56 Z" id="35082"/>
            <path d="M 743.71 283.19 747.38 285.44 750.41 286.85 744.64 289.8 741.5 289.41 743.71 283.19 Z" id="35005"/>
            <path d="M 744 296.75 745.47 295.51 745.31 293.28 746.33 291.21 750.16 291.61 750.73 289.68 752.68 289.2 754.1 291.64 758.2 293.67 760.21 296.08 753.34 298.15 750.54 296.86 748.53 297.86 745.69 296.52 744 296.75 Z" id="35102"/>
            <path d="M 335.45 238.16 337.21 238.68 337.99 236.48 340.24 235.51 340.52 233.86 347.19 232.64 347.95 230.78 350.89 234.57 353.19 235.38 352.93 239.11 351.12 240.92 348.88 240.76 345.27 241.18 342.79 243.08 341.27 243.17 340.74 245.81 337.67 245.3 337.51 243.73 335.45 238.16 Z" id="56238"/>
            <path d="M 361.26 297.47 362.7 292.52 365.86 293.59 370.65 292.21 371.22 290.41 373.89 291.3 376.3 293.15 378.58 296.35 378.56 298.51 376.81 299.25 374.93 298.75 371.58 295.6 367.7 294.98 366.86 301.07 367.64 305.38 363.82 302.95 359.22 301.71 358.73 302.23 358.9 299.13 361.26 297.47 Z" id="56104"/>
            <path d="M 717.23 273.84 721.53 271.54 724.33 271.75 724.06 269.32 727.92 265.92 729 268.8 732.1 270.01 730.02 272.17 728.05 276.88 728.21 279.03 726.85 279.68 725.08 277.99 721.22 277.28 720.72 275.66 718.63 275.38 717.23 273.84 Z" id="35028"/>
            <path d="M 739.7 221.95 742.59 222.52 748.61 222.43 750.04 225.51 748.98 227.68 745.46 228.67 744.39 231.36 741.57 229.05 739.04 227.99 738.58 223.97 739.7 221.95 Z" id="35052"/>
            <path d="M 487.35 299.56 488.99 304.6 490.64 306.3 488.19 307.88 483.83 306.58 483.7 307.58 479.57 307.9 478.77 308.99 477.82 305.81 476.61 303.33 477.47 301.5 479.18 301.56 483.49 300.07 484.23 301.33 487.35 299.56 Z" id="56019"/>
            <path d="M 751.37 261.14 754.85 260.9 756.23 258.93 758.01 254.74 763.62 253.95 763.33 256.04 764.51 260.08 763.32 265.73 761.24 269.16 758.12 269.77 758.19 272.25 752.52 276.19 752.25 277.92 750.06 279.28 749.26 278.75 748.62 273.8 747.04 270.2 745.47 269.55 745.76 267.22 752.22 267.05 752.13 264.04 753.53 262.57 751.37 261.14 Z" id="35097"/>
            <path d="M 716.78 273.7 717.23 273.84 718.63 275.38 720.72 275.66 721.22 277.28 725.08 277.99 726.85 279.68 729.96 282.88 725.21 286.66 721.11 284.9 719.19 285.36 716.14 284.5 714.21 286.26 710.48 285.77 709.44 280.58 710.93 280.13 712.32 277.46 716.78 273.7 Z" id="35108"/>
            <path d="M 350.98 296.16 354.22 295.52 357.78 295.56 361.26 297.47 358.9 299.13 358.73 302.23 355.73 306.53 353.8 307.3 352.42 307.07 350.54 301.96 350.23 298.49 350.98 296.16 Z" id="56029"/>
            <path d="M 313.82 229.43 312.94 234.83 309.57 240.54 310.23 241.45 309.62 242.47 309.24 246.93 308.21 248.14 307.68 252.19 305.29 248.31 303.21 246.24 300.53 245.19 299.41 242.68 297.76 240.31 299.68 236.84 299.23 235.04 300.31 233.2 303.32 230.54 309.66 227.87 313.82 229.43 Z" id="56201"/>
            <path d="M 322.81 245.46 325.75 243.82 326.14 240.97 330.47 235.99 331.67 235.69 335.45 238.16 337.51 243.73 337.67 245.3 338.87 247.14 337.93 248.82 339.02 251.07 336.96 253.32 338.77 256.08 340.73 257.28 341.59 261.56 340.87 261.89 338.19 258.46 336.5 259.45 335.84 258.44 332.5 259.43 331.04 261.23 329.34 259.63 324.93 261.39 323.29 257.6 322.4 252.77 324.21 247.6 322.81 245.46 Z" id="56182"/>
            <path d="M 340.73 257.28 343.41 252.17 345.18 250.29 348.71 251.77 350.93 254.29 355.47 253.03 354.74 255.79 353.17 255.88 352.93 259.86 351.68 259.77 348.11 261.43 345.64 261.73 341.59 261.56 340.73 257.28 Z" id="56210"/>
            <path d="M 373.42 248.01 374.54 245.33 377.72 245.7 376.09 248.47 373.42 248.01 Z" id="56073"/>
            <path d="M 587.29 212.35 591.83 214.26 594.37 214.52 596.42 211.62 600.29 213.64 599.86 217.23 600.02 222.17 595.73 223.78 592.07 221.87 591.1 223.72 591 223.77 589.12 221.53 586.88 222.59 586.61 219.86 584.55 220.73 584.96 218.72 583.88 215.79 585.58 213.25 587.29 212.35 Z" id="35027"/>
            <path d="M 738.46 234.61 738.71 234.58 741.19 237.45 745.43 239.89 747.3 242.51 749.22 242.81 747.47 245.01 742.61 246.37 739.38 247.81 736.96 246.4 736.86 245.13 735.83 244.22 736.64 241.93 739.48 237.91 738.46 234.61 Z" id="35087"/>
            <path d="M 752.25 237.18 754.25 236.89 757.44 239.25 764.31 242.37 765.98 244.29 770.11 243.89 770.73 242.18 773.01 243.38 770.54 244.8 769.68 246.9 769.14 250.98 765.35 250.99 765.95 252.78 763.62 253.95 758.01 254.74 758.78 253.31 756.62 251.76 757.24 250.5 755.71 245.73 753.56 245.2 750.26 242.56 752.25 237.18 Z" id="35109"/>
            <path d="M 337.67 245.3 340.74 245.81 341.27 243.17 342.79 243.08 345.27 241.18 348.88 240.76 348.58 245.61 346.95 246.2 348.54 248.97 348.71 251.77 345.18 250.29 343.41 252.17 340.73 257.28 338.77 256.08 336.96 253.32 339.02 251.07 337.93 248.82 338.87 247.14 337.67 245.3 Z" id="56048"/>
            <path d="M 391.61 244.95 392.84 244.77 394.53 242.15 399.61 239.44 398.14 237.73 401.31 239.44 404.8 239.2 408.52 237.88 412.65 240.19 411.73 241.62 412.68 243.47 414.99 245.09 414.4 247.03 416.03 248.09 414.46 249.75 411.97 249.53 409.69 255.32 408.56 254.05 404.85 254.91 403.35 253.08 400.5 252.28 399.92 250.69 397.63 251.31 393.46 248.16 391.61 244.95 Z" id="56125"/>
            <path d="M 605.63 188.7 608.57 188.99 610.91 187.94 613.26 189.85 613.81 191.7 611.16 192.66 612.01 195.34 610.35 197.74 607.88 198.77 605.07 201.43 601.65 200.53 601.61 196.39 602.85 194.72 603.95 190.99 605.63 188.7 Z" id="35143"/>
            <path d="M 749.21 135.56 750.68 136.85 753.49 135.64 758.13 139.06 757.32 141.95 759.32 144.17 761.46 149.14 757.95 149.27 754 146.87 752.18 145.04 752.1 143.18 749.83 142.23 748.68 138.53 749.21 135.56 Z" id="35071"/>
            <path d="M 682.1 306.9 682.86 301.34 685.71 300.38 687.76 300.84 691.33 301.71 693.69 301.56 696.04 303.35 695.59 305.18 692.59 306.24 690.51 305.63 688.1 308.31 683 307.21 682.1 306.9 Z" id="35030"/>
            <path d="M 627.23 303.62 627.29 305.13 629.61 307.62 630.02 311.54 629.2 312.35 624.75 312.42 624.76 313.87 622.3 314.32 621.4 313.13 619.16 313.23 617.34 311.93 614.84 309.99 614.28 308.56 611.84 307.23 612.44 306.02 615.88 305.87 618.15 306.79 622.12 305.85 623.69 303.16 627.23 303.62 Z" id="35151"/>
            <path d="M 726.85 279.68 728.21 279.03 728.05 276.88 730.02 272.17 732.1 270.01 733.55 269.09 735.5 271.08 740.12 274.9 737.38 278.52 739.19 278.95 739.8 281.28 738.04 282.71 737.35 285.33 731.21 285.91 731.77 283.91 729.96 282.88 726.85 279.68 Z" id="35165"/>
            <path d="M 454.33 248.94 456.66 245.14 459.66 246.27 461.68 245.82 465.97 248.05 466.86 249.26 466.63 251.74 471.67 256.35 472.02 258.56 467.86 258.13 463.76 260.58 464.4 264.03 462.71 263.96 461.38 262.11 456.31 265.3 456.12 262.71 454.94 259.27 453.94 258.83 453.15 255.71 452.05 252.41 454.33 248.94 Z" id="56047"/>
            <path d="M 628.37 274.88 629.72 272.55 631.58 271.5 636.25 270.7 640.73 270.53 639.76 276.74 638.1 278.03 636.04 276.26 632.58 278.55 629.51 275.07 628.37 274.88 Z" id="35149"/>
            <path d="M 678.44 283.61 680.17 282.98 682.53 284.2 686.75 283.64 688.65 284.57 687.05 285.07 687.59 287.28 687.02 289.51 683.84 289.84 682.55 291.31 679.21 291.19 679.11 289.36 678.44 283.61 Z" id="35218"/>
            <path d="M 751.23 216.91 752.21 216.71 756.24 219.57 759.54 218.12 758.84 215.04 761.44 217.13 762.31 219.2 761.89 222.07 763.29 223.91 759.83 224.05 758.2 227.02 755.77 226.03 753.97 227.53 748.98 227.68 750.04 225.51 748.61 222.43 751.23 216.91 Z" id="35194"/>
            <path d="M 780.47 242.95 782.15 240.4 783.91 240.13 785.34 237.45 787.55 239.04 787.68 242.2 786.98 244.73 783.57 245.01 780.84 244.17 780.47 242.95 Z" id="35183"/>
            <path d="M 736.05 258.09 737.28 262.29 735.6 263.69 735.19 265.49 736.99 269.28 735.5 271.08 733.55 269.09 732.1 270.01 729 268.8 727.92 265.92 729.19 264.45 726 261.35 725.31 259.46 728.92 259.15 729.33 256.49 733.27 258.64 736.05 258.09 Z" id="35198"/>
            <path d="M 438.07 259.47 439.13 257.67 441.43 257.58 440.03 253.37 440.55 251.28 443.84 248.93 445.26 249.59 447.35 248.38 448.59 245.98 451.29 246.53 454.33 248.94 452.05 252.41 453.15 255.71 450.15 258.44 445.55 258.81 444.86 260.03 438.07 259.47 Z" id="56092"/>
            <path d="M 472.02 258.56 473.92 260.36 477.17 260.31 479.05 263.39 481.38 263.76 482.7 265.28 481.92 267.76 483.38 270.55 485.88 270.76 486.51 274.98 485.73 276.04 480.06 276.83 478.17 278.38 476.14 277.7 475.55 274.16 471.72 272.76 470.74 273.25 470.44 272.16 467.15 269.35 464.4 264.03 463.76 260.58 467.86 258.13 472.02 258.56 Z" id="56160"/>
            <path d="M 429.68 292.22 432.59 292.87 435.97 290.3 441.55 290.02 441.82 292.9 444.67 296.33 445.76 299.02 442.99 304.02 440.51 305.93 436.64 307.54 434.07 309.21 433.13 311.33 433.32 314.33 431.79 316.62 430.97 314.87 430.57 310.76 431.09 307.46 433.6 300.28 431.87 298.39 429.6 297.66 431.23 293.84 429.68 292.22 Z" id="56174"/>
            <path d="M 744.39 231.36 745.46 228.67 748.98 227.68 753.97 227.53 758.6 233.25 756.93 235.37 759.28 236.22 757.44 239.25 754.25 236.89 752.25 237.18 750.33 237.05 748.29 234.8 744.35 232.66 744.39 231.36 Z" id="35229"/>
            <path d="M 430.03 235.36 432.17 232.45 433.24 229.63 433.56 228.21 436.28 228.81 439.77 230.67 442.65 229.77 443.6 232.65 445.34 234.7 444.14 236.47 443.86 236.7 442.63 238.22 440.51 238.06 439.84 239.8 434.97 241.11 433.12 240.19 432.87 237.53 431.05 237.82 430.03 235.36 Z" id="56213"/>
            <path d="M 655.73 295.16 657.89 292.99 660.58 294.2 664.3 294.26 663.91 297.15 665.05 298.75 667.09 298.34 669.45 300.54 672.58 299.87 672.24 301.77 674.8 303.06 673.9 304.64 671.53 304.72 670.27 306.4 667.7 305.57 663.46 306.93 661.72 306.9 660.31 309.39 657.78 310.71 655.06 309.71 653.47 310.8 650.95 310.13 652.15 307.08 651.68 301.64 650.18 299.04 651.07 297.28 654.63 296.53 655.73 295.16 Z" id="35221"/>
            <path d="M 744.64 289.8 750.41 286.85 752.68 289.2 750.73 289.68 750.16 291.61 746.33 291.21 745.31 293.28 744.64 289.8 Z" id="35199"/>
            <path d="M 512.24 269.01 514.6 270.28 515.71 269.31 516.9 269.27 517.07 275.01 519.71 278.51 519.8 279.82 517.5 281.56 515.13 281.1 511.55 269.97 512.24 269.01 Z" id="56082"/>
            <path d="M 466.31 286.26 467.88 288.98 470.18 288.25 473.81 288.13 473.3 290.28 474.61 292.39 471.84 294.45 469.34 295.38 465.64 298.77 463.04 298.39 460.62 296.74 460.98 294.8 459.5 293.15 458.45 290.5 460.49 289.39 461.56 290.82 466.31 286.26 Z" id="56204"/>
            <path d="M 680.02 101.2 682.25 100.51 684.25 103.61 687.61 101.11 691.05 101.49 693.93 101.93 694.18 104.25 691.03 103.56 690.04 106.29 685.49 107.75 681.92 108.38 679.3 108.47 680.46 104.47 680.02 101.2 Z" id="35248"/>
            <path d="M 738.71 234.58 741.48 232.01 744.35 232.66 748.29 234.8 750.33 237.05 752.25 237.18 750.26 242.56 749.22 242.81 747.3 242.51 745.43 239.89 741.19 237.45 738.71 234.58 Z" id="35252"/>
            <path d="M 747.72 130.28 748.62 127.73 752.78 124.4 753.83 126.25 756.24 127.17 758.18 129.38 757.94 130.98 755.99 132.18 753.49 135.64 750.68 136.85 749.21 135.56 747.72 130.28 Z" id="35230"/>
            <path d="M 420.54 348.99 422.96 349.04 425.15 351.03 429.47 351.72 427.96 354.22 423.28 355.43 421.75 354.7 421.3 350.14 420.54 348.99 Z" id="56263"/>
            <path d="M 499.08 308 500.77 307.3 501.51 304.58 504.84 305.76 506.59 301.24 508.94 298.45 513.89 298.72 515.05 301.44 515.43 307.72 509.29 307.42 503.16 311.42 500.84 310.38 498.51 310.54 499.08 308 Z" id="56112"/>
            <path d="M 431.79 316.62 433.32 314.33 433.13 311.33 434.07 309.21 436.64 307.54 440.51 305.93 442.99 304.02 445.54 304.65 449.8 302.65 452.27 306.2 454.03 311.38 452.23 313.13 447.21 314.54 446.54 316.61 444.4 316.45 442.96 319.57 439.03 318.78 432.51 318.75 431.79 316.62 Z" id="56141"/>
            <path d="M 521.78 244.39 523.95 243.45 528.02 242.8 528.23 244.82 530.38 246.12 531.87 244.12 533.92 243.98 536.62 245.28 537.99 246.88 536.95 248.05 537.89 252.09 539.26 252.66 535.2 257.23 533.59 257.48 529.54 257.85 527.22 260.83 523.26 260.84 523.34 254.4 518.56 249.65 517.97 245.81 519.52 244.58 521.78 244.39 Z" id="56080"/>
            <path d="M 512.53 260.5 513.22 263.21 514.51 263.74 515.71 269.31 514.6 270.28 512.24 269.01 509.79 268.52 507.75 268.07 507.19 262.89 509.29 261.35 510.41 262.13 512.53 260.5 Z" id="56068"/>
            <path d="M 546.29 312.69 554.29 310.31 555.37 310.19 555.75 312.52 559 312.42 560.53 313.99 561.9 317.58 559.87 318.38 561.36 322.34 561.98 324.88 561.38 328.93 559.39 327.82 556.44 327.5 554.84 329.68 551.75 330.33 548.32 322.63 545.12 321.74 544.2 320.91 545.28 315.67 544.75 314.27 546.29 312.69 Z" id="56200"/>
            <path d="M 547.94 329.74 551.75 330.33 554.84 329.68 556.44 327.5 559.39 327.82 561.38 328.93 564.72 330.3 567.1 330.48 564.71 334.32 566.22 339.58 564.77 341.89 563.09 340.65 559.38 341.59 558.03 339.52 554.35 340.01 551.62 337.71 547.59 335.73 547.94 329.74 Z" id="56229"/>
            <path d="M 527.64 239.26 529.86 238.43 533.86 239.75 532.96 241.21 533.92 243.98 531.87 244.12 530.38 246.12 528.23 244.82 528.02 242.8 527.64 239.26 Z" id="56056"/>
            <path d="M 503.98 275.7 504.15 273.65 508.83 270.97 509.79 268.52 512.24 269.01 511.55 269.97 515.13 281.1 513.82 283.71 506.41 280.27 504.32 277.44 503.98 275.7 Z" id="56050"/>
            <path d="M 450.38 276.26 452.28 273.76 450.83 268.96 451.34 266.07 453.25 264.14 456.12 262.71 456.31 265.3 461.38 262.11 462.71 263.96 464.4 264.03 467.15 269.35 470.44 272.16 470.74 273.25 465.55 275.01 463.44 274.65 461.65 275.91 459 275.82 456.08 276.83 450.38 276.26 Z" id="56190"/>
            <path d="M 475.73 281.86 478.17 278.38 480.06 276.83 485.73 276.04 485.73 278.65 483.67 279.19 482.52 283.79 479.35 284.19 476.12 283.15 475.73 281.86 Z" id="56103"/>
            <path d="M 471.99 305.02 475.32 300.41 475.49 299.25 478.61 298.25 478.55 295.25 481.77 293.15 482.66 290.41 485.91 288.64 490.63 289.87 493.82 293.24 492.18 293.94 490.35 296.57 488.78 296.87 487.35 299.56 484.23 301.33 483.49 300.07 479.18 301.56 477.47 301.5 476.61 303.33 477.82 305.81 476.05 307.33 470.85 308.87 471.99 305.02 Z" id="56071"/>
            <path d="M 526.15 312.67 527.56 309 528.71 308.19 529.33 305.14 532.16 305.19 533.34 306.8 533.45 309.89 531.49 313.36 529.81 315.14 526.25 312.73 526.15 312.67 Z" id="56202"/>
            <path d="M 510.53 258.89 513.71 258.31 516.4 255.34 519.74 255.87 523.34 254.4 523.26 260.84 522.7 264.72 519.19 266.2 516.9 269.27 515.71 269.31 514.51 263.74 513.22 263.21 512.53 260.5 510.53 258.89 Z" id="56227"/>
            <path d="M 555.37 310.19 558.39 309.51 561.45 308.03 563.29 308.79 566.36 307.98 568.16 308.37 568.87 309.82 568.4 312.34 570.96 313.91 571.91 316.41 570.33 318.25 571.21 319.75 564.76 320 561.36 322.34 559.87 318.38 561.9 317.58 560.53 313.99 559 312.42 555.75 312.52 555.37 310.19 Z" id="56253"/>
            <path d="M 487.35 299.56 488.78 296.87 490.35 296.57 492.18 293.94 493.82 293.24 496.2 292.46 496.23 294.55 494.25 296.86 494.85 299.85 498.8 301.07 501.95 300.38 501.51 304.58 500.77 307.3 499.08 308 496.72 307.76 495.55 305.09 492.51 304.82 490.64 306.3 488.99 304.6 487.35 299.56 Z" id="56051"/>
            <path d="M 537.61 321.57 540.39 321.33 544.95 323.23 545.94 326.38 547.32 327.19 547.94 329.74 547.59 335.73 551.62 337.71 554.35 340.01 552.55 342.44 547.34 343.23 541.16 339.61 542.4 338.08 543.72 333.14 540.69 332.56 542.85 328.97 540.65 327.37 537.96 323.52 536.29 322.72 537.61 321.57 Z" id="56211"/>
            <path d="M 709.38 295.26 710.21 292.35 712.82 292.69 717.11 294.09 718.63 295.63 719.24 297.93 719.27 299.28 715.89 298.58 712.57 299.64 708.24 296.82 709.38 295.26 Z" id="35262"/>
            <path d="M 473.81 288.13 475.64 285.13 475.73 281.86 476.12 283.15 479.35 284.19 482.52 283.79 481.85 288.92 482.66 290.41 481.77 293.15 478.55 295.25 476.67 295.49 474.61 292.39 473.3 290.28 473.81 288.13 Z" id="56027"/>
            <path d="M 764.51 260.08 765.85 260.64 772.2 260.62 774.62 262.57 773.57 264.94 773.45 267.48 771.16 268.31 770.69 270.08 767.63 270.43 767.14 267.92 765.44 265.76 763.32 265.73 764.51 260.08 Z" id="35272"/>
            <path d="M 501.95 300.38 503.31 299.09 503.64 296.62 505.54 296.09 506.9 294.24 505.91 291.13 507.3 290.25 506.16 287.63 506.58 285.99 508.98 286.17 513 288.15 515.12 290.19 515.55 292.13 519.49 294.98 518.39 296.68 513.89 298.72 508.94 298.45 506.59 301.24 504.84 305.76 501.51 304.58 501.95 300.38 Z" id="56236"/>
            <path d="M 526.25 312.73 529.81 315.14 531.49 313.36 532.71 314.48 532.93 318.09 530.98 320.98 530.23 325.59 527.99 328.15 526.84 327.73 524.06 326.41 523.22 323.61 522.85 321.58 526.2 320.28 525.34 314.76 526.25 312.73 Z" id="56228"/>
            <path d="M 536.43 359.49 542.63 360.82 543.44 361.87 541.49 364.56 542.75 366.52 543.67 370.43 541.96 373.68 546.94 377.24 546.38 378.58 543.47 378.4 542.58 379.55 539.39 379.92 534.32 376.67 533.76 377.66 532.54 377.03 531.43 375.75 532.73 373.12 534.74 372.32 531.84 369.76 535.36 367.29 535.74 364.22 537.49 361.09 536.43 359.49 Z" id="56111"/>
            <path d="M 521.57 332.98 526.93 329.98 526.84 327.73 527.99 328.15 530.23 325.59 532.2 324.59 534.16 325.63 536.29 322.72 537.96 323.52 540.65 327.37 542.85 328.97 540.69 332.56 543.72 333.14 542.4 338.08 541.16 339.61 537.82 340.83 534.94 340.77 532.07 339.58 531.1 337.07 523.74 336.72 521.57 332.98 Z" id="56159"/>
            <path d="M 588.44 230.17 589.9 229.03 591.38 230.15 593.83 230.16 596.4 230.31 600.69 234.65 596.46 237.1 595.29 238.36 592.86 234.94 588.3 235.08 589.06 233.9 588.44 230.17 Z" id="35277"/>
            <path d="M 518.57 326.03 520.55 322.07 523.22 323.61 524.06 326.41 526.84 327.73 526.93 329.98 521.57 332.98 518.83 334.5 519.91 332.22 518.3 328.18 518.57 326.03 Z" id="56020"/>
            <path d="M 501.63 355.24 501.6 352.18 503.89 351.71 504.91 349.85 510.31 351.66 513.43 351.28 515.06 354.87 513.49 360.56 511.61 362.12 509.11 359.48 507.39 360.07 505.91 358.93 501.14 358.07 501.63 355.24 Z" id="56261"/>
            <path d="M 753.49 135.64 755.99 132.18 757.94 130.98 758.18 129.38 760.34 127.48 763.45 131.25 767.31 131.78 766.21 133.17 765.61 134.4 762.74 134.02 761.13 137.01 760.87 139.12 758.13 139.06 753.49 135.64 Z" id="35357"/>
            <path d="M 600.29 213.64 602.94 212.91 605.26 213.94 605.31 221.23 600.02 222.17 599.86 217.23 600.29 213.64 Z" id="35320"/>
            <path d="M 621.77 331.34 625.88 330.4 626.59 328.13 628.9 326.77 633.39 327.05 635.17 330.98 634.48 333.63 634.07 338.43 630.27 337.37 626.24 337.09 624.88 333.78 621.77 331.34 Z" id="35268"/>
            <path d="M 559.11 372.8 561.16 367.42 561.46 364.55 566.51 366.76 566.61 368.71 564.71 369.39 563.96 372.76 560.85 373.58 559.11 372.8 Z" id="56216"/>
            <path d="M 512.87 364.46 514.83 369.34 514.56 371.5 512.35 375.1 509.73 374.4 508.39 376.05 506.6 376.02 503.25 375.53 499.36 373.11 496.71 372.34 497.06 370.41 500.85 366.41 503.44 367.04 504.02 365.79 507.91 365.29 510.44 364.06 512.87 364.46 Z" id="56015"/>
            <path d="M 489.08 352.52 491.16 352.46 491.79 354.9 496.7 354.87 497.95 355.5 496.18 358.35 494.78 358.89 495.05 362.18 491.71 363.55 490.49 365.97 490.74 361.97 486.45 360.99 485.42 362.68 483.42 362.46 481.23 364.59 477.97 362.34 477.06 360.88 478.06 359.75 484.72 358.66 486.23 353.6 489.08 352.52 Z" id="56255"/>
            <path d="M 643.36 296.54 644.39 293.93 644.3 291.7 645.61 290.4 647.06 284.78 648.15 282.92 649.76 284.95 652.93 285.82 655.71 288.46 653.2 289.47 655.73 295.16 654.63 296.53 651.07 297.28 650.18 299.04 645.33 298.65 643.36 296.54 Z" id="35312"/>
            <path d="M 545.12 321.74 548.32 322.63 551.75 330.33 547.94 329.74 547.32 327.19 545.94 326.38 544.95 323.23 545.12 321.74 Z" id="56224"/>
            <path d="M 567.1 330.48 571.46 331.71 571.86 334.31 576.95 335.68 577.15 337.38 575.84 339.23 574.82 344.63 575.58 344.77 572.74 345.87 567.75 345.42 564.77 341.89 566.22 339.58 564.71 334.32 567.1 330.48 Z" id="56060"/>
            <path d="M 551.06 381.51 554.01 379.77 554.54 376.79 556.51 373.33 559.11 372.8 560.85 373.58 563.96 372.76 565.84 372.58 567.63 373.76 567.12 375.36 571.38 375.87 572.45 379.64 571.05 384 568.46 385.07 563.93 385.78 562.36 387.96 558.13 387.3 553.97 388.48 552.73 385.93 551.05 384.39 551.06 381.51 Z" id="56011"/>
            <path d="M 607.35 325.86 610.45 327.59 612.72 327.29 612.98 329.75 616.29 328.37 621.77 331.34 624.88 333.78 626.24 337.09 624.47 337.23 624.26 339.32 621.99 339.97 618.54 338.31 616.69 338.37 611.96 340.56 608.97 338.74 607.45 339.16 606.6 334.85 604.72 333.78 607.26 328.41 607.35 325.86 Z" id="35285"/>
            <path d="M 543.45 351.92 541.08 354.87 540.61 351.64 543.45 351.92 Z" id="56196"/>
            <path d="M 503.88 325.66 506.74 324.35 507.88 325.55 512.23 325.69 513.93 324.64 516.07 326.92 518.57 326.03 518.3 328.18 519.91 332.22 518.83 334.5 515.64 334.48 511.69 332.94 509.28 334.11 505.75 332.77 502.19 330 503.88 325.66 Z" id="56219"/>
            <path d="M 485.02 326.88 488.18 326.32 488.18 323.42 492.28 321.73 494.64 321.54 496.5 323.44 500.44 324.24 503.88 325.66 502.19 330 505.75 332.77 504.28 335.06 501.46 336.67 499.62 333.55 493.34 333.7 490.52 333.26 489.66 331.4 486.89 332.07 487.78 329.74 485.02 326.88 Z" id="56254"/>
            <path d="M 532.71 314.48 535.86 313.84 539.99 312.11 546.29 312.69 544.75 314.27 545.28 315.67 544.2 320.91 545.12 321.74 544.95 323.23 540.39 321.33 537.61 321.57 537.65 319.94 534.65 318 532.93 318.09 532.71 314.48 Z" id="56133"/>
            <path d="M 556.55 300.21 560.77 300.76 562.26 299.91 562.46 301.98 564.13 304.52 566.76 306.32 568.16 308.37 566.36 307.98 563.29 308.79 561.45 308.03 558.39 309.51 555.37 310.19 554.29 310.31 553.88 306.15 556.6 303.29 556.55 300.21 Z" id="56191"/>
            <path d="M 561.36 322.34 564.76 320 571.21 319.75 572.45 320.28 571.46 325.07 568.93 325.76 568.31 327.73 569.69 329.65 571.46 331.71 567.1 330.48 564.72 330.3 561.38 328.93 561.98 324.88 561.36 322.34 Z" id="56230"/>
            <path d="M 542.63 360.82 554.69 362.81 558.73 363.7 561.46 364.55 561.16 367.42 559.11 372.8 556.51 373.33 554.54 376.79 554.01 379.77 551.06 381.51 548.87 378.54 546.94 377.24 541.96 373.68 543.67 370.43 542.75 366.52 541.49 364.56 543.44 361.87 542.63 360.82 Z" id="56028"/>
            <path d="M 529.77 389.35 532.69 389.46 535.25 387.06 535.55 389.62 536.97 393.07 539.04 393.42 543.54 393.03 550.27 394.86 548.92 397.48 550.51 400.36 549.52 403.19 549.07 404.66 545.67 406.76 541.82 405.68 539.56 407.66 535.2 402.63 534.8 398.52 532.07 394.99 529.14 394.92 527.7 393.3 529.77 389.35 Z" id="56126"/>
            <path d="M 455.52 344.67 455.95 343.34 459.59 341.48 462.88 344.75 460.64 349.48 457.75 348.42 456.31 348.92 455.88 346.93 455.52 344.67 Z" id="56132"/>
            <path d="M 746.61 253.06 751.12 251.72 756.62 251.76 758.78 253.31 758.01 254.74 756.23 258.93 754.85 260.9 751.37 261.14 750.65 258.83 748.18 257.44 748.3 254.85 746.61 253.06 Z" id="35350"/>
            <path d="M 742.61 246.37 747.47 245.01 749.22 242.81 750.26 242.56 753.56 245.2 755.71 245.73 757.24 250.5 756.62 251.76 751.12 251.72 746.61 253.06 743.79 252.1 742.61 246.37 Z" id="35338"/>
            <path d="M 506.6 376.02 508.39 376.05 509.73 374.4 512.35 375.1 514.56 371.5 518.48 372.06 519.96 370.58 524.02 372.53 524.57 376.28 527.37 375.3 528.91 376.66 531.43 375.75 532.54 377.03 530.46 377.79 529.52 380.98 527.76 382.68 527.98 387.43 529.77 389.35 527.7 393.3 524.79 395.25 524.27 392.28 521.42 392.33 519.03 391.01 519.66 388.31 515.31 389.03 514.43 387.43 515.5 385.48 514.12 383.26 511.21 382 510.2 383.37 507.17 382.95 506.89 381.17 506.6 376.02 Z" id="56149"/>
            <path d="M 507.71 343.09 514.94 343.76 518.35 346.33 520.45 345.09 522.26 349.1 518.5 349.99 516.3 352.39 515.06 354.87 513.43 351.28 510.31 351.66 504.91 349.85 504.43 346.72 506.25 346.06 507.71 343.09 Z" id="56108"/>
            <path d="M 496.17 376.67 499.36 373.11 503.25 375.53 506.6 376.02 506.89 381.17 504.57 380.65 500.84 381.98 496.15 381.44 497.1 379.36 495.76 378.26 496.17 376.67 Z" id="56109"/>
            <path d="M 740.36 303.14 741.65 303.24 742.79 300.89 744.66 302.3 744.99 304.6 747.06 305.53 748.6 304.73 748.97 306.48 751.16 309.01 749.98 310.43 748.86 311.53 748.48 309.36 745.3 308.33 744.05 306.94 741.11 306.45 740.36 303.14 Z" id="35114"/>
            <path d="M 740.12 274.9 743.67 272.41 743.77 270.66 745.47 269.55 747.04 270.2 748.62 273.8 749.26 278.75 750.06 279.28 748.67 282.42 747.64 282.89 747.38 285.44 743.71 283.19 743.02 281.7 739.8 281.28 739.19 278.95 737.38 278.52 740.12 274.9 Z" id="35359"/>
            <path d="M 463.4 324.26 471.43 324.77 470.91 323.06 474.41 323.02 474.23 318.87 475.8 315.33 478.1 316.61 476.4 320.33 478.4 320.68 479.1 322.84 477.69 326.38 481.08 327.54 485.02 326.88 487.78 329.74 486.89 332.07 484.64 335.54 483.03 336.11 480.16 334.6 478.82 333.8 474.51 334.51 473.51 338.78 471.93 339.75 467.48 340.42 466.82 339.69 467.15 336.84 466.43 332.96 463.1 331.2 463.4 324.26 Z" id="56157"/>
            <path d="M 442.96 319.57 444.4 316.45 446.54 316.61 447.21 314.54 452.23 313.13 454.03 311.38 454.61 312.06 459.44 312.7 460.47 316.7 458.7 321.7 460.48 322.87 457.9 324.59 458.1 326.29 453.1 326.51 452.71 325.16 449.27 325.02 447.25 321.89 443.99 322.48 442.96 319.57 Z" id="56042"/>
            <path d="M 491.34 378.93 494.39 377.88 495.76 378.26 497.1 379.36 496.15 381.44 492.51 381.58 491.34 378.93 Z" id="56259"/>
            <path d="M 628.08 274.84 628.37 274.88 629.51 275.07 632.58 278.55 636.04 276.26 638.1 278.03 638.9 280.69 640.42 280.8 641.59 282.99 645.43 283.5 648.15 282.92 647.06 284.78 645.61 290.4 644.3 291.7 644.39 293.93 643.36 296.54 639.65 298.77 634.41 298.09 633.75 298.48 631.28 299.02 629.57 298.37 628.8 297.75 626.51 294.86 625.4 292.44 625.21 289.65 627.05 288.5 626.63 285.35 628.41 283.63 626.69 276.21 628.08 274.84 Z" id="35127"/>
            <path d="M 453.1 326.51 458.1 326.29 458.07 327.85 455.69 329.82 458.56 331.52 458.54 334.95 459.84 336.64 458.83 338.84 459.59 341.48 455.95 343.34 455.52 344.67 452.67 339.72 452.83 333.27 452.45 330.98 454.28 330.11 453.1 326.51 Z" id="56115"/>
            <path d="M 710.48 285.77 714.21 286.26 716.14 284.5 719.19 285.36 721.11 284.9 725.21 286.66 729.96 282.88 731.77 283.91 731.21 285.91 730.31 288.56 726.46 290.55 723.19 296.96 719.24 297.93 718.63 295.63 717.11 294.09 712.82 292.69 712.78 290.12 711.1 288.29 710.48 285.77 Z" id="35333"/>
            <path d="M 657.7 316.46 660.08 316.58 662.41 321.72 662.91 323.99 665.63 324.3 667.07 326.76 666.94 328.45 663.91 328.44 660.66 330.77 658.64 331.11 656.16 330.23 654.28 331.62 651.65 331.91 651.62 331.72 652.35 331.11 649.39 326.39 652.48 324.04 653.61 322.15 653.34 320.26 656.26 318.71 657.7 316.46 Z" id="35202"/>
            <path d="M 610.81 217.7 612.11 215.75 615.67 215.29 615.9 220.49 613.11 220.25 610.81 217.7 Z" id="35203"/>
            <path d="M 174.58 248.51 177.59 247.33 185.43 247.77 188.77 253.25 191.51 256.08 193.19 256.83 195.05 259.56 192.56 261.13 189.59 261.02 188.88 259.71 186.61 259.92 183.76 258.87 183.92 256.87 182.38 256.19 178.47 257.51 175.38 256.72 175.01 256.25 176.6 253.9 176.9 251.14 174.69 249.94 174.58 248.51 Z" id="29066"/>
            <path d="M 391.29 144.43 393.58 144.17 397.87 142.55 400.71 145.02 402.3 143.18 406.56 142.16 408.63 144.39 408.57 146.83 406.1 148.69 406.26 149.76 400.44 150.54 395.24 149.03 394.89 146.74 391.29 144.43 Z" id="22284"/>
            <path d="M 425.66 171.81 427.89 168.87 428.12 165.48 430.16 165.86 431.18 167.61 435.79 168.91 436.2 171.12 433.09 173.07 432.19 176.9 429.76 177.1 429.34 175.79 426.57 174.72 425.66 171.81 Z" id="22009"/>
            <path d="M 440.97 116.97 444.22 115.68 445.58 116.64 448.66 115.3 448.4 119.67 446.59 123.4 445.78 123.64 443.07 123.69 441.9 121.76 442.42 119.34 440.97 116.97 Z" id="22144"/>
            <path d="M 415.74 124.19 417 121.35 421.47 120.39 425.02 121.33 425.29 128.34 424.96 130.9 423.38 132.07 421.61 131.46 420.69 133.42 421.45 139.49 418.47 140.68 416.77 140.84 415.39 137.33 410.93 135.99 410.77 133.57 410.77 129.05 412.17 127.25 415.12 125.63 415.74 124.19 Z" id="22011"/>
            <path d="M 432.84 116.09 434.43 114.62 440.6 114.24 440.97 116.97 442.42 119.34 441.9 121.76 443.07 123.69 440.5 123.32 438.23 125.01 436.55 124.81 435.86 122.56 437.6 119.52 432.84 116.09 Z" id="22188"/>
            <path d="M 425.12 114.84 430.3 113.69 432.84 116.09 437.6 119.52 435.86 122.56 436.55 124.81 433.85 126.32 432.82 129.51 429.97 129.23 427.57 129.64 425.29 128.34 425.02 121.33 421.47 120.39 424.16 117.12 425.12 114.84 Z" id="22234"/>
            <path d="M 445.47 188.2 445.92 186.92 448.92 185.27 449.9 183.59 449.45 181.45 451.87 179.95 454.11 184.06 456.26 186.97 457.33 188.72 450.41 192.1 448.62 190.88 448.18 188.65 445.47 188.2 Z" id="22300"/>
            <path d="M 508.11 149.41 509.39 151.12 510.48 153.31 513.22 153.69 515.09 155.15 517.48 156.2 519.08 159.34 516.6 162.96 514.51 163.55 511.61 164.83 510.89 162.82 511.39 160.59 510.13 158.59 511.25 156.31 510.55 154.13 507.39 153.79 506.89 151.69 508.11 149.41 Z" id="22165"/>
            <path d="M 212.96 213.55 213.55 214.73 211.25 219.08 210.17 218.33 207.37 219.52 206.35 217.78 207.24 215.07 204.52 214.46 203.16 215.29 202.62 213.52 204.06 211.05 206.71 210.84 209.47 206.77 212.41 207.86 211.5 212.28 212.96 213.55 Z" id="29243"/>
            <path d="M 425.29 128.34 427.57 129.64 429.97 129.23 430.57 132.67 429.06 136.64 427.81 140.03 426.49 141.49 423.81 140.91 421.45 139.49 420.69 133.42 421.61 131.46 423.38 132.07 424.96 130.9 425.29 128.34 Z" id="22045"/>
            <path d="M 433.39 212.3 436.12 211.39 437.04 206.1 440.57 202.18 437.27 201.65 437.53 199.06 440.12 200.39 442.46 200.16 442.6 201.78 445.04 209.56 447.25 212.11 446.71 213.43 448.95 217.38 446.18 217.64 443.04 215.71 441.04 216.66 436.98 217.14 434.6 217.17 433.39 212.3 Z" id="22279"/>
            <path d="M 439.21 183.74 440.31 182.54 444.29 182.27 448.81 181.39 449.45 181.45 449.9 183.59 448.92 185.27 445.92 186.92 445.47 188.2 442.31 188.97 441.99 186.73 439.21 183.74 Z" id="22384"/>
            <path d="M 232.35 246.58 231.24 249.58 233.42 251.94 230.96 253.79 228.02 253.83 226.94 254.83 224.38 254.48 220.94 255.2 220.74 252.65 217.63 250.47 219.28 249.54 220.12 247.2 222.77 247.68 226.13 243.03 228.56 244.46 230.43 243.98 232.35 246.58 Z" id="29107"/>
            <path d="M 364.39 178.02 367.58 175.74 369.43 171.84 372.69 175.3 373.76 177.15 377.51 178.59 379.89 177.99 381.08 182.65 378.62 186.52 378.7 189 377.76 189.66 374.02 189.64 372.37 188.88 370.25 191.11 367.65 192.22 364.45 191.87 362.76 189.8 360.42 189.38 358.21 190.58 357.52 188.35 357.49 183.72 359.56 181.87 358.74 177.43 362.51 176.52 364.39 178.02 Z" id="22229"/>
            <path d="M 382.93 154.22 383.88 148.99 384.59 148.1 387.1 146.5 387.19 144.94 391.29 144.43 394.89 146.74 395.24 149.03 395.25 150.02 392.77 153.28 393.14 155.87 391.95 159.39 392.58 162.06 388.02 161.13 385.48 158.88 384.27 159.08 382.8 156.09 382.93 154.22 Z" id="22092"/>
            <path d="M 390.12 129.63 393.36 128.75 393.31 125.96 395.72 128.26 396.55 131.84 398.19 133.31 398.21 134.11 396.9 135.07 396.74 139.79 397.87 142.55 393.58 144.17 391.29 144.43 387.19 144.94 387.1 146.5 384.59 148.1 383.42 143.74 382.19 139.42 385.14 135.96 387.34 135.98 390.12 129.63 Z" id="22189"/>
            <path d="M 604.66 162.54 605.59 161.36 609.07 162.3 609.97 165.2 613.97 164.05 614.24 166.28 608.11 169.62 604.66 162.54 Z" id="22263"/>
            <path d="M 372.69 175.3 373.89 170.61 376.38 169.84 380.21 164.16 380.73 162.01 384.27 159.08 385.48 158.88 388.02 161.13 388.97 163.55 388.8 170.57 389.66 171.96 390.73 177.42 393.52 177.71 392.59 179.6 390.5 182.16 388.57 182.31 387.86 183.97 385.14 183.96 381.08 182.65 379.89 177.99 377.51 178.59 373.76 177.15 372.69 175.3 Z" id="22321"/>
            <path d="M 403.68 104.68 404.13 103.79 406.03 103.4 406.14 101.69 412.22 99.84 414.55 102.19 413.68 104.42 412.09 105.44 411.51 108.9 410.45 110.13 403.12 111.01 404.79 107.33 403.68 104.68 Z" id="22304"/>
            <path d="M 375.33 115.7 376.45 112.93 377.99 113.67 381.67 112.94 383.33 111.55 384.01 114.37 385.94 114.34 388.55 115.64 387.4 117.59 382.89 117.51 379.34 121 375.16 120.11 376 118.36 375.33 115.7 Z" id="22040"/>
            <path d="M 376.65 153.03 379.23 148.38 378.31 147.09 379.83 145 381.48 145.86 383.42 143.74 384.59 148.1 383.88 148.99 382.93 154.22 379.2 153.86 376.81 155.44 376.65 153.03 Z" id="22139"/>
            <path d="M 387.4 117.59 388.55 115.64 389.8 116.7 392.75 121.15 392.2 124.36 393.31 125.96 393.36 128.75 390.12 129.63 389.08 129.87 386.39 127.47 385.97 125.67 387.29 123.21 387.4 117.59 Z" id="22271"/>
            <path d="M 392.75 121.15 394.54 118.88 397.97 119.65 399.42 122.06 399.33 123.6 404.38 129.28 398.19 133.31 396.55 131.84 395.72 128.26 393.31 125.96 392.2 124.36 392.75 121.15 Z" id="22322"/>
            <path d="M 404.59 91.67 406.7 90.3 405.09 88.33 404.94 86.31 406.69 86.61 410.72 83.42 409.51 81.72 412.47 80.8 412.99 81.81 413.41 83.86 415.19 83.68 416.49 85.82 417.67 92.31 417.42 94.66 415.35 96.3 412.82 95.38 408.83 97.21 408.58 98.57 405.78 98.74 403.76 97.14 403.15 94.98 404.59 91.67 Z" id="22065"/>
            <path d="M 481.08 207.2 481.53 205.14 484.27 203.9 485.93 204.02 487.69 204.9 487.51 206.68 489 208.57 488.02 210.71 487.38 214.81 485.13 215.84 485.42 218.68 483.33 220.8 477.9 218.33 478.36 212.26 481.33 208.7 481.08 207.2 Z" id="22255"/>
            <path d="M 388.13 102.62 388.72 99.67 391.48 98.34 391.22 96.62 394.16 96.07 399.67 101.82 401.47 101.05 404.13 103.79 403.68 104.68 401.7 106.95 397.35 106.56 394.36 107.19 390.68 103.39 388.83 103.38 388.13 102.62 Z" id="22272"/>
            <path d="M 353.89 157.73 355.23 159.13 357.73 158.36 359.58 159.63 361.81 159.27 364.98 152.66 366.61 152.73 367.07 153.65 366.09 154.88 366.27 159.17 365.63 163.31 365.94 164.9 363 166.1 361.48 164.66 357.89 165.6 356.41 163.47 353.41 163.67 350.93 160.99 350.9 159.62 353.89 157.73 Z" id="22169"/>
            <path d="M 367.07 153.65 373.01 153.14 373.81 154.06 376.65 153.03 376.81 155.44 379.2 153.86 382.93 154.22 382.8 156.09 384.27 159.08 380.73 162.01 380.21 164.16 376.38 169.84 373.89 170.61 372.69 175.3 369.43 171.84 367.88 169.32 367.79 167.35 365.94 164.9 365.63 163.31 366.27 159.17 366.09 154.88 367.07 153.65 Z" id="22115"/>
            <path d="M 365.3 140.11 370.3 140.53 372.97 143.75 376.06 144.95 379.83 145 378.31 147.09 379.23 148.38 376.65 153.03 373.81 154.06 373.01 153.14 367.07 153.65 366.61 152.73 363.27 145.76 363.48 140.63 365.3 140.11 Z" id="22088"/>
            <path d="M 351.95 123.95 352.94 123.38 356.18 124.23 360.13 122.5 363.13 122.68 367 121 368.86 123.1 366.73 125.22 366.27 127.81 367.07 131.75 364.48 133.27 364.23 134.79 359.22 134.54 356.32 134.82 354.43 132.83 352.63 132.8 352.08 130.5 353.28 129.37 351.95 123.95 Z" id="22249"/>
            <path d="M 397.97 119.65 399.9 117.43 402.52 116.76 403.12 111.01 410.45 110.13 412.3 112.07 410.95 118.34 412.13 121.33 415.74 124.19 415.12 125.63 412.17 127.25 410.77 129.05 410.77 133.57 408.7 133 408.21 129.89 404.38 129.28 399.33 123.6 399.42 122.06 397.97 119.65 Z" id="22116"/>
            <path d="M 370.57 91.83 372.63 90.88 378.5 89.37 383.21 89.59 386.71 90.92 386.5 94.93 383.61 96.11 383.86 99.44 382.31 101.12 380.91 100.49 378.96 102.29 377.58 105.69 375.27 105.63 372.92 106.86 371.75 107.25 375.04 102.87 372.48 99.4 371.88 95.22 370.57 91.83 Z" id="22223"/>
            <path d="M 351.58 90.81 354.28 91.44 355.82 87.83 357.44 87.43 360.88 91.11 362.15 89.31 363.68 89.4 365.22 91.39 369.85 91.01 370.57 91.83 371.88 95.22 372.48 99.4 368.9 98.27 364.69 100.82 363.73 98.34 360.37 100.65 359.82 102.45 357.81 103.3 355.76 101.91 355.47 97.97 352.36 95.86 353.64 94.34 351.5 92.33 351.58 90.81 Z" id="22164"/>
            <path d="M 601.7 155 603.55 154.2 604.04 152.41 606.01 156.01 610.7 156.29 608.33 158.13 609.07 162.3 605.59 161.36 603.49 159.58 603.41 156.63 601.7 155 Z" id="22274"/>
            <path d="M 351.59 117.99 353.93 115.27 357.79 114.6 360.72 112.31 363.8 110.62 365.63 113.05 364.98 117.08 368.09 118.26 367 121 363.13 122.68 360.13 122.5 356.18 124.23 352.94 123.38 351.95 123.95 350.87 123.41 351.89 120.19 351.59 117.99 Z" id="22072"/>
            <path d="M 378.45 192.67 378.64 194.34 380.75 197 381.07 200.97 378.87 200.52 375.78 202.8 373.91 200.55 375.06 197.33 375.33 193.6 378.45 192.67 Z" id="22064"/>
            <path d="M 357.39 166.94 357.89 165.6 361.48 164.66 363 166.1 365.94 164.9 367.79 167.35 367.88 169.32 369.43 171.84 367.58 175.74 364.39 178.02 364.21 175.64 358.6 171.04 357.39 166.94 Z" id="22365"/>
            <path d="M 395.24 149.03 400.44 150.54 406.26 149.76 404.35 154.7 404.99 156.56 402.27 159.69 400.5 160.65 397.72 164.07 393.93 161.42 392.58 162.06 391.95 159.39 393.14 155.87 392.77 153.28 395.25 150.02 395.24 149.03 Z" id="22294"/>
            <path d="M 398.21 134.11 403.92 137.98 406.54 137.03 409.73 137.54 408.44 140.66 410.33 143.2 408.63 144.39 406.56 142.16 402.3 143.18 400.71 145.02 397.87 142.55 396.74 139.79 396.9 135.07 398.21 134.11 Z" id="22335"/>
            <path d="M 398.19 133.31 404.38 129.28 408.21 129.89 408.7 133 410.77 133.57 410.93 135.99 409.73 137.54 406.54 137.03 403.92 137.98 398.21 134.11 398.19 133.31 Z" id="22289"/>
            <path d="M 405.78 98.74 408.58 98.57 408.83 97.21 412.82 95.38 415.35 96.3 417.42 94.66 417.67 92.31 419.51 94.65 419.57 97.05 420.87 99.14 418.93 100.84 416.22 100.89 414.55 102.19 412.22 99.84 406.14 101.69 405.78 98.74 Z" id="22019"/>
            <path d="M 357.81 103.3 359.82 102.45 360.37 100.65 363.73 98.34 364.69 100.82 368.9 98.27 372.48 99.4 375.04 102.87 371.75 107.25 368.75 108.09 363.9 107.69 363.8 110.62 360.72 112.31 359.49 107.25 357.81 103.3 Z" id="22354"/>
            <path d="M 372.95 85.31 375.6 83.82 378.88 83.26 379.74 85 379.84 87.42 378.5 89.37 372.63 90.88 372.24 86.67 372.95 85.31 Z" id="22091"/>
            <path d="M 371.75 107.25 372.92 106.86 374.12 110.51 376.38 110.76 376.45 112.93 375.33 115.7 373.22 115.59 371.9 117.63 368.09 118.26 364.98 117.08 365.63 113.05 363.8 110.62 363.9 107.69 368.75 108.09 371.75 107.25 Z" id="22156"/>
            <path d="M 334.25 154.97 336.11 151.28 335.86 149.96 337.66 148.27 340.52 147.01 341.29 144.3 343.55 143.64 345.35 145.72 347.97 147.25 349.71 150.94 351.13 153.82 349.71 154.47 349.22 156.98 345.4 159.67 345.31 161.37 343.68 162.87 340.25 163.72 341.69 166.42 338.74 166.08 339.79 162.27 338.41 159.31 339.44 157.98 336.96 155.65 334.25 154.97 Z" id="22328"/>
            <path d="M 404.59 91.67 403.15 94.98 403.76 97.14 405.78 98.74 406.14 101.69 406.03 103.4 404.13 103.79 401.47 101.05 399.67 101.82 394.16 96.07 396.28 94.24 398.46 91.12 400.38 90.54 401.95 91.73 404.59 91.67 Z" id="22150"/>
            <path d="M 398.5 178.1 401.7 175.12 406.36 171.61 410.76 171.4 413.47 172.61 415.62 174.8 413.46 176.43 414.57 178.47 412.02 178.7 410.41 181.89 409.29 182.48 407.41 180.91 407.29 178.29 402.26 179.48 398.5 178.1 Z" id="22047"/>
            <path d="M 306.19 80.79 308.2 79.47 311.46 82.63 314.64 80.73 315.91 82.25 315.55 86.92 317.46 93.06 319.47 95.64 319.94 97.96 317.35 98.76 313.53 98.33 313.16 95.1 310.84 95.02 309.21 93.41 308.42 90.62 309.35 86.26 306.19 80.79 Z" id="22119"/>
            <path d="M 310.79 72.74 313.6 74.34 313.91 76.07 316.55 78.16 314.64 80.73 311.46 82.63 308.2 79.47 307.33 74.56 307.74 73.39 310.79 72.74 Z" id="22235"/>
            <path d="M 301.29 84.24 302.49 82.52 302.44 80.5 303.56 77.9 306.19 80.79 309.35 86.26 308.42 90.62 309.21 93.41 306.17 96.91 304.46 96.68 304.06 94.66 300.77 93.51 298.46 90.71 300.62 87.2 301.29 84.24 Z" id="22238"/>
            <path d="M 317.32 141.71 317.48 136.93 319.03 133.31 324.08 134.25 325.99 133.62 329.93 134.3 332.21 135.92 328.06 138.46 324.92 142.61 319.25 143.62 317.32 141.71 Z" id="22024"/>
            <path d="M 378.5 89.37 379.84 87.42 379.74 85 381.9 84.48 384.88 85.86 388.13 83.68 388.61 84.97 387.39 88.68 386.71 90.92 383.21 89.59 378.5 89.37 Z" id="22358"/>
            <path d="M 412.99 81.81 416.99 79.27 417.95 77.29 420.02 78.67 419.59 80.18 420.77 83.4 418.06 84.26 416.49 85.82 415.19 83.68 413.41 83.86 412.99 81.81 Z" id="22121"/>
            <path d="M 441.54 190.85 442.31 188.97 445.47 188.2 448.18 188.65 448.62 190.88 450.41 192.1 451.89 196.46 451.19 197.37 449.57 199.47 446.78 200.66 444.13 200.78 442.6 201.78 442.46 200.16 441.54 190.85 Z" id="22330"/>
            <path d="M 426.27 197.41 429.54 193.9 434.54 193.59 437.08 192.03 439.09 189.76 441.54 190.85 442.46 200.16 440.12 200.39 437.53 199.06 432.13 200.74 426.24 200.57 426.27 197.41 Z" id="22260"/>
            <path d="M 413.47 172.61 418.55 171.53 420.52 172.41 424.94 171.34 425.66 171.81 426.57 174.72 429.34 175.79 429.76 177.1 428.32 179.16 429.18 181.34 426.69 184.38 425 184.71 421.19 183.24 417.51 179.87 414.57 178.47 413.46 176.43 415.62 174.8 413.47 172.61 Z" id="22313"/>
            <path d="M 150.44 94.96 152.64 92.02 155.13 87.14 157.68 90.29 159.11 90.57 157.41 94.05 158.3 97.78 157.32 98.38 153.88 97.48 150.44 94.96 Z" id="29094"/>
            <path d="M 582.41 233.8 586.28 229.52 588.44 230.17 589.06 233.9 588.3 235.08 586.89 235.49 585.19 233.72 582.41 233.8 Z" id="35026"/>
            <path d="M 450.41 192.1 457.33 188.72 459.32 189.27 458.06 191.26 459.54 196.28 457.13 197.31 451.19 197.37 451.89 196.46 450.41 192.1 Z" id="22068"/>
            <path d="M 426.69 184.38 429.18 181.34 428.32 179.16 429.76 177.1 432.19 176.9 434.43 179.14 436.28 178.22 438.16 182.8 439.21 183.74 441.99 186.73 442.31 188.97 441.54 190.85 439.09 189.76 437.08 192.03 434.54 193.59 429.54 193.9 426.27 197.41 424.61 195.97 422.65 192.78 426.16 191.41 425.14 188.4 426.85 186.97 426.69 184.38 Z" id="22149"/>
            <path d="M 682.55 291.31 683.84 289.84 687.02 289.51 686.78 293.67 688.54 296 687.76 300.84 685.71 300.38 682.86 301.34 682.34 297.12 680.92 296.42 682.55 291.31 Z" id="35322"/>
            <path d="M 620.01 105.05 622.47 101.82 625.06 102.46 621.95 105.94 620.01 105.05 Z" id="35070"/>
            <path d="M 687.02 289.51 687.59 287.28 687.05 285.07 688.65 284.57 689.9 282.51 694.46 286.17 695.47 288.21 695.04 289.21 692.77 291.68 692.43 294.21 688.54 296 686.78 293.67 687.02 289.51 Z" id="35321"/>
            <path d="M 697.42 292.62 698.57 292.06 701.92 293.61 704.17 291.54 708.04 292.58 709.38 295.26 708.24 296.82 712.57 299.64 710.15 301.56 708.33 302.77 706.33 301.44 704.99 302.09 705.44 300.96 700.91 296.88 700.65 295.62 696.76 294.43 697.42 292.62 Z" id="35089"/>
            <path d="M 381.16 31.09 382.63 28.4 390.19 26.01 395.33 23.9 395.64 24.04 393.67 29.53 392.3 30.83 388.96 32.09 389.34 36.63 388.72 37.62 386.44 37.49 383.91 38.63 382.94 40.28 380.91 40.97 379.92 42.9 377.74 42.7 378.41 38.12 380.57 35.64 381.16 31.09 Z" id="22199"/>
            <path d="M 241.9 153.86 242.6 153.54 247.26 155.9 247.12 158.33 250.57 157.79 253.25 160.96 255.88 161.51 260.03 160.73 263.23 163.7 261.43 166.05 258.6 165.92 256.86 167.64 249.6 165.96 248.69 164.21 241.74 162.96 241.12 162.55 242.65 158.91 241.66 157.48 241.9 153.86 Z" id="29018"/>
            <path d="M 366.96 269.64 368.02 268.98 368.08 262.62 367.4 260.52 369.91 258.89 369.83 257.22 372.32 253.11 375.53 255.42 377.49 253.46 378.85 254.93 379.54 258.72 378.56 259.61 378.24 263 377.19 265.43 374.84 267.66 371.98 268.12 370.17 270.51 368.08 271.32 366.96 269.64 Z" id="56156"/>
            <path d="M 372.2 250.16 373.42 248.01 376.09 248.47 377.72 245.7 374.54 245.33 375.19 241.91 376.5 241.09 381.07 241.15 382.64 237.31 384.22 238.82 385.29 243.15 388.16 246.36 384.27 249.37 382.65 251.67 383.19 253.92 381.92 256.35 379.54 258.72 378.85 254.93 377.49 253.46 375.53 255.42 372.32 253.11 372.2 250.16 Z" id="56113"/>
            <path d="M 386.44 332.66 392.21 325.4 392.8 323.26 394.75 322.93 398 325.69 399.33 326.1 399.34 329.47 400.29 331.66 399.98 334.99 397.93 337.45 394.58 336.84 391.58 337 387.27 340.67 386.22 340.78 386.75 337.8 385.41 336.95 386.44 332.66 Z" id="56097"/>
            <path d="M 394.16 363.64 395.17 359.7 397.97 360.56 400.51 356.94 403.18 356.04 405.13 357.43 407.05 361.68 409.51 361.57 409.98 364.05 408.23 370.16 407.21 369.09 402.16 370.59 401.2 369.55 397.17 368.87 394.82 370.27 391.68 367.04 394.35 364.77 394.16 363.64 Z" id="56161"/>
            <path d="M 374.57 362.56 378.84 363.59 379.58 364.72 377.83 367.1 375.04 367.22 374.57 362.56 Z" id="56055"/>
            <path d="M 381.92 256.35 383.19 253.92 382.65 251.67 384.27 249.37 388.16 246.36 391.61 244.95 393.46 248.16 397.63 251.31 399.92 250.69 400.5 252.28 403.35 253.08 404.85 254.91 408.56 254.05 409.69 255.32 411.96 258.54 410.52 258.96 407.47 262.91 405.46 261.23 404.16 262.49 402.45 263.61 397.23 263.55 389.75 264.05 388.4 262.54 386.64 258.27 384.95 256 381.92 256.35 Z" id="56076"/>
            <path d="M 352.59 269.42 356.02 267.61 363.03 266.64 366.96 269.64 368.08 271.32 370.17 270.51 370.5 272.21 373.88 273.76 372.31 275.88 374.21 278.05 375.56 280.85 372.52 284.31 372.3 286.07 373.89 291.3 371.22 290.41 370.65 292.21 365.86 293.59 362.7 292.52 361.45 290.62 362.2 288.35 361.58 283.97 362.3 281.91 360.38 281.44 359.71 277.44 357.6 276.4 356.6 274.04 353.96 275.52 352.14 273 350.05 272.72 352.59 269.42 Z" id="56089"/>
            <path d="M 354.74 255.79 355.47 253.03 359.21 250.31 361.59 252.18 362.01 249.72 368.3 248.11 369.84 249.69 372.2 250.16 372.32 253.11 369.83 257.22 369.91 258.89 367.4 260.52 368.08 262.62 368.02 268.98 366.96 269.64 363.03 266.64 356.02 267.61 354.2 264.34 354.72 259.73 353.63 258.43 354.74 255.79 Z" id="56110"/>
            <path d="M 376.21 342.54 379.11 342.29 381.49 342.19 383.67 345.67 383.1 346.96 384.34 351.46 381.53 353.89 377.8 351.89 374.55 347.21 376.21 342.54 Z" id="56220"/>
            <path d="M 376.18 329.71 379.12 330.58 381.51 329.9 383.53 331.93 386.44 332.66 385.41 336.95 386.75 337.8 386.22 340.78 385.14 342.31 385.98 343.93 383.67 345.67 381.49 342.19 379.11 342.29 378.31 339.09 376.41 336.74 375.97 334.35 376.18 329.71 Z" id="56148"/>
            <path d="M 369.28 322.63 370.34 320.56 370 314.87 373.86 315.34 375.53 312.34 373.55 310.28 373.59 308.51 376.73 305.04 377.32 303.03 375.9 300.9 376.81 299.25 378.56 298.51 381.32 298.93 384.79 297.99 388.72 297.94 390.62 299.79 393.77 299.86 395.36 301.28 398.59 302.2 400.49 302.03 402.56 303.4 402.38 307.01 401.52 310.01 404.03 314.66 403.4 316.09 400.87 315.51 392.59 322.05 392.8 323.26 392.21 325.4 386.44 332.66 383.53 331.93 381.51 329.9 381.54 327.85 380.07 325.64 379.93 323.32 374.3 321.01 373.96 323.14 369.28 322.63 Z" id="56101"/>
            <path d="M 406.6 315.61 408.38 314.57 408.74 310.54 410.76 306.56 412.61 304.85 414.45 305.7 420.42 306.1 422.06 307.33 422.23 309.82 424.54 316.1 423.85 319.35 421.99 320.06 421.24 321.76 416.98 320.58 414.98 323.26 410.95 325.78 407.84 325.76 406.99 322.04 409.25 317.76 406.6 315.61 Z" id="56031"/>
            <path d="M 376.3 293.15 378.18 292.58 380.61 289.41 382.16 289.3 387.73 287.37 389.48 284.94 392.99 281.91 396.48 282.8 399.53 285.35 400.99 285.11 401.01 287.3 398.99 289.96 400.16 292.12 400.52 294.89 400.31 299.96 398.9 300.93 395.78 298.63 393.77 299.86 390.62 299.79 388.72 297.94 384.79 297.99 381.32 298.93 378.56 298.51 378.58 296.35 376.3 293.15 Z" id="56188"/>
            <path d="M 404.03 314.66 406.6 315.61 409.25 317.76 406.99 322.04 407.84 325.76 410.95 325.78 414.98 323.26 416.98 320.58 421.24 321.76 421.99 320.06 423.85 319.35 424.54 316.1 430.23 318.14 428.8 319.1 427.88 322.84 424.03 325.48 425.27 327.99 425.49 331.9 424.05 334.01 425.51 337.37 423.97 338.46 422.24 341.4 417.31 343.31 415.28 341.71 414.52 339.79 412.3 340.75 409.06 340.78 408.07 338.99 404.73 339.29 399.98 334.99 400.29 331.66 399.34 329.47 399.33 326.1 398 325.69 394.75 322.93 392.8 323.26 392.59 322.05 400.87 315.51 403.4 316.09 404.03 314.66 Z" id="56177"/>
            <path d="M 432.31 334 435.39 331.86 435.03 327.62 436.06 324.93 435.04 322.25 439.03 318.78 442.96 319.57 443.99 322.48 447.25 321.89 449.27 325.02 452.71 325.16 453.1 326.51 454.28 330.11 452.45 330.98 452.83 333.27 452.67 339.72 455.52 344.67 455.88 346.93 453.72 347.09 450.61 350.38 449.08 350.54 446.65 348.81 444.76 349.11 442.61 348.16 440.36 348.26 439.04 350.09 436.9 348.79 436.75 347.3 439.84 345.5 438.57 341.95 440.02 337.2 438.3 335.48 435.98 335.49 432.31 334 Z" id="56067"/>
            <path d="M 430.23 318.14 432.51 318.75 439.03 318.78 435.04 322.25 436.06 324.93 435.03 327.62 435.39 331.86 432.31 334 432.8 338.01 430.35 338.88 428.18 337.68 426.23 339.28 423.97 338.46 425.51 337.37 424.05 334.01 425.49 331.9 425.27 327.99 424.03 325.48 427.88 322.84 428.8 319.1 430.23 318.14 Z" id="56022"/>
            <path d="M 374.57 362.56 375.99 360.56 376.78 357.71 381.53 353.89 382.55 354.95 389.33 355.42 391.1 358.21 388.06 361.84 389.01 363.75 389.35 366.08 387.45 366.54 386.28 364.38 382.99 365.09 379.58 364.72 378.84 363.59 374.57 362.56 Z" id="56013"/>
            <path d="M 381.53 353.89 384.34 351.46 383.1 346.96 383.67 345.67 385.98 343.93 387.96 342.9 391.86 343.77 393.3 342.95 395.04 345.95 397.22 346.47 399.68 343.51 401.28 344.43 402.89 345.28 402.21 349.14 405.93 351.15 407.41 355.21 405.13 357.43 403.18 356.04 400.51 356.94 397.97 360.56 395.17 359.7 394.16 363.64 391.66 362.54 389.01 363.75 388.06 361.84 391.1 358.21 389.33 355.42 382.55 354.95 381.53 353.89 Z" id="56119"/>
            <path d="M 386.22 340.78 387.27 340.67 391.58 337 394.58 336.84 397.93 337.45 399.98 334.99 404.73 339.29 403.48 342.42 401.28 344.43 399.68 343.51 397.22 346.47 395.04 345.95 393.3 342.95 391.86 343.77 387.96 342.9 385.98 343.93 385.14 342.31 386.22 340.78 Z" id="56096"/>
            <path d="M 439.04 350.09 440.36 348.26 442.61 348.16 444.76 349.11 446.65 348.81 449.08 350.54 450.61 350.38 453.72 347.09 455.88 346.93 456.31 348.92 455.54 352.35 458.44 354.46 456.76 356.33 454.15 357.73 454.38 359.95 448.07 357.37 443.14 356.91 437.88 356.08 437.87 356.07 439.04 350.09 Z" id="56158"/>
            <path d="M 428.56 366.14 429.98 363.28 429.81 359.9 431.29 358.31 434.27 358.29 436.12 356.4 437.88 356.08 443.14 356.91 442.9 359.39 438.55 365.39 437.8 368.95 436.54 370.45 431.89 372.72 429.33 370.99 429.67 368.22 428.56 366.14 Z" id="56167"/>
            <path d="M 345.64 261.73 348.11 261.43 351.68 259.77 352.93 259.86 353.17 255.88 354.74 255.79 353.63 258.43 354.72 259.73 354.2 264.34 356.02 267.61 352.59 269.42 350.52 266.56 346.91 265.91 345.64 261.73 Z" id="56264"/>
            <path d="M 373.96 323.14 374.3 321.01 379.93 323.32 380.07 325.64 381.54 327.85 381.51 329.9 379.12 330.58 376.18 329.71 376.34 326.78 373.96 323.14 Z" id="56021"/>
            <path d="M 379.54 258.72 381.92 256.35 384.95 256 386.64 258.27 388.4 262.54 389.75 264.05 388.94 264.93 389.53 268.43 387.57 270.54 387.21 276.92 391.2 281.48 392.99 281.91 389.48 284.94 387.73 287.37 382.16 289.3 380.61 289.41 378.18 292.58 376.3 293.15 373.89 291.3 372.3 286.07 372.52 284.31 375.56 280.85 374.21 278.05 372.31 275.88 373.88 273.76 370.5 272.21 370.17 270.51 371.98 268.12 374.84 267.66 377.19 265.43 378.24 263 378.56 259.61 379.54 258.72 Z" id="56026"/>
            <path d="M 393.77 299.86 395.78 298.63 398.9 300.93 400.31 299.96 400.52 294.89 400.16 292.12 404.15 292.15 407.33 292.93 412.65 291.65 412.11 294.33 414.48 295.27 416.95 297.86 419.76 296.92 421.83 299.38 420.34 301.27 420.45 303.43 424.02 302.54 425.39 303.29 424.04 307.69 422.06 307.33 420.42 306.1 414.45 305.7 412.61 304.85 410.76 306.56 408.74 310.54 408.38 314.57 406.6 315.61 404.03 314.66 401.52 310.01 402.38 307.01 402.56 303.4 400.49 302.03 398.59 302.2 395.36 301.28 393.77 299.86 Z" id="56010"/>
            <path d="M 401.28 344.43 403.48 342.42 404.73 339.29 408.07 338.99 409.06 340.78 412.3 340.75 414.52 339.79 415.28 341.71 417.31 343.31 416.8 347.67 417.44 349.65 418.55 351.71 416.95 354.72 418.63 356.36 419.2 358.79 414.19 357.86 412.8 362.04 412.05 360.98 409.51 361.57 407.05 361.68 405.13 357.43 407.41 355.21 405.93 351.15 402.21 349.14 402.89 345.28 401.28 344.43 Z" id="56023"/>
            <path d="M 443.14 356.91 448.07 357.37 454.38 359.95 455.01 363.91 451.99 363.9 450.97 365.07 444.94 367.06 443.8 371.25 441.76 371.99 437.8 368.95 438.55 365.39 442.9 359.39 443.14 356.91 Z" id="56164"/>
            <path d="M 422.06 307.33 424.04 307.69 425.39 303.29 424.02 302.54 425.19 299.65 429.6 297.66 431.87 298.39 433.6 300.28 431.09 307.46 430.57 310.76 430.97 314.87 431.79 316.62 432.51 318.75 430.23 318.14 424.54 316.1 422.23 309.82 422.06 307.33 Z" id="56039"/>
            <path d="M 665.19 259.19 668.54 257.24 670.56 258.63 670.8 263.98 671.53 264.88 668.6 268.26 666.56 268.17 665.7 265.66 664.21 266.19 663.1 263.96 664.04 259.96 665.19 259.19 Z" id="35363"/>
            <path d="M 670.56 258.63 672.61 259.42 677.9 257.97 679.99 258.46 682.98 253.91 683.14 259.1 682.75 262.48 677.89 266.69 676.87 263.93 674.14 261.11 672.16 261.64 671.53 264.88 670.8 263.98 670.56 258.63 Z" id="35266"/>
            <path d="M 400.99 285.11 402.53 281.2 410.31 281.42 412.12 283.43 415.26 283.56 416.31 286.24 413.95 291.15 412.65 291.65 407.33 292.93 404.15 292.15 400.16 292.12 398.99 289.96 401.01 287.3 400.99 285.11 Z" id="56207"/>
            <path d="M 506.41 280.27 513.82 283.71 515.13 281.1 517.5 281.56 520.05 282.6 518.72 285.36 520.68 287.34 521.77 289.93 524.13 289.71 525.4 290.9 523.7 295.12 524.33 298.49 521.61 298.8 519.49 294.98 515.55 292.13 515.12 290.19 513 288.15 508.98 286.17 506.58 285.99 504.49 284.29 504.26 282.58 506.41 280.27 Z" id="56079"/>
            <path d="M 416.03 248.09 417.5 250.32 419.39 251.06 422.62 250.5 423.17 252.76 421.1 255.18 421.12 256.81 420.14 257.88 419.76 260.82 417.99 260.93 416.1 261.28 411.96 258.54 409.69 255.32 411.97 249.53 414.46 249.75 416.03 248.09 Z" id="56246"/>
            <path d="M 525.87 299.35 527.84 297.15 529.58 296.81 532.47 298.06 532.19 299.84 528.09 300.45 525.87 299.35 Z" id="56139"/>
            <path d="M 389.75 264.05 397.23 263.55 402.45 263.61 404.16 262.49 402.86 264.64 403.01 267.91 401.04 270.17 403.47 274.06 404.72 277.11 402.53 281.2 400.99 285.11 399.53 285.35 396.48 282.8 392.99 281.91 391.2 281.48 387.21 276.92 387.57 270.54 389.53 268.43 388.94 264.93 389.75 264.05 Z" id="56128"/>
            <path d="M 417.99 260.93 419.76 260.82 420.14 257.88 421.12 256.81 422.83 254.35 425.78 252.01 429.63 255.14 430.61 258.26 433.08 260.22 431.83 263.93 432.61 265.19 431.65 268.35 428.92 268.24 426.42 267.05 424.4 267.47 422.51 265.24 419.91 264.57 417.99 260.93 Z" id="56237"/>
            <path d="M 412.65 291.65 413.95 291.15 416.31 286.24 420.29 285.77 422.57 284.31 423.57 286 423.36 289.02 425.71 288.37 428.6 289.82 429.68 292.22 431.23 293.84 429.6 297.66 425.19 299.65 424.02 302.54 420.45 303.43 420.34 301.27 421.83 299.38 419.76 296.92 416.95 297.86 414.48 295.27 412.11 294.33 412.65 291.65 Z" id="56074"/>
            <path d="M 522.7 264.72 523.26 260.84 527.22 260.83 529.54 257.85 533.59 257.48 534.6 261.54 536.62 262.42 537.58 264.2 540.54 267.25 547.48 267.34 550.01 268.95 548.28 271.68 542.36 273.19 541.03 277.58 539.13 276.9 536.53 278.5 532.98 275.91 530.32 275.32 527.85 273.02 527.48 269.68 525.62 268.16 523.66 268.22 522.7 264.72 Z" id="56122"/>
            <path d="M 541.74 281.83 543.03 284.07 541.22 285.46 537.53 285.62 536.22 284.27 536.77 281.66 541.74 281.83 Z" id="56065"/>
            <path d="M 516.9 269.27 519.19 266.2 522.7 264.72 523.66 268.22 525.62 268.16 527.48 269.68 527.85 273.02 530.32 275.32 528.93 277.65 528.41 282.9 527.56 284.51 525.34 285.02 524.09 286.95 524.13 289.71 521.77 289.93 520.68 287.34 518.72 285.36 520.05 282.6 517.5 281.56 519.8 279.82 519.71 278.51 517.07 275.01 516.9 269.27 Z" id="56249"/>
            <path d="M 404.16 262.49 405.46 261.23 407.47 262.91 410.52 258.96 411.96 258.54 416.1 261.28 417.99 260.93 419.91 264.57 422.51 265.24 424.4 267.47 426.42 267.05 428.92 268.24 428.04 269.85 429.31 273.08 431.63 274.18 431.1 277.48 432.67 281.47 432.08 283.75 429.72 284.5 425.71 288.37 423.36 289.02 423.57 286 422.57 284.31 420.29 285.77 416.31 286.24 415.26 283.56 412.12 283.43 410.31 281.42 402.53 281.2 404.72 277.11 403.47 274.06 401.04 270.17 403.01 267.91 402.86 264.64 404.16 262.49 Z" id="56173"/>
            <path d="M 425.71 288.37 429.72 284.5 432.08 283.75 432.67 281.47 431.1 277.48 431.63 274.18 429.31 273.08 428.04 269.85 428.92 268.24 431.65 268.35 432.61 265.19 434.7 265.29 435.87 263.36 435.95 259.06 438.07 259.47 444.86 260.03 445.55 258.81 450.15 258.44 453.15 255.71 453.94 258.83 454.94 259.27 456.12 262.71 453.25 264.14 451.34 266.07 450.83 268.96 452.28 273.76 450.38 276.26 447.39 275.57 443.06 279.66 442.26 283.8 443.08 285.99 441.55 290.02 435.97 290.3 432.59 292.87 429.68 292.22 428.6 289.82 425.71 288.37 Z" id="56144"/>
            <path d="M 513.89 298.72 518.39 296.68 519.49 294.98 521.61 298.8 524.33 298.49 525.87 299.35 528.09 300.45 532.19 299.84 532.47 298.06 536.23 297.08 535.91 299.45 536.51 302.37 538.3 302.47 539.12 304.57 535.24 307.45 533.34 306.8 532.16 305.19 529.33 305.14 528.71 308.19 527.56 309 526.15 312.67 520.2 308.89 518.89 306.5 515.43 307.72 515.05 301.44 513.89 298.72 Z" id="56197"/>
            <path d="M 530.32 275.32 532.98 275.91 536.53 278.5 539.13 276.9 541.03 277.58 540.83 280.02 541.74 281.83 536.77 281.66 536.22 284.27 537.53 285.62 541.22 285.46 543.03 284.07 543.15 286.92 546.93 287.25 548.03 288.49 547.48 292.39 544.84 294.26 544.71 296.05 543.85 299.09 542.5 300.56 540.41 304.77 539.12 304.57 538.3 302.47 536.51 302.37 535.91 299.45 536.23 297.08 532.47 298.06 529.58 296.81 527.84 297.15 525.87 299.35 524.33 298.49 523.7 295.12 525.4 290.9 524.13 289.71 524.09 286.95 525.34 285.02 527.56 284.51 528.41 282.9 528.93 277.65 530.32 275.32 Z" id="56165"/>
            <path d="M 714.22 238.51 716.43 238.02 718.31 238.72 721.6 241.11 724.6 241.66 727.05 239.93 729.4 240.85 729.93 242.84 731.74 242.84 733.15 246.29 731.61 247.79 730.24 250.98 729.57 254.78 727.02 253.24 723.03 251.97 721.17 255.38 718.45 255.84 716.98 255.12 719.07 253.59 717.43 245.58 715.56 244.77 714.27 243.05 715.1 240.38 714.22 238.51 Z" id="35096"/>
            <path d="M 442.69 110.64 444.08 110.46 448.66 111.36 449.46 112.46 448.66 115.3 445.58 116.64 444.22 115.68 440.97 116.97 440.6 114.24 440.49 111.93 442.69 110.64 Z" id="22372"/>
            <path d="M 586.88 133.19 589.24 129.51 588.44 128.51 589.53 125.62 592.47 123.42 592.6 125.3 596.26 129.16 597.63 132.34 596.02 133.52 594.39 137.1 591.82 137.82 589.15 136.5 586.88 133.19 Z" id="22259"/>
            <path d="M 589.64 118.33 590.86 119.56 593.42 117.61 596.75 118.8 597.57 116.98 599.67 118.04 602 117.63 603.77 120.1 600.25 122.27 600.51 125.29 604.65 125.33 607.94 126.64 606.54 129.42 604.54 129.71 602.52 132.1 601.96 130.29 597.63 132.34 596.26 129.16 592.6 125.3 592.47 123.42 589.37 120.13 589.64 118.33 Z" id="22339"/>
            <path d="M 602.52 132.1 604.54 129.71 606.54 129.42 607.94 126.64 608.71 128.72 611.11 130.43 611.39 134.59 615.75 135.78 611.63 137.13 608.74 136.75 605.91 141.41 604.12 141.66 602.07 141.25 601.02 136.87 602.52 132.1 Z" id="22118"/>
            <path d="M 280.61 189.29 281.27 186.15 283.77 180.25 287.86 181.23 293.09 177.74 296.81 177.54 297.29 181.5 299.07 183.75 298.4 184.85 299.16 187.72 297.8 189.71 296.1 188.2 294.08 189.2 292.22 188.62 289.73 189.38 288.83 191.35 285.6 190.13 284.3 190.56 280.61 189.29 Z" id="29089"/>
            <path d="M 261.32 171.2 263.85 168.81 261.43 166.05 263.23 163.7 266 165.48 270.49 164.9 272.7 165.8 275.85 165.55 281.41 171.18 283.53 172.69 283.86 174.15 281.36 176.28 281.07 177.88 283.77 180.25 281.27 186.15 280 184.16 278.26 183.68 277.53 181.07 275.33 179.96 274.9 177.79 272.74 177.35 269.58 178.33 265.67 174.87 262.2 173.03 261.32 171.2 Z" id="29211"/>
            <path d="M 710.22 257.32 713.56 255.68 716.98 255.12 718.45 255.84 721.17 255.38 723.03 251.97 727.02 253.24 729.57 254.78 733.32 254.95 735.91 256.25 736.05 258.09 733.27 258.64 729.33 256.49 728.92 259.15 725.31 259.46 726 261.35 729.19 264.45 727.92 265.92 724.06 269.32 724.33 271.75 721.53 271.54 717.23 273.84 716.78 273.7 713.98 269.93 712.61 268.62 712.35 265.14 710.51 259.88 710.22 257.32 Z" id="35220"/>



        </g>
    </svg>
</template>

<script>
    export default {
        props: {
            communesId: {
                default: null
            },
            width: {
                type: [Number, String],
                default: 20
            },
            height: {
                type: [Number, String],
                default: 20
            },
        },
        methods: {
            // Style features
            styleBv (communesId) {
                // Clean previous selected features
                const featuresSelected = this.$el.getElementsByClassName("selected-features");
                if (featuresSelected.length > 0) {
                    featuresSelected.forEach(feature => {
                        feature.classList.remove("selected-features")
                    });
                };
                // Style selected features
                if(communesId) {
                    this.$el.getElementById(communesId.toString())
                            .classList.add("selected-features");
                }
            }
        },
        mounted () {
            this.styleBv(this.communesId);
        },
        watch: {
            communesId () {
                this.styleBv(this.communesId);
            }
        }   
    }
</script>

<style scoped>
    .default-features {
        fill: #F7CF0F;
        stroke: #F7CF0F;
        stroke-width: 1px;
    }
    .selected-features {
        fill: #FF380D;
        stroke: #FF380D;
    }
</style>