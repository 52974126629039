<template>

        <div class="icons-grid">

            <!-- Title  -->
            <div class="header">
                <h5> {{ name }} </h5>
                <!-- Year input select  -->
                <b-form-select 
                    :options="yearsOptions"
                    :value="yearSelected"
                    @change="setYearSelected($event)"
                ></b-form-select>
            </div>
            
            <!-- Overview Map  -->
            <div class="fiche-btn" v-if="featureDataTerYear.lien_fiche">
                <b-button 
                    :href="featureDataTerYear.lien_fiche" 
                    target="_blank"
                    variant="secondary"
                    size="sm"
                >
                    <i class="fas fa-file-pdf"></i> Télécharger la<br>fiche observatoire
                </b-button>
            </div>
            <div class="icon-map">
                <map-epci 
                    :epciId="featureId" 
                    height="160" 
                    width="200"
                    v-if="layer=='EPCI'"
                ></map-epci>
                <map-bv 
                    :bvId="featureId"
                    height="160" 
                    width="200"
                    v-if="layer=='Bassin Versant'"
                ></map-bv>
                <map-communes 
                    :communesId="featureId" 
                    height="160" 
                    width="200"
                    v-if="layer=='Commune'"
                ></map-communes>
                <map-departements
                    :departementsId="featureId" 
                    height="160" 
                    width="200"
                    v-if="layer=='département'"
                ></map-departements>
                <map-regions
                    :regionsId="featureId" 
                    height="160" 
                    width="200"
                    v-if="layer=='Région'"
                ></map-regions>
            </div>

            <!-- Icons with key indicators  -->
            <icon-ferme-bio-nb 
                :nbFerme="featureDataTerYear.nb_ferme" 
                class="icon-fermesNb"
                width=110
                height=100
            ></icon-ferme-bio-nb>

            <icon-fermes-bio-nb-pourcentage 
                :pourcentage="featureDataTerYear.ferme_pourcent" 
                class="icon-fermesPour"
                width=200
                height=100
            ></icon-fermes-bio-nb-pourcentage>

            <icon-fermes-bio-nouvelles 
                :nbFermes="featureDataTerYear.nb_nx" 
                class="icon-fermesNew"
                width=200
                height=60
            ></icon-fermes-bio-nouvelles>

            <icon-fermes-bio-arrets 
                :nbFermes="featureDataTerYear.nb_cess" 
                class="icon-fermesStop"
                width=200
                height=60
            ></icon-fermes-bio-arrets>

            <icon-fermes-bio-surfaces 
                :surface="featureDataTerYear.surf_bio" 
                :surfaceConversion="featureDataTerYear.surf_conv" 
                class="icon-sau"
                width=200
                height=100
            ></icon-fermes-bio-surfaces>

            <icon-sau-bio-pourcentage 
                :pourcentage="featureDataTerYear.surf_pourcent" 
                class="icon-sauPour"
                width=200
                height=100
            ></icon-sau-bio-pourcentage>

            <icon-evo-nb 
                :evoNb="featureDataTerYear.evoNbFermes" 
                class="icon-nbEvo"
                width=160
                height=90
            ></icon-evo-nb>

            <icon-evo-sau 
                :evoSau="featureDataTerYear.evoSauBio" 
                class="icon-sauEvo"
                width=160
                height=90
            ></icon-evo-sau>

            <icon-fermes-communes-pourcentage 
                :pourcentage="featureDataTerYear.commune_pourcent"
                v-if="layer != 'Commune'"
                class="icon-fermes-communes"
                width=100
                height=120
            ></icon-fermes-communes-pourcentage>

            <div class="info">
                <p v-if="featureDataTerYear.nb_cetis > 0">
                    Dans {{ layerLabel }}, les données de {{ featureDataTerYear.nb_cetis }} 
                    ferme{{ featureDataTerYear.nb_cetis > 1 ? "s" : "" }} sont manquantes.
                </p>
                <p v-if="featureDataTerYear.surf_bio == -1">
                    Information sur les surfaces non disponibles 
                    en raison du secret statistique.
                </p>
            </div>
        </div>

</template>

<script>

    // Vuex
    import { mapGetters, mapActions } from 'vuex';

    // Import components
    import IconFermeBioNb from "./icons/IconFermeBioNb";
    import IconFermesBioNouvelles from "./icons/IconFermesBioNouvelles";
    import IconFermesBioArrets from "./icons/IconFermesBioArrets";
    import IconFermesBioSurfaces from "./icons/IconFermesBioSurfaces";
    import IconFermesBioNbPourcentage from "./icons/IconFermesBioNbPourcentage";
    import IconSauBioPourcentage from "./icons/IconSauBioPourcentage";
    import IconEvoSau from "./icons/IconEvoSau";
    import IconEvoNb from "./icons/IconEvoNb";
    import IconFermesCommunesPourcentage from "./icons/IconFermesCommunesPourcentage";
    import MapEpci  from "./map/MapEpci";
    import MapBv from "./map/MapBv";
    import MapCommunes from "./map/MapCommunes";
    import MapDepartements from "./map/MapDepartements";
    import MapRegions from "./map/MapRegions";

    export default {
        // Component name
        name: "KeyIndicators",

        // Components
        components: {
            MapEpci,
            MapBv,
            MapCommunes,
            MapDepartements,
            MapRegions,
            IconFermeBioNb,
            IconFermesBioNouvelles,
            IconFermesBioArrets,
            IconFermesBioSurfaces,
            IconFermesBioNbPourcentage,
            IconSauBioPourcentage,
            IconFermesCommunesPourcentage,
            IconEvoSau,
            IconEvoNb
        },

        computed: {
            ...mapGetters([
                "featureId",
                "layer",
                "frabData",
                "yearsList",
                "yearSelected"
            ]),
            layerLabel () {
                return this.layer == "Région" ? "cette région" :
                       this.layer == "Bassin Versant" ? "ce bassin versant" :
                       this.layer == "département" ? "ce département" :
                       this.layer == "EPCI" ? "cet EPCI" :
                       this.layer == "Commune" ? "cette commune" : "";

            },
            // Set options for year selection
            yearsOptions () {
                return this.yearsList.slice(0, -1).map(year => ({value: year, text: year}));
            },
            // Data of the feature selected
            featureDataTer () {
                return this.frabData.filter(x => String(x.ter_id) == String(this.featureId));
            },
            // Data of the feature selected of the target year
            featureDataTerYear () {
                return this.featureDataTer.find(x => x.annee == this.yearSelected);
            },
            // Name of the feature
            name () {
                if (this.featureDataTer[0] && this.featureDataTer[0].lib) {
                    return this.featureDataTer[0].lib
                }
            },
        },

        methods: {
            ...mapActions([
                "setYearSelected"
            ]),
        }
    }

</script>

<style scoped>

    .icons-grid {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: 50px 160px repeat(3, 100px);
        row-gap: 15px;
        /* grid-auto-flow: row; */
        grid-template-areas: 
            "header     header     header     header      header      header"
            "fiche-btn  fiche-btn  map        map         fermes-com  fermes-com"
            "fermes-nb  fermes-nb  nb-evo     nb-evo      fermes-pour fermes-pour"
            "fermes-new fermes-new fermes-new fermes-stop fermes-stop fermes-stop"
            "sau        sau        sau-evo    sau-evo     sau-pour    sau-pour"
            "info       info       info       info        info        info"
    }
    .header {
        grid-area: header;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #e9531b;
        color: white;
        width: 100%;
        padding: 10px;
    }
    .header >>> h5 {
        padding-left: 10px;
        margin: 0 !important;
    }
    .header >>> select {
        margin-left: 10px;
        font-weight: 700;
        width: 100px;
    }
    .fiche-btn {
        grid-area: fiche-btn;
        align-self: start;
        justify-self: start;
        margin-left: 20px;
    }
    .fiche-btn >>> .btn {
        background-color: #1bad87;
        border-color: #1bad87;
    }
    .icon-map {
        grid-area: map;
        align-self: center;
        place-self: center;
    }
    .icon-fermes-communes {
        grid-area: fermes-com;
    }
    .icon-fermesNb {
        grid-area: fermes-nb;
    }
    .icon-nbEvo {
        grid-area: nb-evo;
    }
    .icon-fermesPour {
        grid-area: fermes-pour;
    }
    .icon-fermesNew {
        grid-area: fermes-new;
    }
    .icon-fermesStop {
        grid-area: fermes-stop;
    }
    .icon-sau {
        grid-area: sau;
    }
    .icon-sauEvo {
        grid-area: sau-evo;
    }
    .icon-sauPour {
        grid-area: sau-pour;
    }
    .info {
        grid-area: info;
    }
    .info >>> p {
        font-size: 0.8em;
        font-style: italic;
        margin: 0;
        padding: 0;
    }
</style>