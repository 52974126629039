<template>

    <svg xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 60 80"
        role="presentation"
    >
        <g 
            id="fermeBio"
            transform="translate(8, 0)"
        >
            <path style="fill:#4d4d4d"
                d="M 19.055421,6.2663462 V 25.081543 l 18.817402,-0.0066 c -0.831234,8.734564 -8.21091,15.591688 -17.160448,15.591688 -9.506266,0 -17.2398218,-7.734659 -17.2398218,-17.239823 0,-8.94623 6.8516108,-16.3237013 15.5828678,-17.1604468 M 20.709068,4.5344265 C 10.275657,4.5355289 1.8189061,12.995587 1.8189061,23.426793 c 0,10.434514 8.4578539,18.89347 18.8934689,18.89347 10.434514,0 18.89347,-8.458956 18.89347,-18.89347 H 20.709068 Z" />
            <path style="fill:#f9cd03"
                d="M 22.7018,0.98651293 V 21.533578 h 20.547065 v -0.826823 -0.06098 C 43.227655,13.678737 39.59222,7.5671349 34.121775,4.0757279 l -0.105937,-0.105937 -0.02325,0.02325 C 30.960427,2.0908569 27.376389,0.98690193 23.53224,0.98651293 Z m 1.653646,1.67431497 c 0.0175,7.86e-4 0.03471,0.0028 0.05219,0.0036 l -0.05219,0.05168 z m 1.425236,0.124024 c 1.070568,0.133127 2.111338,0.360148 3.115056,0.672311 l -4.540292,4.54029 v -3.787365 z m 4.225581,1.055232 c 0.970984,0.373401 1.902,0.827479 2.783811,1.355474 L 24.355446,13.630186 V 9.4914199 Z m 3.692282,1.940967 c 0.792998,0.541708 1.543039,1.142021 2.238623,1.799889 L 24.355446,19.162663 V 15.12415 Z m 2.985346,2.547131 c 0.631492,0.670951 1.208847,1.392579 1.732711,2.1538751 l -9.397875,9.397875 h -3.887102 z m 2.301669,3.0308231 c 0.52841,0.871802 0.983755,1.79211 1.360641,2.752288 l -5.768639,5.768639 h -4.113445 z m 1.752864,3.853513 c 0.342451,1.073518 0.585722,2.190928 0.722437,3.341397 l -1.326017,1.326017 H 36.07101 Z" />
        </g>
        <text
            class="textBig"
            x="30"
            y="56"
        >
            {{ pourcentage.toFixed(1) }} %
        </text>
        <text>
            <tspan class="textComments" x="30" y="68">DES FERMES</tspan>
            <tspan class="textComments" x="30" y="78">DU TERRIOIRE</tspan>
        </text>

    </svg>
</template>

<script>
    export default {
        props: {
            pourcentage: {
                type: Number,
                default: null
            },
            width: {
                type: [Number, String],
                default: 60
            },
            height: {
                type: [Number, String],
                default: 80
            },
        }     
    }
</script>

<style scoped>
    .textBig {
        font-size: 13px;
        font-weight: 900;
        text-align:center;
        text-anchor:middle;
        fill:#F7CF0F
    }
    .textComments {
        font-size: 8px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        text-anchor: middle;
        fill:#313131
    }
</style>