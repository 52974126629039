<template>
    <div>
        <!-- Data loader info -->
        <b-overlay
          id="overlay-background"
          :show="showLoader"
          variant="white"
          opacity=0.80
          rounded="sm"
        >

            <div id="app" class="grid-container">

                <!-- Main map  -->
                <div class="grid-map">
                    <the-map></the-map>
                </div>


                <transition name="fade">
                    <!-- Information  -->
                    <div class="grid-summary" v-if="featureId">
                        <!-- Indicator  -->
                        <the-indicators></the-indicators>
                        <!-- Graph  -->
                        <the-plot class="graph"></the-plot>
                    </div>

                    <!-- Unselected placeholder  -->
                    <div class="grid-summary-placeholder p-4" v-if="!featureId">
                            <p class="font-italic mn-2">
                                Cliquez sur la carte pour obtenir plus d'information sur les territoires
                            </p>
                            <!-- Skeleton -->
                            <div>
                                <b-skeleton animation="wave" width="85%"></b-skeleton>
                                <b-skeleton animation="wave" width="55%"></b-skeleton>
                                <b-skeleton animation="wave" width="70%"></b-skeleton>
                            </div>
                            <div class="mt-3">
                                <b-skeleton-img no-aspect height="150px"></b-skeleton-img>
                            </div> 
                    </div>
                </transition>
            </div>


            <!-- Custom Overlay elements  -->
            <template #overlay>
                <div class="d-flex justify-content-center">
                    <b-spinner label="Loading..."></b-spinner>
                    <strong class="ml-3">Chargement des données...</strong>
                </div>
            </template>
        </b-overlay>

    </div>


    
</template>

<script>

    // Vuex
    import { mapGetters, mapActions } from 'vuex';

    // Axios
    const axios = require('axios').default;

    import TheMap from "./components/TheMap"
    import TheIndicators from "./components/TheIndicators";
    import ThePlot from "./components/ThePlot";


    export default {

        // Component name
        name: 'App',

        // Register external components
        components: {
            TheMap,
            TheIndicators,
            ThePlot,
        },

        // Data
        data () {
            return {
                showLoader: false
            }
        },

        // Computed values
        computed: {
            ...mapGetters([
                "featureId",
                "layerId",
                "yearsList"
            ])
        },

        // Methods
        methods: {
            ...mapActions([
                "loadFrabData"
            ]),
        },

        // On creation
        async created () {
            // Load data from database
            this.showLoader = true;
            await this.loadFrabData();
            this.showLoader = false;
        },
    }
</script>

<style lang="scss">
    // Default style
    @import "./assets/styles/custom.scss";

    .spinner-container {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 10;
    }
    // Vue Transition
    .fade-enter-active, .fade-leave-active {
        transition: opacity .4s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

</style>