import Vue from "vue"
import App from "./App"

import { BootstrapVue, IconsPlugin } from "bootstrap-vue"

// Import Bootstrap an BootstrapVue CSS files and fontawesome
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";


// Import Vuex store file
import { store } from "./store/store";

// Use Bootstrap
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

// Initiate Vue
new Vue({
  el: "#app",         // Target DOM
  store: store,       // Vuex Store
  render: h => h(App)
});
