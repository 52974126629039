<template>
    <svg xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 60 70"
        role="presentation"
    >
    <g style="fill:#4d4d4d" id="fields">
      <path
         d="m 53.513296,20.906418 c 0.418,0 0.757,-0.34 0.757,-0.758 0,-0.418 -0.339,-0.757 -0.757,-0.757 h -27.271 l -2.237,-2.527 h 29.508 c 0.418,0 0.757,-0.34 0.757,-0.758 0,-0.418 -0.339,-0.757 -0.757,-0.757 H 1.6622967 c -0.418,0 -0.75699997,0.339 -0.75699997,0.757 0,0.418 0.33899997,0.758 0.75699997,0.758 h 0.844 v 12.411001 c 0,0.418 0.34,0.758 0.758,0.758 0.418,0 0.757,-0.34 0.757,-0.758 V 16.864418 h 1.069 l 2.379,19.751 c 0.047,0.385 0.374,0.668 0.752,0.668 0.03,0 0.06,-0.002 0.091,-0.007 0.416,-0.049 0.712,-0.426 0.662,-0.842 l -2.359,-19.57 h 1.44 l 6.8410003,23.159 c 0.097,0.33 0.399,0.543 0.726,0.543 0.071,0 0.144,-0.01 0.215,-0.031 0.401,-0.119 0.631,-0.541 0.511,-0.942 l -6.7140003,-22.729 h 1.3770003 l 10.849999,24.588 c 0.126,0.283 0.403,0.452 0.694,0.452 0.102,0 0.205,-0.02 0.305,-0.065 0.383,-0.169 0.556,-0.616 0.387,-0.999 l -10.578999,-23.976 h 1.694 l 15.123999,24.558 c 0.144,0.232 0.392,0.36 0.646,0.36 0.136,0 0.273,-0.036 0.397,-0.113 0.356,-0.219 0.467,-0.685 0.248,-1.042 l -14.635999,-23.763 h 2.002999 l 18.191,24.37 c 0.149,0.2 0.377,0.305 0.608,0.305 0.157,0 0.316,-0.049 0.453,-0.151 0.335,-0.25 0.403,-0.725 0.153,-1.06 l -17.514,-23.464 h 1.947 l 20.109,22.717 c 0.15,0.168 0.359,0.255 0.568,0.255 0.178,0 0.357,-0.062 0.502,-0.191 0.313,-0.277 0.342,-0.756 0.065,-1.068 l -2.575,-2.909 h 12.862 c 0.418,0 0.757,-0.34 0.757,-0.758 0,-0.418 -0.339,-0.756999 -0.757,-0.756999 h -14.157 c -0.014,0 -0.026,0.007 -0.04,0.008 l -1.932,-2.184 h 16.129 c 0.418,0 0.757,-0.339 0.757,-0.757 0,-0.418 -0.339,-0.758 -0.757,-0.758 h -17.416 c -0.016,0 -0.03,0.008 -0.046,0.009 l -1.934,-2.184 h 19.396 c 0.418,0 0.757,-0.34 0.757,-0.758 0,-0.419001 -0.339,-0.757001 -0.757,-0.757001 h -20.523 c -0.065,0 -0.122,0.021 -0.182,0.036 l -1.958,-2.212 h 22.663 c 0.418,0 0.757,-0.338 0.757,-0.757 0,-0.418 -0.339,-0.758 -0.757,-0.758 h -24.004 l -1.925,-2.175 z"/>
    </g>
        <text
            class="textBig"
            id="surface"
            x="26"
            y="13"
        >
            {{ formatNumber(surface) }}
        </text>
        <text>
            <tspan class="textComments" x="8" y="50">HECTARES</tspan>
            <tspan class="textNormal" x="8" y="60">
                dont
                <tspan class="textBoldRed">{{ formatNumber(surfaceConversion) }} ha</tspan>
            </tspan>
            
            <tspan class="textNormal" x="8" y="69">en conversion</tspan>
        </text>
        <!-- Mask  -->
        <rect width="60" height="70" style="fill:rgba(243, 242, 242, 0.8)" v-if="surface == -1"/>
    </svg>
</template>

<script>
    export default {
        props: {
            surface: {
                type: Number,
                default: null
            },
            surfaceConversion: {
                type: Number,
                default: null
            },
            width: {
                type: [Number, String],
                default: 60
            },
            height: {
                type: [Number, String],
                default: 70
            },
        },
        methods: {
            formatNumber (value) {
                if (value == null || value == -1) {
                    return "NA"
                } else {
                    return value.toFixed(0);
                }

            }
        },
    }
</script>

<style scoped>
    .textBig {
        font-size: 15px;
        font-weight: 900;
        text-align:center;
        text-anchor:middle;
        fill:#F7CF0F
    }
    .textComments {
        font-size: 8px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        text-anchor: start;
        fill:#313131
    }
    .textNormal {
        font-size: 8px;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        text-anchor: start;
        fill:#313131
    }
    .textBoldRed {
        font-size: 8px;
        font-weight: 900;
        font-style: normal;
        text-align: center;
        text-anchor: start;
        fill:#e9531b
    }
</style>