<template>
    <svg xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 800 528"
        role="presentation"
    >
        <g id="epci_bzh" class=default-features>
            <path d="M 37.55 68.52 40.67 80.59 38.81 89.97 53.76 91.46 58.48 101.74 64.75 104.89 64.58 108.88 55.94 112.95 51.62 110.92 35.21 119.65 34.41 129.64 37.11 137.46 35.54 143.7 23.57 136.94 19.15 142.39 13.79 144.28 2.97 143.07 2.17 135.27 7.01 129.79 4.95 118.62 1 115.69 9.2 99.67 7.56 87.3 15.56 74.64 23.91 72.57 29.51 67.82 37.55 68.52 Z" id="242900074"/>
            <path d="M 644.29 105.49 647.81 106.52 671.26 105.79 691.34 101.72 698.02 111.01 696.37 114.56 701.32 121.53 697.71 126.56 705.27 140.94 706.07 146.61 695.06 148.13 689.32 147.15 678.64 152.46 670.3 151.13 664.71 155.5 658.31 153.28 655.12 142.59 641.59 146.92 638.75 140.95 639.8 132.54 628.73 120.34 639.71 119.66 644.48 115.98 644.29 105.49 Z" id="200070670"/>
            <path d="M 100.36 124.15 94.31 110.93 99.18 108.58 98.73 97.25 105.09 104.23 110.92 105.75 109.86 97.52 121.16 91.04 126.71 101.88 139.26 104.77 147.03 104.99 151.87 119.33 148.06 124.33 148.61 138.52 151.99 146.67 157.82 148.97 156.18 160.07 151.79 163.58 140.38 158.04 135.02 162.59 125.62 165.3 118.05 164.95 115.62 161.79 101.07 156.73 103.76 148.39 100.83 142.19 98.11 139.63 95.65 128.84 100.36 124.15 Z" id="242900801"/>
            <path d="M 189.13 74.12 191.37 77.84 188.08 87.36 179.12 100.06 176.22 109.91 176.21 118.69 183.18 125.1 187.26 136.96 179.91 143.84 176.63 150.07 168.97 147.01 157.82 148.97 151.99 146.67 148.61 138.52 148.06 124.33 151.87 119.33 147.03 104.99 139.26 104.77 126.71 101.88 121.16 91.04 123.83 85.77 122.34 78.09 135.52 83.3 139.16 78.71 139.18 71.05 144.73 63.64 150.57 62.2 159.87 65.03 162.51 70.43 169.05 66.72 184.93 78.62 189.13 74.12 Z" id="242900751"/>
            <path d="M 98.73 97.25 99.18 108.58 94.31 110.93 86.71 107.68 75.77 109.97 70.06 105.3 64.58 108.88 64.75 104.89 58.48 101.74 53.76 91.46 38.81 89.97 40.67 80.59 37.55 68.52 44.8 68.98 57.71 53.97 78.14 54.71 80.92 61.8 85.85 66.97 79.59 72.79 90.07 78.53 96.38 86.45 98.73 97.25 Z" id="242900553"/>
            <path d="M 677.91 189.25 689.93 189.8 693.21 185.78 706.18 187.59 708.21 190.68 705.44 200.16 704.18 212.69 697.61 211.02 695.51 216.13 680.25 222.43 679.4 233.48 677.55 238.37 671.74 235.67 673.71 224.24 669.15 221.94 664.96 235.94 656.86 240.85 649.31 238.64 638.56 239.47 642.16 229.28 636.25 221.01 627.74 220.64 626.59 213.21 629.53 206.65 634.73 204.3 642.61 210.82 647.71 204.33 649.3 196.94 657.97 196.89 662.94 199.23 666.6 191.02 677.91 189.25 Z" id="243500667"/>
            <path d="M 735.81 224.52 741.73 222.32 751.33 225.46 756.33 219.86 764.09 226.82 773.87 225.53 780.13 227.41 785.44 221.93 785.28 227.38 789.69 243.58 788.07 251.93 791.96 260.25 793.23 279.5 798.91 286.97 795.48 297.85 799 306.15 797.46 310.61 786.72 314.03 779.06 311.21 773.05 317.71 768.21 318.69 762.83 339.89 758.3 342.26 759.57 349.46 754.21 352.33 747.88 346.97 738.41 349.49 734.23 344.17 737.4 338.92 736.97 331.92 743.54 329.01 732.58 322.18 732.93 315.19 719.9 304.41 718.07 299.3 724.3 293.07 712.17 286.93 706.13 278.4 711.89 268.28 710.39 259.77 718.06 254.34 729.83 249.81 728.26 246.28 733.16 237.79 740.89 231.82 735.81 224.52 Z" id="200039022"/>
            <path d="M 610.03 223.61 612.72 217.41 602.06 209.33 602.8 204.46 610.08 199.8 613.42 203.64 613.11 211.6 629.53 206.65 626.59 213.21 627.74 220.64 636.25 221.01 642.16 229.28 638.56 239.47 649.31 238.64 656.86 240.85 664.96 235.94 669.15 221.94 673.71 224.24 671.74 235.67 677.55 238.37 679.4 233.48 684.27 228.63 687.01 234.1 681.56 239.72 677.22 238.91 673.76 244.36 677.46 247.97 687.32 250.18 693.13 247.81 702.47 249.99 704.55 255.1 700.39 258.05 700.1 264.17 703.73 271.96 693.14 267.75 691.97 260.72 686.54 263.76 680.85 281.74 693.91 296.09 689.79 296.94 689.49 305.05 686.24 311.3 679.51 315.82 671.53 316.36 668.93 309.59 663.68 316.94 654.15 321.16 648.16 321.05 640.47 315.73 641.79 311.54 637.71 299.05 636.72 290.91 631.84 285.51 626.87 284.04 627.79 280.21 619.86 275.44 599.15 280.88 598.57 275.02 602.85 271.91 605.82 278.82 614.83 276.11 609.08 267.41 607.94 260.61 612.98 255.1 618.89 238.71 608.45 229.22 610.03 223.61 Z" id="243500139"/>
            <path d="M 524.32 455.35 519.96 459.06 519.4 465.98 515 470.04 516.9 478.07 510.73 483.84 509.54 492.31 505.78 499.14 496.31 506.58 494.94 510.87 507.99 517.68 504.07 524.29 499.91 526.15 489.75 521.57 481.37 522.22 476.6 527.23 472.36 523.66 454.27 515.77 463.78 509.04 463.72 499.15 458.84 491.86 458.32 486.65 479.37 470.11 472.19 468.45 468.32 463.15 480.32 452.03 486.16 452.25 495.79 452.14 502.54 446.92 510.76 446.51 518 448.83 524.32 455.35 Z" id="244400610"/>
            <path d="M 644.29 105.49 644.48 115.98 639.71 119.66 628.73 120.34 639.8 132.54 638.75 140.95 635.23 145.42 628.86 146.3 625.49 141.31 616.71 143.8 615.2 123.72 609.55 124.95 604.52 117.74 595.21 92.34 595.28 87.81 601.97 85.47 604.37 78.85 612.36 78.88 614.79 75.24 621.77 77.13 624.95 74.77 633.53 74.82 635.89 81.47 630.23 84.94 627.86 91.27 633.47 102 644.29 105.49 Z" id="243500782"/>
            <path d="M 95.65 128.84 98.11 139.63 100.83 142.19 87.67 152.52 81.32 152.79 78.06 149.5 72.14 151.28 76.39 139.6 85.03 132.87 95.65 128.84 Z M 94.31 110.93 100.36 124.15 83.94 131.77 62.44 133.53 53.95 139.39 35.54 143.7 37.11 137.46 34.41 129.64 35.21 119.65 51.62 110.92 55.94 112.95 64.58 108.88 70.06 105.3 75.77 109.97 86.71 107.68 94.31 110.93 Z" id="242900314"/>
            <path d="M 452.66 428.53 452.24 424.94 458.7 417.48 465.55 417 465.54 411.35 474.86 406.83 481 406.23 489.03 412.16 496.61 414.18 503.72 419 506.45 416.77 514.87 420.82 516.89 428.64 526.88 428.9 536.06 424.8 539.38 427.36 549.71 427.09 549.47 441.19 546.73 446.75 543.91 455.75 536.25 456.02 530.86 451.03 524.32 455.35 518 448.83 510.76 446.51 502.54 446.92 495.79 452.14 486.16 452.25 465.1 440.59 457.14 444.56 448.87 442.42 455.32 430.57 452.66 428.53 Z" id="200027027"/>
            <path d="M 754.21 352.33 753.13 360.65 746.99 367.81 745.75 377.73 720.43 369.16 715.76 359.66 700.47 356.38 695.82 357.28 691.6 355.47 693.09 347.87 699.36 339.46 696.23 332.67 684.31 331.65 681.47 324.8 676.79 321.84 679.51 315.82 686.24 311.3 689.49 305.05 689.79 296.94 693.91 296.09 700.65 294.34 702.99 308.49 707.68 312.56 719.9 304.41 732.93 315.19 732.58 322.18 743.54 329.01 736.97 331.92 737.4 338.92 734.23 344.17 738.41 349.49 747.88 346.97 754.21 352.33 Z" id="243500634"/>
            <path d="M 334.54 357.97 335.17 353.18 341.38 347.39 342.88 341.83 355.91 334.68 360.18 336.07 364.45 326.2 375 326.4 378.87 338 385.09 340.7 377.49 348.4 379.21 363.83 385.77 358.3 394.37 362.73 391.2 380.35 381.14 387.09 379.89 393.81 373.14 398.11 372.77 410.83 370.41 413.83 375.68 421.48 368.9 423.86 353.35 415.7 342.87 419.51 335.7 417.35 332.62 429.03 335.14 440.8 328.72 444.45 328.14 432.7 332.74 423.77 332.39 413.84 329.04 404.77 320.97 398.7 318.6 392.75 320.07 390.83 319.52 384.71 325.1 379.38 329.8 381.41 333.52 371.62 334.54 357.97 Z" id="200043123"/>
            <path d="M 654.15 321.16 663.68 316.94 668.93 309.59 671.53 316.36 679.51 315.82 676.79 321.84 681.47 324.8 684.31 331.65 696.23 332.67 699.36 339.46 693.09 347.87 691.6 355.47 695.82 357.28 698.64 365.3 686.84 369.03 684.79 371.75 672.69 372.92 664.82 377.17 659.74 389.25 653.15 393.11 639.28 395.72 630.79 396.36 617.38 392.34 616.3 381.91 622.04 375.33 631.61 371.89 628.61 366.46 636.71 359.51 639.01 349.65 633.05 344.94 631.36 334.63 640.31 329.96 648.2 331.59 656.55 325.85 654.15 321.16 Z" id="200070662"/>
            <path d="M 616.71 143.8 625.49 141.31 628.86 146.3 635.23 145.42 638.75 140.95 641.59 146.92 655.12 142.59 658.31 153.28 664.71 155.5 670.3 151.13 678.64 152.46 670.41 166.69 672.53 177.64 668.3 186.11 677.91 189.25 666.6 191.02 662.94 199.23 657.97 196.89 649.3 196.94 647.71 204.33 642.61 210.82 634.73 204.3 629.53 206.65 613.11 211.6 613.42 203.64 610.08 199.8 602.8 204.46 602.47 203.48 603.79 196.35 601.59 187.45 609.58 186.74 606.29 180.06 609.56 163.12 607.57 154.51 611.91 152.93 612.81 145.07 616.71 143.8 Z" id="243500733"/>
            <path d="M 162.06 269.65 164.91 270.04 169.8 255.74 169.72 251.38 177.99 248.1 190.66 254.82 203.58 249.2 198.75 266.28 210.64 271.68 208.28 279.16 209.32 289.59 206.96 291.35 213.61 310.09 208.28 317.33 209.05 332.89 204.1 335.44 194.86 335.19 187.03 332.17 176.44 319.53 175.27 310.77 166.35 300.8 167.23 295.59 169.35 284.46 161.05 279.82 162.06 269.65 Z" id="242900769"/>
            <path d="M 63.24 224.24 70.28 230.41 70.22 238.24 80.26 245.86 75.22 249.25 74.31 254.04 58.79 259.49 51.11 253.49 40.98 249.33 37.3 252.92 30.8 247.44 9.47 241.62 7.34 230.57 15.76 231.13 18.37 228.58 27.22 229.81 36.01 226.37 42.63 228.44 46.1 225.05 56.08 226.88 63.24 224.24 Z" id="242900629"/>
            <path d="M 344.75 277.32 365.39 277.83 369.84 273.21 382.94 284.12 388.18 285.51 394.18 282.89 398.71 276.4 406.36 277.53 415.73 273.44 418.41 281.42 412.83 284.8 412.01 290.06 417.27 297.13 427.5 295.25 427.01 307.62 429.14 311.61 436.7 311.03 438.63 304.31 445.15 306.65 449.49 312.52 457.1 318.36 450.97 323.43 451.64 330.5 462.56 335.07 459.85 340.14 462.91 353.33 456.52 349.69 450.63 350.68 447.33 354.95 439.53 354.51 436.28 346.86 428.48 351.61 421.03 350.48 415.93 347.11 418.71 337.69 412.25 335.16 401.02 339.95 399.1 343.34 385.09 340.7 378.87 338 375 326.4 364.45 326.2 360.18 336.07 355.91 334.68 353.85 329.16 355.58 322.04 353.35 317.95 355.31 303.68 349.72 297.78 344.79 296.71 340.76 291.36 344.75 277.32 Z" id="200067221"/>
            <path d="M 559.69 108.08 555.97 115.14 566.44 124.2 573.31 126 581.01 118.76 592.38 118.71 597.59 115.42 604.3 125.96 609.55 124.95 615.2 123.72 616.71 143.8 612.81 145.07 611.91 152.93 607.57 154.51 609.56 163.12 606.29 180.06 609.58 186.74 601.59 187.45 603.79 196.35 602.47 203.48 593.96 201.7 590.22 207.07 582.6 202.96 574.27 207.44 570.64 212.75 560.69 211.83 552.67 214.84 552.37 226.9 543.07 226.06 537.16 225.94 534.71 207.24 532.32 200.34 535.02 196.49 533.54 190.3 537.42 186.53 552.38 179.59 551.33 173.13 534.18 173.38 529.83 169.47 537.42 161.01 526.27 149.61 530.58 144.82 525.44 140.65 523.63 128.99 525.69 121.16 521.84 105.59 523.5 99.48 519.39 89.11 513.56 87.1 518.99 82.39 524.31 81.84 530.74 77.43 540.51 79.04 534.19 89.5 532.73 96.93 545.68 86.55 554.13 103.98 559.69 108.08 Z" id="200068989"/>
            <path d="M 78.14 54.71 85.11 47.48 92.7 46.88 103.49 41.15 114.47 46.06 109.59 53.43 114.15 55.11 118.65 56.86 117.61 63.93 122.4 67.16 122.34 78.09 123.83 85.77 121.16 91.04 109.86 97.52 110.92 105.75 105.09 104.23 98.73 97.25 96.38 86.45 90.07 78.53 79.59 72.79 85.85 66.97 80.92 61.8 78.14 54.71 Z" id="242900793"/>
            <path d="M 706.07 146.61 713.72 147.56 718.77 156.15 728.91 158.6 739.98 149.61 741.86 155.55 750.38 156.68 759.88 167.92 765.56 183 757.99 186.87 742.74 184.92 735.77 188.61 734.65 201.8 724.46 203.54 725.22 199.25 708.21 190.68 706.18 187.59 693.21 185.78 689.93 189.8 677.91 189.25 668.3 186.11 672.53 177.64 670.41 166.69 678.64 152.46 689.32 147.15 695.06 148.13 706.07 146.61 Z" id="200070688"/>
            <path d="M 597.59 115.42 592.38 118.71 581.01 118.76 573.31 126 566.44 124.2 555.97 115.14 559.69 108.08 565.32 106.72 565.04 100.09 571.03 98.69 572.21 91.46 587.37 90.88 597.72 108.19 597.59 115.42 Z" id="243500725"/>
            <path d="M 100.59 224.08 106.08 224.52 110.72 228.92 111.24 234.12 105.81 236.53 110.13 243.1 107.53 248.53 98.93 244.08 94.83 254.23 91.53 251.04 80.23 248.45 80.26 245.86 70.22 238.24 70.28 230.41 63.24 224.24 83.91 222.08 96.8 229.28 100.59 224.08 Z" id="242900645"/>
            <path d="M 785.44 221.93 780.13 227.41 773.87 225.53 764.09 226.82 756.33 219.86 751.33 225.46 741.73 222.32 735.81 224.52 731.5 221.88 720.49 220.26 724.09 207.86 710.37 201.18 705.44 200.16 708.21 190.68 725.22 199.25 724.46 203.54 734.65 201.8 735.77 188.61 742.74 184.92 757.99 186.87 765.56 183 759.88 167.92 750.38 156.68 741.86 155.55 739.98 149.61 751.53 142.48 753.19 134.92 760.4 134.05 774.92 136.99 795.71 145.29 793.78 151.67 796.41 158.71 791.67 169.14 792.06 174.56 797.32 186.38 795.32 196.48 798.15 203.38 787.79 215.37 785.44 221.93 Z" id="200072452"/>
            <path d="M 189.12 73.99 189.13 74.12 184.93 78.62 169.05 66.72 162.51 70.43 159.87 65.03 150.57 62.2 144.73 63.64 139.18 71.05 139.16 78.71 135.52 83.3 122.34 78.09 122.4 67.16 117.61 63.93 118.65 56.86 114.15 55.11 118.68 51.26 126.34 51.62 139.56 42.12 153.99 39.86 165.41 43.49 166.23 39.12 180.36 33.2 186.23 34.09 183.33 41.16 184.07 48.61 187.4 53.05 185.94 66.56 189.12 73.99 Z" id="200067072"/>
            <path d="M 513.56 87.1 519.39 89.11 523.5 99.48 521.84 105.59 525.69 121.16 523.63 128.99 525.44 140.65 530.58 144.82 526.27 149.61 537.42 161.01 529.83 169.47 534.18 173.38 551.33 173.13 552.38 179.59 537.42 186.53 533.54 190.3 535.02 196.49 532.32 200.34 534.71 207.24 537.16 225.94 524.77 220.82 521.06 212.03 511.7 207 512.62 199.78 505.81 190.22 500.62 191.08 495.8 186.37 496 178.49 488.73 177.1 482.45 182.61 478.14 191.27 468.77 184.7 462.86 183.92 454.92 187.6 444.56 188.68 439.25 185.17 440.71 168.22 433.1 163.39 440.85 159.7 445.96 151.36 447.92 143.48 455.27 143.9 468.58 131.32 464.26 119.22 469.96 111.81 483.36 97.64 495.2 94.59 499.72 90.78 501.57 82.08 507.96 81.61 513.56 87.1 Z" id="200069391"/>
            <path d="M 401.59 49.99 405.57 56.14 414.38 62.82 414.53 68 407.88 77.4 403.31 89.14 409.19 97.3 415.59 95.82 412.71 110.36 414.6 125.16 404.88 129.53 397.35 143.75 385.07 143.97 377.66 139.78 371.06 139.7 363.68 132.67 359.41 124.01 372 114.02 374.33 101.47 360.26 92.56 357.72 84.84 357.74 74.07 364.24 70.89 369.2 61.38 375.77 59 383.98 64.67 390.28 65.18 399.22 56.15 401.59 49.99 Z" id="200069086"/>
            <path d="M 385.09 340.7 399.1 343.34 401.02 339.95 412.25 335.16 418.71 337.69 415.93 347.11 421.03 350.48 428.48 351.61 436.28 346.86 439.53 354.51 447.33 354.95 450.63 350.68 456.52 349.69 462.91 353.33 467.94 363.08 466.06 371.97 469.47 375.76 460.87 388.92 469.72 393.01 472.94 396.95 455.58 404.13 453.49 416.23 458.7 417.48 452.24 424.94 452.66 428.53 449.44 437.1 444.91 433.35 439.9 441.58 433.84 441.04 429.35 448.2 420.49 444 400.38 448.89 394.35 445.44 393.99 440.24 388.53 432.67 388.47 426.15 396.62 430.26 408.26 429.98 414.46 433.72 419.99 432.43 421.63 424.89 429.92 417.94 430.98 407.3 423.51 413.14 411.52 407.25 404.31 405.56 396.37 406.4 390.3 412.22 383.3 414.26 373.93 407.33 373.14 398.11 379.89 393.81 381.14 387.09 391.2 380.35 394.37 362.73 385.77 358.3 379.21 363.83 377.49 348.4 385.09 340.7 Z" id="200067932"/>
            <path d="M 705.44 200.16 710.37 201.18 724.09 207.86 720.49 220.26 731.5 221.88 735.81 224.52 740.89 231.82 733.16 237.79 728.26 246.28 729.83 249.81 718.06 254.34 710.39 259.77 704.55 255.1 702.47 249.99 693.13 247.81 687.32 250.18 677.46 247.97 673.76 244.36 677.22 238.91 681.56 239.72 687.01 234.1 684.27 228.63 679.4 233.48 680.25 222.43 695.51 216.13 697.61 211.02 704.18 212.69 705.44 200.16 Z" id="243500774"/>
            <path d="M 374.5 163.51 381.35 166.95 384.98 178.31 400.09 181.02 407.21 177.12 417.24 183.14 423.95 197.37 428.82 183.7 439.25 185.17 444.56 188.68 454.92 187.6 462.86 183.92 468.77 184.7 478.14 191.27 482.45 182.61 488.73 177.1 496 178.49 495.8 186.37 500.62 191.08 505.81 190.22 512.62 199.78 511.7 207 521.06 212.03 524.77 220.82 537.16 225.94 543.07 226.06 544.21 229.61 541.99 240.72 529.84 246.27 528.73 249.91 521.27 252.76 509.7 250.81 510.2 246.58 501.49 235.5 493.83 237.91 476.22 239.05 480.15 247.01 471.83 257.15 471.67 264.19 465.18 265.74 461.99 270.39 453.43 276.83 447.49 276.81 444.69 267.6 450.1 255.6 450.21 249.27 446.18 244.47 438.99 250.51 428.13 248.76 423.1 257.52 421.14 243.29 415.49 237.67 408.22 239.01 401.77 234.79 397.03 221.37 388.69 220.07 385.18 227.19 379.54 230.91 369.84 215.91 361.51 213.37 367.08 207.39 366.63 202.88 360.65 200.67 361.37 195.25 358.22 184.39 365.59 180.41 365.18 171.76 374.5 163.51 Z" id="200067460"/>
            <path d="M 610.03 223.61 608.45 229.22 618.89 238.71 612.98 255.1 607.94 260.61 609.08 267.41 614.83 276.11 605.82 278.82 602.85 271.91 586.58 275.75 569.15 270.96 574.39 264.39 567.7 261.62 568.14 257.03 574.06 254.55 579.93 247.59 586.87 246.97 591.34 240.36 595.37 228.61 602.06 224.84 610.03 223.61 Z" id="243500550"/>
            <path d="M 187.26 136.96 211.89 130.62 221.23 126.34 236.38 125.33 247.57 119.38 251.76 122.91 256.81 120.99 261.04 123.94 266.64 129.2 255.29 136.69 252.88 147.21 246.42 149.21 240.57 145.56 235.9 153.01 234.89 176.11 231.64 181.66 224.47 171.35 219.69 172.07 209.35 163.05 201.81 162.9 200.98 167.44 193.51 170.22 189.98 167.32 174.32 172.96 167.16 174.17 158.24 181.22 151.95 178.67 151.79 163.58 156.18 160.07 157.82 148.97 168.97 147.01 176.63 150.07 179.91 143.84 187.26 136.96 Z" id="200067197"/>
            <path d="M 704.55 255.1 710.39 259.77 711.89 268.28 706.13 278.4 712.17 286.93 724.3 293.07 718.07 299.3 719.9 304.41 707.68 312.56 702.99 308.49 700.65 294.34 693.91 296.09 680.85 281.74 686.54 263.76 691.97 260.72 693.14 267.75 703.73 271.96 700.1 264.17 700.39 258.05 704.55 255.1 Z" id="243500659"/>
            <path d="M 365.76 36.93 370.92 34.22 372.48 25.48 378.86 17.7 389.99 18.56 385.41 30.51 388.01 35.68 401.94 38.41 405.61 41.94 401.59 49.99 399.22 56.15 390.28 65.18 383.98 64.67 375.77 59 369.2 61.38 364.24 70.89 357.74 74.07 357.72 84.84 360.26 92.56 374.33 101.47 372 114.02 359.41 124.01 363.68 132.67 371.06 139.7 377.66 139.78 375.7 147.17 374.09 144.67 363.58 144.6 357.53 155.53 356.56 165.24 352.14 164.28 345.29 155.72 330.76 153.98 326.88 151.83 322.97 159.42 312.67 157.67 312.05 169.78 302.94 164.96 297.42 165.38 291.1 161.83 278.28 161.85 270.86 171.09 264.22 172.6 261.74 161.12 263.35 150.95 252.88 147.21 255.29 136.69 266.64 129.2 261.04 123.94 268.7 120.37 280.85 120.62 283.83 117.37 289.96 118.3 296.89 108.59 301.85 106.19 301.33 100.61 306.14 87.78 307.07 80.34 317.21 76.49 317.98 69.79 328.51 62.1 335.55 68.39 339.43 65.57 338.83 56.66 346.62 44.21 349.85 41.6 360.17 42.78 365.76 36.93 Z" id="200067981"/>
            <path d="M 193.51 170.22 194.98 186.64 186.96 207.2 179.98 210.71 176.6 216.7 161.14 218.78 159.1 212.95 150.02 211.37 149.94 218.83 144.95 223.74 133.52 215.51 125.25 219.76 119.9 218.91 111.45 224.87 110.72 228.92 106.08 224.52 100.59 224.08 104.35 215.7 104.01 208.96 95.56 192.66 99.73 186.53 108.23 185.13 113.48 179.87 141.93 185.14 147.18 184.46 151.95 178.67 158.24 181.22 167.16 174.17 174.32 172.96 189.98 167.32 193.51 170.22 Z" id="200067247"/>
            <path d="M 447.49 276.81 453.43 276.83 461.99 270.39 465.18 265.74 471.67 264.19 471.83 257.15 480.15 247.01 476.22 239.05 493.83 237.91 501.49 235.5 510.2 246.58 509.7 250.81 521.27 252.76 528.73 249.91 535.11 258.14 536.1 265.96 552.97 270.01 547.04 277.6 536.74 276.45 528.08 284.39 526.6 295.68 536.19 293.51 541.37 295.91 540.1 302.7 542 308.68 537.92 314.82 534.31 311.94 520.82 313.1 517.54 321.45 508.79 334.66 502.71 337.14 496.47 334.15 492.3 342.27 481.18 334.2 479.92 325.9 474.3 325.15 462.56 335.07 451.64 330.5 450.97 323.43 457.1 318.36 449.49 312.52 445.15 306.65 438.63 304.31 441.27 300.65 451.58 296.93 447.72 286.57 447.49 276.81 Z" id="200066777"/>
            <path d="M 252.88 147.21 263.35 150.95 261.74 161.12 264.22 172.6 270.86 171.09 271.63 182.33 276.79 180.55 283.83 183.59 272.09 193.97 274.32 201.52 274.65 214.18 264.44 215.77 255.81 212.71 244.26 216.45 242.91 205.46 235.42 199.42 225.13 198.3 224.56 194.77 231.64 181.66 234.89 176.11 235.9 153.01 240.57 145.56 246.42 149.21 252.88 147.21 Z" id="242900744"/>
            <path d="M 331.04 232.69 335.88 225.9 346.99 227.11 349.93 215.55 361.51 213.37 369.84 215.91 379.54 230.91 385.18 227.19 388.69 220.07 397.03 221.37 401.77 234.79 408.22 239.01 415.49 237.67 421.14 243.29 423.1 257.52 428.13 248.76 438.99 250.51 446.18 244.47 450.21 249.27 450.1 255.6 444.69 267.6 447.49 276.81 447.72 286.57 451.58 296.93 441.27 300.65 438.63 304.31 436.7 311.03 429.14 311.61 427.01 307.62 427.5 295.25 417.27 297.13 412.01 290.06 412.83 284.8 418.41 281.42 415.73 273.44 406.36 277.53 398.71 276.4 394.18 282.89 388.18 285.51 382.94 284.12 369.84 273.21 365.39 277.83 344.75 277.32 340.32 268.57 336.68 268.14 342.59 255.57 332.06 250.78 329.79 242.76 331.04 232.69 Z" id="245614433"/>
            <path d="M 125.62 165.3 135.02 162.59 140.38 158.04 151.79 163.58 151.95 178.67 147.18 184.46 141.93 185.14 115.02 178.36 111.15 169.51 117.36 166.39 125.62 165.3 Z M 113.48 179.87 108.23 185.13 99.73 186.53 95.56 192.66 87.91 191.59 69.36 180 57.83 180.59 51.06 194.34 46.49 191.16 44.58 178.74 49.29 174.86 47.04 170.54 38.56 170.82 34.13 166.68 38.26 161.66 45.85 161.1 44.01 150.34 51.4 149.65 48.9 159.14 56.28 160.21 59.63 164.19 68.83 161.6 75.2 162.8 78.78 167.88 87.94 168.19 101.33 163.06 105.44 170.4 113.48 179.87 Z" id="200066868"/>
            <path d="M 467.94 363.08 487.85 368.95 496.11 369.85 500.09 374.3 507.21 373.45 513.6 377.9 524.55 374.05 527.51 377.22 525.1 385.95 529.41 391.32 525.05 411.36 521.33 412.22 514.87 420.82 506.45 416.77 503.72 419 496.61 414.18 489.03 412.16 481 406.23 474.86 406.83 465.54 411.35 465.55 417 458.7 417.48 453.49 416.23 455.58 404.13 472.94 396.95 469.72 393.01 460.87 388.92 469.47 375.76 466.06 371.97 467.94 363.08 Z" id="245614383"/>
            <path d="M 365.76 36.93 360.17 42.78 349.85 41.6 346.62 44.21 338.83 56.66 339.43 65.57 335.55 68.39 328.51 62.1 317.98 69.79 317.21 76.49 307.07 80.34 306.14 87.78 301.33 100.61 301.85 106.19 296.89 108.59 289.96 118.3 283.83 117.37 280.85 120.62 268.7 120.37 261.04 123.94 256.81 120.99 256.43 112.91 269.33 102.17 265.36 94.72 260.49 94.37 261.5 85.4 251.47 80.22 248.91 72.31 249.25 59.69 259.27 56.38 266.03 57.62 269.3 53.83 266.68 42.05 273.45 39.19 274.77 33.67 267.95 25.66 272.63 17.38 279.29 16.22 278.54 9.51 289.31 8.65 301 14.17 299.18 19.05 308.34 19.01 310.49 14.38 321.96 11.26 338.89 8.78 341.93 3.42 347.57 1 350.36 11.38 370.4 2.53 375.06 6.04 373.92 20.77 365.76 36.93 Z" id="200065928"/>
            <path d="M 218.19 238.21 222.27 230.5 223.22 223.92 233.82 221.58 244.26 216.45 255.81 212.71 264.44 215.77 274.65 214.18 287.04 218.87 283.02 222.37 286.6 227.26 303.46 221.32 317.62 231.89 324.95 229.27 331.04 232.69 329.79 242.76 332.06 250.78 342.59 255.57 336.68 268.14 330.6 277.87 321.55 283.65 314.9 277.87 305.35 278.64 301.04 281.67 296.76 290.52 287.21 290.83 281.62 285.01 268.95 289.99 266.72 281.42 260.7 280.52 252.82 274.07 235.09 275.45 231.23 264.16 229.92 251.56 224.05 244.62 226.28 239.87 218.19 238.21 Z" id="245614417"/>
            <path d="M 272.45 396.07 263.63 395.29 253.69 387.11 259.68 384.52 274.21 392.26 272.45 396.07 Z M 298.71 358.04 306.37 363.57 303.45 372.53 295.37 369.57 291.02 363.86 298.71 358.04 Z M 287.21 290.83 296.76 290.52 301.04 281.67 305.35 278.64 314.9 277.87 321.55 283.65 330.6 277.87 336.68 268.14 340.32 268.57 344.75 277.32 340.76 291.36 344.79 296.71 349.72 297.78 355.31 303.68 353.35 317.95 355.58 322.04 353.85 329.16 355.91 334.68 342.88 341.83 341.38 347.39 335.17 353.18 324.77 347.03 322.66 341.83 315.61 342.23 306.54 347.4 294.26 358.32 291.14 357.39 286.73 366.37 282 370.58 273.99 368.99 267.73 371.45 258.37 359.23 253.21 347.7 256.52 335.84 252.28 327.08 256.64 321.05 258.62 326.73 263.62 329.81 272.47 323.31 273.08 317.88 282.68 317.71 282.94 301.72 287.87 299.12 287.21 290.83 Z" id="200042174"/>
            <path d="M 626.87 284.04 631.84 285.51 636.72 290.91 637.71 299.05 641.79 311.54 640.47 315.73 648.16 321.05 654.15 321.16 656.55 325.85 648.2 331.59 640.31 329.96 631.36 334.63 633.05 344.94 639.01 349.65 636.71 359.51 628.61 366.46 631.61 371.89 622.04 375.33 616.88 374.56 610.67 365.85 610.26 355.97 604.14 349.68 608.25 344.67 601.71 338.8 595 342.52 587.56 342.94 576.42 349.54 564.17 339.69 560.54 329.87 566.99 326.41 568.97 321.53 564.09 310.67 571.48 313.45 590.54 308.76 595.06 305.46 593.46 292.79 603.5 290.45 612 290.68 620.67 293.68 624.59 292.06 626.87 284.04 Z" id="200043990"/>
            <path d="M 305.22 493.97 302.53 491.55 300.25 481.25 306.72 476.22 316.18 481.89 322.53 482.93 324.86 491.98 334.27 498.89 341.91 502.21 338.96 508.75 325.89 507.11 318.06 502.04 308.14 504.29 301.96 496 305.22 493.97 Z" id="245600465"/>
            <path d="M 298.71 358.04 306.54 347.4 315.61 342.23 322.66 341.83 324.77 347.03 335.17 353.18 334.54 357.97 331.72 365.75 322.1 379.54 320.31 383.31 317.51 385.1 318.86 386.32 317.43 391.1 306.55 379.88 299.93 375.77 300.37 375.51 303.45 372.53 306.37 363.57 298.71 358.04 Z" id="245600440"/>
            <path d="M 541.37 295.91 536.19 293.51 526.6 295.68 528.08 284.39 536.74 276.45 547.04 277.6 552.97 270.01 555.72 267.33 569.15 270.96 586.58 275.75 602.85 271.91 598.57 275.02 599.15 280.88 619.86 275.44 627.79 280.21 626.87 284.04 624.59 292.06 620.67 293.68 612 290.68 603.5 290.45 593.46 292.79 595.06 305.46 590.54 308.76 571.48 313.45 564.09 310.67 556.22 299.86 548 300.08 541.37 295.91 Z" id="243500618"/>
            <path d="M 193.51 170.22 200.98 167.44 201.81 162.9 209.35 163.05 219.69 172.07 224.47 171.35 231.64 181.66 224.56 194.77 225.13 198.3 235.42 199.42 242.91 205.46 244.26 216.45 233.82 221.58 223.22 223.92 222.27 230.5 218.19 238.21 205.19 244.3 203.58 249.2 190.66 254.82 177.99 248.1 185.77 242 182.36 239.54 183.59 228.06 176.75 222.99 176.6 216.7 179.98 210.71 186.96 207.2 194.98 186.64 193.51 170.22 Z" id="242900561"/>
            <path d="M 189.13 74.12 189.12 73.99 186.52 64.37 187.37 56.39 193.74 51.12 199.35 60.67 208.77 66.67 207.5 55.4 208.72 46.53 218.12 39.65 224.17 44.03 234.78 42.48 241.14 49.14 253.8 54.81 249.25 59.69 248.91 72.31 251.47 80.22 261.5 85.4 260.49 94.37 265.36 94.72 269.33 102.17 256.43 112.91 256.81 120.99 251.76 122.91 247.57 119.38 236.38 125.33 221.23 126.34 211.89 130.62 187.26 136.96 183.18 125.1 176.21 118.69 176.22 109.91 179.12 100.06 188.08 87.36 191.37 77.84 189.13 74.12 Z" id="242900835"/>
            <path d="M 602.47 203.48 602.8 204.46 602.06 209.33 612.72 217.41 610.03 223.61 602.06 224.84 595.37 228.61 591.34 240.36 586.87 246.97 579.93 247.59 574.06 254.55 568.14 257.03 567.7 261.62 574.39 264.39 569.15 270.96 555.72 267.33 552.97 270.01 536.1 265.96 535.11 258.14 528.73 249.91 529.84 246.27 541.99 240.72 544.21 229.61 543.07 226.06 552.37 226.9 552.67 214.84 560.69 211.83 570.64 212.75 574.27 207.44 582.6 202.96 590.22 207.07 593.96 201.7 602.47 203.48 Z" id="200038990"/>
            <path d="M 541.37 295.91 548 300.08 556.22 299.86 564.09 310.67 568.97 321.53 566.99 326.41 560.54 329.87 564.17 339.69 576.42 349.54 568.55 356.12 567.31 363.25 558.19 368.66 568.81 375.62 552.25 384.51 544.24 381.46 547.32 371.72 536.59 365.19 534.84 374.67 527.51 377.22 524.55 374.05 513.6 377.9 507.21 373.45 500.09 374.3 496.11 369.85 487.85 368.95 467.94 363.08 462.91 353.33 459.85 340.14 462.56 335.07 474.3 325.15 479.92 325.9 481.18 334.2 492.3 342.27 496.47 334.15 502.71 337.14 508.79 334.66 517.54 321.45 520.82 313.1 534.31 311.94 537.92 314.82 542 308.68 540.1 302.7 541.37 295.91 Z" id="200066785"/>
            <path d="M 110.72 228.92 111.45 224.87 119.9 218.91 125.25 219.76 133.52 215.51 144.95 223.74 149.94 218.83 150.02 211.37 159.1 212.95 161.14 218.78 176.6 216.7 176.75 222.99 183.59 228.06 182.36 239.54 185.77 242 177.99 248.1 169.72 251.38 169.8 255.74 164.91 270.04 162.06 269.65 158.43 267.77 149.69 274.1 145.21 284.23 141.57 284.13 140.16 283.56 139.88 282.58 138.58 282.45 136.31 280.99 136.26 280.57 134.84 280.06 133.22 280.26 133.56 279.38 128.36 283.42 124.02 289.05 124.15 294.03 119.8 294.24 117.88 290.32 115.43 281.7 109.82 277.51 111.27 268.14 106.59 265.51 105.26 251.47 107.53 248.53 110.13 243.1 105.81 236.53 111.24 234.12 110.72 228.92 Z" id="200068120"/>
            <path d="M 203.58 249.2 205.19 244.3 218.19 238.21 226.28 239.87 224.05 244.62 229.92 251.56 231.23 264.16 235.09 275.45 252.82 274.07 260.7 280.52 266.72 281.42 268.95 289.99 281.62 285.01 287.21 290.83 287.87 299.12 282.94 301.72 282.68 317.71 273.08 317.88 272.47 323.31 263.62 329.81 258.62 326.73 256.64 321.05 252.2 326.45 252.95 332.38 255.23 333.6 252.34 347.1 232.29 344.47 228.11 341.29 219.41 340.33 209.84 331.59 208.28 317.33 213.61 310.09 206.96 291.35 209.32 289.59 208.28 279.16 210.64 271.68 198.75 266.28 203.58 249.2 Z" id="242900694"/>
            <path d="M 527.51 377.22 534.84 374.67 536.59 365.19 547.32 371.72 544.24 381.46 552.25 384.51 568.81 375.62 558.19 368.66 567.31 363.25 568.55 356.12 576.42 349.54 587.56 342.94 595 342.52 601.71 338.8 608.25 344.67 604.14 349.68 610.26 355.97 610.67 365.85 616.88 374.56 622.04 375.33 616.3 381.91 617.38 392.34 630.79 396.36 639.28 395.72 644 402.32 638.57 412.28 641.25 424.65 637.7 425.18 634.94 435.7 628.07 434.17 624.33 442.09 616.57 445.3 614.94 450.47 606.09 455.65 589.28 454.81 586.01 444 579.49 442.96 577.57 438.43 571.31 434.06 558.53 431.36 556.75 444.51 546.73 446.75 549.47 441.19 549.71 427.09 539.38 427.36 536.06 424.8 526.88 428.9 516.89 428.64 514.87 420.82 521.33 412.22 525.05 411.36 529.41 391.32 525.1 385.95 527.51 377.22 Z" id="243500741"/>
            <path d="M 414.53 68 425.25 74.62 424.77 93.55 434 98.26 434.54 102.38 444.63 111.18 445.05 116.23 452 127.43 453.36 116.61 464.26 119.22 468.58 131.32 455.27 143.9 447.92 143.48 445.96 151.36 440.85 159.7 433.1 163.39 440.71 168.22 439.25 185.17 428.82 183.7 423.95 197.37 417.24 183.14 407.21 177.12 400.09 181.02 384.98 178.31 381.35 166.95 374.5 163.51 370.16 158.1 375.7 147.17 377.66 139.78 385.07 143.97 397.35 143.75 404.88 129.53 414.6 125.16 412.71 110.36 415.59 95.82 409.19 97.3 403.31 89.14 407.88 77.4 414.53 68 Z" id="200069409"/>
            <path d="M 80.26 245.86 80.23 248.45 91.53 251.04 94.83 254.23 98.93 244.08 107.53 248.53 105.26 251.47 106.59 265.51 111.27 268.14 109.82 277.51 115.43 281.7 107.58 284.22 103.66 293.1 105.95 301.63 97.36 300.95 85.63 289.03 77.3 292.41 72.24 278.24 66.64 267.76 58.79 259.49 74.31 254.04 75.22 249.25 80.26 245.86 Z" id="242900710"/>
            <path d="M 375.7 147.17 370.16 158.1 374.5 163.51 365.18 171.76 365.59 180.41 358.22 184.39 361.37 195.25 360.65 200.67 366.63 202.88 367.08 207.39 361.51 213.37 349.93 215.55 346.99 227.11 335.88 225.9 331.04 232.69 324.95 229.27 317.62 231.89 303.46 221.32 286.6 227.26 283.02 222.37 287.04 218.87 274.65 214.18 274.32 201.52 272.09 193.97 283.83 183.59 276.79 180.55 271.63 182.33 270.86 171.09 278.28 161.85 291.1 161.83 297.42 165.38 302.94 164.96 312.05 169.78 312.67 157.67 322.97 159.42 326.88 151.83 330.76 153.98 345.29 155.72 352.14 164.28 356.56 165.24 357.53 155.53 363.58 144.6 374.09 144.67 375.7 147.17 Z" id="242200715"/>
            <path d="M 115.43 281.7 117.88 290.32 118.9 294.03 121.42 295.23 125.25 294.54 130.28 305.18 118.9 309.07 117.9 300.87 111.56 305.71 115.57 313.72 114.85 322.91 105.79 326 94.76 325.81 82.97 322.29 72.1 321.24 71.73 313.45 78.54 311.01 79.28 304.8 77.3 292.41 85.63 289.03 97.36 300.95 105.95 301.63 103.66 293.1 107.58 284.22 115.43 281.7 Z" id="242900702"/>
            <path d="M 141.57 284.13 145.21 284.23 149.69 274.1 158.43 267.77 162.06 269.65 161.05 279.82 169.35 284.46 167.23 295.59 157.16 301.7 158.45 310.97 145.37 312.92 139.73 306.09 133.08 306.07 125.94 293.97 126.2 286.46 131 284.06 132.97 282.28 133.07 280.69 134.32 281.32 135.41 280.35 136.48 281.87 139.59 282.82 139.88 283.57 141.57 284.13 Z" id="242900660"/>
        </g>
    </svg>
</template>

<script>
    export default {
        props: {
            epciId: {
                default: null
            },
            width: {
                type: [Number, String],
                default: 20
            },
            height: {
                type: [Number, String],
                default: 20
            },
        },
        methods: {
            // Style features
            styleEpci (epciId) {
                // Clean previous selected features
                const featuresSelected = this.$el.getElementsByClassName("selected-features");
                if (featuresSelected.length > 0) {
                    featuresSelected.forEach(feature => {
                        feature.classList.remove("selected-features")
                    });
                };
                // Style selected features
                if(epciId) {
                    this.$el.getElementById(epciId.toString())
                            .classList.add("selected-features");
                }
            }
        },
        mounted () {
            this.styleEpci(this.epciId);
        },
        watch: {
            epciId () {
                this.styleEpci(this.epciId);
            }
        }   
    }
</script>

<style scoped>
    .default-features {
        fill: #F7CF0F;
        stroke: #F7CF0F;
        stroke-width: 1px;
    }
    .selected-features {
        fill: #FF380D;
        stroke: #FF380D;
    }
</style>