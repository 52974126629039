<template>
    <svg xmlns="http://www.w3.org/2000/svg"
        :width="width"
        :height="height"
        viewBox="0 0 100 40"
        role="presentation"
    >
        <!-- Graph increase  -->
        <g id="evoPlus" v-if="iconShow">
        <path
            d="m 26.644481,26.805244 c 1.941382,2.119975 4.146244,3.574083 6.250786,4.594934 0.353881,0.171979 0.337344,0.676893 -0.03087,0.813594 l -5.125204,1.882953 0.0033,0.0088 c -0.319705,0.10914 -0.552318,0.40349 -0.552318,0.759575 0,0.448689 0.363802,0.812492 0.812491,0.812492 0.104731,0 0.20395,-0.02425 0.296555,-0.06063 l 0.0011,0.0044 8.699286,-3.200359 c 0.239228,-0.08819 0.354983,-0.362699 0.24915,-0.596415 l -3.805594,-8.400527 c -0.0011,-0.0044 -0.0033,-0.0077 -0.0055,-0.01213 l -0.01213,-0.02866 -0.0055,0.0022 c -0.131189,-0.271198 -0.40349,-0.463021 -0.726502,-0.463021 -0.447587,0 -0.811389,0.363802 -0.811389,0.812491 0,0.112448 0.02315,0.219384 0.06614,0.317501 h -0.0011 l 2.4485,5.406323 c 0.171979,0.379237 -0.223793,0.757371 -0.59862,0.578777 -2.01745,-0.956911 -4.123094,-2.325028 -5.956437,-4.328146 -1.415522,-1.547814 -2.209272,-2.960028 -2.859707,-4.346887 -0.277813,0.558933 -0.592006,1.12448 -0.971242,1.699949 0.625079,1.180704 1.434263,2.434169 2.634811,3.742755"
            style="fill:#3c3b3a"/>
        <path
            d="M 22.115687,15.938575 C 21.929376,15.69604 21.762909,15.452402 21.543525,15.212072 19.24716,12.659944 15.064536,10.298536 7.2306582,10.311765 c -1.0770754,0 -2.225809,0.04189 -3.4517127,0.136701 -0.4464847,0.03197 -0.7805214,0.422232 -0.746346,0.868716 0.034175,0.446485 0.4233336,0.781624 0.8698183,0.747449 1.1873187,-0.0904 2.2941598,-0.132292 3.3282404,-0.132292 7.5351188,0.01213 11.1312498,2.193838 13.1112168,4.367833 0.395773,0.438768 0.715478,0.88415 1.008725,1.336147 0.06504,-0.152135 0.125677,-0.287735 0.196232,-0.447587 0.173082,-0.395773 0.3627,-0.814697 0.568855,-1.250157"
            style="fill:#3c3b3a"/>
        <path
            d="M 37.24877,7.0165787 C 37.354603,6.7828632 37.23997,6.5094602 36.998518,6.4201633 l -8.697081,-3.199256 -0.0011,0.00331 c -0.0926,-0.037483 -0.191823,-0.060634 -0.298759,-0.060634 -0.447587,0 -0.811389,0.3638024 -0.811389,0.812492 0,0.3549829 0.233715,0.6504345 0.552318,0.7595752 l -0.0033,0.00772 5.122998,1.8840553 c 0.369315,0.1367015 0.388056,0.641615 0.03307,0.8135943 -2.104541,1.0208515 -4.308302,2.4749585 -6.250786,4.5949339 -1.928152,2.102337 -2.849785,4.063562 -3.612667,5.803199 -0.765088,1.750661 -1.376937,3.247763 -2.691035,4.70187 -1.979967,2.173994 -5.576098,4.355706 -13.1101146,4.367833 -1.0340806,0 -2.1420242,-0.04299 -3.3282404,-0.132292 -0.4464847,-0.03417 -0.8367454,0.300964 -0.8698184,0.747449 -0.034175,0.446484 0.2998614,0.83454 0.7474485,0.869818 1.2236989,0.0926 2.3724324,0.136701 3.4506103,0.136701 7.8327756,0.01103 12.0165026,-2.349281 14.3128666,-4.902512 1.530175,-1.690027 2.223604,-3.428562 2.97436,-5.136228 0.754063,-1.715383 1.561043,-3.432971 3.322728,-5.360021 1.834446,-2.004221 3.938988,-3.3723376 5.956437,-4.3281456 0.375929,-0.1774915 0.769497,0.2006425 0.599723,0.5787764 l -2.451807,5.4063232 0.0011,0.0011 c -0.04079,0.09701 -0.06394,0.202848 -0.06394,0.317501 0,0.448689 0.363802,0.811389 0.812492,0.811389 0.32191,0 0.595313,-0.192925 0.7254,-0.463021 l 0.0055,0.0022 0.01323,-0.02866 c 0.0011,-0.0033 0.0033,-0.0077 0.0044,-0.01103 z"
            style="fill:#f9cd03"/>
        </g>


        <!-- Graph decrease  -->
        <g id="evoMoins"  v-if="!iconShow">
        <path
            d="m 26.644481,12.035931 c 1.941382,-2.1199747 4.146244,-3.5740827 6.250786,-4.5949337 0.353881,-0.171979 0.337344,-0.676893 -0.03087,-0.813594 l -5.125204,-1.882953 0.0033,-0.0088 c -0.319705,-0.10914 -0.552318,-0.40349 -0.552318,-0.759575 0,-0.448689 0.363802,-0.812492 0.812491,-0.812492 0.104731,0 0.20395,0.02425 0.296555,0.06063 l 0.0011,-0.0044 8.699286,3.200359 c 0.239228,0.08819 0.354983,0.362699 0.24915,0.596415 l -3.805594,8.4005267 c -0.0011,0.0044 -0.0033,0.0077 -0.0055,0.01213 l -0.01213,0.02866 -0.0055,-0.0022 c -0.131189,0.271198 -0.40349,0.463021 -0.726502,0.463021 -0.447587,0 -0.811389,-0.363802 -0.811389,-0.812491 0,-0.112448 0.02315,-0.219384 0.06614,-0.317501 h -0.0011 l 2.4485,-5.4063227 c 0.171979,-0.379237 -0.223793,-0.757371 -0.59862,-0.578777 -2.01745,0.956911 -4.123094,2.3250277 -5.956437,4.3281457 -1.415522,1.547814 -2.209272,2.960028 -2.859707,4.346887 -0.277813,-0.558933 -0.592006,-1.12448 -0.971242,-1.699949 0.625079,-1.180704 1.434263,-2.434169 2.634811,-3.742755"
            style="fill:#3c3b3a"/>
        <path
            d="m 22.115687,22.9026 c -0.186311,0.242535 -0.352778,0.486173 -0.572162,0.726503 -2.296365,2.552128 -6.478989,4.913536 -14.3128668,4.900307 -1.0770754,0 -2.225809,-0.04189 -3.4517127,-0.136701 -0.4464847,-0.03197 -0.7805214,-0.422232 -0.746346,-0.868716 0.034175,-0.446485 0.4233336,-0.781624 0.8698183,-0.747449 1.1873187,0.0904 2.2941598,0.132292 3.3282404,0.132292 7.5351188,-0.01213 11.1312498,-2.193838 13.1112168,-4.367833 0.395773,-0.438768 0.715478,-0.88415 1.008725,-1.336147 0.06504,0.152135 0.125677,0.287735 0.196232,0.447587 0.173082,0.395773 0.3627,0.814697 0.568855,1.250157"
            style="fill:#3c3b3a" />
        <path
            d="m 37.24877,31.824597 c 0.105833,0.233715 -0.0088,0.507118 -0.250252,0.596415 l -8.697081,3.199256 -0.0011,-0.0033 c -0.0926,0.03748 -0.191823,0.06063 -0.298759,0.06063 -0.447587,0 -0.811389,-0.363802 -0.811389,-0.812492 0,-0.354983 0.233715,-0.650435 0.552318,-0.759575 l -0.0033,-0.0077 5.122998,-1.884056 c 0.369315,-0.136701 0.388056,-0.641615 0.03307,-0.813594 -2.104541,-1.020851 -4.308302,-2.474958 -6.250786,-4.594934 -1.928152,-2.102337 -2.849785,-4.063562 -3.612667,-5.803199 -0.765088,-1.750661 -1.376937,-3.247763 -2.691035,-4.70187 -1.979967,-2.173994 -5.576098,-4.355706 -13.1101146,-4.367833 -1.0340806,0 -2.1420242,0.04299 -3.3282404,0.132292 -0.4464847,0.03417 -0.8367454,-0.300964 -0.8698184,-0.747449 -0.034175,-0.446484 0.2998614,-0.83454 0.7474485,-0.869818 1.2236989,-0.0926 2.3724324,-0.136701 3.4506103,-0.136701 7.8327756,-0.01103 12.0165026,2.349281 14.3128666,4.902512 1.530175,1.690027 2.223604,3.428562 2.97436,5.136228 0.754063,1.715383 1.561043,3.432971 3.322728,5.360021 1.834446,2.004221 3.938988,3.372338 5.956437,4.328146 0.375929,0.177491 0.769497,-0.200643 0.599723,-0.578777 l -2.451807,-5.406323 0.0011,-0.0011 c -0.04079,-0.09701 -0.06394,-0.202848 -0.06394,-0.317501 0,-0.448689 0.363802,-0.811389 0.812492,-0.811389 0.32191,0 0.595313,0.192925 0.7254,0.463021 l 0.0055,-0.0022 0.01323,0.02866 c 0.0011,0.0033 0.0033,0.0077 0.0044,0.01103 z"
            style="fill:#f9cd03"/>
        </g>

        <!-- Text  -->
        <text
            class="textBig"
            id="evoSau"
            x="45"
            y="18"
        >
           {{ nbFermesFormated }}
        </text>
        <text>
            <tspan class="textComments" x="45" y="30">EN 1 AN</tspan>
        </text>

    </svg>
</template>

<script>
    export default {

        props: {
            evoNb: {
                type: Number,
                default: null
            },
            width: {
                type: [Number, String],
                default: 100
            },
            height: {
                type: [Number, String],
                default: 40
            },
        },

        computed: {
            iconShow() {
                return parseFloat(this.evoNb) >= 0 ? true : false;
            },
            nbFermesFormated () {
                if (this.evoNb == null) {
                    return "na"
                } else {
                    return parseFloat(this.evoNb) >= 0 ? `+ ${this.evoNb} %` :
                                                          `${this.evoNb} %`;
                }  
            }
        }

    }
</script>

<style scoped>
    .invisible {
        visibility: hidden;
    }
    .textBig {
        font-size: 14px;
        font-weight: 900;
        text-align: center;
        text-anchor: start;
        fill:#F7CF0F
    }
    .textComments {
        font-size: 8px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        text-anchor: start;
        fill:#313131
    }
</style>