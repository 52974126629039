import Vue from "vue";
import Vuex from "vuex";

// Axios
const axios = require('axios').default;
// Import modules
// import frabdata from "./modules/frabdata";


Vue.use(Vuex);

export const store = new Vuex.Store({
    strict: true,

    // modules: {
    //     frabdata
    // },
    
    state: {
        frabData: [],
        featureId: null,
        featureData: null,
        layer: "EPCI",
        yearSelected: 2019
    },

    getters: {
        // Get FRAB data
        frabData: state => state.frabData,
        // Get FRAB data by scale and date
        frabDataTarget: (state) => (scale, year) => {
            // Data of selected year
            const dataYear = state.frabData.filter(item => item.echelle == scale && item.annee == year);
            // Data of the selected year - 1
            const dataYearPrevious = state.frabData.filter(item => item.echelle == scale && item.annee == year - 1);
            // Add nb fermes and surf bio of the previous year
            // Calculate the evolution of nb fermes and surf bio
            dataYear.forEach(ter => {
                const dataYearPreviousTer = dataYearPrevious.find(x=>x.ter_id == ter.ter_id);
                ter.nb_ferme_yearPrev = dataYearPreviousTer.nb_ferme;
                ter.evoNbFermes = Math.round((100 * (ter.nb_ferme - ter.nb_ferme_yearPrev) / ter.nb_ferme_yearPrev + Number.EPSILON) * 10) / 10;
                ter.surf_bio_yearPrev = dataYearPreviousTer.surf_bio;
                ter.evoSauBio = Math.round((100 * (ter.surf_bio - ter.surf_bio_yearPrev) / ter.surf_bio_yearPrev + Number.EPSILON) * 10) / 10;
            });
            return dataYear
        },
        // List of recorded years
        yearsList: (state) => {
            const yearsList = [... new Set(state.frabData.map(x=>x.annee))];
            yearsList.sort((a, b) => b - a);
            return yearsList
        },
        featureId: state => state.featureId,
        featureData: state => state.featureData,
        layer: state => state.layer,
        yearSelected: state => state.yearSelected
    },

    mutations: {
        SET_FRABDATA (state, frabData) {
            state.frabData = frabData;
        },
        SET_FEATUREID (state, value) {
            state.featureId = value;
        },
        SET_FEATUREDATA (state, value) {
            state.featureData = value;
        },
        SET_LAYER (state, value) {
            state.layer = value;
        },
        SET_YEARSELECTED(state, value) {
            state.yearSelected = value;
        }
    },

    actions: {
        // Load drivers
        async loadFrabData({commit}) {
            try {
                // Request data from FRAB database
                const farmDataUrl = "https://observatoire.agrobio-bretagne.org/vapi/vues/frab_bretagne/api_chiffre_territoire/?token=3868fc7b9737ebd6b6f553ec96a19922";
                const { data } = await axios.get(farmDataUrl);
                // Extract target data
                const frabData = data.rows[0];

                // Cast data and Add evolution
                for (let i=0; i < frabData.length; i++) {
                    // Cast data
                    frabData[i].surf_pourcent = parseFloat(frabData[i].surf_pourcent);
                    frabData[i].nb_cess = parseInt(frabData[i].nb_cess);
                    frabData[i].commune_pourcent = parseFloat(frabData[i].commune_pourcent);
                    frabData[i].ferme_pourcent = parseFloat(frabData[i].ferme_pourcent);
                    frabData[i].nb_nx = parseInt(frabData[i].nb_nx);
                    frabData[i].nb_cetis = parseInt(frabData[i].nb_cetis);
                    frabData[i].surf_ab = parseFloat(frabData[i].surf_ab);
                    frabData[i].surf_bio = parseFloat(frabData[i].surf_bio);
                    frabData[i].surf_conv = parseFloat(frabData[i].surf_conv);
                    frabData[i].nb_ferme = parseInt(frabData[i].nb_ferme);

                    // Add evolution
                    const idx = frabData.findIndex(x => 
                        x.echelle == frabData[i].echelle &&       
                        x.ter_id == frabData[i].ter_id && 
                        x.annee == frabData[i].annee - 1
                    );
                    // If there is data for the previous year
                    if (idx != -1) {
                        frabData[i].nb_ferme_yearPrev = frabData[idx].nb_ferme;
                        frabData[i].evoNbFermes = Math.round((100 * (frabData[i].nb_ferme - frabData[i].nb_ferme_yearPrev) / frabData[i].nb_ferme_yearPrev + Number.EPSILON) * 10) / 10;
                        // If data of the previous year and the current year in not under
                        // statistical secret
                        frabData[i].surf_bio_yearPrev =  frabData[idx].surf_bio;
                        if (frabData[i].surf_bio != -1 && frabData[idx].surf_bio != -1) {
                            frabData[i].evoSauBio = Math.round((100 * (frabData[i].surf_bio - frabData[i].surf_bio_yearPrev) / frabData[i].surf_bio_yearPrev + Number.EPSILON) * 10) / 10;
                        } else {
                            frabData[i].evoSauBio = -1;
                        };
                    };
                    
                };
                // Set drivers
                commit("SET_FRABDATA", frabData);


            } catch (error) {
                console.error("Error while loading FRAB data:\n", error)
            }
        },
        setFeatureId ({commit}, value) {
            commit("SET_FEATUREID", value);
        },
        setFeatureData({commit}, value) {
            commit("SET_FEATUREDATA", value);
        },
        setLayer ({commit}, value) {
            // Set Feature Id to null after change
            commit("SET_FEATUREID", null);
            // Set layer id
            commit("SET_LAYER", value);
        },
        setYearSelected({commit}, value){
            commit("SET_YEARSELECTED", value)
        }
    }
});