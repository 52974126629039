<template>
    <div class="map-container">

        <!-- Map div  -->
        <div class="map" ref="map-root"></div>

        <!-- Basemap switch -->
        <div class="basemap-container">
            <!-- Button  -->
            <b-button 
                @click="baseMapSwitchShow = !baseMapSwitchShow"
                class="basemap-btn"
            >
                <i class="fas fa-layer-group"></i>
            </b-button>
            <!-- Input select  -->
            <b-form-select 
                :options="baseMaps" 
                v-model="baseMap"
                @change="baseMapLayersChange(baseMap)"
                v-if="baseMapSwitchShow"
                class="basemap-switch"
            ></b-form-select>
        </div>

        
        <!-- Map controls -->
        <div class="map-controls">
            <!-- Title  -->
            <h5>Echelle administrative</h5>
            <!-- Layers switch  -->
            <b-form-select 
                :options="layers"
                :value="layer"
                @change="setLayer($event)"
            ></b-form-select>
            <!-- Ferme Layer  -->
            <b-form-checkbox
                v-model="fermesBioShow"
                @change="fermesBioLayer.setVisible($event)"
                class="mt-3"
            >
                Fermes Bio
            </b-form-checkbox>
            <ul v-if="fermesBioShow" class="legend-fermes">
                <li>
                    <svg width="18" height="18"><circle cx="8" cy="8" r="8" fill="#c2a5cf"/></svg>
                    Groupe de fermes
                </li>
                <li>
                    <svg width="18" height="18"><rect width="15" height="15" fill="#14ad87"/></svg>
                    Bio (ou conversion)
                </li>
                <li>
                    <svg width="18" height="18"><rect width="15" height="15" fill="#f5c701"/></svg>
                    Projet installation
                </li>
                <li>
                    <svg width="18" height="18"><rect width="15" height="15" fill="#e8541d"/></svg>
                    Projet conversion
                </li>
            </ul>
            <!-- RPG layer  -->
            <b-form-checkbox
                v-model="rpgLayerShow"
                @change="rpgLayer.setVisible($event)"
            >
                Parcelles Bio
            </b-form-checkbox>
            <p 
                v-if="(mapZoom < 10) && rpgLayerShow"
                class="font-italic"
            > 
                Zoomer pour afficher<br>les parcelles
            </p>
        
        
        </div>

        <!-- Fermes bio checkbox -->
        <div class="farms-check">

        </div>

    </div>
</template>

<script>

    // Openlayers
    import 'ol/ol.css';
    import {Map, View} from 'ol';
    import TopoJSON from 'ol/format/TopoJSON';
    import GeoJSON from 'ol/format/GeoJSON';
    import Feature from 'ol/Feature';
    import {Circle as CircleStyle, RegularShape, Fill, Stroke, Style, Text} from 'ol/style';
    import {Tile as TileLayer, Vector as VectorLayer, VectorTile as VectorTileLayer} from 'ol/layer';
    import {Vector as VectorSource, XYZ, Cluster} from "ol/source"
    import VectorTileSource from 'ol/source/VectorTile';
    import {fromLonLat} from "ol/proj";
    import Projection from 'ol/proj/Projection';
    import {FullScreen, defaults as defaultControls, ScaleLine} from "ol/control.js";
    import {inAndOut} from 'ol/easing';
    // geojson-vt
    import geojsonvt from 'geojson-vt';
    // topojson-client
    import * as topojson from "topojson-client";
    // Axios
    const axios = require('axios').default;
    // Color
    const Color = require('color'); 
    // Vuex
    import { mapGetters, mapActions } from 'vuex';


    export default {

        name: "map-interactive",
        
        data () {
            return {
                mainMap: null,
                departementsLayer: null,
                departementsShow: true,
                fermesBioLayer: null,
                fermesBioShow: true,
                rpgLayer: null,
                rpgLayerShow: true,

                // List of administrative vector layers
                layers: [
                    {
                        value: "Région", 
                        text:  "Région",
                        file:  "region_bzh_wgs84_simplify_topo.json"
                    },
                    {
                        value: "Bassin Versant", 
                        text:  "Bassins versants",
                        file:  "bv_bzh_wgs84_simplify_topo.json"
                    },
                    {
                        value: "département", 
                        text:  "Départements",
                        file:  "departement_bzh_wgs84_simplify_topo.json"
                    },
                    {
                        value: "EPCI", 
                        text:  "EPCI",
                        file:  "epci_bzh_wgs84_simplify_topo.json"
                    },
                    {
                        value: "Commune", 
                        text:  "Communes",
                        file:  "commune_bzh_wgs84_simplify_topo.json"
                    }
                ],

                // BaseMap
                baseMaps:[ 
                    {value: "osmFr", text: "Carte Topo (OSM)"},
                    {value: "EsriWorldTopo", text: "Carte Topo (ESRI)"},
                    {value: "EsriWorldImagery", text: "Esri Imagery"},
                ],
                baseMapLayers: null,
                baseMap: "osmFr",
                baseMapSwitchShow: false,
                featureSelected: null,
                featureOverlay: null
            }
        },

        async mounted() {
            await this.initiateMap();
        },

        computed: {
            ...mapGetters([
                "featureId",
                "featureData",
                "layer"
            ]),
            mapZoom () {
                if(this.mainMap) {
                    return this.mainMap.getView().getZoom() 
                } 
            }
        },

        methods: {
            ...mapActions([
                "setFeatureId",
                "setFeatureData",
                "setLayer"
            ]),

            async initiateMap () {

                /*----------  Base maps  ---------*/

                // ESRI basemap
                // Topo
                const EsriWorldTopo = new TileLayer({
                    source: new XYZ({
                        attributions: "Tiles © <a href='https://services.arcgisonline.com/ArcGIS/" +
                            "rest/services/World_Topo_Map/MapServer'>ArcGIS</a>",
                        url: "https://server.arcgisonline.com/ArcGIS/rest/services/" +
                            "World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                    }),
                    preload: Infinity,
                    zIndex: 0,
                    layerLabel: "EsriWorldTopo"
                });


                // Imagery
                const EsriWorldImagery = new TileLayer({
                    source: new XYZ({
                        attributions: "Tiles &copy; Esri &mdash; Source: Esri, i-cubed," + 
                                    " USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN," + 
                                    " IGP, UPR-EGP, and the GIS User Community",
                        url: "https://server.arcgisonline.com/ArcGIS/rest/services/" +
                            "World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    }),
                    preload: Infinity,
                    zIndex: 0,
                    layerLabel: "EsriWorldImagery"
                });


                // OpenStreetMap
                // OSM France
                const osmFr = new TileLayer({
                    source: new XYZ({
                        attributions: "&copy; Openstreetmap France | &copy; " + 
                                    "<a href='https://www.openstreetmap.org/copyright'>" + 
                                    "OpenStreetMap</a> contributors",
                        url: "https://{a-c}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png"

                    }),
                    preload: Infinity,
                    zIndex: 0,
                    layerLabel: "osmFr"
                });


                // List of base maps layers
                this.baseMapLayers = [EsriWorldTopo, osmFr, EsriWorldImagery];


                /*----------  Ol map setup  ----------*/

                // Center of the map (convert from longLat to Mercator)
                const centerLonLat = [-2.5, 48.2];
                const centerWebMercator = fromLonLat(centerLonLat);

                // View for the main map
                const view = new View({ 
                    center: centerWebMercator, 
                    zoom: 8,
                    constrainResolution: true,
                    minZoom: 8,
                    maxZoom: 15
                });

                //  Additional controls
                const scaleLineControl = new ScaleLine();
                const fullScreen = new FullScreen();

                // Map
                this.mainMap = new Map({ 
                    layers: this.baseMapLayers, 
                    target: this.$refs['map-root'], 
                    view: view,
                    controls: defaultControls().extend([
                        scaleLineControl,
                        // fullScreen
                    ])
                }); 

                // Set basemap visibility
                this.baseMapLayersChange (this.baseMap);

            


                /*==================================
                =            Fermes bio            =
                ===================================*/
                

                // Farms URL
                const fermesBioUrl = "https://observatoire.agrobio-bretagne.org/vapi/vues/frab_bretagne/api_test/?token=8cb4c097-3a94-473e-9fba-7c77c156f39bu";

                const { data } = await axios.get(fermesBioUrl);
                // Extract GeoJSON
                const fermesBioGeojson = data.rows[0][0].objet_geojson;
                
                // Create farm source
                const fermesBioSource = new VectorSource({
                     features: new GeoJSON().readFeatures(fermesBioGeojson, {
                        featureProjection: "EPSG:3857"
                    }),
                });

                
                // Create cluster source
                const fermesBioClusterSource = new Cluster({
                    distance: 25,
                    source: fermesBioSource,
                });

                // Color according to the type of farms
                // "Bio (ou conversion)", "Projet installation", "Projet conversion"
                const farmsColor = (type) => {
                    return type == "Bio (ou conversion)" ? "#14ad87" :
                           type == "Projet installation" ? "#f5c701" : 
                           type == "Projet conversion"   ? "#e8541d" :
                           "#636363"     
                };

                // Style farm cluster
                let styleCache = {};
                const fermesBioClusterStyle = (feature) => {
                    let size = feature.get('features').length;
                    const type = size == 1 ? feature.get('features')[0].values_.act_bio : "";
                    const size_type = `${size}_${type}`;
                    let style = styleCache[size_type];
                    if (!style) {
                        // Style for single point
                        if (size == 1) {
                            style = new Style({
                                image: new RegularShape({
                                    radius: 8,
                                    points: 4,
                                    angle: Math.PI / 4,
                                    stroke: new Stroke({
                                        color: Color(farmsColor(type)).darken(0.5),
                                    }),
                                    fill: new Fill({
                                        color: farmsColor(type),
                                    }),
                                }),
                            });
                        // Style for cluster
                        } else {
                            style = new Style({
                                image: new CircleStyle({
                                    radius: 8 + (size < 10 ? 2 : size < 20 ? 4 : 6),
                                stroke: new Stroke({
                                    color: "#7b3294",
                                }),
                                fill: new Fill({
                                    color: "#c2a5cf",
                                }),
                                }),
                                text: new Text({
                                    text: size.toString(),
                                    font: "Panton",
                                    fill: new Fill({
                                        color: "white",
                                    }),
                                    offsetY: 0.5
                                }),
                            });
                        }
                        styleCache[size_type] = style;
                    }
                    return style;
                };

                this.fermesBioLayer = new VectorLayer({
                    source: fermesBioClusterSource,
                    style: fermesBioClusterStyle,
                    visible: this.fermesBioShow,
                    zIndex: 2
                });
                
                this.mainMap.addLayer(this.fermesBioLayer);


                /*=====  End of Fermes bio  ======*/
                
                



                /*===============================
                =            RPG Bio            =
                ================================*/


                // Style for RPG
                const rpgStyle = new Style({
                    fill: new Fill({
                        color: "#dfc27d",
                    }),
                    stroke: new Stroke({
                        color: "#a6611a",
                        width: 1,
                    }),
                });

                // Converts geojson-vt data to GeoJSON
                const replacer = (key, value) => {
                    if (value.geometry) {
                        var type;
                        var rawType = value.type;
                        var geometry = value.geometry;

                        if (rawType === 1) {
                            type = 'MultiPoint';
                            if (geometry.length == 1) {
                                type = 'Point';
                                geometry = geometry[0];
                            }
                            } else if (rawType === 2) {
                            type = 'MultiLineString';
                            if (geometry.length == 1) {
                                type = 'LineString';
                                geometry = geometry[0];
                            }
                            } else if (rawType === 3) {
                            type = 'Polygon';
                            if (geometry.length > 1) {
                                type = 'MultiPolygon';
                                geometry = [geometry];
                            }
                            }

                            return {
                                'type': 'Feature',
                                'geometry': {
                                    'type': type,
                                    'coordinates': geometry,
                                },
                                'properties': value.tags,
                            };
                        } else {
                            return value;
                        }
                };


                const url = "data/rpg_bio_bzh_topo.json";
                fetch(url)
                    .then(resp => resp.json())
                    .then(json => {
                        // Convert of topojson to GeoJSON
                        const geojson = topojson.feature(json, json.objects["rpg_bio_bzh"]);
                        const tileIndex = geojsonvt(geojson, {
                            extent: 4096,
                            debug: 0,
                        });
                        const format = new GeoJSON({
                            // Data returned from geojson-vt is in tile pixel units
                            dataProjection: new Projection({
                                code: 'TILE_PIXELS',
                                units: 'tile-pixels',
                                extent: [0, 0, 4096, 4096],
                            }),
                        });
                        const rpgSource = new VectorTileSource({
                        tileUrlFunction: (tileCoord) => {
                            // Use the tile coordinate as a pseudo URL for caching purposes
                            return JSON.stringify(tileCoord);
                        },
                        tileLoadFunction: (tile, url) => {
                            const tileCoord = JSON.parse(url);
                            const data = tileIndex.getTile(
                                tileCoord[0],
                                tileCoord[1],
                                tileCoord[2]
                            );
                            const geojson = JSON.stringify(
                                {
                                    type: 'FeatureCollection',
                                    features: data ? data.features : [],
                                },
                                replacer
                            );

                            const features = format.readFeatures(geojson, {
                                extent: rpgSource.getTileGrid().getTileCoordExtent(tileCoord),
                                featureProjection: "EPSG:3857",
                            });

                            tile.setFeatures(features);
                        },
                    });
                    this.rpgLayer = new VectorTileLayer({
                        source: rpgSource,
                        style: rpgStyle,
                        visible: this.rpgShow,
                        minZoom: 10,
                        zIndex: 1,
                    });
                    this.mainMap.addLayer(this.rpgLayer);
                });


                /*=====  End of RPG Bio  ======*/




                /*=======================================
                =            Thematic layers            =
                =======================================*/


                /*----------  OL Vector Style  ----------*/


                // Fill color according to feature prperties
                const getColor = (feature) => {
                    if (feature.get("actions")) {
                        return "rgba(242, 217, 116, 0.4)";
                    } else {
                        return "rgba(255, 255, 255, 0.2)";
                    };
                };

                // Default style function of feature properties
                const defaultStyle = (feature) => {
                    return new Style({
                        fill: new Fill({
                            color: getColor(feature),
                        }),
                        stroke: new Stroke({
                            color: "rgba(90, 90, 90, 0.8)",
                            width: 2,
                        }),
                        text: new Text({
                            text: feature.get("nom"),
                            font: "12px 'Segoe UI',sans-serif",
                            fill: new Fill({
                                color: '#000',
                            }),
                            stroke: new Stroke({
                                color: "rgba(255, 255, 255, 0.5)",
                                width: 2.5,
                            }),
                        }),
                    });
                };

                // Style for selected territory
                const featureSelectStyle = new Style({
                    stroke: new Stroke({
                        color: "rgba(204, 93, 36, 0.9)",
                        width: 5
                    })
                });


                /*----------  Add vector  ----------*/
                
                // Create vector layers for all entries of layer list
                for (const layer of this.layers) {
                    // layers vector
                    layer.vectorLayer = new VectorLayer({
                        source: new VectorSource({
                            url: `data/${layer.file}`,
                            format: new TopoJSON(),
                            overlaps: false
                        }),
                        style: defaultStyle,
                        layerType: "admin"
                    });
                };


                // Create vector layer for feature selected
                this.featureOverlay = new VectorLayer({
                    source: new VectorSource(),
                    map: this.mainMap,
                    style: featureSelectStyle
                });

                // Set layer to map
                this.layerVectorChange();



                /*----------  Map interactions  ----------*/
 

                const displayFeatureInfo = (pixel) => {

                    // Get features of admin layer at click coordinates (pixel)
                    const features = this.mainMap.getFeaturesAtPixel(pixel, {
                        layerFilter: layer => layer.get("layerType") == "admin" ? true : false
                    });

                    // Extract the first feature if exists
                    const feature = features.length ? features[0] : undefined;

                    if (feature) {
                        // Store feature id
                        this.setFeatureId(feature.get("id"));

                        // Style select feature
                        if (feature !== this.featureSelected) {
                            if (this.featureSelected) {
                                this.featureOverlay.getSource().clear();
                            };
                            this.featureOverlay.getSource().addFeature(feature);
                            this.featureSelected = feature;
                        };

                        // Fit view to selected feature
                        view.fit(feature.getGeometry(), {
                            padding: [80, 20, 20, 100],
                            duration: 400,
                            easing: inAndOut
                        });

                    } else {
                        this.setFeatureId(null);
                        this.setFeatureData(null);
                        this.featureOverlay.getSource().clear();
                    };



                };

                // Add event to map
                this.mainMap.on("click", (evt) => {
                    displayFeatureInfo(evt.pixel);
                });

            },

            // Base map switch
            baseMapLayersChange (value) {
                this.baseMapLayers.forEach(layer => {
                    layer.setVisible(layer.get("layerLabel") == value)
                });
                this.baseMapSwitchShow = false;
            },

            // Bind swith and vector map
            layerVectorChange () {
                for (const layer of this.layers) {
                    if (layer.value == this.layer) {
                        this.mainMap.addLayer(layer.vectorLayer);
                    } else {
                        this.mainMap.removeLayer(layer.vectorLayer);
                    }
                };
                this.featureOverlay.getSource().clear();
            }

        },
        watch: {
            // Change vector layer on layer update
            layer () {
                this.layerVectorChange ()
            }
        }
    }
</script>

<style scoped>


</style>